import React from 'react';
import { TableRowType } from '../../../components/Table/TableRows';
import { ReactComponent as DownloadIcon } from './Icons/Download.svg';

interface IFileListingRowProps
{
	data: IFileListingRowItem[];
}

export interface IFileListingRowItem
{
	Id: string; // File Id
	JobId: string;
	FileName: string;
	UploadedDate: string;
	Status: string;
	UserName: string;

	downloadFile?: (jobId: string) => void;
}

export const FileListingRows = (props: IFileListingRowProps): TableRowType<IFileListingRowItem>[]  =>
{
	const { data } = props;

	return data.map((item: IFileListingRowItem, index) =>
	{
		const keyPrefix = `fileListing-cell-${item.JobId}-${index}`;

		return {
			data: item,
			items: [
				<div
					key={`${keyPrefix}-fileName`}
					className={`${keyPrefix}-fileName`}
					style={{ 
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap'
					}}
				>
					{item.FileName}
				</div>,
				<div
					key={`${keyPrefix}-uploadedDate`}
					className={`${keyPrefix}-uploadedDate`}
					style={{ 
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						paddingLeft: 10,
						paddingRight: 10,
						textAlign: 'center',
						display: 'grid',
					}}
				>
					<span>{new Date(item.UploadedDate).toLocaleDateString()}</span>
					<span>{new Date(item.UploadedDate).toLocaleTimeString()}</span>
				</div>,
				<div 
					key={`${keyPrefix}-status`}
					className={`${keyPrefix}-status`}
					style={{ 
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textAlign: 'center'
					}}
				>
					{item.Status}
				</div>,
				<div 
					key={`${keyPrefix}-uploadedby`}
					className={`${keyPrefix}-uploadedby`}
					style={{ 
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textAlign: 'center'
					}}
				>
					{item.UserName}
				</div>,
				<div
					key={`${keyPrefix}-downloadIcon`}
					className={`${keyPrefix}-downloadIcon`}
					style={{
						textAlign: 'right'
					}}
				>
					{
						item.Status.toLowerCase() !== 'created' ?
							<DownloadIcon style={{ cursor: 'pointer' }} onClick={() => item.downloadFile(item.JobId)} />
							:
							<></>
					}
				</div>,
			]
		};
	});
};