import { v4 as uuidv4 } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductGamePlanDetails, ProductPlanCropType } from '../../Models/Responses/ProductGamePlan';
import { orderBy } from 'lodash';
import { getSeedsForGrower, ISeedResponseWithGrowerZip, ISeries, ISeriesWithHybrid } from '../Seeds/SeedsActions';
import { IProductLine } from '../../Models/Requests/ProductPlanRequest';
import { IHybridResponse } from '../../Models/Responses/SeedResponse';
import { createProductGamePlan, getFieldlessSeedAllocations, getFieldlessSeedRankings, getProductGamePlan, getTreatmentPricing, ITreatmentDictionary } from './ProductGamePlanActions';
import { createLocalEditingState, getUntreated, newPlanDummyId, getDefaultTreatment, updateProductData, simpleUpdateYieldTarget } from './ProductPlanUtils';
import { saveProductPlan, updateProduct, updateYieldTarget } from './ProductGamePlanUpdateActions';
import { RootState } from '../Store';
import { downloadProductPlan } from './downloadProductPlan';
import { setSelectedGrowerId } from '../UI/UISlice';
import { ITreatment } from '../../../pages/FieldActivities/ProductGamePlan/Editor/SeriesProductRow';
import { cornId, CropConfig, soyId } from '../Seeds/CropsSlice';
import { ICustomTreatmentResponse } from '../../Models/Responses/CustomTreatmentResponse';
import { IFieldlessAllocationResponse, IFieldlessRankingSeed } from '../../Models/Responses/FieldlessSeedRankingResponse';
import { ISeriesYields } from '../Seeds/PlantingRateSlice';
import { IGrowerResponse } from '../../Models/Responses/GrowerResponse';
import { RoundAcres } from '../../Utility/CalculationUtilities';

export interface IGrowerYearIdentifier 
{
	userId: string;
	growerId: string;
	year: string;
}
export type TreatmentProduct = Pick<IProductLine, 'AppliedArea' | 'Quantity'> & { treatment: ITreatment; }

export interface IRecommendationOptions
{
	acres: number;
	irrigated: boolean;
	isFilterApplied: boolean;
	fungicide: boolean;
	performance: number;
	rmMax: number;
	rmMin: number;
	seedsRequired: number;
	selectedTraits: string[];
	targetYield: number;
	topEndYield: number;
	zip: string;
}

export interface IPlanEditState
{
	recommendationOptions : {
		[cropIdAndBrandApplication: string]: IRecommendationOptions
	},
	series: {
		[seriesId: string]: {
			cropId: string;
			cropType: ProductPlanCropType;
			series: ISeriesWithHybrid;
		};
	};
	products: {
		[key: string]: {
			key: string;
			cropId: string;
			cropType: ProductPlanCropType;
			seriesId: string;
			hybridId: string;
			product: IProductLine;
		};
	};
}

export interface IProductGamePlanWithEdits
{
	originalData?: IProductGamePlanDetails;
	localEditingState?: IPlanEditState;
}

export interface IProductGamePlanState
{
	isError: boolean;
	isLoadingCreateProductPlan: boolean;
	isLoadingGetProductPlan: boolean;
	isSavingProductPlan: boolean;
	isDownloadingProductPlan: boolean;
	isTreatmentsLoading: boolean;
	isFieldlessLoading: boolean;
	fieldlessRecommendations: IFieldlessAllocationResponse;
	fieldlessRankings: { [key: string]: IFieldlessRankingSeed[] };
	treatments: Partial<ITreatmentDictionary>;
	errorMessage: string;
	hybridLookup: { [key: string]: IHybridResponse; };
	seriesLookup: { [key: string]: ISeriesWithHybrid; };
	recommendationOptions: { [key: string]: IRecommendationOptions; };
	productGamePlans: {
		[key: string]: IProductGamePlanWithEdits
	};
	selectedGamePlanId: string | undefined;
	isDoneModifyingSoyProducts: boolean;
}

export const initialState: IProductGamePlanState = {
	isError: false,
	isLoadingCreateProductPlan: false,
	isLoadingGetProductPlan: false,
	isSavingProductPlan: false,
	isTreatmentsLoading: false,
	isFieldlessLoading: false,
	isDownloadingProductPlan: false,
	errorMessage: undefined,
	treatments: {},
	productGamePlans: {},
	fieldlessRecommendations: undefined,
	fieldlessRankings: {},
	selectedGamePlanId: undefined,
	hybridLookup: undefined,
	seriesLookup: undefined,
	recommendationOptions: undefined,
	isDoneModifyingSoyProducts: false
};

export const productGamePlanSlice = createSlice({
	name: 'productGamePlan',
	initialState,
	reducers: {
		migrateSoyProductEntries: (state) =>
		{
			/**
			 * Need to convert the series on existing plans to contain treatments for soy
			 * series without any prior products will default to untreated
			 */

			// get plan ids with soy products on them
			const soyPlanKeys = Object.keys(state.productGamePlans).filter(planId => 
			{
				// only get the plans that have soy products on them
				const plan = state.productGamePlans[planId];
				const hasSoyProduct = Object.keys(plan.localEditingState.products)
					.some(productId => plan.localEditingState.products[productId].cropId === soyId);

				return hasSoyProduct;
			});

			for (const planId of soyPlanKeys)
			{
				// for each plan with a soy product
				const plan = state.productGamePlans[planId];

				// on each soy product that doesn't have a treatment
				const soyProductIds = Object.keys(plan.localEditingState.products)
					.filter(productId =>
					{
						const product = plan.localEditingState.products[productId];

						// check that it's soy and there is no treatment 
						// AND product id doesn't include treatment name already
						return product.cropId === soyId && (!product.product.Treatment ||
							!productId.includes(product.product.Treatment.Name));
					});

				// collect existing keys to delete
				const idsToDelete = [...soyProductIds];

				// change the key of soy products in the store from hybrid id to hybrid id + treatment name
				// add default untreated to treatment prop
				const replacedSoyProducts = soyProductIds.reduce((migratedSoyProducts, soyProductId) =>
				{
					// Generate a unique id for this product line
					const productKey = uuidv4();

					plan.localEditingState.products[soyProductId].product = {
						...plan.localEditingState.products[soyProductId].product,
						Treatment: getUntreated()
					};
					// get product data and append treatment
					migratedSoyProducts[productKey] = plan.localEditingState.products[soyProductId];
					return migratedSoyProducts;
				}, {});

				// delete old keys off of products
				idsToDelete.forEach(id =>
				{
					delete state.productGamePlans[planId].localEditingState.products[id];
				});

				// spread new product keys containing default treatment
				state.productGamePlans[planId].localEditingState.products = {
					...state.productGamePlans[planId].localEditingState.products,
					...replacedSoyProducts
				};
			}

			state.isDoneModifyingSoyProducts = true;

			return state;
		},
		selectProductGamePlanId: (state, { payload }: PayloadAction<string | undefined>) => 
		{
			state.selectedGamePlanId = payload;
		},
		clearSelectedProductPlanId: (state) =>
		{
			state.selectedGamePlanId = undefined;
			state.productGamePlans[newPlanDummyId] = undefined;
			state.productGamePlans = {};
		},
		clearState: (state) => 
		{
			state.selectedGamePlanId = undefined;
			state.isError = false;
			state.isLoadingCreateProductPlan = false;
			state.isLoadingGetProductPlan = false;
			state.isSavingProductPlan = false;
			state.isDownloadingProductPlan = false;
			state.errorMessage = undefined;
			state.productGamePlans = {};

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		},
		clearRankings: (state) =>
		{
			state.fieldlessRankings = {};
			return state;
		},
		/**
		 * Given a specific series, add it (and by assumption the initial first hybrid) to the plan's editing state 
		 */
		addSeriesToEditingState: (state, { payload }: PayloadAction<{
			customTreatments?: ICustomTreatmentResponse[];
			defaultTreatmentName?: string;
			planId: string;
			series: ISeriesWithHybrid;
			cropId: string;
			cropType: ProductPlanCropType;
			appliedArea?: number;
			yieldTarget?: number;
			plantingRates?: ISeriesYields;
			selectedGrower?: IGrowerResponse;
		}>) => 
		{
			const { 
				appliedArea,
				cropId,
				cropType,
				customTreatments,
				defaultTreatmentName,
				planId,
				plantingRates,
				selectedGrower,
				series,
				yieldTarget,
			} = payload;
			const selectedPlanId = state.selectedGamePlanId;
			const localEditingState = state.productGamePlans[selectedPlanId]?.localEditingState;
			const cropTreatments: ITreatment[] = state.treatments[cropId];
			const isSoybean = cropId === soyId;
			const nonDeletedCustomTreatments = customTreatments.filter(ct => !ct.IsDeleted);

			// Make sure we have a 'series' entry
			if (!localEditingState?.series[series.seriesId])
			{
				localEditingState.series[series.seriesId] = {
					cropId,
					cropType,
					series,
				};
			}
			
			// Get the index for the highest priced hybrid for the first defaulted product - if no prices (GH vs GHX), go for highest availability
			let highestPriceIndex = 0;
			if (series.hybrids.every(h => !h.Pricing))
			{
				let availabilityIndex = 0;
				const greenHybrids = series.hybrids.filter(h => h.Availability.toLowerCase() === 'green');
				if (greenHybrids && greenHybrids.length >= 1)
				{
					// How do we pick when there's more than one green hybrid? Check the length of the name
					const greenIndex = greenHybrids.reduce(
						(highestIndexSoFar, currentItem, currentIndex, allItems) => 
							currentItem.Name.length > allItems[highestIndexSoFar].Name.length ? currentIndex : highestIndexSoFar, 0);
					
					const greenHybridId = greenHybrids[greenIndex].Id;
					availabilityIndex = series.hybrids.findIndex(h => h.Id === greenHybridId);
				}
				else
				{
					availabilityIndex = series.hybrids.findIndex(h => h.Availability.toLowerCase() === 'green');

					// Check if we're less than 0, If it is less than 0, try yellow
					if (availabilityIndex < 0)
					{
						const yellowHybrids = series.hybrids.filter(h => h.Availability.toLowerCase() === 'yellow');
						if (yellowHybrids && yellowHybrids.length >= 1)
						{
							// How do we pick when there's more than one yellow hybrid? Check the length of the name
							const yellowIndex = yellowHybrids.reduce(
								(highestIndexSoFar, currentItem, currentIndex, allItems) => 
									currentItem.Name.length > allItems[highestIndexSoFar].Name.length ? currentIndex : highestIndexSoFar, 0);
							
							const yellowHybridId = yellowHybrids[yellowIndex].Id;
							availabilityIndex = series.hybrids.findIndex(h => h.Id === yellowHybridId);
						}
						else
						{	
							availabilityIndex = series.hybrids.findIndex(h => h.Availability.toLowerCase() === 'yellow');

							// Check if we're less than 0 again
							if (availabilityIndex < 0)
							{
								const redHybrids = series.hybrids.filter(h => h.Availability.toLowerCase() === 'red');
								if (redHybrids && redHybrids.length >= 1)
								{
									// How do we pick when there's more than one red hybrid? Check the length of the name
									const redIndex = redHybrids.reduce(
										(highestIndexSoFar, currentItem, currentIndex, allItems) => 
											currentItem.Name.length > allItems[highestIndexSoFar].Name.length ? currentIndex : highestIndexSoFar, 0);
									
									const redHybridId = redHybrids[redIndex].Id;
									availabilityIndex = series.hybrids.findIndex(h => h.Id === redHybridId);
								}
								else
								{
									// If it is less than 0, try red
									availabilityIndex = series.hybrids.findIndex(h => h.Availability.toLowerCase() === 'red');
								}
							}
						}
					}
				}
				
				// If we're still less than 0, just default to 0
				highestPriceIndex = availabilityIndex < 0 ? 0 : availabilityIndex;
			}
			else
			{
				// Check for pricing
				highestPriceIndex = series.hybrids.reduce(
					(highestIndexSoFar, currentItem, currentIndex, allItems) => 
						currentItem.Pricing?.Price > allItems[highestIndexSoFar].Pricing?.Price ? currentIndex : highestIndexSoFar, 0);
			}

			const chosenHybrid = series.hybrids[highestPriceIndex];
			const defaultTreatment: ITreatment = isSoybean ? getDefaultTreatment(defaultTreatmentName, cropTreatments, nonDeletedCustomTreatments) : undefined;
			
			// Generate a unique id for this product line
			const productKey = uuidv4();
			// if soy, add treatment to hybrid always
			if (!localEditingState?.products[productKey])
			{
				localEditingState.products[productKey] = {
					key: productKey,
					cropId,
					cropType,
					seriesId: series.seriesId,
					hybridId: chosenHybrid.Id,
					product: {
						Key: productKey,
						HybridId: chosenHybrid.Id,
						PriceRate: chosenHybrid.Pricing?.Price,
						PriceUnit: chosenHybrid.Pricing?.Unit,
						LocalPositioning: orderBy(chosenHybrid.LocalPositioning, (lp) => lp.Ordinal).map(lp => lp.Position ?? '').join('\n'),
						Treatment: isSoybean ? defaultTreatment : undefined,
						AppliedArea: undefined,
						Quantity: undefined,
						QuantityRequired: undefined,
						SeedingRate: undefined,
						TotalPrice: undefined,
						YieldTarget: undefined
					}
				};

				if (appliedArea || yieldTarget)
				{
					let updateResults: IProductLine = {
						Key: productKey,
						HybridId: '',
						YieldTarget: yieldTarget,
						PriceRate: 0,
						PriceUnit: 'acre',
						AppliedArea: appliedArea,
						Quantity: 0,
						SeedingRate: 0,
						QuantityRequired: 0,
						TotalPrice: 0,
						LocalPositioning: ''
					};

					// If we have a yield and planting rates and a grower, update the calculations for the yield
					if (yieldTarget && plantingRates && selectedGrower)
					{
						updateResults = simpleUpdateYieldTarget({
							planId: planId,
							productKey: productKey,
							cropId: cropId,
							yieldTarget: yieldTarget,
							seriesName: series.seriesName,
							selectedGrower: selectedGrower,
							plantingRates: plantingRates,
							localEditingState: localEditingState
						});
					}
					
					updateResults = updateProductData({
						productData: localEditingState.products[productKey].product,
						update: {
							AppliedArea: RoundAcres(appliedArea),
							YieldTarget: updateResults.YieldTarget !== 0 ? updateResults.YieldTarget : yieldTarget,
							SeedingRate: updateResults.SeedingRate !== 0 ? updateResults.SeedingRate : CropConfig()[cropId].defaultSeedingRate
						},
						cropConfig: CropConfig()[cropId],
						cropId,
						defaultGrowerSeedingRate: CropConfig()[cropId].defaultSeedingRate
					});

					localEditingState.products[productKey].product.AppliedArea = updateResults.AppliedArea;
					localEditingState.products[productKey].product.YieldTarget = updateResults.YieldTarget;
					localEditingState.products[productKey].product.PriceRate = updateResults.PriceRate;
					localEditingState.products[productKey].product.PriceUnit = updateResults.PriceUnit;
					localEditingState.products[productKey].product.SeedingRate = updateResults.SeedingRate;
					localEditingState.products[productKey].product.Quantity = updateResults.Quantity;
					localEditingState.products[productKey].product.QuantityRequired = updateResults.QuantityRequired;
					localEditingState.products[productKey].product.TotalPrice = updateResults.TotalPrice;
				}
			}
		},
		/** Add a specific hybrid; assumes the series is already added */
		addProductLineToEditingState: (state, { payload }: PayloadAction<{
			customTreatments?: ICustomTreatmentResponse[];
			defaultTreatmentName?: string;
			planId: string;
			product: IHybridResponse;
			cropId: string;
			cropType: ProductPlanCropType;
			treatment?: ITreatment;
		}>) =>
		{
			const { planId, product, cropId, cropType, treatment, defaultTreatmentName, customTreatments } = payload;
			const localEditingState = state.productGamePlans[planId]?.localEditingState;
			const cropTreatments: ITreatment[] = state.treatments[cropId];
			const isSoybean = cropId === soyId;

			const defaultTreatment: ITreatment = isSoybean ? getDefaultTreatment(defaultTreatmentName, cropTreatments, customTreatments) : undefined;
			
			// Generate a unique id for this product line
			const productKey = uuidv4();
			localEditingState.products[productKey] = {
				key: productKey,
				cropId,
				cropType,
				seriesId: product.SeriesId,
				hybridId: product.Id,
				product: {
					Key: productKey,
					HybridId: product.Id,
					PriceRate: product.Pricing?.Price,
					PriceUnit: product.Pricing?.Unit,
					LocalPositioning: orderBy(product.LocalPositioning, (lp) => lp.Ordinal).map(lp => lp.Position ?? '').join('\n'),
					Treatment: treatment ?? defaultTreatment,
					AppliedArea: undefined,
					Quantity: undefined,
					QuantityRequired: undefined,
					SeedingRate: undefined,
					TotalPrice: undefined,
					YieldTarget: undefined
				}
			};
		},
		/** Remove a specific hybrid */
		removeProductLineFromEditingState: (state, { payload }: PayloadAction<{
			planId: string; key: string
		}>) => 
		{
			const { planId, key } = payload;
			const localEditingState = state.productGamePlans[planId].localEditingState;
			const seriesId = localEditingState.products[key].seriesId;

			// get products on a series
			const productKeysInSeries = Object.keys(localEditingState.products)
				.filter(key => localEditingState.products[key].seriesId === seriesId);

			if (productKeysInSeries.length > 1)
			{
				delete localEditingState.products[key];
			}
		},
		/** Remove an entire series */
		removeSeriesFromEditingState: (state, { payload }: PayloadAction<{ planId: string; seriesId: string; }>) =>
		{
			const { planId, seriesId } = payload;
			const localEditingState = state.productGamePlans[planId].localEditingState;
			delete localEditingState.series[seriesId];
			for (const key in localEditingState.products)
			{
				if (localEditingState.products[key].seriesId === seriesId)
				{
					delete localEditingState.products[key];
				}
			}
		},
		createNewEditPlan: (state, { payload }: PayloadAction<{ defaultTreatmentName: string; growerZip: string; }>) =>
		{
			state.productGamePlans[newPlanDummyId] = {
				localEditingState: createLocalEditingState(
					{},
					state.hybridLookup,
					state.seriesLookup,
					state.treatments,
					payload.growerZip,
					payload.defaultTreatmentName,
				)
			};

			state.selectedGamePlanId = newPlanDummyId;
		},
		updateRecommendationOptions: (state, { payload }: PayloadAction<{ cropId: string; brandAssociation: string; recommendationOptions: IRecommendationOptions }>) =>
		{
			const selectedPlanId = state.selectedGamePlanId;
			const localEditingState = state.productGamePlans[selectedPlanId]?.localEditingState;
			if (localEditingState && payload.recommendationOptions)
			{
				localEditingState.recommendationOptions[payload.cropId + payload.brandAssociation] = payload.recommendationOptions;
			}

			return state;
		}
	},
	extraReducers: (builder) =>
	{
		builder.addCase(setSelectedGrowerId, (state, action) =>
		{
			state.selectedGamePlanId = undefined;
		});
		builder.addCase(updateYieldTarget.fulfilled, (state, { payload }: PayloadAction<{ productData: IProductLine; productKey: string; planId: string; }>) => 
		{
			const { productData, productKey, planId } = payload;
			state.productGamePlans[planId].localEditingState.products[productKey].product = productData;
		});
		builder.addCase(updateYieldTarget.rejected, (state, action) =>
		{
			state.errorMessage = action.payload as string;
		});
		builder.addCase(updateProduct.fulfilled, (state, { payload }: PayloadAction<{ productData: IProductLine; productKey: string; planId: string; }>) => 
		{
			const { productData, productKey, planId } = payload;
			state.productGamePlans[planId].localEditingState.products[productKey].product = productData;
		});
		builder.addCase(updateProduct.rejected, (state, action) => 
		{
			state.errorMessage = action.payload as string;
		});
		builder.addCase(createProductGamePlan.pending, (state, action) =>
		{
			state.isLoadingCreateProductPlan = true;
		});
		builder.addCase(createProductGamePlan.fulfilled, (state, { payload }: PayloadAction<string>) =>
		{
			state.isLoadingCreateProductPlan = false;
		});
		builder.addCase(createProductGamePlan.rejected, (state, action) =>
		{
			state.isLoadingCreateProductPlan = false;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to create a Acre Proposal. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem creating a Acre Proposal. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem creating a Acre Proposal. Please refresh and try again.';
			}

		});
		builder.addCase(getProductGamePlan.pending, (state) =>
		{
			state.isLoadingGetProductPlan = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getProductGamePlan.fulfilled, (state, { payload }: PayloadAction<IProductGamePlanDetails & { defaultTreatmentName: string; growerZip: string; }>) =>
		{
			state.isLoadingGetProductPlan = false;
			const planId = payload.id;
			// If this was a new plan, then update our planId to the one we just created
			if (state.selectedGamePlanId === newPlanDummyId)
			{
				state.selectedGamePlanId = planId;
			}
			// If we don't have this store already, then create an entry
			if (!state.productGamePlans[planId])
			{
				state.productGamePlans[planId] = { originalData: payload };
				state.productGamePlans[planId].localEditingState = createLocalEditingState(
					state.productGamePlans[planId],
					state.hybridLookup,
					state.seriesLookup,
					state.treatments,
					payload.growerZip,
					payload.defaultTreatmentName,
				);
			}
			// Otherwise just update the 'original' data for reference.
			else
			{
				state.productGamePlans[planId].originalData = payload;
			}

			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getProductGamePlan.rejected, (state, action) =>
		{
			state.isLoadingGetProductPlan = false;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the Acre Proposal. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the Acre Proposal. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the Acre Proposal. Please refresh and try again.';
			}
		});
		// Capture the update of a product to the server
		builder.addCase(saveProductPlan.pending, (state) => 
		{
			state.isSavingProductPlan = true;
			state.isError = false;
		});
		// Capture the update of a product to the server
		builder.addCase(saveProductPlan.rejected, (state, action) => 
		{
			state.isSavingProductPlan = false;
			state.isError = true;
			state.errorMessage = action.payload as string;
		});
		// On a specific update, store the result
		builder.addCase(saveProductPlan.fulfilled, (state, { payload }) => 
		{
			const planId = payload.id;
			state.isSavingProductPlan = false;

			if (state.selectedGamePlanId === newPlanDummyId)
			{
				state.productGamePlans[planId] = {
					originalData: payload,
					localEditingState: state.productGamePlans[newPlanDummyId].localEditingState
				};
				state.selectedGamePlanId = planId;
			}
			else
			{
				state.productGamePlans[planId].originalData = payload;
			}

			state.isError = false;
			state.errorMessage = undefined;
		});
		// Capture the seed list to create a local lookup
		builder.addCase(getSeedsForGrower.fulfilled, (state, { payload }: PayloadAction<ISeedResponseWithGrowerZip>) => 
		{
			const seeds = payload.seeds;
			const hybrids = seeds
				.flatMap(s => s.BrandApplications)
				.flatMap(ba => ba.Hybrids);

			state.seriesLookup = hybrids.reduce<{ [key: string]: ISeriesWithHybrid; }>((lookup, hybrid) =>
			{
				const {
					SeriesId: seriesId,
					SeriesName: seriesName,
					Availability,
					BrandName: brandName,
					RelativeMaturity: relativeMaturity,
				} = hybrid;

				if (!lookup[seriesId])
				{
					lookup[seriesId] = {
						seriesId,
						seriesName,
						availability: 'Red',
						brandName,
						relativeMaturity,
						hybrids: []
					};
				}

				// highest availability
				if (lookup[seriesId].availability === 'Red')
				{
					lookup[seriesId].availability = Availability;
				}
				else if (lookup[seriesId].availability === 'Yellow' && Availability === 'Green')
				{
					lookup[seriesId].availability = Availability;
				}

				lookup[seriesId].hybrids.push(hybrid);
				return lookup;
			}, {});

			state.hybridLookup = hybrids.reduce<{ [key: string]: IHybridResponse; }>((collect, hybrid) =>
			{
				collect[hybrid.Id] = hybrid;
				return collect;
			}, {});
		});

		// The start of generating and downloading a PDF
		builder.addCase(downloadProductPlan.pending, (state, action) => 
		{
			state.isDownloadingProductPlan = true;
		});

		// Capture the failure in generating a PDF
		builder.addCase(downloadProductPlan.rejected, (state, action) => 
		{
			state.isDownloadingProductPlan = false;
			state.isError = true;
			if (action.payload)
			{
				const error = action.payload as string;
				if (error.indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to download the PDF. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = error;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem downloading the PDF. Please refresh and try again.';
			}
		});

		// Capture a success in generating a PDF.  The actual payload is handled in the original thunk.
		builder.addCase(downloadProductPlan.fulfilled, (state) => 
		{
			state.isDownloadingProductPlan = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getTreatmentPricing.pending, (state, action) =>
		{
			state.isTreatmentsLoading = true;
		});
		builder.addCase(getTreatmentPricing.rejected, (state, action) =>
		{
			state.isTreatmentsLoading = false;
			state.isError = true;
			if (action.payload)
			{
				const error = action.payload as string;
				if (error.indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get treatment pricing. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = error;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting treatment pricing. Please refresh and try again.';
			}

		});
		builder.addCase(getTreatmentPricing.fulfilled, (state, { payload }: PayloadAction<ITreatmentDictionary>) =>
		{
			state.isTreatmentsLoading = false;
			let cornTreatments = [];
			let soyTreatments = [];
			// Filter out any Treatments that are not Active - Corn
			if (payload[cornId])
			{
				cornTreatments = payload[cornId].filter(treatment => treatment.IsActive);
			}
			// Filter out any Treatments that are not Active - Soybean
			if (payload[soyId])
			{
				soyTreatments = payload[soyId].filter(treatment => treatment.IsActive);
			}
			state.treatments = {
				[cornId]: cornTreatments,
				[soyId]: soyTreatments
			};
		});

		/**
		 * Fieldless Recommendation Allocation Rankings -- when clicking "Show Recommendation" button on the Product GamePlan page
		 **/
		builder.addCase(getFieldlessSeedAllocations.pending, (state, action) =>
		{
			state.isFieldlessLoading = true;
		});
		builder.addCase(getFieldlessSeedAllocations.rejected, (state, action) =>
		{
			state.isFieldlessLoading = false;
			state.isError = true;
			if (action.payload)
			{
				const error = action.payload as string;
				if (error.indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get a fieldless recommendation. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = error;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting a fieldless recommendation. Please refresh and try again.';
			}

		});
		builder.addCase(getFieldlessSeedAllocations.fulfilled, (state, { payload }: PayloadAction<IFieldlessAllocationResponse>) =>
		{
			state.isFieldlessLoading = false;
			state.fieldlessRecommendations = payload;
		});

		/**
		 * Fieldless Filtered Rankings -- For the Agronomics Modal on the Product GamePlan page
		 * */
		builder.addCase(getFieldlessSeedRankings.pending, (state, action) =>
		{
			state.isFieldlessLoading = true;
		});
		builder.addCase(getFieldlessSeedRankings.rejected, (state, action) =>
		{
			state.isFieldlessLoading = false;
			state.isError = true;
			if (action.payload)
			{
				const error = action.payload as string;
				if (error.indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get a fieldless product rankings. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = error;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting a fieldless product rankings. Please refresh and try again.';
			}

		});
		builder.addCase(getFieldlessSeedRankings.fulfilled, (state, { payload }: PayloadAction<{ cropAssociation: string, rankings: IFieldlessRankingSeed[] }>) =>
		{
			state.isFieldlessLoading = false;
			state.fieldlessRankings[payload.cropAssociation] = payload.rankings;
		});
	},
});

export const {
	addProductLineToEditingState,
	addSeriesToEditingState,
	clearError,
	clearRankings,
	clearSelectedProductPlanId,
	clearState,
	createNewEditPlan,
	migrateSoyProductEntries,
	removeProductLineFromEditingState,
	removeSeriesFromEditingState,
	selectProductGamePlanId,
	updateRecommendationOptions,
} = productGamePlanSlice.actions;

export const selectedEditingProductPlan = (state: RootState) => 
{
	const { productGamePlan } = state;
	return productGamePlan.productGamePlans[productGamePlan.selectedGamePlanId]?.localEditingState;
};
