import React, { useCallback, useEffect, useRef, useState } from 'react';
import L, { LeafletMouseEvent } from 'leaflet';
import { AppDispatch, RootState } from '../../store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { baseStyle, defaultZoomLevel, hybridLayerParams, maxBoundsContinentalUSParams, satelliteLayerParams, streetsLayerParams } from '../Shared/MapCommon';
import turf, {
	feature,
	Feature,
	FeatureCollection,
	featureCollection,
	Geometry,
	LineString,
	point,
	pointsWithinPolygon,
	polygon,
	Polygon,
	polygonToLineString,
	Position
} from '@turf/turf';
import { get } from 'lodash';
import { IGrowerResponse } from '../../Models/Responses/GrowerResponse';
import { IFieldResponse } from '../../Models/Responses/FieldResponse';
import { VrsMapPopUp } from '../PopUp/VrsMapPopUp';
import { IPlantingPlanSummary } from '../../Models/Responses/PlantingPlanSummaryResponse';
import { ExportTray } from './ExportTray';
import { ZoomTray } from '../ZoomTray';
import { dynamicSort, makeDispatch } from '../../Utility/Utils';
import { getSupportedEquipment } from '../../store/Grower/PlantingPlanThunks';
import { ExportModal } from './ExportModal';
import { setVRSMapInteractionMode, setSelectedZone, SelectionInteractionMode, paintZone } from '../../store/ManagementZones/ManagementZoneSlice';
import { IZoneData } from '../../../pages/FieldActivities/VRS/VRSMain';
import { VRSPaintTray } from './VRSPaintTray';
import { VRSGeneralButtonTray } from './VrsGeneralButtonTray';
import { cutPolygons } from '../../store/ManagementZones/ManagementZoneSlice';
import './DrawingStyles.css';

/**
 * In case a map marker is ever needed, this is a workaround, otherwise it will fail to get
 * the marker icon file properly.
 * Ref: https://github.com/PaulLeCam/react-leaflet/issues/453
 */
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { IFieldSoilData } from '../PopUp/MapPopUp';
import { IPlantingPlanZone } from '../../Models/Responses/PlantingPlanResponse';
import { useTheme } from 'styled-components';
 
const DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow
});
 
L.Marker.prototype.options.icon = DefaultIcon;
/**
 * end map marker solution
 */

interface IVrsMapProps extends PropsFromRedux
{
	displayZoneManagementButton: boolean;
	selectedCropPlanId?: string;
	selectedGrower: IGrowerResponse;
	showFieldZones: boolean;
	selectedFieldId?: string;
	selectedSeed?: string;
	downloadPdf: () => void;
	exportFieldPlan: (format: string, monitor: string) => void;
	openZoneManagement: () => void;
	undoLast: () => void;
	resetAll: () => void;
}

interface IExtendedField extends IFieldResponse
{
	cropPlanId?: string;
	color?: string;
}

const VrsMapComponent = (props: IVrsMapProps) =>
{
	const {
		displayZoneManagementButton,
		selectedGrower,
		showFieldZones,
		selectedCropPlanId,
		selectedFieldId,
		selectedSeed,
		IsLoadingGetSummary,
		IsLoadingRetrieveSummary,
		IsLoadingFieldPlanData,
		ExportEquipmentList,
		GetSupportedEquipment,
		downloadPdf,
		exportFieldPlan,
		openZoneManagement,
		ZoneManagementFeatures,
		SetVRSMapInteractionMode,
		MapMode,
		MapboxToken,
		SetSelectedZone,
		SelectedZone,
		CutGeometry,
		PaintZone,
		undoLast,
		resetAll,
	} = props;

	const theme = useTheme();
	
	const [allGrowerFields, setAllGrowerFields] = useState<IFieldResponse[]>([]);

	const selectedPlan = selectedGrower?.PlantingPlanSummary;
	const [popUpField, setPopUpField] = useState<IFieldResponse>(undefined);
	const [popUpFarmName, setPopUpFarmName] = useState<string>('');
	const [displayExportModal, setDisplayExportModal] = useState<boolean>(false);
	const [showSoilMapButton, setShowSoilMapButton] = useState<boolean>(true);

	// Reference to the map control
	const mapRef = useRef<L.DrawMap | undefined>(undefined);

	// TODO: For future popups: Reference to the div of any popup
	const [popupRef, setPopupRef] = useState<HTMLDivElement | undefined>(undefined);

	// Store the element the map will be rendered into
	const [mapContainer, setMapContainer] = useState<HTMLDivElement>();

	// Store the geojson data layers we will use to represent everything.
	const [fieldLayer] = useState(L.geoJSON());
	const [readOnlyManagementZoneLayer] = useState(L.geoJSON());
	const [editableManagementZoneLayer] = useState(L.geoJSON());

	// Base Layers for the map
	const hybridLayer = L.tileLayer((hybridLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), hybridLayerParams[1] as {attribution: string});
	const streetsLayer = L.tileLayer((streetsLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), streetsLayerParams[1] as {attribution: string});
	const satelliteLayer = L.tileLayer((satelliteLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), satelliteLayerParams[1] as {attribution: string});

	// Event to catch a resize
	window.dispatchEvent(new Event('resize'));

	// Create a layer object to pass to the layer control on the map
	const baseMaps = {
		'Hybrid': hybridLayer,
		'Streets': streetsLayer,
		'Satellite': satelliteLayer
	};

	// Continental US bounds
	const maxBoundsContinentalUS: L.LatLngBounds =  L.latLngBounds(
		L.latLng(maxBoundsContinentalUSParams[0][0], maxBoundsContinentalUSParams[0][1]), //Southwest
		L.latLng(maxBoundsContinentalUSParams[1][0], maxBoundsContinentalUSParams[1][1])  //Northeast
	);

	useEffect(() =>
	{
		if (!IsLoadingGetSummary && !IsLoadingRetrieveSummary)
		{
			setAllGrowerFields(selectedGrower ? selectedGrower.Farms?.flatMap(fa => fa.Fields) : []);			
		}

		if (!ExportEquipmentList || (!ExportEquipmentList.monitors.length || !ExportEquipmentList.brands.length) )
		{
			GetSupportedEquipment();
		}
	}, [selectedGrower, IsLoadingGetSummary, IsLoadingRetrieveSummary]);

	// Create the default map styler that will apply color to the rendered vectors.
	const styler = ((feature: Feature<Geometry, IExtendedField>) =>
	{
		if (selectedCropPlanId)
		{
			if (feature.properties?.cropPlanId === selectedCropPlanId && feature.properties?.color)
			{
				return {
					...baseStyle(theme),
					fillColor: feature.properties?.color,
					fillOpacity: 0.5
				};
			}
			else
			{
				return {
					...baseStyle(theme),
					fillColor: theme.colors.mediumGrey
				};
			}
		}
		else
		{
			if (feature.properties?.color)
			{
				return {
					...baseStyle(theme),
					fillColor: feature.properties?.color,
					fillOpacity: 0.5
				};
			}
			else
			{
				return {
					...baseStyle(theme),
					fillColor: theme.colors.mediumGrey
				};
			}
		}
	}) as L.StyleFunction<any>;

	// Create the styler for the ManagementZone Layer
	const managementZoneStyler = ((feature: Feature<Geometry>) =>
	{
		if (feature.properties)
		{
			const color = feature.properties.color;
			return {
				...baseStyle(theme),
				fillColor: color,
				fillOpacity: 0.5,
			};
		}

		return {
			...baseStyle(theme),
			color: theme.colors.blueLM,
			fillColor: theme.colors.transparentWhite
		};
	}) as L.StyleFunction<any>;

	// Create the styler for the editable ManagementZone Layer
	const editableManagementZoneStyler = ((feature: Feature<Geometry, IZoneData>) =>
	{
		const unselectedOpacity = 0.5;
		const selectedOpacity = 0.8;

		if (feature.properties)
		{
			const color = feature.properties.Color;
			return {
				...baseStyle(theme),
				fillColor: color,
				color: color,
				opacity: SelectedZone && SelectedZone === feature.properties.ZoneName ? selectedOpacity : unselectedOpacity,
				fillOpacity: SelectedZone && SelectedZone === feature.properties.ZoneName ? selectedOpacity : unselectedOpacity,
			};
		}

		return {
			...baseStyle(theme),
			color: theme.colors.blueLM,
			fillColor: theme.colors.transparentWhite
		};
	}) as L.StyleFunction<any>;

	fieldLayer.setStyle(styler);
	readOnlyManagementZoneLayer.setStyle(managementZoneStyler);

	// When the container is rendered, save the element so we can install the map there
	const installVrsMap = useCallback((ref: HTMLDivElement) =>
	{
		if (ref)
		{
			// On refreshes, if the mapRef exists, it can throw an error about the map already having been initialized
			// Removing it before we re-initialize it will fix that
			if (mapRef.current)
			{
				mapRef.current.remove();
			}

			if (!selectedGrower || !selectedGrower.ReferencePoint)
			{
				mapRef.current = L.map(ref, {
					zoomControl: false,
					layers: [hybridLayer], // default to the Streets layer
				}).fitBounds(maxBoundsContinentalUS);
			}
			else
			{
				const growerReferencePoint = selectedGrower.ReferencePoint.coordinates as turf.Position;
				mapRef.current = L.map(ref, {
					zoomControl: false,
					zoom: defaultZoomLevel,
					center: [growerReferencePoint[1], growerReferencePoint[0]],
					layers: [hybridLayer] // default to the Streets layer
				});
			}

			// Layer control - defaults to topright
			L.control.layers(baseMaps).addTo(mapRef.current);

			// Zoom control
			L.control.zoom({ position: 'bottomright' }).addTo(mapRef.current);

			setMapContainer(ref);
		}
	}, [setMapContainer]);

	useEffect(() =>
	{
		if (!mapContainer)
		{
			return;
		}

		fieldLayer.options = { pmIgnore: true };
		fieldLayer.addTo(mapRef.current);
		fieldLayer.setStyle(styler);

		readOnlyManagementZoneLayer.options = { pmIgnore: true };
		readOnlyManagementZoneLayer.addTo(mapRef.current);
		readOnlyManagementZoneLayer.setStyle(managementZoneStyler);

		editableManagementZoneLayer.options = { pmIgnore: true };
		editableManagementZoneLayer.addTo(mapRef.current);
		editableManagementZoneLayer.setStyle(editableManagementZoneStyler);

		// make all layers not intersect during draw  
		mapRef.current.pm.setGlobalOptions(
			{
				allowSelfIntersection: false,
				snappingOrder: ['Marker', 'CircleMarker', 'Circle', 'Line', 'Polygon', 'Rectangle'], // Default
				panes: { vertexPane: 'markerPane', layerPane: 'overlayPane', markerPane: 'markerPane' } // Default
			}
		);

		mapRef.current.pm.Toolbar.copyDrawControl('Line',
			{
				name: 'Polyline',
				className: 'custom-control-icon-line',
				block: 'draw',
				title: 'Split',
			});
		mapRef.current.pm.Toolbar.copyDrawControl('Circle',
			{
				name: 'Circle_copy',
				className: 'custom-control-icon-circle',
				block: 'draw',
				title: 'Draw Circle',
			});
		mapRef.current.pm.Toolbar.createCustomControl(
			{
				name: 'Undo Last Action',
				title: 'Undo',
				className: 'custom-control-icon-undo-last',
				block: 'custom',
				onClick: () => undoLast(),
			});
		mapRef.current.pm.Toolbar.createCustomControl(
			{
				name: 'Reset All',
				title: 'Reset',
				className: 'custom-control-icon-reset',
				block: 'custom',
				onClick: () => resetAll(),
			});

		mapRef.current.pm.addControls({
			position: 'topright',
			customControls: true,
			drawControls: true,
			drawPolyline: false, 
			drawMarker: false,
			drawCircleMarker: false,
			drawRectangle: false,
			drawPolygon: false,
			drawCircle: false,
			drawText: false,
			editMode: false,
			dragMode: false,
			cutPolygon: false,
			removalMode: false,
			rotateMode: false,
		});

		checkDrawingToolsDisplay();

	}, [mapContainer, mapRef.current]);

	useEffect(() =>
	{
		if (!mapContainer)
		{
			return;
		}

		checkDrawingToolsDisplay();

		// Switching map modes from the Zone Management modes should remove any drawn features
		if (MapMode.mode === SelectionInteractionMode.Default || MapMode.mode === SelectionInteractionMode.Selection)
		{
			const drawnLayers: L.Layer[] = mapRef.current.pm.getGeomanDrawLayers() as L.Layer[];
			drawnLayers.forEach(drawnLayer =>
			{
				drawnLayer.remove();
			});
		}

	}, [mapRef.current, mapContainer, MapMode]);

	const checkDrawingToolsDisplay = () =>
	{
		const drawingToolsVisible = mapRef.current.pm.controlsVisible();

		if (MapMode.mode === SelectionInteractionMode.Default && drawingToolsVisible)
		{
			// hide the drawing tools
			mapRef.current.pm.toggleControls();
			mapRef.current.pm.disableDraw();
		}
		else if (MapMode.mode === SelectionInteractionMode.AddingZone && drawingToolsVisible)
		{
			// hide the drawing tools
			mapRef.current.pm.toggleControls();
			mapRef.current.pm.disableDraw();
		}
		else if (MapMode.mode !== SelectionInteractionMode.Default && !drawingToolsVisible)
		{
			// show the tools while drawing
			mapRef.current.pm.toggleControls();
		}
	};

	// When the popup actually renders, this is called and allows us to store the popup div.  We actually
	// store the inner containing element so we can later 'portal' to it.
	const popupWasOpened = useCallback((e: any) =>
	{
		setPopupRef(((e.popup._wrapper as HTMLDivElement)?.getElementsByClassName('vrsMapPopupView').item(0) as HTMLDivElement) ?? undefined);
	}, [setPopupRef]);

	useEffect(() =>
	{
		if (!mapRef.current || MapMode.mode !== SelectionInteractionMode.Default)
		{
			return;
		}

		mapRef.current.closePopup();

		// Only displays on crop plan list and field list views
		if (allGrowerFields?.length > 0 && !IsLoadingGetSummary && !IsLoadingRetrieveSummary && selectedPlan
			&& !selectedFieldId)
		{
			// Clear the layers just in case
			fieldLayer.clearLayers();
			setShowSoilMapButton(true);
			readOnlyManagementZoneLayer.clearLayers();
			editableManagementZoneLayer.clearLayers();

			// Get all of the boundaries
			const fieldBoundaries: Feature<Geometry, IExtendedField>[] = [];
			const gamePlanFields: { color: string, cropPlanId: string, fieldId: string }[] = [];
			selectedPlan?.CropPlans.map(cp =>
			{
				cp.PlantingPlanSummaries.map(s =>
				{
					gamePlanFields.push({ color: cp.Color, cropPlanId: cp.CropPlanId, fieldId: s.FieldId });
				});
			});

			for (let i = 0, len = allGrowerFields.length; i < len; i++)
			{
				const field = allGrowerFields[i];
				const gamePlanField: IExtendedField = { ...field };
				const foundGamePlanField = gamePlanFields.find(gpf => gpf.fieldId === field.Id);
				if (foundGamePlanField)
				{
					gamePlanField.color = foundGamePlanField.color;
					gamePlanField.cropPlanId = foundGamePlanField.cropPlanId;
					if (!gamePlanField.CurrentCrop)
					{
						const cropPlanSummary = selectedPlan?.CropPlans.find(cp => cp.CropPlanId === foundGamePlanField.cropPlanId);
						gamePlanField.CurrentCrop = cropPlanSummary.PlantingPlanSummaries[0].CropId;
					}
				}
				if (gamePlanField.Boundary)
				{
					fieldBoundaries.push(feature(field.Boundary, gamePlanField));
				}
			}
			if (fieldBoundaries.length > 0)
			{
				const featureLayer = fieldLayer.addData(featureCollection(fieldBoundaries)).bindTooltip(function (layer: L.Layer)
				{
					const name = get(layer, 'feature.properties.Name', '');
					const acres = Number(get(layer, 'feature.properties.Area', '')).toFixed(1);
					return name + '<br>' + 'Acres: ' + acres;
				}) as L.GeoJSON<any>;

				if (!showFieldZones)
				{
					// Padding will scoot the map over a bit to give space for the overlay on the left
					mapRef.current.fitBounds(featureLayer.getBounds(), { paddingTopLeft: [10, 0] });

					// Hook into the user clicking on this feature and potentially bubble up the interaction
					featureLayer.on('click', (l: LeafletMouseEvent) =>
					{
						const latlng = l.latlng;
						const clickedFeature = l.propagatedFrom?.feature as Feature<Geometry, IExtendedField>;
						
						// we have to set the width here to make sure the popup fits on the screen
						const popup = l.propagatedFrom.bindPopup(
							'<div class="vrsMapPopupView"><div class="placeholder" style="width:400px;height:400px"></div>',
							{
								keepInView: true,
								closeButton: true,
								className: 'vrsMapPopupView',
								autoPan: true,
								maxWidth: 200
							}
						);

						setPopUpField(clickedFeature.properties);
						setPopUpFarmName(selectedGrower.Farms.find(fa => fa.Id === clickedFeature.properties.FarmId).Name);
						popup.on('popupopen', popupWasOpened);
						popup.openPopup(latlng);
					});
				}

				fieldLayer.setStyle(styler);

				// Center based on what set of fields the user is viewing
				if (selectedCropPlanId)
				{
					onCenterSelected();
				}
				else
				{
					onCenterAll();
				}

				return () =>
				{
					featureLayer.off('click');
				};
			}
		}
	}, [IsLoadingGetSummary, IsLoadingRetrieveSummary, selectedGrower, selectedPlan, mapRef.current, selectedFieldId, selectedCropPlanId, MapMode.mode]);

	useEffect(() =>
	{
		if (!mapRef.current || MapMode.mode !== SelectionInteractionMode.Default)
		{
			return;
		}

		// Only displays on the seed listing view
		if (selectedFieldId && showFieldZones && !IsLoadingFieldPlanData || selectedSeed)
		{
			// We have a selected field so add it's zone JSON to the map and zoom to it -- get the Zone layer json data
			readOnlyManagementZoneLayer.clearLayers(); // Clear the layer to prepare

			// Get the summary
			const summary: IPlantingPlanSummary = selectedPlan.CropPlans.find(cp => cp.CropPlanId === selectedCropPlanId)
				.PlantingPlanSummaries.find(ps => ps.FieldId === selectedFieldId);

			if (summary.PlantingPlanFieldData)
			{
				// Load the field's zone boundaries
				const zoneData: FeatureCollection<Geometry> = summary.PlantingPlanFieldData.PlanData;
				
				// Add tooltip to show the soil type name
				const zoneLayer = readOnlyManagementZoneLayer.addData(zoneData).bindTooltip(function (layer: L.Layer)
				{
					const soilName = get(layer, 'feature.properties.meta_data.properties.soil_type[0]', '');
					return soilName;
				}) as L.GeoJSON<any>;

				mapRef.current.fitBounds(zoneLayer.getBounds(), { paddingTopLeft: [10, 0] });

				// Clear the field layer so that they don't show underneath the management zone layer and cause the colors to look off
				fieldLayer.clearLayers();
				editableManagementZoneLayer.clearLayers();
				readOnlyManagementZoneLayer.setStyle(managementZoneStyler);

				return () =>
				{
					readOnlyManagementZoneLayer.off('click');
				};
			}
		}
	}, [mapRef.current, selectedFieldId, IsLoadingFieldPlanData, selectedSeed, MapMode.mode]);

	useEffect(() =>
	{
		if (!mapRef.current || MapMode.mode === SelectionInteractionMode.Default)
		{
			return;
		}

		// Only displays on the zone management view
		if (selectedFieldId && showFieldZones && !IsLoadingFieldPlanData)
		{
			// We have a selected field so add it's zone JSON to the map and zoom to it -- get the Zone layer json data
			editableManagementZoneLayer.clearLayers(); // Clear the layer to prepare

			if (ZoneManagementFeatures)
			{
				// Load the field's zone boundaries
				const zoneData: FeatureCollection<Geometry, IZoneData> = ZoneManagementFeatures.editedFeatures;
				
				// Add tooltip to show the soil type name
				const zoneLayer = editableManagementZoneLayer.addData(zoneData) as L.GeoJSON<any>;

				if (!SelectedZone && MapMode.mode === SelectionInteractionMode.Selection)
				{
					mapRef.current.fitBounds(zoneLayer.getBounds(), { paddingTopLeft: [10, 0] });
				}
				
				// Hook into the user clicking on this feature and potentially bubble up the interaction
				editableManagementZoneLayer.on('click', (l: LeafletMouseEvent) =>
				{
					const latlng = l.latlng;
					const clickedPoints = point([latlng.lng, latlng.lat]);
					const clickedFeature = l.propagatedFrom?.feature as Feature<Geometry, IZoneData>;
					const clickedZone = clickedFeature.properties.ZoneName;

					if (MapMode.mode === SelectionInteractionMode.Selection)
					{
						SetSelectedZone(clickedFeature.properties.ZoneName);
					}
					else if (MapMode.mode === SelectionInteractionMode.Painting)
					{
						let idx = -1;
						const geometryType = clickedFeature.geometry.type;
						clickedFeature.geometry.coordinates.forEach((polygonString, polyIdx) =>
						{
							if (geometryType.toLowerCase() === 'multipolygon')
							{
								const polygonFeature = polygon(polygonString);
								if (pointsWithinPolygon(clickedPoints, polygonFeature).features.length)
								{
									idx = polyIdx;
								}
							}
							else
							{
								const polygonFeature = polygon([polygonString]);
								if (pointsWithinPolygon(clickedPoints, polygonFeature).features.length)
								{
									idx = polyIdx;
								}
							}
						});

						PaintZone({ zone: clickedZone, polygonIndex: idx });
					}
				});

				// Clear the field layer and the readonly layer so that they don't show underneath the management zone layer and cause the colors to look off
				fieldLayer.clearLayers();
				readOnlyManagementZoneLayer.clearLayers();
				editableManagementZoneLayer.setStyle(editableManagementZoneStyler);

				return () =>
				{
					editableManagementZoneLayer.off('click');
				};
			}
		}
	}, [mapRef.current, selectedFieldId, IsLoadingFieldPlanData, MapMode.mode, ZoneManagementFeatures, SelectedZone]);

	useEffect(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		// When a user starts drawing
		mapRef.current.on('pm:drawstart', (e: { shape: string; workingLayer: L.Layer; }) =>
		{
			if (e.shape === 'Polyline')
			{
				SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Cutting });
			}

			if (e.shape === 'Circle_copy')
			{
				SetVRSMapInteractionMode({ mode: SelectionInteractionMode.CircleCutting });
			}
			
			// Turn off dragging temporarily so iPad can draw rectangles and circles
			if (mapRef.current.dragging.enabled())
			{
				mapRef.current.dragging.disable();
			}
		});

		mapRef.current.on('pm:drawend', (e: { shape: string }) =>
		{
			if (e.shape === 'Polyline')
			{
				// Get the current drawn layers
				const drawnLayers: L.Layer[] = mapRef.current.pm.getGeomanDrawLayers() as L.Layer[];
				drawnLayers.forEach(drawnLayer =>
				{
					if (drawnLayer instanceof L.Polyline)
					{
						const lineLayer = drawnLayer.toGeoJSON().geometry as LineString;
						CutGeometry({ cutLine: lineLayer, zones: ZoneManagementFeatures.editedFeatures });
					}
				});
			}

			if (e.shape === 'Circle_copy')
			{
				// Get the current drawn layers
				const drawnLayers: L.Layer[] = mapRef.current.pm.getGeomanDrawLayers() as L.Layer[];
				drawnLayers.forEach(drawnLayer =>
				{
					if (drawnLayer instanceof L.Circle)
					{
						const circleLayer = drawnLayer as L.Circle;
						const convertedCircleToPoly = L.PM.Utils.circleToPolygon(circleLayer);
						const circlePoly = convertedCircleToPoly.toGeoJSON().geometry as Polygon;
						const circleLineString = polygonToLineString(circlePoly);
						CutGeometry({ cutLine: (circleLineString as Feature<LineString>).geometry, zones: ZoneManagementFeatures.editedFeatures });
					}
				});
			}

			// Set the map mode back to selection
			SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Selection });

			// Re-enable dragging
			if (!mapRef.current.dragging.enabled())
			{
				mapRef.current.dragging.enable();
			}
		});

		return () =>
		{
			if (!mapRef.current)
			{
				return;
			}

			mapRef.current.off('pm:drawstart');
			mapRef.current.off('pm:drawend');
			/* mapRef.current.off('pm:create');
			mapRef.current.off('pm:globalremovalmodetoggled');
			mapRef.current.off('pm:globaleditmodetoggled');
			mapRef.current.off('pm:globaldragmodetoggled');
			mapRef.current.off('pm:cut');
			mapRef.current.off('pm:globalrotatemodetoggled'); */
		};

	},[mapRef.current, mapContainer, MapMode]);

	const onFileExportButtonClick = () =>
	{
		if (ExportEquipmentList)
		{
			// Open the modal
			setDisplayExportModal(true);
		}
	};

	const onCancelExportModal = () =>
	{
		setDisplayExportModal(false);
	};

	const onZoneManagement = () =>
	{
		openZoneManagement();
	};

	/**
	 * Zooms to all fields in the Plan
	 */
	const onCenterAll = useCallback(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		// Padding will scoot the map over a bit to give space for the overlay on the left
		if (readOnlyManagementZoneLayer.getLayers().length > 0)
		{
			mapRef.current.fitBounds(readOnlyManagementZoneLayer.getBounds(), { paddingTopLeft: [10, 0] });
		}
		if (editableManagementZoneLayer.getLayers().length > 0)
		{
			mapRef.current.fitBounds(editableManagementZoneLayer.getBounds(), { paddingTopLeft: [10, 0] });
		}
		if (fieldLayer.getLayers().length > 0)
		{
			mapRef.current.fitBounds(fieldLayer.getBounds(), { paddingTopLeft: [10, 0] });
		}
		
	}, [mapRef.current, fieldLayer]);

	/**
	 * Zooms to the fields belonging to a CropPlan on the Plan or to a specific selected field
	 */
	const onCenterSelected = useCallback(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		if (editableManagementZoneLayer.getLayers().length > 0)
		{
			mapRef.current.fitBounds(editableManagementZoneLayer.getBounds(), { paddingTopLeft: [10, 0] });
			return;
		}

		// If we're already looking at a specific field, zoom to it
		if (selectedFieldId)
		{
			// Padding will scoot the map over a bit to give space for the overlay on the left
			mapRef.current.fitBounds(readOnlyManagementZoneLayer.getBounds(), { paddingTopLeft: [10, 0] });
		}
		else if (selectedCropPlanId)
		{
			// Get all of the boundaries that belong to the specific crop plan
			const planFieldBoundaries: Feature<Geometry, IExtendedField>[] = [];
			const gamePlanFields: { color: string, cropPlanId: string, fieldId: string }[] = [];
			selectedPlan?.CropPlans.filter(cp => cp.CropPlanId === selectedCropPlanId).map(cp =>
			{
				cp.PlantingPlanSummaries.map(s =>
				{
					gamePlanFields.push({ color: cp.Color, cropPlanId: cp.CropPlanId, fieldId: s.FieldId });
				});
			});
			for (let i = 0, len = gamePlanFields.length; i < len; i++)
			{
				const field: IExtendedField = { ...(allGrowerFields.find(f => f.Id === gamePlanFields[i].fieldId)) };
				if (field && field.Boundary)
				{
					field.color = gamePlanFields[i].color;
					field.cropPlanId = gamePlanFields[i].cropPlanId;
					planFieldBoundaries.push(feature(field.Boundary, field));
				}
			}

			if (planFieldBoundaries.length > 0)
			{
				const collection = featureCollection(planFieldBoundaries);
				const group = L.geoJSON(collection);
				// Padding will scoot the map over a bit to give space for the overlay on the left
				mapRef.current.fitBounds(group.getBounds(), { paddingTopLeft: [10, 0] });
			}
		}

	}, [selectedFieldId, selectedCropPlanId, selectedPlan, allGrowerFields, mapRef.current]);

	const enablePainting = useCallback(() =>
	{
		// Enable painting mode if current mode is not painting
		if (MapMode.mode !== SelectionInteractionMode.Painting)
		{
			SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Painting, zone: SelectedZone ? SelectedZone : undefined });
		}

		// If painting is already enabled, this is a second click of the button to disable it
		if (MapMode.mode === SelectionInteractionMode.Painting)
		{
			SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Selection });
		}
	},[MapMode]);

	const startPainting = useCallback((zone: number) =>
	{
		SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Painting, zone: zone });
	},[MapMode]);

	const selectedPaintZone = (): number =>
	{
		if (MapMode.mode === SelectionInteractionMode.Painting)
		{
			return MapMode.zone;
		}
		else
		{ 
			return undefined;
		}
	};

	const getFieldSoilData = (): IFieldSoilData[] =>
	{
		if (!selectedPlan || !selectedPlan.CropPlans || !selectedCropPlanId || !selectedFieldId)
		{
			return undefined;
		}

		const summary: IPlantingPlanSummary = selectedPlan.CropPlans.find(cp => cp.CropPlanId === selectedCropPlanId)
			.PlantingPlanSummaries.find(ps => ps.FieldId === selectedFieldId);
		
		if (!summary.PlantingPlanFieldData || !summary.PlantingPlanFieldData.Zones)
		{
			return undefined;
		}

		const plantingPlanZones: IPlantingPlanZone[] = summary.PlantingPlanFieldData.Zones;
		const allFields = selectedGrower.Farms.flatMap(fa => fa.Fields);
		const field = allFields.find(f => f.Id === selectedFieldId);
		const allFieldSoilData: IFieldSoilData[] = [];
		plantingPlanZones.forEach(ppz =>
		{
			const zoneFeature = summary.PlantingPlanFieldData.PlanData.features.find(f => f.properties.zone === ppz.Zone);
			const area = zoneFeature.properties.meta_data.properties.area;
			const percentOfTotal = ((area / field.Area ) * 100).toFixed(1);
			const singleSoilSectionData: IFieldSoilData =
			{
				Acres: ppz.Area ? ppz.Area.toFixed(1) : '0',
				Color: ppz.Color,
				MapUnit: ppz.Area + ppz.Color,
				OverallNccpi: '',
				PercentOfTotal: percentOfTotal,
				SoilType: zoneFeature.properties.meta_data.properties.soil_type[0],
			};

			allFieldSoilData.push(singleSoilSectionData);
		});

		return allFieldSoilData.sort(dynamicSort('Acres', 'descending'));
	};

	return (
		<div id='vrsMapId' ref={installVrsMap} style={{ width: 'calc(100% - 70px)', height: '100%' }}>
			<VrsMapPopUp
				reference={popupRef}
				cropName={popUpField?.CurrentCrop}
				fieldName={popUpField?.Name}
				farmName={popUpFarmName}
				acres={popUpField?.Area.toFixed(1)}
			/>
			<ExportTray
				mapRef={mapRef}
				display={MapMode.mode === SelectionInteractionMode.Default}
				displayZoneManagement={displayZoneManagementButton}
				onDownloadFieldPdf={downloadPdf}
				onExportPlanFile={onFileExportButtonClick}
				onZoneManagement={onZoneManagement}
			/>
			<VRSPaintTray
				mapRef={mapRef}
				display={MapMode.mode !== SelectionInteractionMode.Default && MapMode.mode !== SelectionInteractionMode.AddingZone}
				paintingEnabled={MapMode.mode === SelectionInteractionMode.Painting}
				selectedZone={selectedPaintZone()}
				onEnablePaint={enablePainting}
				onStartPainting={startPainting}
				zones={ZoneManagementFeatures ? Object.values(ZoneManagementFeatures.zones) : [] }
			/>
			<ZoomTray
				mapRef={mapRef}
				onCenterAll={onCenterAll}
				onCenterSelected={onCenterSelected}
				displayTools={selectedGrower.Id ? true : false}
			/>
			<VRSGeneralButtonTray
				mapRef={mapRef}
				displayTray={!!selectedFieldId}
				fieldSoilData={getFieldSoilData()}
				showSoilMapButton={showSoilMapButton}
				showFieldBoundary={() => setShowSoilMapButton(true)}
				showSoilZone={() => setShowSoilMapButton(false)}
			/>
			<ExportModal equipment={ExportEquipmentList} displayModal={displayExportModal} cancelModal={onCancelExportModal} exportFile={exportFieldPlan} />
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	IsLoadingGetSummary: state.grower.isLoadingGetPlantingPlansSummaries,
	IsLoadingRetrieveSummary: state.grower.isLoadingPlantingPlansSummaries,
	IsLoadingFieldPlanData: state.grower.isLoadingFieldPlantingPlan,
	ExportEquipmentList: state.ui.PlantingPlanEquipment,
	ZoneManagementFeatures: state.managementZoneEdits.zoneEditingState,
	MapMode: state.managementZoneEdits.mode,
	MapboxToken: state.config.MapboxAccessToken,
	SelectedZone: state.managementZoneEdits.selectedZone,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		GetSupportedEquipment: makeDispatch(dispatch, getSupportedEquipment),
		SetVRSMapInteractionMode: makeDispatch(dispatch, setVRSMapInteractionMode),
		SetSelectedZone: makeDispatch(dispatch, setSelectedZone),
		CutGeometry: makeDispatch(dispatch, cutPolygons),
		PaintZone: makeDispatch(dispatch, paintZone),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const VrsMap = connector(VrsMapComponent);