import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Input, Modal, ModalProps } from 'antd';
import { Button } from '../../components/Button/Button';
import { IPlanCoverConfig } from '../../logic/Models/Requests/PlanRequest';
import { PlanTypeEnum } from '../../logic/Models/Responses/PlanResponse';

const Body = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px 24px;
`;

const InputGroup = styled.div`
	border-bottom: thin solid black;
	margin-top: 35px;
`;

const InputLabel = styled.div`
	color: ${themeGet('colors.lightGray')};
	font-size: ${themeGet('fontSizes.extraSmall')};
`;

const StyledInput = styled(Input)`
	color: black;
	padding: 2px;
`;

const ThinButton = styled(Button)`
	border-width: 1px;
	font-weight: ${themeGet('fontWeights.normal')};
`;

interface IModalProps extends ModalProps
{
	onSubmit: (coverConfig: IPlanCoverConfig) => void;
	downloadPlanType: string;
}

export const DownloadModal = (props: IModalProps) =>
{
	const [title, setTitle] = useState<string>('');
	const [location, setLocation] = useState<string>('');
	const [author, setAuthor] = useState<string>('');
	const [additional, setAdditional] = useState<string>('');
	const [contact, setContact] = useState<string>('');
	const [recipient, setRecipient] = useState<string>('');
	const planType = props.downloadPlanType;

	const theme = useTheme();

	const DownloadFooter = (
		<div>
			<Button
				variant='light'
				onClick={(e) => props.onCancel(e)}
				className='cancelButton'
			>
				Cancel
			</Button>
			<Button
				variant='light'
				style={{ marginLeft: 24, color: theme.colors.primary }}
				onClick={() => props.onSubmit({
					Title: title,
					Author: author,
					Recipient: recipient,
					Location: location,
					Additional: additional,
					Contact: contact,
					LogoBase64: undefined
				})}
				className='submitButton'
			>
				Submit
			</Button>
		</div>
	);

	return (
		<Modal
			{...props}
			title='Plan Cover Page Details'
			className='DownloadModal'
			closable={false}
			footer={DownloadFooter}
			centered
		>
			<Body>
				<InputGroup style={{ marginTop: 0 }}>
					<InputLabel>Title</InputLabel>
					<StyledInput
						bordered={false}
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						className='titleInput'
					/>
				</InputGroup>
				<InputGroup>
					<InputLabel>Location</InputLabel>
					<StyledInput
						bordered={false}
						value={location}
						onChange={(e) => setLocation(e.target.value)}
						className='locationInput'
					/>
				</InputGroup>
				<InputGroup>
					<InputLabel>Presenter</InputLabel>
					<StyledInput
						bordered={false}
						value={author}
						onChange={(e) => setAuthor(e.target.value)}
						className='authorInput'
					/>
				</InputGroup>
				<InputGroup hidden={planType === PlanTypeEnum[PlanTypeEnum.ProductGamePlan]}>
					<InputLabel>Additional</InputLabel>
					<StyledInput
						bordered={false}
						value={additional}
						onChange={(e) => setAdditional(e.target.value)}
						className='additionalInput'
					/>
				</InputGroup>
				
				<InputGroup hidden={planType !== PlanTypeEnum[PlanTypeEnum.ProductGamePlan]}>
					<InputLabel>Contact</InputLabel>
					<StyledInput
						bordered={false}
						value={contact}
						onChange={(e) => setContact(e.target.value)}
						className='contactInput'
					/>
				</InputGroup>
				<InputGroup style={{ marginBottom: 35 }}>
					<InputLabel>Prepared For</InputLabel>
					<StyledInput
						bordered={false}
						value={recipient}
						onChange={(e) => setRecipient(e.target.value)}
						className='recipientInput'
					/>
				</InputGroup>
			</Body>
		</Modal>
	);
};