import { Tooltip } from 'antd';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { ColorTabButton } from '../../../components/ColorTabButton/ColorTabButton';
import { CropConfig, unknownId } from '../../../logic/store/Seeds/CropsSlice';
import { CalculateBags } from '../../../logic/Utility/CalculationUtilities';
import { IMaxScriptOrderData } from './MaxScriptMain';
import { ReactComponent as AlertIcon } from '../Icons/AlertIcon.svg';

const TabButtonContainer = styled.div`
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 20px;
	padding-bottom: 20px;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
`;

const ImportErrorContainer = styled.div`
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 20px;
	display: flex;
	flex-direction: column;
`;

const ImportContainer = styled.div`
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	margin-top: auto;
`;

interface ICropPlanOverviewProps
{
	cropPlanData: {[key: string]: IMaxScriptOrderData};
	showImportError: boolean;
	importOrder: () => void;
	openManageFields: () => void;
	openOverageModal: (cropId: string) => void;
	selectCropPlan: (planId: string) => void;
}

export const MaxScriptCropPlanOverview = (props: ICropPlanOverviewProps) =>
{
	const {
		cropPlanData,
		showImportError,
		importOrder,
		openManageFields,
		openOverageModal,
		selectCropPlan,
	} = props;

	const theme = useTheme();

	const displayCropPlanTabButtons = () =>
	{
		return Object.keys(cropPlanData).map(cropId =>
		{
			if (cropId === unknownId)
			{
				return;
			}
			const totalAcres = cropPlanData[cropId].CropPlanDetails.TotalFieldAcreage;
			const totalFields = cropPlanData[cropId].CropPlanDetails.TotalFieldCount;
			const disabled = totalFields === 0 || (!cropPlanData[cropId].CropPlanProducts || cropPlanData[cropId].CropPlanProducts.length === 0);
			const disabledTooltip = totalFields === 0 ? 'There are no fields for this Crop Plan.' : 
				(!cropPlanData[cropId].CropPlanProducts || cropPlanData[cropId].CropPlanProducts.length === 0) ?
					'There are no products ordered for this Crop Plan.'
					:
					'';
			const productOverages = cropPlanData[cropId].CropPlanProducts.map(product =>
			{
				const productUsage = calcProductUsage(cropId, product.HybridId);
				return product.Bags < productUsage;
			});

			return (
				<Tooltip key={cropId} title={disabled? disabledTooltip : ''} placement='right'>
					<div key={cropId} style={{ cursor: disabled ? 'not-allowed' : 'pointer', display: 'flex' }}>
						<ColorTabButton
							addCard={false}
							key={cropId}
							disabled={disabled}
							className={`CropPlanTabButton_${CropConfig()[cropId].cropName}`}
							title={CropConfig()[cropId].cropName}
							tabColor={CropConfig(theme)[cropId].maxScriptColor}
							displayNamesAndValues={[
								['Fields', totalFields?.toString()],
								['Acres', totalAcres?.toFixed(1)]
							]}
							onClick={() => selectCropPlan(cropId)}
							variant='reg'
						/>
						{
							productOverages.some(po => po) && 
							<div>
								<AlertIcon width={35} height={35} style={{ position: 'relative', top: 40, marginLeft: 5 }} onClick={() => openOverageModal(cropId)} />
							</div>
						}
					</div>
				</Tooltip>
			);
		});
	};

	const calcProductUsage = (cropId: string, hybridId: string): number =>
	{
		let totalAppliedAcresForHybrid = 0;
		let totalWeightedRate = 0;
		let totalAppliedBagsForHybrid = 0;
		// Determine how many bags are being used for the specified Hybrid
		if (cropPlanData[cropId].CropPlanFields)
		{
			// Bags per field = Acres * seeding rate / seeds per bag
			const specifiedHybridFields = cropPlanData[cropId].CropPlanFields.flatMap(f => f.Inventory);
			const seedsPerBag = CropConfig()[cropId].seedsPerBag;
			specifiedHybridFields.forEach(hybridField =>
			{
				if (hybridField && hybridField.HybridId === hybridId && hybridField.Acres > 0 && !hybridField.IsExternal)
				{
					// Acres applied
					totalAppliedAcresForHybrid += hybridField.Acres;

					// Weighted Rate
					totalWeightedRate += hybridField.Rate * hybridField.Acres;
				}
			});

			totalWeightedRate = totalWeightedRate > 0 && totalAppliedAcresForHybrid > 0 ? Math.ceil(totalWeightedRate/totalAppliedAcresForHybrid) : 0;
			totalAppliedBagsForHybrid = CalculateBags(totalAppliedAcresForHybrid, totalWeightedRate, seedsPerBag);
		}
		return totalAppliedBagsForHybrid;
	};

	return (
		<TabButtonContainer className='MaxScript_TabButtonContainer'>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.normal }}>Crop Plans</div>
				<Button variant='light' style={{ marginLeft: 'auto' }} onClick={openManageFields}>
					<span style={{ textDecoration: 'underline' }}>Manage Fields</span>
				</Button>
			</div>
			{
				showImportError ? <ImportErrorContainer>
					<span style={{ marginBottom: 10 }}>
						There was no order plan data. Please try importing your order or refresh to try again.
					</span>
					<Button variant='reversed' onClick={() => importOrder()}>
						Import Order
					</Button>
				</ImportErrorContainer>
					:
					cropPlanData && displayCropPlanTabButtons()
			}
			<ImportContainer>
				<div style={{ padding: 5, textAlign: 'center' }}>
					Missing Assignments? Try importing.
				</div>
				<Button variant='reversed' onClick={() => importOrder()}>
					Import Order
				</Button>
			</ImportContainer>
		</TabButtonContainer>
	);
};