import { Popover, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../../components/Button/Button';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { IProductLine } from '../../../../logic/Models/Requests/ProductPlanRequest';
import { IHybridResponse } from '../../../../logic/Models/Responses/SeedResponse';
import { ISeries, ISeriesWithHybrid } from '../../../../logic/store/Seeds/SeedsActions';
import { ReactComponent as PlusIcon } from '../../../../assets/images/PlusIcon.svg';
import { ReactComponent as StickyIcon } from '../../../../assets/images/StickyNote.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/Trashcan.svg';
import { ConnectedLocalPositioningDrawer } from './LocalPositioningDrawer';
import { IconButton } from '../../../../components/IconButton/IconButton';
import { ProductPlanCropType } from '../../../../logic/Models/Responses/ProductGamePlan';
import { ReactComponent as GreenAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Green.svg';
import { ReactComponent as YellowAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Yellow.svg';
import { ReactComponent as RedAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Red.svg';
import { HybridAvailability, SeriesAvailability } from '../SeriesAvailability';
import { soyId } from '../../../../logic/store/Seeds/CropsSlice';
import { ICustomTreatmentResponse } from '../../../../logic/Models/Responses/CustomTreatmentResponse';
import { themeGet } from '@styled-system/theme-get';
import { AvailabilitySection } from './HybridProductRow';

const StyledSeriesRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 50px;
	min-height: 50px;
	border: 1px solid;
	border-color: ${themeGet('colors.lightestGrey')};
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-top: 16px;
	align-items: center;

	.SeriesName { 
		color: ${themeGet('colors.darkGrey')};
		font-family: ${themeGet('fonts.base')};
		font-size: ${themeGet('fontSizes.extraSmall')};
		font-weight: ${themeGet('fontWeights.bold')};
		padding-left: 12px;
		padding-right: 12px;
		width: 88px;
	}

	.SeriesRm {
		color: ${themeGet('colors.darkGrey')};
		font-family: ${themeGet('fonts.base')};
		font-size: ${themeGet('fontSizes.extraSmall')};
		font-weight: ${themeGet('fontWeights.bold')};
		padding-left: 12px;
		padding-right: 12px;
	}

	.Spacer {
		flex-grow: 1;
	}

	.SeriesAction {
		padding-right: 12px;
		display: flex;
		flex-direction: row;
	}
`;

export interface ITreatment
{
	Price: number;
	Name: string;
	CustomTreatmentId?: string;
	IsActive?: boolean;
}

interface ISeriesProductRowProps
{
	defaultTreatmentName: string | undefined;
	planId: string;
	cropId: string | undefined;
	cropType: ProductPlanCropType | undefined;
	series: ISeries;
	seriesTemplate: ISeriesWithHybrid & {
		treatments?: ITreatment[];
	};
	selectedHybrids: IProductLine[];
	addNewProduct: (props: {
		customTreatments?: ICustomTreatmentResponse[], defaultTreatmentName?: string; planId: string; product: IHybridResponse; cropId: string; cropType: ProductPlanCropType; treatment?: ITreatment;
	}) => void;
	selectedTreatments: ITreatment[];
	removeSeries: (props: { planId: string; seriesId: string; }) => void;
	treatments?: ITreatment[];
	customTreatments?: ICustomTreatmentResponse[];
	// for soy
	firstProduct?: IHybridResponse;
}

/**
 * Represents a top-level row for a series on the main editing area of the product plan.
 * This will have multiple hybrids below it.
 */
export function SeriesProductRow(props: ISeriesProductRowProps)
{
	const {
		series,
		defaultTreatmentName,
		seriesTemplate,
		firstProduct,
		selectedHybrids,
		selectedTreatments,
		addNewProduct,
		removeSeries,
		planId,
		cropId,
		cropType,
		treatments,
		customTreatments = []
	} = props;

	const theme = useTheme();

	const [showDrawer, setShowDrawer] = useState(false);
	const addRowForProduct = useCallback((product: IHybridResponse) =>
	{
		addNewProduct({
			customTreatments,
			defaultTreatmentName,
			planId,
			cropId,
			cropType,
			product
		});
	}, [addNewProduct, cropId, cropType, customTreatments, defaultTreatmentName, planId]);

	const addRowForTreatment = useCallback((treatment: ITreatment, checked: boolean) =>
	{
		addNewProduct({
			customTreatments,
			defaultTreatmentName,
			treatment,
			product: firstProduct,
			planId,
			cropId,
			cropType
		});
	}, [addNewProduct, customTreatments, defaultTreatmentName, firstProduct, planId, cropId, cropType]);

	const addRowForCustomTreatment = useCallback((treatment: ICustomTreatmentResponse, checked: boolean) =>
	{
		const {
			Name,
			Id: CustomTreatmentId,
			PricePerBag: Price
		} = treatment;

		addNewProduct({
			customTreatments,
			defaultTreatmentName,
			treatment: { Name, CustomTreatmentId, Price },
			product: firstProduct,
			planId,
			cropId,
			cropType
		});
	}, [addNewProduct, customTreatments, defaultTreatmentName, firstProduct, planId, cropId, cropType]);

	const onRemoveSeries = useCallback(() => 
	{
		removeSeries({
			planId,
			seriesId: series.seriesId
		});
	}, [planId, removeSeries, series.seriesId]);

	const filteredCustomTreatments = customTreatments.filter(customTreatment =>
		!customTreatment.IsDeleted || selectedTreatments.some(selected => selected.CustomTreatmentId === customTreatment.Id
		));

	return <StyledSeriesRow className={`SeriesContainer SeriesContainer-${series.seriesId}`}>
		<SeriesAvailability className='SeriesAvailability'>
			{series.availability == 'Green' ? <GreenAvailabilityIcon />
				: series.availability === 'Yellow' ? <YellowAvailabilityIcon />
					: <RedAvailabilityIcon />
			}
		</SeriesAvailability>
		<div className='SeriesName'>{series.seriesName}</div>
		<div className='SeriesRm'>RM: {series.relativeMaturity}</div>
		{
			(cropId !== soyId) && <div className='SeriesHybridPicker'>
				<Popover
					placement='bottom'
					trigger='click'
					content={
						<div style={{display:'flex', flexDirection:'column', minWidth:185}}>{
							seriesTemplate.hybrids.filter(h => h.Availability !== 'None').map(hybrid =>
							{
								return <Tooltip key={hybrid.Id} title={`Add another row for ${hybrid.Name}`}>
									<Button 
										style={{marginBottom: 8, display:'flex', flexDirection: 'row', alignItems:'center'}} 
										variant='outlined' 
										className={'AddHybridButton AddHybridButton-${hybrid.Id}'}
										onClick={() => addRowForProduct(hybrid)}>
										<AvailabilitySection hybrid={hybrid} />  {hybrid.Name}
									</Button>
								</Tooltip>;
							})
						}</div>
					}>
					<Button style={{
						width: 206,
						height: 32,
						marginLeft: 32,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center'
					}} variant='outlined'>
						<span style={{ paddingLeft: 9 }}>Add trait(s)</span>
						<span style={{ paddingRight: 9, display: 'flex', flexDirection: 'row', alignItems: 'center' }}><PlusIcon fill={theme.colors.darkGrey} /></span>
					</Button>
				</Popover>
			</div>
		}
		{(cropId === soyId && ((treatments && !!treatments.length) || customTreatments.length) && selectedTreatments) && <div className='SeriesTreatmentPicker' style={{
			marginLeft: 32,
		}}>
			<Popover
				placement='bottom'
				trigger='click'
				content={
					<div style={{display:'flex', flexDirection:'column', minWidth:185}}>
						{
							treatments.map(treatment => 
							{
								return <Tooltip key={treatment.Name} title={`Add another row for ${treatment.Name}`}>
									<Button 
										style={{marginBottom: 8}} 
										variant='outlined' 
										className={'AddHybridButton AddHybridButton-${hybrid.Id}'}
										onClick={() => addRowForTreatment(treatment, false)}>
										{treatment.Name}
									</Button>
								</Tooltip>;
							}).concat(filteredCustomTreatments.map(customTreatment =>
							{
								return <Tooltip key={customTreatment.Name} title={`Add another row for ${customTreatment.Name}`}>
									<Button 
										style={{marginBottom: 8}} 
										variant='outlined' 
										className={'AddHybridButton AddHybridButton-${hybrid.Id}'}
										onClick={() => addRowForCustomTreatment(customTreatment, false)}>
										{customTreatment.Name}
									</Button>
								</Tooltip>;
							}))
						}
					</div>
				}>
				<Button style={{
					height: 32,
					padding: '0 10px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center'
				}} variant='outlined'>
					<span style={{ paddingRight: 10 }}>Add Treatment(s)</span>
					<span style={{ paddingRight: 9, display: 'flex', flexDirection: 'row', alignItems: 'center' }}><PlusIcon fill={theme.colors.darkGrey} /></span>
				</Button>
			</Popover>
		</div>}
		<div className='Spacer' />
		<div className='SeriesAction'>
			<IconButton className='SeriesActionEditLocalPositioning' onClick={() => setShowDrawer(true)}>
				<StickyIcon />
			</IconButton>
			<IconButton className='SeriesActionDelete' onClick={onRemoveSeries} color={theme.colors.redLM}>
				<DeleteIcon />
			</IconButton>

		</div>
		<ConnectedLocalPositioningDrawer
			customTreatments={
				customTreatments.filter(customTreatment => selectedTreatments.find(selected => selected.Name === customTreatment.Name))
			}
			cropId={cropId} cropType={cropType} visible={showDrawer} planId={planId} series={series} products={selectedHybrids} onClose={() => setShowDrawer(false)} />
	</StyledSeriesRow >;
}

export const ReadOnlySeriesProductRow = (props: { series: ISeries }) =>
{
	const { series } = props;
	return <StyledSeriesRow className={`SeriesContainer SeriesContainer-${series.seriesId}`}>
		<div className='SeriesAvailability' />
		<div className='SeriesName'>{series.seriesName}</div>
		<div className='SeriesRm'>RM: {series.relativeMaturity}</div>
		<div className='Spacer' />
	</StyledSeriesRow>;
};