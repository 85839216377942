import React, { useState } from 'react';
import { TableRowType } from '../../../components/Table/TableRows';
import { ReactComponent as DownloadPDF } from '../../../assets/images/PDFIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/Trashcan.svg';
import { ReactComponent as PrescriptionIcon } from '../../../assets/images/Prescription.svg';
import { ReactComponent as DollarIcon } from '../../../assets/images/DollarIcon.svg';
import { StyledRowCell } from '../../../components/Table/TableRow';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { Button } from '../../../components/Button/Button';
import { IconButton } from '../../../components/IconButton/IconButton';
import { RecallStatus } from './RecallStatus';
import { IPlanResponse, PlanType, PlanTypeEnum, PlanStatusEnum } from '../../../logic/Models/Responses/PlanResponse';
import { Link, useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { ReactComponent as ShoppingCart } from '../../../assets/images/ShoppingCart.svg';
import { Features, getFeatureFlag } from '../../../logic/store/User/ConfigSlice';
import { ReactComponent as GetPDFIcon } from '../../../assets/images/GetPdf.svg';
import { ReactComponent as EditVRS } from '../../../assets/images/EditVRS.svg';
import { Tooltip } from 'antd';
import { themeGet } from '@styled-system/theme-get';

const PlanDisplay: { [key in PlanType]: string } = {
	'FieldGamePlan': 'Field Proposal',
	'OrderGamePlan': 'Order Proposal',
	'ProductGamePlan': 'Acre Proposal',
	'Order': 'MaxScript',
};
const PlanColors = (theme: DefaultTheme) : { [key in PlanType]: string } =>
{
	const planColors = {
		'FieldGamePlan': theme.colors.purpleLM,
		'OrderGamePlan': theme.colors.blueLM,
		'ProductGamePlan': theme.colors.primary,
		'Order': theme.colors.orderGreen,
	};

	return planColors;
};
const UpdateWarningModal = (props: {
	visible: boolean;
	onCancel: () => void;
	onContinue: () => void;
}) =>
{
	const { visible, onCancel, onContinue } = props;
	return <StyledModal open={visible} onCancel={onCancel} closable={true}>
		<div>
			<span>
				Any changes made to an accepted proposal will need to be approved by the grower. To see these updates in the PDF and quote,
				please change the status to "presented" and update to "accepted" when your grower approves the changes.
			</span>
			<div style={{ marginTop: 20 }}>
				<Button fullWidth variant='outlined' onClick={onContinue}>Continue</Button></div>
		</div>
	</StyledModal>;
};

const utilityState = (state: RootState) => ({
	currentCropYear: state.crops.CropYear,
	featureFlags: state.config.FeatureFlags,
	isLoadingPlanLaunch: state.grower.isLoadingPlanLaunch,
	selectedFieldIds: state.ui.SelectedFields,
	Growers: state.grower.Growers,
	SelectedGrowerId: state.ui.SelectedGrowerId,
});
const emptyDispatch = (dispatch: AppDispatch) => ({
});
const utilityPropsConnector = connect(utilityState, emptyDispatch);
type UtilityPropsFromRedux = ConnectedProps<typeof utilityPropsConnector>;

interface IPlanProps extends IPlanResponse 
{
	onLaunchExisting: (id: string) => void;
	selectProductGamePlanId: (id: string) => void;
	selectGamePlanId: (id: string) => void;
	currentlySelectedCropYear: string;
	isGhx: boolean;
}
const PlanComponent = (props: IPlanProps & UtilityPropsFromRedux) =>
{
	const {
		Id,
		Status,
		Type,
		currentCropYear,
		currentlySelectedCropYear,
		featureFlags,
		Growers,
		SelectedGrowerId,
		isGhx,
		onLaunchExisting,
		selectProductGamePlanId,
		selectGamePlanId,
	} = props;

	const theme = useTheme();

	const history = useHistory();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const selectedGrower = Growers.find(g => g.Id === SelectedGrowerId);
	const allFieldsCount: number = selectedGrower.Farms ? selectedGrower.Farms.flatMap(fa => fa.Fields).length : 0;

	const isMaxScriptEnabled = getFeatureFlag(featureFlags, Features.MaxScript) && allFieldsCount !== 0;

	const isOrder = Type === 'Order';

	const onPlanClick = () => 
	{
		if (Type === 'ProductGamePlan')
		{
			selectProductGamePlanId(Id);
			history.push('/fieldactivities/productplan');
		}
		else if (Type === 'FieldGamePlan')
		{
			selectGamePlanId(Id);
			history.push('/fieldactivities/fieldplan');
		}
		else
		{
			onLaunchExisting(Id);
		}
	};

	const clickHandler = () =>
	{
		if (Status === 'Accepted')
		{
			setIsModalOpen(true);
		}
		else
		{
			onPlanClick();
		}
	};

	const isPlanOld = Number.parseInt(currentlySelectedCropYear) < Number.parseInt(currentCropYear);

	return <>
		{ 
			isOrder ? 
				<Tooltip
					title={isMaxScriptEnabled ? '' : allFieldsCount === 0 ? 'Please add fields in Field Activities to finish your Maxscript.' : 'Coming Soon!'}
					className='MaxScript_Pill_Tooltip'
					placement='top'
				>
					<span style={{ cursor: isPlanOld || !isMaxScriptEnabled ? 'not-allowed' : 'pointer' }}>
						<Link 
							to={{
								pathname: '/fieldactivities/maxscript',
								state: { planId: Id, selectedYear: currentlySelectedCropYear }
							}}
							style={isPlanOld || !isMaxScriptEnabled ? {pointerEvents: 'none'} : null}
						>
							<StyledRowCell style={{
								borderRadius: '10px',
								color: 'white',
								margin: '0 auto',
								width: '80%',
								cursor: isPlanOld ? 'not-allowed' : 'pointer',
								opacity: isPlanOld ? 0.6 : 1.0,
								padding: '3px',
								lineHeight: theme.lineHeights.base,
								backgroundColor: !isGhx ? PlanColors(theme)['OrderGamePlan'] : PlanColors(theme)[Type] || 'black'
							}}>
								{!isGhx ? PlanDisplay['OrderGamePlan'] : PlanDisplay[Type]}
							</StyledRowCell>
						</Link>
					</span>
				</Tooltip>
				:
				<StyledRowCell onClick={() => isPlanOld ? null : clickHandler()} style={{
					borderRadius: '10px',
					color: 'white',
					margin: '0 auto',
					width: '80%',
					cursor: isPlanOld ? 'not-allowed' : 'pointer',
					opacity: isPlanOld ? 0.6 : 1.0,
					padding: '3px',
					lineHeight: theme.lineHeights.base,
					backgroundColor: PlanColors(theme)[Type] || 'black'
				}}>
					{PlanDisplay[Type]}
				</StyledRowCell>
		}
		<UpdateWarningModal visible={isModalOpen}
			onCancel={() => setIsModalOpen(false)}
			onContinue={() =>
			{
				setIsModalOpen(false);
				onPlanClick();
			}} />
	</>;
};
const Plan = utilityPropsConnector(PlanComponent);

interface INameProps extends IPlanResponse
{
	onLaunchExisting: (id: string) => void;
	selectProductGamePlanId: (id: string) => void;
	selectGamePlanId: (id: string) => void;
	currentlySelectedCropYear: string;
	launchCropEdgeSso: () => void;
	launchLogisticsSso: (salesforceOrderId?: string) => void;
	isGhx: boolean;
	salesforceOrderId?: string;
}
const NameComponent = (props: INameProps & UtilityPropsFromRedux) =>
{
	const {
		Id,
		Name,
		Type,
		Status,
		currentlySelectedCropYear,
		currentCropYear,
		isGhx,
		salesforceOrderId,
		onLaunchExisting,
		selectProductGamePlanId,
		launchCropEdgeSso,
		selectGamePlanId,
		launchLogisticsSso,
	} = props;

	const theme = useTheme();
	const history = useHistory();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const isOrder = Type === 'Order';

	// GHX users will be sent to logistics, anyone else will be sent to CropEdge
	const launchCropEdgeSsoForCurrentUser = () =>
	{
		if (isGhx)
		{
			launchLogisticsSso(salesforceOrderId);
		}
		else
		{
			launchCropEdgeSso();
		}
	};

	const onPlanClick = () => 
	{
		if (Type === 'ProductGamePlan')
		{
			selectProductGamePlanId(Id);
			history.push('/fieldactivities/productplan');
		}
		else if (Type === 'FieldGamePlan')
		{
			selectGamePlanId(Id);
			history.push('/fieldactivities/fieldplan');
		}
		else
		{
			onLaunchExisting(Id);
		}
	};

	const clickHandler = () =>
	{
		if (Status === 'Accepted')
		{
			setIsModalOpen(true);
		}
		else
		{
			onPlanClick();
		}
	};

	const isPlanOld = Number.parseInt(currentlySelectedCropYear) < Number.parseInt(currentCropYear);

	return <>
		{
			isOrder ?
				// TODO: Replace this Link for the onClick once iFrame functionality is supported for Salesforce/CropEdge
				//<Link to={{ pathname: '/fieldactivities/cropedgeview', state: { orderName: Name } }} style={{ color: 'inherit' }}>
				<StyledRowCell onClick={() => launchCropEdgeSsoForCurrentUser()} style={{
					width: '90%',
					display: 'block',
					margin: '0 auto',
					textOverflow: 'ellipsis',
					color: isOrder ? theme.colors.blueLM : 'inherit',
					textDecoration: isOrder ? 'underline' : 'inherit',
					textAlign: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					cursor: isPlanOld ? 'not-allowed' : 'pointer',
					opacity: isPlanOld ? 0.5 : 1.0,
				}}>{Name}</StyledRowCell>
				//</Link>
				:
				<StyledRowCell onClick={() => isPlanOld ? null : clickHandler()} style={{
					width: '90%',
					display: 'block',
					margin: '0 auto',
					textOverflow: 'ellipsis',
					textAlign: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					cursor: isPlanOld ? 'not-allowed' : 'pointer',
					opacity: isPlanOld ? 0.5 : 1.0,
				}}>{Name}</StyledRowCell>
		}
		
		<UpdateWarningModal visible={isModalOpen}
			onCancel={() => setIsModalOpen(false)}
			onContinue={() =>
			{
				setIsModalOpen(false);
				onPlanClick();
			}} />
	</>;
};
const Name = utilityPropsConnector(NameComponent);

const LastUpdated = (props: IPlanResponse) =>
{
	const { Modified } = props;
	const updated = new Date(Modified);
	const date = updated.toLocaleDateString();
	const time = updated.toLocaleTimeString();
	return <StyledRowCell className='last-updated' style={{ width: '90%', display: 'grid' }}>
		<span>{date}</span>
		<span>{time}</span>
	</StyledRowCell>;
};
const CornPerAcre = (props: IPlanResponse) =>
{
	const { CostsByCrop } = props;
	const cornCost = CostsByCrop && Object.keys(CostsByCrop).includes('corn') ? 
		Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(CostsByCrop['corn'].CostPerAcre) : '';
	return <StyledRowCell style={{ width: '90%', textAlign: 'center' }}>
		{cornCost}
	</StyledRowCell>;
};
const SoyPerAcre = (props: IPlanResponse) =>
{
	const { CostsByCrop } = props;
	const soyCost = CostsByCrop && Object.keys(CostsByCrop).includes('soybean') ? 
		Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(CostsByCrop['soybean'].CostPerAcre) : '';
	return <StyledRowCell style={{ width: '90%', textAlign: 'center' }}>
		{soyCost}
	</StyledRowCell>;
};
const TotalPrice = (props: IPlanResponse) =>
{
	const { CostsByCrop } = props;
	let totalCost: number = 0;
	if (CostsByCrop)
	{
		Object.keys(CostsByCrop).forEach(key =>
		{
			totalCost += CostsByCrop[key].TotalCost ?? 0;
		});
	}
	return <StyledRowCell style={{ width: '90%', textAlign: 'center' }}>
		{totalCost > 0 ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCost) : ''}
	</StyledRowCell>;
};

const StyledButtonCell = styled(StyledRowCell)`
	height: 100%;
	margin-left: auto;
	margin-right: 0;
	padding-left: 5%;
	@media (hover: none) {
		padding-left: 15%;
	}
	border-left: 1px solid;
	border-left-color: ${themeGet('colors.lightestGrey')};
	svg {
		position: inherit;
	}
`;
const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
`;
const AcceptedQuoteTypes = [
	PlanTypeEnum[PlanTypeEnum.FieldGamePlan],
	PlanTypeEnum[PlanTypeEnum.ProductGamePlan]
];

interface IButtonCellProps extends IPlanResponse
{
	currentlySelectedCropYear: string;
	enableShoppingCart: boolean;
	growerHasZip: boolean;
	hasOrderedPlan: boolean;
	isLoadingDelete: boolean;
	canSeeCompetitiveAllowance: boolean;
	onClickDownload: (planId: string, planType: string) => void;
	onClickDownloadVRS: () => void;
	onClickInvite: (planId: string, planType: string) => void;
	onDeletePlan: (planId: string) => void;
	selectPlan: (planId: string) => void;
}
const ButtonCellComponent = (props: IButtonCellProps & UtilityPropsFromRedux) =>
{
	const {
		Id, Status, Type, isLoadingDelete, enableShoppingCart, growerHasZip, currentCropYear, currentlySelectedCropYear,
		hasOrderedPlan, SelectedGrowerId, Growers, canSeeCompetitiveAllowance,
		onClickDownload, onClickInvite, onDeletePlan, onClickDownloadVRS, selectPlan } = props;

	const theme = useTheme();

	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [openVRSModal, setOpenVRSModal] = useState(false);
	const selectedGrower = Growers.find(g => g.Id === SelectedGrowerId);
	const allFieldsCount: number = selectedGrower.Farms ? selectedGrower.Farms.flatMap(fa => fa.Fields).length : 0;
	const allFields = selectedGrower?.Farms ? selectedGrower.Farms.flatMap(fa => fa.Fields) : [];
	const noCropsOnAllFields: boolean = allFields && allFields.length > 0 ? allFields.every(f => f.CurrentCrop?.toLowerCase() === 'unknown') : false;

	const downloadVRS = () =>
	{
		setOpenVRSModal(false);
		onClickDownloadVRS();
	};

	const enableDownload = growerHasZip || Type === PlanTypeEnum[PlanTypeEnum.OrderGamePlan];
	const isOrder = Type === 'Order';
	const enableVRS = hasOrderedPlan ? Type === 'Order' ? true : false : // if we have an order and the row is the order, enable MaxScript, else do not
		// If we do not have an order, enable for Field/Order Plan
		Type === PlanTypeEnum[PlanTypeEnum.FieldGamePlan] || Type === PlanTypeEnum[PlanTypeEnum.OrderGamePlan];
	const isPlanOld = Number.parseInt(currentlySelectedCropYear) < Number.parseInt(currentCropYear);

	return <StyledButtonCell className='recall-button-section' style={{ borderLeft: isOrder ? '1px solid' : `1px ${theme.colors.lightestGrey} solid`}}>
		{(Status === 'Accepted' || Status === 'Order') &&
		<>
			{
				enableVRS && allFieldsCount > 0 && !noCropsOnAllFields ?
					<StyledIconButton
						className='VRS-button'
						tooltip={'VRS'}
						disabled={!enableVRS}
						showDisabledTooltip={true}
						placement='bottom'
						hoverbgcolor={theme.colors.lightestGrey}
						onClick={() => Status !== 'Order' ? setOpenVRSModal(true) : null}
					>
						{
							Status === 'Order' &&
							<Link to={{ pathname: '/fieldactivities/VRS', state: { selectedYear: currentlySelectedCropYear } }}>
								<PrescriptionIcon style={{ display: 'flex' }} />
							</Link>
						}
						{
							Status !== 'Order' &&
							<PrescriptionIcon style={{ display: 'flex' }} />
						}
					</StyledIconButton>
					:
					noCropsOnAllFields ? 
						<StyledIconButton
							className='VRS-button'
							tooltip={'Please assign a crop to at least one field to enable VRS.'}
							disabled={true}
							showDisabledTooltip={true}
							placement='bottom'
							hoverbgcolor={theme.colors.lightestGrey}
						>
							<PrescriptionIcon style={{ display: 'flex' }} />
						</StyledIconButton>
						:
						enableVRS && allFieldsCount === 0 && 
						<StyledIconButton
							className='VRS-button'
							tooltip={'Please add fields to enable VRS.'}
							disabled={true}
							showDisabledTooltip={true}
							placement='bottom'
							hoverbgcolor={theme.colors.lightestGrey}
						>
							<PrescriptionIcon style={{ display: 'flex' }} />
						</StyledIconButton>
			}
			{
				(AcceptedQuoteTypes.indexOf(Type) > -1 && !hasOrderedPlan ) &&
				<StyledIconButton
					className='quote-button'
					tooltip={!enableShoppingCart ? 'Shopping Cart is not available for this grower' : 'Shopping Cart'}
					disabled={!enableShoppingCart || isPlanOld}
					showDisabledTooltip={true}
					placement='bottom'
					hoverbgcolor={theme.colors.lightestGrey}
				>
					<Link to={Type === PlanTypeEnum[PlanTypeEnum.ProductGamePlan]
						? { pathname: '/fieldactivities/productshoppingcart', state: { planId: Id } }
						: { pathname: '/fieldactivities/fieldshoppingcart', state: { planId: Id } }}>
						<ShoppingCart style={{ display: 'flex' }} />
					</Link>
				</StyledIconButton>
			}
			<StyledModal className='VRS-modal' title='VRS' open={openVRSModal} onCancel={() => setOpenVRSModal(false)}>
				<div style={{ display: 'flex', justifyContent: 'center', height: '100px' }}>
					<Button variant='light' className='VRS_GetPDF' style={{ height: 'auto', padding: 10, width: 100 }} onClick={() => downloadVRS()}>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<GetPDFIcon />
							<span style={{ paddingTop: 2 }}>Get PDF</span>
						</div>
					</Button>
					<div style={{ paddingLeft: 30 }}>
						<Link to={{ pathname: '/fieldactivities/VRS', state: { selectedYear: currentlySelectedCropYear } }}>
							<Button variant='light' className='VRS_Edit' style={{ height: 'auto', paddingTop: 10, paddingBottom: 10, width: 100 }}>
								<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
									<EditVRS style={{ position: 'relative', left: 3 }} />
									<span style={{ paddingTop: 2 }}>Edit VRS</span>
								</div>
							</Button>
						</Link>
					</div>
				</div>
			</StyledModal>
		</>}
		{(Status !== 'Order' && canSeeCompetitiveAllowance) && <>
			<StyledIconButton className='competitive-allowance-button' onClick={() => selectPlan(Id)}
				tooltip='Open Competitive Allowance'
				placement='bottom'
				hoverbgcolor={theme.colors.lightestGrey}>
				<Link to={{ pathname: '/fieldactivities/competitiveallowance', state: { planId: Id } }}>
					<DollarIcon style={{ position: 'relative', top: 2 }} />
				</Link>
			</StyledIconButton>
		</>}
		<StyledIconButton
			className='download-button'
			onClick={() => [
				PlanStatusEnum[PlanStatusEnum.Planned],
				PlanStatusEnum[PlanStatusEnum.Accepted]
			].indexOf(Status) > -1 ? onClickInvite(Id, Type) : onClickDownload(Id, Type)}
			tooltip={!enableDownload ?
				'Grower zip code is required to generate a Field/Acre Proposal PDF. Please update Grower information and try again.'
				:
				isOrder && allFieldsCount === 0 ? 'Please add fields to download the VRS Pdf.' : 'Download' }
			placement='bottom'
			hoverbgcolor={theme.colors.lightestGrey}
			disabled={!enableDownload || (isOrder && allFieldsCount === 0)}
			showDisabledTooltip={true}
		>
			<DownloadPDF width={24} style={{ position: 'relative' }} />
		</StyledIconButton>
		{Status === 'Draft' && <>
			<StyledIconButton className='delete-button' onClick={() => setDeleteModalOpen(true)}
				tooltip='Delete'
				placement='bottom'
				color={theme.colors.redLM}
				hoverbgcolor={theme.colors.lightestGrey}>
				<DeleteIcon />
			</StyledIconButton>
			<StyledModal className='delete-modal' title='Delete Plan' open={isDeleteModalOpen} onCancel={() => setDeleteModalOpen(false)}>
				<span>Deleting this Proposal will remove it from your list. It can not be recovered.</span>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
					<Button className='delete-modal-cancel-button' variant='outlined' style={{ marginRight: '15px' }} onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
					<Button className='delete-modal-delete-button'
						disabled={isLoadingDelete}
						variant='dark'
						onClick={() =>
						{
							if (!isLoadingDelete)
							{
								onDeletePlan(Id);
								setDeleteModalOpen(false);
							}
						}}>
						Delete
					</Button>
				</div>
			</StyledModal>
		</>
		}
	</StyledButtonCell >;
};
const ButtonCell = utilityPropsConnector(ButtonCellComponent);

interface IGamePlanRecallRows
{
	currentlySelectedCropYear: string;
	currentlySelectedId?: string;
	data: IPlanResponse[];
	enableShoppingCart: boolean;
	growerHasZip: boolean;
	hasOrderedPlan: boolean;
	isLoadingDelete: boolean;
	isGhx: boolean;
	canSeeCompetitiveAllowance: boolean;
	deletePlan: (planId: string) => void;
	launchCropEdgeSso: () => void;
	onClickDownload: (planId: string, planType: string) => void;
	onClickDownloadVRS: () => void;
	onClickInvite: (planId: string, planType: string) => void;
	onLaunchExisting: (planId: string) => void;
	selectProductGamePlanId: (planId: string) => void;
	selectGamePlanId: (planId: string) => void;
	launchLogisticsSso: (salesforceOrderId?: string) => void;
}

export const GamePlanRecallRows = (props: IGamePlanRecallRows): TableRowType<IPlanResponse>[] =>
{
	const { 
		currentlySelectedCropYear,
		currentlySelectedId,
		data,
		enableShoppingCart,
		growerHasZip,
		hasOrderedPlan,
		isLoadingDelete,
		isGhx,
		canSeeCompetitiveAllowance,
		deletePlan,
		launchCropEdgeSso,
		launchLogisticsSso,
		onClickDownload,
		onClickDownloadVRS,
		onClickInvite,
		onLaunchExisting,
		selectProductGamePlanId,
		selectGamePlanId,
	} = props;

	return data.map((data: IPlanResponse, index) =>
	{
		const keyPrefix = `plan-cell-${data.Id}-${index}`;
		return {
			data,
			items: [
				<RecallStatus key={`${keyPrefix}-status`} {...data} currentlySelectedId={currentlySelectedId} 
					currentlySelectedCropYear={currentlySelectedCropYear} hasOrderedPlan={hasOrderedPlan} />,
				<Plan key={`${keyPrefix}-plan`} {...data} onLaunchExisting={onLaunchExisting} 
					selectProductGamePlanId={selectProductGamePlanId} selectGamePlanId={selectGamePlanId} 
					currentlySelectedCropYear={currentlySelectedCropYear} isGhx={isGhx} />,
				<Name key={`${keyPrefix}-name`} {...data} onLaunchExisting={onLaunchExisting} 
					selectProductGamePlanId={selectProductGamePlanId} selectGamePlanId={selectGamePlanId} 
					currentlySelectedCropYear={currentlySelectedCropYear} launchCropEdgeSso={launchCropEdgeSso}
					isGhx={isGhx} launchLogisticsSso={launchLogisticsSso} salesforceOrderId={data.SalesforceOrderId} />,
				<LastUpdated key={`${keyPrefix}-lastUpdated`} {...data} />,
				<CornPerAcre key={`${keyPrefix}-cornperacre`} {...data} />,
				<SoyPerAcre key={`${keyPrefix}-soyperacre`} {...data} />,
				<TotalPrice key={`${keyPrefix}-totalprice`} {...data} />,
				<ButtonCell
					key={`${keyPrefix}-buttons`}
					{...data}
					currentlySelectedCropYear={currentlySelectedCropYear}
					enableShoppingCart={enableShoppingCart}
					growerHasZip={growerHasZip}
					hasOrderedPlan={hasOrderedPlan}
					isLoadingDelete={isLoadingDelete}
					onClickDownload={onClickDownload}
					onClickDownloadVRS={onClickDownloadVRS}
					onClickInvite={onClickInvite}
					onDeletePlan={deletePlan}
					selectPlan={selectGamePlanId}
					canSeeCompetitiveAllowance={canSeeCompetitiveAllowance} 
				/>,
			]
		};
	});
};