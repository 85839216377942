
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { AppDispatch, RootState } from '../Store';
import { CropConfig } from './CropsSlice';

interface IPlantingRateResponse
{
	[seriesName: string]: {
		best_pop: {
			value: {
				yield: {
					[yieldEnvironment: number]: number
				}
			},
			error?: string;
		}
	}
}

export interface IGetPlantingRatesRequest
{
	cropId?: string;
	cropName?: string;
	seriesNames: string[];
	vrs?: boolean;
	minPopulationRate?: number,
	maxPopulationRate?: number
}
export const getPlantingRates = createTracedAsyncThunk<ISeriesYields, IGetPlantingRatesRequest, { dispatch: AppDispatch, state: RootState }>(
	'planting_rates/get',
	async (context, request, thunkAPI) =>
	{
		try
		{
			const {cropId, cropName, seriesNames, vrs} = request;

			const cropConfig = cropId ?
				CropConfig()[cropId] : cropName ?
					Object.values(CropConfig()).find(cc => cc.cropName.toLowerCase() === cropName.toLowerCase()) : undefined;
			if (!cropConfig)
			{
				thunkAPI.rejectWithValue('Could not find correct crop configuration.');
				return;
			}

			const determinedCropName = cropName ? cropName : CropConfig()[cropId].cropName;

			const yieldSteps = [];
			const yldnterval = vrs ? cropConfig.variableRateYieldInterval : cropConfig.yieldInterval;
			const maxYield = vrs ? cropConfig.maxVRSYield + 75 : cropConfig.maxYield + 75;
			const minYield = vrs ? cropConfig.minYield - 75 : cropConfig.minYield - 75;			
			for (let y = minYield, yieldInterval = yldnterval; y <= maxYield; y += yieldInterval)
			{
				yieldSteps.push(vrs ? y.toFixed(2) : y);
			}
			const { auth } = thunkAPI.getState();
			const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/4/seeds/rates`, {
				method: 'POST',
				body: JSON.stringify({
					productIds: seriesNames,
					outputs: ['best_pop'],
					parameters: {
						'crop': determinedCropName,
						yield: yieldSteps,
						minPopulationRate: request.minPopulationRate ?? cropConfig.minSeedingRate,
						maxPopulationRate: request.maxPopulationRate ?? cropConfig.maxSeedingRate
					}
				}),
				headers: {
					Authorization: `Bearer ${auth.userAuthToken}`,
					'Content-Type': 'application/json'
				}
			});

			if (!response.ok)
			{
				return thunkAPI.rejectWithValue(await response.text());
			}

			const data: IPlantingRateResponse = await response.json();
			// Re-organize the planting rate response into an easier to access dictionary
			// [seriesName: string]: { [yieldEnvironment: number]: number };
			return Object.keys(data)
				.reduce((lookup, key) =>
				{
					const seriesYields = data[key].best_pop.value?.yield;
					if (!data[key].best_pop.error && seriesYields !== undefined)
					{
						lookup[key] = seriesYields;
					}
					return lookup;
				}, {});
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IGetPlantingRatesSpecificRequest
{
	cropId?: string;
	cropName?: string;
	seriesNames: string[];
	yields: string[];
	minPopulationRate?: number,
	maxPopulationRate?: number
}
export const getPlantingRatesSpecificYield = createTracedAsyncThunk<ISeriesYields, IGetPlantingRatesSpecificRequest, { dispatch: AppDispatch, state: RootState }>(
	'planting_rates_specific/get',
	async (context, request, thunkAPI) =>
	{
		try
		{
			const { cropId, cropName, seriesNames, yields } = request;

			const cropConfig = cropId ?
				CropConfig()[cropId] : cropName ?
					Object.values(CropConfig()).find(cc => cc.cropName.toLowerCase() === cropName.toLowerCase()) : undefined;
			if (!cropConfig)
			{
				thunkAPI.rejectWithValue('Could not find correct crop configuration.');
				return;
			}

			const determinedCropName = cropName ? cropName : CropConfig()[cropId].cropName;
			const { config, auth } = thunkAPI.getState();
			const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/4/seeds/rates`, {
				method: 'POST',
				body: JSON.stringify({
					productIds: seriesNames,
					outputs: ['best_pop'],
					parameters: {
						'crop': determinedCropName,
						yield: yields,
						minPopulationRate: request.minPopulationRate ?? cropConfig.maxSeedingRate,
						maxPopulationRate: request.maxPopulationRate ?? cropConfig.maxSeedingRate
					}
				}),
				headers: {
					Authorization: `Bearer ${auth.userAuthToken}`,
					'Content-Type': 'application/json'
				}
			});

			if (!response.ok)
			{
				return thunkAPI.rejectWithValue(await response.text());
			}

			const data: IPlantingRateResponse = await response.json();
			// Re-organize the planting rate response into an easier to access dictionary
			// [seriesName: string]: { [yieldEnvironment: number]: number };
			return Object.keys(data)
				.reduce((lookup, key) =>
				{
					const seriesYields = data[key].best_pop.value?.yield;
					if (!data[key].best_pop.error && seriesYields !== undefined)
					{
						lookup[key] = seriesYields;
					}
					else
					{
						lookup[key] = null;
					}
					return lookup;
				}, {});
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface ISeriesYields
{
	[seriesName: string]: { [yieldEnvironment: number]: number };
}
interface IPlantingRateState
{
	isLoadingPlantingRates: boolean;
	isError: boolean;
	error?: string;
	rates: ISeriesYields;
}

const initialState: IPlantingRateState = {
	isLoadingPlantingRates: false,
	isError: false,
	rates: {}
};

export const plantingRateSlice = createSlice({
	name: 'plantingRate',
	initialState,
	reducers: {
		clearState: (state) => 
		{
			return { ...initialState };
		}
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getPlantingRates.pending, (state, action) =>
		{
			state.isLoadingPlantingRates = true;
			state.error = undefined;
			state.isError = false;
		});
		builder.addCase(getPlantingRates.rejected, (state, action) =>
		{
			state.isLoadingPlantingRates = false;
			state.isError = true;
			state.error = String(action.payload);
		});
		builder.addCase(getPlantingRates.fulfilled, (state, { payload }: PayloadAction<ISeriesYields>) =>
		{
			state.isLoadingPlantingRates = false;
			state.isError = false;
			state.rates = { ...state.rates, ...payload };
		});

		// Specific Yields
		builder.addCase(getPlantingRatesSpecificYield.pending, (state, action) =>
		{
			state.isLoadingPlantingRates = true;
			state.error = undefined;
			state.isError = false;
		});
		builder.addCase(getPlantingRatesSpecificYield.rejected, (state, action) =>
		{
			state.isLoadingPlantingRates = false;
			state.isError = true;
			state.error = String(action.payload);
		});
		builder.addCase(getPlantingRatesSpecificYield.fulfilled, (state, { payload }: PayloadAction<ISeriesYields>) =>
		{
			state.isLoadingPlantingRates = false;
			state.isError = false;
			state.rates = { ...state.rates, ...payload };
		});
	}
});

// export a couple of slices to help with getting seeding rates
export const getSeedingRateForSeriesAndRates = (seeding_rates: ISeriesYields, productName: string, yieldTarget: number, requireExact: boolean = false) =>
{
	// If the product doesn't already exist
	if(!seeding_rates[productName])
	{
		// This may return undefined (no data loaded yet) or null (loaded, but error or missing data)
		return seeding_rates[productName];
	}

	// Do we have an exact answer
	if(seeding_rates[productName][yieldTarget])
	{
		return seeding_rates[productName][yieldTarget];
	}
	
	// If an exact response was requested, don't interpolate
	if(requireExact)
	{
		return undefined;
	}

	// get lower bound
	const lowerBoundYield = Object.keys(seeding_rates[productName]).reduce((prev, cur) => 
	{
		const keyYield = Number(cur);
		return (keyYield <= yieldTarget && (prev === undefined ||  keyYield > Number(prev))) ? cur : prev;
	}, undefined);

	// get upper bound
	const upperBoundYield =  Object.keys(seeding_rates[productName]).reduce((prev, cur) => 
	{
		const keyYield = Number(cur);
		return (keyYield >= yieldTarget && (prev === undefined || keyYield < Number(prev))) ? cur : prev;
	}, undefined);
	
	// If we only have one bound, use it
	if (lowerBoundYield && !upperBoundYield)
	{
		return seeding_rates[productName][lowerBoundYield];
	}

	if(!lowerBoundYield && upperBoundYield)
	{
		return seeding_rates[productName][upperBoundYield];
	}

	// If we found an exact match, return it
	if (lowerBoundYield === upperBoundYield)
	{
		return seeding_rates[productName][lowerBoundYield];
	}

	// Otherwise do a linear interpolation between our bounds
	const linearInterpolation = (yieldTarget - Number(lowerBoundYield)) / (Number(upperBoundYield) - Number(lowerBoundYield));

	const pop : number | undefined = (seeding_rates[productName][upperBoundYield] * linearInterpolation) + 
		(seeding_rates[productName][lowerBoundYield] * (1-linearInterpolation));

	return pop ? Math.round(pop) : undefined;
};

export const { clearState } = plantingRateSlice.actions;
