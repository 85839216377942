import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICLUItem } from '../../Models/Responses/CLUResponse';
import { ICLURequest } from '../../Models/Requests/CLURequest';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export const getCLUs = createTracedAsyncThunk<ICLUItem[], ICLURequest, { dispatch: AppDispatch, state: RootState }>(
	'clus/get',
	// Nothing needs to be passed here so set the request to null
	async (context, request: ICLURequest, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.postAsync<ICLUItem[]>('reference/clu', request);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface ICLUState
{
	selectedCLUs: ICLUItem[];
	isLoading: boolean;
	isError: boolean;
	errorMessage: string;
}

export const initialState: ICLUState = {
	selectedCLUs: [],
	isLoading: false,
	isError: false,
	errorMessage: undefined,
};

export const CLUSlice = createSlice({
	name: 'clu',
	initialState,
	reducers: {
		clearState: (state) => 
		{
			state = initialState;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;

			return state;
		},
		// From selecting a CLU from the map
		addSelectedCLU: (state, { payload }: PayloadAction<ICLUItem>) =>
		{
			// Remove it if it already exists, this is probably a second click that would 'un-select' the clu
			if (state.selectedCLUs.find(sc => sc.fid === payload.fid))
			{
				state.selectedCLUs = state.selectedCLUs.filter(sc => sc.fid !== payload.fid);
			}
			else
			{
				state.selectedCLUs.push(payload);
			}

			return state;
		},
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getCLUs.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getCLUs.fulfilled, (state) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getCLUs.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the CLU Boundaries. Please try again.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the CLU Boundaries. Please try again.';
			}
		});
	}
});

export const { clearState, clearError, addSelectedCLU } = CLUSlice.actions;