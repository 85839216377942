import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import { CropTabBar } from './CropTabBar';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { getGrowerOrdersForYear } from '../../../logic/store/Grower/OrderThunks';
import { getCropYear } from '../../../logic/store/Seeds/CropsSlice';
import { createProductGamePlan, getFieldlessSeedRankings, getProductGamePlan, getTreatmentPricing, IFieldlessSeedRankingsRequest } from '../../../logic/store/Plans/ProductGamePlanActions';
import { convertProductPlanToFieldPlan } from '../../../logic/store/Plans/FieldPlan/FieldPlanThunks';
import { dynamicSort, makeDispatch } from '../../../logic/Utility/Utils';
import { ICropBrandAssociation, cropBrandAssociations } from './CropBrandAssociation';
import { ProductPlanHeaderRow } from './ProductPlanHeaderRow';
import { ConnectedPlanProductList } from './SeedList/PlanProductList';
import { ConnectedPlanProductEditor } from './Editor/PlanProductEditor';
import { createNewEditPlan, migrateSoyProductEntries, selectedEditingProductPlan } from '../../../logic/store/Plans/ProductGamePlanSlice';
import { getSeedsForGrower, ISeriesWithHybrid } from '../../../logic/store/Seeds/SeedsActions';
import { getPlantingRates } from '../../../logic/store/Seeds/PlantingRateSlice';
import { saveProductPlan } from '../../../logic/store/Plans/ProductGamePlanUpdateActions';
import { newPlanDummyId } from '../../../logic/store/Plans/ProductPlanUtils';
import { IPlanDownloadRequest } from '../../../logic/Models/Requests/PlanRequest';
import { getCustomTreatments, getUserDefaultTreatment } from '../../../logic/store/User/CustomTreatmentsActions';
import { IHybridResponse, ISeedResponse } from '../../../logic/Models/Responses/SeedResponse';
import { SeedListBrandApplication } from '../../../logic/Models/Responses/ProductGamePlan';
import { sortBy } from 'lodash';
import _ from 'lodash';
import { hasEffectivePermission } from '../../../logic/store/User/AuthSlice';
import { UserPermission } from '../../../logic/Models/Responses/UserPermissions';
import {  Modal } from 'antd';
import { Button } from '../../../components/Button/Button';
import { setSelectedPlanId } from '../../../logic/store/UI/UISlice';
import { useHistory } from 'react-router-dom';
import { ReactComponent as FarmIcon } from '../../../assets/images/FarmIcon.svg';
import { useTheme } from 'styled-components';

interface IProductGamePlanComponentProps
{
	selectedGrower: IGrowerResponse
}

const mapStateToProps = (state: RootState) => ({
	CurrentCropYear: state.crops.CropYear,
	CurrentUser: state.userInfo,
	Errors: state.productGamePlan.errorMessage,
	FilteredRankings: state.productGamePlan.fieldlessRankings,
	GrowerId: state.ui.SelectedGrowerId,
	IsCurrentCropYearLoading: state.crops.isLoading,
	IsCustomTreatmentsLoading: state.customTreatments.isLoadingGetTreatments,
	IsDoneModifyingSoyProducts: state.productGamePlan.isDoneModifyingSoyProducts,
	IsDownloadingProductPlan: state.productGamePlan.isDownloadingProductPlan,
	IsFieldlessLoading: state.productGamePlan.isFieldlessLoading,
	IsGettingSeedsForGrower: state.seeds.isLoading,
	IsLoadingCreateProductPlan: state.productGamePlan.isLoadingCreateProductPlan,
	IsLoadingGetProductPlan: state.productGamePlan.isLoadingGetProductPlan,
	IsLoadingGetUserDefaultTreatments: state.customTreatments.isLoadingGetUserDefaultTreatments,
	IsSavingProductPlan: state.productGamePlan.isSavingProductPlan,
	IsTreatmentsLoading: state.productGamePlan.isTreatmentsLoading,
	LocalEditingState: selectedEditingProductPlan(state),
	PlanId: state.productGamePlan.selectedGamePlanId,
	UserCanSeeEnogenBrand: hasEffectivePermission(state, UserPermission.CanSeeEnogenBrand),
	Seeds: state.seeds.seeds,
	UserDefaultTreatments: state.customTreatments.userDefaultTreatments
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
	CreateNewEditPlan: makeDispatch(dispatch, createNewEditPlan),
	CreateProductGamePlan: makeDispatch(dispatch, createProductGamePlan),
	GetCurrentCropYear: makeDispatch(dispatch, getCropYear),
	GetCustomTreatments: makeDispatch(dispatch, getCustomTreatments),
	GetFilteredSeedRankings: makeDispatch(dispatch, getFieldlessSeedRankings),
	GetOrdersForGrower: makeDispatch(dispatch, getGrowerOrdersForYear),
	GetPlantingRates: makeDispatch(dispatch, getPlantingRates),
	GetProductGamePlan: makeDispatch(dispatch, getProductGamePlan),
	GetSeedsForGrower: makeDispatch(dispatch, getSeedsForGrower),
	GetTreatmentPricing: makeDispatch(dispatch, getTreatmentPricing),
	GetUserDefaultTreatments: makeDispatch(dispatch, getUserDefaultTreatment),
	MigrateSoyProductEntries: makeDispatch(dispatch, migrateSoyProductEntries),
	SaveProductPlan: makeDispatch(dispatch, saveProductPlan),
	ConvertProductPlanToFieldPlan: makeDispatch(dispatch, convertProductPlanToFieldPlan),
	SetSelectedPlanId: makeDispatch(dispatch, setSelectedPlanId)
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/**
 * The Product Plan page 
 */
const ProductGamePlan = (props: IProductGamePlanComponentProps & PropsFromRedux) =>
{
	const {
		selectedGrower,
		CurrentCropYear,
		FilteredRankings,
		GrowerId,
		IsCurrentCropYearLoading,
		IsCustomTreatmentsLoading,
		IsDoneModifyingSoyProducts,
		IsDownloadingProductPlan,
		IsFieldlessLoading,
		IsGettingSeedsForGrower,
		IsLoadingCreateProductPlan,
		IsLoadingGetProductPlan,
		IsLoadingGetUserDefaultTreatments,
		IsSavingProductPlan,
		IsTreatmentsLoading,
		LocalEditingState,
		PlanId,
		Seeds,
		UserCanSeeEnogenBrand,
		UserDefaultTreatments,
		CreateNewEditPlan,
		CreateProductGamePlan,
		GetCurrentCropYear,
		GetCustomTreatments,
		GetFilteredSeedRankings,
		GetOrdersForGrower,
		GetPlantingRates,
		GetProductGamePlan,
		GetSeedsForGrower,
		GetTreatmentPricing,
		GetUserDefaultTreatments,
		MigrateSoyProductEntries,
		SaveProductPlan,
		ConvertProductPlanToFieldPlan,
		SetSelectedPlanId
	} = props;

	const theme = useTheme();
	const history = useHistory();

	const [currentRecommendation, setCurrentRecommendation] = useState<ICropBrandAssociation>(cropBrandAssociations[0]);
	const defaultTreatmentName = (UserDefaultTreatments && UserDefaultTreatments[currentRecommendation.cropId]?.Treatment) ?? '';

	const [filteredSeriesList, setFilteredSeriesList] = useState<ISeriesWithHybrid[]>([]);
	const [filterdHybridList, setFilteredHybridList] = useState<IHybridResponse[]>([]);
	const [rankedSeriesList, setRankedSeriesList] = useState<ISeriesWithHybrid[]>([]);
	const [isConvertingModalOpen, setConvertingModalOpen] = useState(false);

	const getRates = useCallback(() =>
	{
		if (Seeds && Seeds.length)
		{
			const cropId = currentRecommendation.cropId;
			GetPlantingRates({
				cropId,
				seriesNames: Seeds.filter(seed => seed.CropId === cropId)
					.flatMap(seed => seed.BrandApplications)
					.filter(brand => brand.BrandApplication === currentRecommendation.brandApplication)
					.flatMap(series => series.Hybrids)
					.map(hybrid => hybrid.SeriesName)
			});

		}
	}, [Seeds, currentRecommendation]);

	// Fetch current crop year for subsequent calls
	useEffect(() =>
	{
		if (!CurrentCropYear && !IsCurrentCropYearLoading)
		{
			GetCurrentCropYear();
		}
		if (!IsTreatmentsLoading)
		{
			GetTreatmentPricing();
		}
		if (!UserDefaultTreatments || !UserDefaultTreatments[currentRecommendation.cropId] && !IsLoadingGetUserDefaultTreatments) 
		{
			GetUserDefaultTreatments({ cropId: currentRecommendation.cropId });
		}
		if (!IsCustomTreatmentsLoading)
		{
			GetCustomTreatments();
		}
		else
		{
			if (!IsDoneModifyingSoyProducts)
			{
				MigrateSoyProductEntries();
			}
		}
	}, []);

	useEffect(() =>
	{
		if (!IsFieldlessLoading && 
			(LocalEditingState && LocalEditingState.recommendationOptions &&
				LocalEditingState.recommendationOptions[currentRecommendation.cropId + currentRecommendation.brandApplication].isFilterApplied) &&
			(!FilteredRankings || (Object.keys(FilteredRankings).length === 0 || !FilteredRankings[currentRecommendation.cropId + currentRecommendation.brandApplication])))
		{
			getSeedListFilteredRankings();
		}
	},[LocalEditingState]);

	useEffect(() =>
	{
		getRates();
	}, [Seeds, currentRecommendation]);

	useEffect(() =>
	{
		// Fetch the seeds list
		if (IsGettingSeedsForGrower || (Seeds && Seeds.length > 0))
		{
			return;
		}
		GetSeedsForGrower();
	}, [GrowerId]);

	useEffect(() =>
	{
		if (CurrentCropYear && selectedGrower.SalesforceId && selectedGrower.FoundationId)
		{
			const cropYear = Number.parseInt(CurrentCropYear);
			// Retrieve the orders for last crop year
			GetOrdersForGrower({
				growerId: GrowerId,
				year: cropYear - 1
			});
		}
	}, [GrowerId, CurrentCropYear, GetOrdersForGrower]);

	useEffect(() => 
	{
		if (IsLoadingCreateProductPlan || IsLoadingGetProductPlan) return;
		if (!PlanId)
		{
			CreateNewEditPlan({ defaultTreatmentName, growerZip: selectedGrower.Zip });
		}
		else if (PlanId !== newPlanDummyId)
		{
			GetProductGamePlan({ planId: PlanId, defaultTreatmentName });
		}
	}, [PlanId]);

	const onSave = useCallback(async () =>
	{
		const pdfConfig: IPlanDownloadRequest = {
			PlanId: PlanId,
			CoverConfig: null
		};
		if (PlanId !== newPlanDummyId)
		{
			await SaveProductPlan({ request: pdfConfig, printPDF: false });
		}
		else
		{
			await CreateProductGamePlan({ coverConfig: pdfConfig, printPDF: false });
		}
	}, [PlanId]);

	const onClickConvert = useCallback(() => 
	{
		setConvertingModalOpen(true);
	}, [setConvertingModalOpen, onSave]);

	const getSeedListFilteredRankings = useCallback((filterApplied?: boolean) =>
	{
		const cropAssociation = currentRecommendation.cropId + currentRecommendation.brandApplication;
		const recOptions = LocalEditingState.recommendationOptions[cropAssociation];
		// Make sure we supply the configuration settings the user has entered
		const fieldlessRankingRequest: IFieldlessSeedRankingsRequest =
		{
			acres: recOptions.acres,
			consistencyPerf: recOptions.performance,
			cropAssociation: cropAssociation,
			enogenOnly: currentRecommendation.brandApplication === 'Enogen',
			cropId: currentRecommendation.cropId,
			fungicide: recOptions.fungicide,
			irrigated: recOptions.irrigated,
			isFilterApplied: filterApplied === undefined ? recOptions.isFilterApplied : filterApplied,
			rmMax: recOptions.rmMax,
			rmMin: recOptions.rmMin,
			selectedTraits: recOptions.selectedTraits,
			topEndYield: recOptions.topEndYield,
			zipCode: recOptions.zip
		};

		GetFilteredSeedRankings(fieldlessRankingRequest);
	}, [currentRecommendation, LocalEditingState]);

	const useFilteredSeriesList = (seeds: ISeedResponse[], cropId: string, brandApplication: SeedListBrandApplication) =>
	{
		if (!seeds)
		{
			return;
		}
		
		const hybrids = seeds
			.filter(seedLevel => seedLevel.CropId === cropId)
			.flatMap(seedLevel => seedLevel.BrandApplications)
			.filter(brandLevel => brandLevel.BrandApplication === brandApplication)
			.flatMap(brandLevel => brandLevel.Hybrids);

		const allSeriesHybrids = Object.values(hybrids.reduce<{ [key: string]: ISeriesWithHybrid; }>((collection, cur) =>
		{
			if (!collection[cur.SeriesId])
			{
				collection[cur.SeriesId] = {
					seriesId: cur.SeriesId,
					seriesName: cur.SeriesName,
					availability: 'Red',
					brandName: cur.BrandName,
					relativeMaturity: cur.RelativeMaturity,
					hybrids: []
				};
			}

			if (collection[cur.SeriesId].availability === 'Red')
			{
				collection[cur.SeriesId].availability = cur.Availability;
			}
			else if (collection[cur.SeriesId].availability === 'Yellow' && cur.Availability === 'Green')
			{
				collection[cur.SeriesId].availability = cur.Availability;
			}

			collection[cur.SeriesId].hybrids.push(cur);
			return collection;
		}, {}));

		// If there are any green hybrids, the series is considered green overall
		const greenAvailHybrids = sortBy(allSeriesHybrids.filter(s => s.hybrids.some(h => h.Availability === 'Green')), s=> s.seriesName.toLowerCase());
		// If there are no green hybrids, but there is a yellow, the series is considered yellow overall
		const yellowAvailHybrids = sortBy(allSeriesHybrids.filter(s => 
			!s.hybrids.some(h => h.Availability === 'Green') && s.hybrids.some(h => h.Availability === 'Yellow')), s=> s.seriesName.toLowerCase());
		// If there are only red hybrids, the series is considered red overall
		const redAvailHybrids = sortBy(allSeriesHybrids.filter(s => s.hybrids.every(h => h.Availability === 'Red')), s=> s.seriesName.toLowerCase());
		// Now sort each set of availbility by RM
		const availibilitySortedHybrids = greenAvailHybrids.sort((a,b) => Number(a.relativeMaturity) > Number(b.relativeMaturity) ? 1 : -1)
			.concat(yellowAvailHybrids.sort((a,b) => Number(a.relativeMaturity) > Number(b.relativeMaturity) ? 1 : -1)
				.concat(redAvailHybrids.sort((a,b) => Number(a.relativeMaturity) > Number(b.relativeMaturity) ? 1 : -1)));

		setFilteredSeriesList([..._.cloneDeep(availibilitySortedHybrids)]);
		setRankedSeriesList([]);
	};

	useEffect(() =>
	{
		// This sets the filtered hierarchy of series for the current recommendation only, for the 'able to add' section
		useFilteredSeriesList(Seeds, currentRecommendation.cropId, currentRecommendation.brandApplication);

		// This is the un-filtered list of hybrids for the current crop/brand association
		const filteredHybrids = (Seeds || [])
			.filter(seedLevel => seedLevel.CropId === currentRecommendation.cropId)
			.flatMap(seedLevel => seedLevel.BrandApplications)
			.filter(brandLevel => brandLevel.BrandApplication === currentRecommendation?.brandApplication)
			.flatMap(brandLevel => brandLevel.Hybrids);

		setFilteredHybridList(filteredHybrids);
		
	},[Seeds, currentRecommendation, currentRecommendation.cropId, currentRecommendation.brandApplication]);

	useEffect(() =>
	{
		if (!IsFieldlessLoading && FilteredRankings && LocalEditingState && Object.keys(FilteredRankings).length > 0 && filteredSeriesList && filteredSeriesList.length > 0)
		{
			// Update the filtered seed list with the ranks from the ranking engine
			let currentSeeds = _.cloneDeep(filteredSeriesList);
			if (currentSeeds[0].hybrids[0].CropId !== currentRecommendation.cropId)
			{
				return;
			}
			const cropAssociation = currentRecommendation.cropId + currentRecommendation.brandApplication;
			const rankingsForCropAssociation = FilteredRankings[cropAssociation];
			const isFilterApplied = LocalEditingState?.recommendationOptions[cropAssociation].isFilterApplied;

			if (isFilterApplied && rankingsForCropAssociation && rankingsForCropAssociation.length > 0)
			{
				const seedsToRemove = [];
				currentSeeds.forEach(existingSeed =>
				{
					const foundRanking = rankingsForCropAssociation.find(ranked => ranked.SeedName.toLowerCase() === existingSeed.seriesName.toLowerCase());
					if (foundRanking)
					{
						existingSeed.rank = foundRanking.Rank;
					}
					else
					{
						seedsToRemove.push(existingSeed);
					}
				});

				// Remove the seeds that do not have a rank and then sort the list by the rank
				currentSeeds = [...currentSeeds.filter(cs => !seedsToRemove.includes(cs))];
				currentSeeds = [...currentSeeds.sort(dynamicSort('rank'))];

				setRankedSeriesList([...currentSeeds]);
			}
		}
	}, [FilteredRankings, filteredSeriesList, LocalEditingState]);

	const resetSeedFilters = () =>
	{
		// Reset the seed filters to the original
		useFilteredSeriesList(Seeds, currentRecommendation.cropId, currentRecommendation.brandApplication);
	};

	const lastYearGrowerOrders = selectedGrower.OrdersByYear ? selectedGrower.OrdersByYear[Number.parseInt(CurrentCropYear) - 1] : [];

	/**
	 * The action when a user confirms that they want to convert.  Will save the current plan and then convert it to a product plan.
	 */
	const convert = useCallback(async () => 
	{
		const pdfConfig: IPlanDownloadRequest = {
			PlanId: PlanId,
			CoverConfig: null
		};
		
		let effectivePlanId = PlanId;
		if (PlanId !== newPlanDummyId)
		{
			await SaveProductPlan({ request: pdfConfig, printPDF: false });
		}
		else
		{
			// Grab the new ID from the response
			const result = await CreateProductGamePlan({ coverConfig: pdfConfig, printPDF: false });
			effectivePlanId = result.payload;
		}

		const response = await ConvertProductPlanToFieldPlan({ productPlanId: effectivePlanId });
		const newPlanId = response?.payload?.Id;
		if(newPlanId)
		{
			// Errors are already handled by the thunk rejecting
			SetSelectedPlanId(newPlanId);
			history.push('/fieldactivities/fieldplan');
		}
	}, [PlanId, setSelectedPlanId, history]);

	return <div className='RootProductPlanPage'
		style={{
			width: '100%',
			height: '100%',
			zIndex: 998,
			paddingLeft: 70,
			paddingRight: 60,
		}}>
		<div className='ProductPlanVerticalStack' style={{
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: '100%'
		}}>
			<ProductPlanHeaderRow planId={PlanId}
				isDownloadingProductPlan={IsDownloadingProductPlan}
				isSavingPlan={IsSavingProductPlan || IsLoadingCreateProductPlan}
				onSave={onSave}
				onConvert={onClickConvert}
				localEditState={LocalEditingState}
				selectedGrower={selectedGrower} />
			<div className='ProductPlanRecTabs' style={{
				display: 'flex',
				flexDirection: 'row',
				borderBottom: `1px solid ${theme.colors.lightGrey}`,
				paddingLeft: 18
			}}>
				{
					cropBrandAssociations.filter(cropBrand => UserCanSeeEnogenBrand ? cropBrand.brandApplication : cropBrand.brandApplication !== 'Enogen')
						.map((cba) => <CropTabBar
							key={cba.cropId + cba.brandApplication}
							label={cba.title}
							tabWidth={'95px'}
							selected={cba.cropId === currentRecommendation.cropId &&
							cba.brandApplication == currentRecommendation.brandApplication}
							onClick={() => setCurrentRecommendation({...cba})} />)
				}
			</div>
			{LocalEditingState && <div className='ProductPlanEditorContent' style={{
				display: 'flex',
				flexDirection: 'row',
				height: '100%',
				flex: '1 1 auto',
				overflow: 'hidden'
			}}>
				<ConnectedPlanProductList
					editingState={LocalEditingState}
					planId={PlanId}
					defaultTreatmentName={defaultTreatmentName}
					recommendation={currentRecommendation}
					seriesList={rankedSeriesList && rankedSeriesList.length > 0 ? rankedSeriesList : filteredSeriesList}
				/>
				<ConnectedPlanProductEditor
					editingState={LocalEditingState}
					planId={PlanId}
					getRates={getRates}
					resetSeedFilters={resetSeedFilters}
					getFilteredRankings={getSeedListFilteredRankings}
					recommendation={currentRecommendation}
					lastYearGrowerOrders={lastYearGrowerOrders}
					hybrids={filterdHybridList}
					selectedGrower={selectedGrower}
				/>
				<Modal
					open={isConvertingModalOpen}
					onOk={() => setConvertingModalOpen(false)}
					onCancel={() => setConvertingModalOpen(false)}
					title={<div style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'flex-start'
					}}>
						<FarmIcon style={{ marginRight: 16 }}/>
						<div style={{
							fontSize: 16,
							fontWeight: 700,
							lineHeight: '20px',
							maxWidth: 260
						}}>Convert to MaxScript Field Proposal?</div>
					</div>}
					className='ConvertModal'
					closable={true}
					width={373}
					footer={
						<div>
							<Button
								variant='outlined'
								style={{paddingLeft: 16, paddingRight: 16}}
								onClick={() => setConvertingModalOpen(false)}
								className='cancelButton'
							>
								Cancel
							</Button>
							<Button
								variant='dark'
								style={{ marginLeft: 8, paddingLeft: 16, paddingRight: 16 }}
								onClick={convert}
								className='submitButton'
							>
								Convert
							</Button>
						</div>}
					centered
				>
					<div style={{ fontSize: 14, lineHeight: '20px' }}>The following action will convert your current Acre Proposal into a Field Proposal. 
						This may adjust recommended seed rates and acreage based on the drawn fields. 
						Do you wish to continue?</div>
				</Modal>
			</div>}
		</div>
	</div>;
};

export const ConnectedProductGamePlan = connector(ProductGamePlan);
