import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { InputNumber, Tooltip } from 'antd';
import { ReactComponent as Checkmark } from '../../assets/images/Checkmark.svg';
import { ReactComponent as Xmark } from '../../assets/images/Xmark.svg';
import { themeGet } from '@styled-system/theme-get';

const ButtonContainer = styled(Button)`
	width: 100%;
	height: 100px;
	border: 1px solid gray;
	border-radius: 6px;
	padding: 0px;
	margin-top: 10px;
`;

const ColorTab = styled.div<{ color: string }>`
	min-width: 15px;
	background-color: ${props => props.color};
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: 10px;
	margin-right: 10px;
`;

const TitleText = styled.div`
	width: 90%;
	height: 50%;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: flex-end;
`;

const DisplayTextContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	font-size: 11px;
	height: 50%;
	align-items: flex-start;
	padding-top: 3px;
`;

const AddCardContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 12px;
	height: 80%;
	align-items: flex-start;
	padding-top: 3px;
`;

const DisplayNameText = styled.div`
	color: darkgray;
	margin-right: 3px;
`;

const DisplayValueText = styled.div`
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const NewZoneButton = styled.div`
	cursor: pointer;
	width: 60px;
	border: 1px solid black;
	&:hover {
		background-color: ${themeGet('colors.primary')};
	}
	&.disabled {
		background-color: #717171;
	}
`;

const NumberInput = styled(InputNumber)`
	width: 100px;
	height: 25px;
	font-size: 14px;
	margin-right: -2px;
	border: thin solid #E7E7E7;
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: white;
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: right;
		height: auto;
		padding-right: 3px;
	}
`;

interface IColorTabButtonProps {
	className?: string;
	title: string;
	tabColor: string;
	disabled?: boolean;
	displayNamesAndValues: [string, string][];
	onClick: () => void;
	variant: 'slim' | 'reg' | 'med';
	selected?: boolean;
	selectedOutlineColor?: string;
	addCard: boolean;
	/* If AddCard is true, populates the label of the input */
	inputName?: string;
	addCardValue?: number;
	updateCardValue?: (value: number) => void;
	onSaveCard?: () => void;
	onCancelCard?: () => void;
}

/** @component */
export const ColorTabButton = (props: IColorTabButtonProps) =>
{
	const {
		addCard,
		addCardValue,
		className,
		disabled,
		displayNamesAndValues,
		inputName,
		onClick,
		selected,
		selectedOutlineColor,
		tabColor,
		title,
		variant,
		updateCardValue,
		onSaveCard,
		onCancelCard,
	} = props;

	const buttonContainerWidth = (variant: string) => ({ slim: 50, med: 65, reg: 100 }[variant]);
	const selectedButtonOutline = (selected: boolean) => { return selected ? `6px solid ${selectedOutlineColor}` : 'none'; };
	const colorTabWidth = (variant: string) => ({ slim: 8, med: 15, reg: 15 }[variant]);
	const titleTextSize = (variant: string) => ({ slim: 12, med: 16, reg: 16 }[variant]);
	const displayNameTextDisplay = (variant: string) => ({ slim: 'none', med: 'flex', reg: 'flex' }[variant]);
	const displayValueTextFontWeight = (variant: string) => ({ slim: 400, med: 700, reg: 700 }[variant]);
	const displayValueTextColor = (variant: string) => ({ slim: 'darkGrey', med: 'darkGrey', reg: 'darkGrey' }[variant]);

	return (
		<ButtonContainer
			style={{ height: buttonContainerWidth(variant), outline: selectedButtonOutline(selected) }}
			className={className ? className : `ColorTabButton_${title}_${tabColor}`}
			onClick={() => onClick()}
			type='button'
			variant='outlined'
			disabled={disabled}
		>
			<div style={{ display: 'flex', width: '94%', height: '100%' }}>
				<ColorTab style={{ minWidth: colorTabWidth(variant) }} className='ColorTab' color={ tabColor ?? '#ffffff' } />
				<TextContainer>
					<TitleText style={{ fontSize: titleTextSize(variant) }} className='Title'>
						<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{title}</div>
					</TitleText>
					{
						addCard ?
							<AddCardContainer>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<div style={{ paddingRight: 5 }}>{inputName}</div>
									<NumberInput
										className={`${className}-AddCard-input`}
										type='number'
										style={{ height: 20, width: 70 }}
										value={addCardValue ?? 0}
										onBlur={(evt: React.FocusEvent<HTMLInputElement>) => 
											updateCardValue(evt.currentTarget.value ? Number(evt.currentTarget.value) : addCardValue)}
										onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) => 
											updateCardValue(evt.target && (evt.target as HTMLInputElement).value
												? Number((evt.target as HTMLInputElement).value)
												: addCardValue)}
									/>
								</div>
								<div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto', paddingTop: 10 }}> 
									<NewZoneButton
										style={{ marginLeft: 'auto', marginRight: 5 }}
										className={`${className}-AddCard-cancel-button`}
										onClick={() => onCancelCard()}
									>
										<Xmark style={{ position: 'relative', top: 1, paddingRight: 3 }} />
										Cancel
									</NewZoneButton>
									<Tooltip title={addCardValue === 0 ? 'Please enter a value to Save' : ''} placement='top'>
										<span style={{ cursor: addCardValue === 0 ? 'not-allowed' : 'pointer' }}>
											<NewZoneButton
												style={{ cursor: addCardValue === 0 ? 'not-allowed' : 'pointer' }}
												className={addCardValue === 0 ? `disabled ${className}-AddCard-save-button` : `${className}-AddCard-save-button`}
												onClick={addCardValue === 0 ? () => console.log() : () => onSaveCard()}
											>
												<Checkmark style={{ position: 'relative', top: 1, paddingRight: 4 }} />
												Save
											</NewZoneButton>
										</span>
									</Tooltip>
								</div>
							</AddCardContainer>
							:
							<DisplayTextContainer className='DisplayTextContainer'>
								{displayNamesAndValues.map((nameValueTuple, index) => 
									<div key={`DisplayName_${index + 1}`} style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
										<DisplayNameText
											style={{ display: displayNameTextDisplay(variant) }}
											className={`DisplayNameTitle_${index + 1}`}
										>
											{nameValueTuple[0]}:
										</DisplayNameText>
										<DisplayValueText
											style={{ fontWeight: displayValueTextFontWeight(variant), color: displayValueTextColor(variant) }}
											className={`DisplayValue_${index + 1}`}
										>
											{nameValueTuple[1]}
										</DisplayValueText>
									</div>
								)}
							</DisplayTextContainer>
					}
				</TextContainer>
			</div>
		</ButtonContainer>
	);
};