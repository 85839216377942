import React, { useCallback, useEffect, useState } from 'react';
import { IconButton } from '../../components/IconButton/IconButton';
import { Input } from '../../components/Input/Input';
import { Button } from '../../components/Button/Button';
import { IFarmResponse } from '../../logic/Models/Responses/FarmResponse';
import { StyledAutoComplete } from '../../components/AutoComplete/StyledAutoComplete';
import styled, { useTheme } from 'styled-components';
import { saveFarm, ISaveFarmRequest } from '../../logic/store/Grower/FarmThunks';
import { saveField, ISaveFieldRequest, updateField, IEditFieldRequest, IEditFieldBoundary } from '../../logic/store/Grower/FieldThunks';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { area, combine, FeatureCollection, MultiPolygon, Polygon } from '@turf/turf';
import { Tooltip } from 'antd';
import { clearSelectedFieldForEdit, DrawingInteractionMode, MapInteractionMode } from '../../logic/store/UI/UISlice';
import { clearState as clearCluState } from '../../logic/store/CLUs/CLUSlice';
import { makeDispatch } from '../../logic/Utility/Utils';
import { RoundAcres } from '../../logic/Utility/CalculationUtilities';

interface IFieldDrawingToolsProps extends PropsFromRedux
{
	onExit: () => void;
	farms: IFarmResponse[];
}

const AcreInput = styled(Input)`
	-moz-appearance: textfield;
`;

const FieldDrawingToolsPanelBase = (props: IFieldDrawingToolsProps) =>
{
	const { 
		farms,
		DrawnFeatures,
		MapMode,
		SelectedFieldForEdit,
		SelectedGrowerId,
		onExit,
		ClearCluState,
		ClearSelectedFieldForEdit,
		SaveNewFarm,
		SaveNewField,
		UpdateExistingField,
	} = props;

	const theme = useTheme();

	const [farmOptions, setFarmOptions] = useState<{ key: string, value: string }[]>([]);
	const [farmName, setFarmName] = useState<string>('');
	const [farmId, setFarmId] = useState<string>(undefined);
	const [newFieldName, setNewFieldName] = useState<string>('');
	const [newFieldAcres, setNewFieldAcres] = useState<number>(0);
	const [editingFieldName, setEditingFieldName] = useState<string>('');
	const [isSaved, setIsSaved] = useState(false);

	const NEW_FarmId = 'NEW_FARM_ID';

	// Calculate the Drawn Boundary Acreage
	useEffect(() =>
	{
		if (DrawnFeatures)
		{
			let totalArea: number = 0;
			DrawnFeatures.features.forEach(feature =>
			{
				const layerArea = area(feature.geometry); // square meters
				const layerAcres = layerArea / 4046.86; // 4046.86 square meters per Acre

				totalArea += layerAcres;
			});
			setNewFieldAcres(parseFloat(totalArea.toFixed(1)));
		}
		else if (!DrawnFeatures && SelectedFieldForEdit)
		{
			// If we're editing a field but have not yet actually drawn anything, set the acreage to the field's area/acreage
			const editingField = farms?.flatMap(fa => fa.Fields).find(f => f.Id === SelectedFieldForEdit);
			const editingFieldFarm = farms?.find(fa => fa.Id === editingField.FarmId);
			setEditingFieldName(editingField?.Name);
			setNewFieldAcres(editingField ? RoundAcres(editingField.Area) : 0);
			setFarmName(editingFieldFarm?.Name);
		}
	}, [DrawnFeatures, SelectedFieldForEdit]);

	// By default, AutoComplete would normally show the options if there was nothing entered.
	// This will force it to only display the options if a user has started typing.
	const handleSearch = (value: string) =>
	{
		setFarmOptions(value ? farms?.map(farm => ({ key: farm.Id, value: farm.Name })) : []);
	};

	const onSelectFarm = (value: { key: string, value: string }) =>
	{
		// If selecting an existing farm, we will definitely have an id
		setFarmId(value.key);
		setFarmName(value.value);
	};

	const onChangeFarmName = (value: string) =>
	{
		// The user may have entered an existing farm without selecting the name, make sure if it is
		// otherwise set it to a dummy id
		const farmName = value;
		const possibleFarmId = farms.find(f => f.Name.toLocaleLowerCase() === farmName.toLocaleLowerCase());
		if (possibleFarmId)
		{
			setFarmId(possibleFarmId.Id);
		}
		else
		{
			setFarmId(NEW_FarmId);
		}
		setFarmName(farmName);
	};

	const onChangeCreateFieldName = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		setNewFieldName(e.target.value);
	};

	const onChangeEditingFieldName = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		setEditingFieldName(e.target.value);
	};

	const onChangeArea = (e: React.ChangeEvent<HTMLInputElement>) =>
	{
		setNewFieldAcres(Number(e.target.value));
	};

	const createFieldRequest = (farmId: string, nextField: boolean): ISaveFieldRequest =>
	{
		const newFieldRequest: ISaveFieldRequest =
		{
			SelectedGrowerId: SelectedGrowerId,
			FarmId: farmId,
			Name: newFieldName,
			Area: newFieldAcres,
			Boundary: convertDrawnToGeometry(),
			MapMode: nextField ? MapInteractionMode.Drawing : MapInteractionMode.Selection,
			DrawingInteractionMode: nextField ? DrawingInteractionMode.SaveCleanUp : undefined
		};

		return newFieldRequest;
	};

	const onSaveNewField = useCallback((nextField: boolean) =>
	{
		// Farm Name, Field Name, and Boundary are required
		if (farmName && newFieldName && DrawnFeatures)
		{
			// We need to create a new farm if the Farm ID is the dummy
			if (farmId === NEW_FarmId)
			{
				const newFarmRequest: ISaveFarmRequest =
				{
					SelectedGrowerId: SelectedGrowerId,
					Name: farmName,
					FieldBoundary: convertDrawnToGeometry(),
					FieldArea: newFieldAcres,
					FieldName: newFieldName,
					MapMode: nextField ? MapInteractionMode.Drawing : MapInteractionMode.Selection,
					DrawingInteractionMode: nextField ? DrawingInteractionMode.SaveCleanUp : undefined
				};

				SaveNewFarm(newFarmRequest);
			}
			else if (farmId)
			{
				// Save just the field to an existing farm
				const newFieldRequest = createFieldRequest(farmId, nextField);

				SaveNewField(newFieldRequest);
			}
			else if (!farmId && farmName)
			{
				// Previously saved Farm that now needs an Id
				const possibleFarm = farms.find(f => f.Name.toLocaleLowerCase() === farmName.toLocaleLowerCase());

				if (possibleFarm)
				{
					setFarmId(possibleFarm.Id);

					// Save just the field to an existing farm
					const newFieldRequest = createFieldRequest(possibleFarm.Id, nextField);

					SaveNewField(newFieldRequest);
				}
			}

			// After saving, the user may want to add another field
			if (nextField)
			{
				// Clear the field data so that another field can be added
				setNewFieldAcres(0);
				ClearCluState();
				setNewFieldName('');

				// Clear out the new Farm Id -- This will get set when the user tries to save again
				if (farmId === NEW_FarmId)
				{
					setFarmId(undefined);
				}
			}
			else
			{
				// If not saving another field, go back to the field list
				setIsSaved(true);
				ClearCluState();
				closeWindow();
			}
		}
	}, [newFieldAcres, newFieldName, farmId, farmName, DrawnFeatures]);

	const onSaveEditedField = useCallback(() =>
	{
		const editingField = farms?.flatMap(fa => fa.Fields).find(f => f.Id === SelectedFieldForEdit);

		const updateRequest: IEditFieldRequest =
		{
			MapMode: MapInteractionMode.Selection,
		};

		if (editingFieldName && editingField.Name.toLowerCase() !== editingFieldName)
		{
			updateRequest.Name = editingFieldName;
		}

		if (DrawnFeatures)
		{
			const editedBoundary: IEditFieldBoundary = 
			{
				Boundary: convertDrawnToGeometry(),
			};
			updateRequest.Boundary = editedBoundary;
		}

		UpdateExistingField(updateRequest);
		setIsSaved(true);
		ClearCluState();
		closeWindow();
	},[SelectedFieldForEdit, editingFieldName, DrawnFeatures]);

	const convertDrawnToGeometry = (): MultiPolygon | Polygon =>
	{
		if (DrawnFeatures.features.length > 1)
		{
			// If we have more than one drawn feature, combine into a multi-polygon
			// For some reason, combine  returns an array with 1 value that is the feature multi-polygon
			const muliPolygonCollection: FeatureCollection<MultiPolygon> = combine(DrawnFeatures) as FeatureCollection<MultiPolygon>;
			return muliPolygonCollection.features[0].geometry;
		}
		else
		{
			return DrawnFeatures.features[0].geometry;
		}
	};

	const closeWindow = () =>
	{
		// Clear the state because it seems to stick around after closing/re-opening
		setNewFieldAcres(0);
		setEditingFieldName('');
		ClearSelectedFieldForEdit();
		setNewFieldName('');
		setFarmName('');
		setFarmId(undefined);
		setFarmOptions([]);
		onExit();
	};

	const isCreateSaveDisabled = (): boolean =>
	{
		return !(farmName && newFieldName && DrawnFeatures && MapMode.mode === MapInteractionMode.Drawing);
	};

	const isCreateEditSaveDisabled = (): boolean =>
	{
		const currentField = farms?.flatMap(fa => fa.Fields).find(f => f.Id === SelectedFieldForEdit);
		// We cannot have a blank field name
		if (!editingFieldName)
		{
			return false;
		}
		return !((currentField?.Name.toLowerCase() !== editingFieldName.toLowerCase()) || DrawnFeatures);
	};

	const CreateSaveButton = <Button
		className='Done_Button'
		variant='outlined'
		fullWidth
		onClick={() => onSaveNewField(false)}
		disabled={isCreateSaveDisabled()}
	>
		Done
	</Button>;

	const CreateEditSaveButton = <Button
		className='EditField_Save_Button'
		variant='outlined'
		fullWidth
		onClick={() => onSaveEditedField()}
		disabled={isCreateEditSaveDisabled()}
	>
		Save
	</Button>;

	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '95%' }}>
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 15 }}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
					<span style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.normal }}>
						{SelectedFieldForEdit ? 'Edit a Field' : 'Create a Field'}
					</span>
					<div style={{ display: 'flex', marginLeft: 'auto' }}>
						<IconButton
							className='Close_Drawing'
							variant='close'
							onClick={closeWindow}
							hoverbgcolor={theme.colors.lightestGrey}
							tooltip='Cancel'
						/>
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
					<span style={{ fontSize: theme.fontSizes.extraSmall, marginBottom: 10 }}>
						Use the drawing tools on the map to draw your field
					</span>
				</div>
			</div>			
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflowY: 'auto', paddingBottom: 25 }}>
				{/* 07/5/2021 - Disabled for now, least used option */}
				{/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
						<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
							<span style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.normal, marginBottom: 10 }}>
							Add Field by Shapefile
							</span>
							<Button variant='outlined' fullWidth>
							Upload Shapefile
								</Button>
							</div>
						</div> */}
				{
					!SelectedFieldForEdit ?
						<div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<span style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.normal, marginBottom: 5 }}>
									Field Details
								</span>
								<span style={{ fontSize: theme.fontSizes.extraSmall, marginBottom: 10 }}>
									Enter a new Farm name to create a new field:
								</span>
								<StyledAutoComplete
									className='Farm_AutoComplete'
									popupClassName='Farm_AutoComplete_Dropdown'
									label='Farm Name'
									labelVariant='dark'
									variant='outlined'
									placeholder='Enter a new or existing Farm Name'
									filterOption={(inputValue: string, option: { key: string, value: string }) =>
								option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
									}
									options={farmOptions}
									onSearch={handleSearch}
									value={farmName}
									onSelect={(key: string, value: { key: string, value: string }) => onSelectFarm(value)}
									onChange={(value: string) => onChangeFarmName(value)}
								/>
								<Input
									className='Create_Field_Name'
									label='Field Name'
									labelVariant='dark'
									type='text'
									variant='outlined'
									fullWidth
									style={{ marginBottom: 10 }}
									value={newFieldName}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChangeCreateFieldName(evt)}
								/>
								<AcreInput
									className='Field_Acres'
									label='Acres'
									labelVariant='dark'
									type='number'
									variant='outlined'
									fullWidth
									value={newFieldAcres}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChangeArea(evt)}
								/>
							</div>
						</div>
						:
						<div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1, fontWeight: theme.fontWeights.bold, width: '100%' }}>
								<span style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.normal, marginBottom: 20 }}>
									Field Details
								</span>
								<span style={{ marginBottom: 5 }}>
									Farm Name:
								</span>
								<span style={{ marginBottom: 15, fontWeight: theme.fontWeights.normal, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
									{farmName}
								</span>
								<Input
									className='Edit_Field_Name'
									label='Field Name'
									labelVariant='dark'
									type='text'
									variant='outlined'
									fullWidth
									style={{ marginBottom: 15 }}
									value={editingFieldName}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onChangeEditingFieldName(evt)}
								/>
								<div>
									<span>Acres: {newFieldAcres}</span>
								</div>
							</div>
						</div>
				}
			</div>
			{
				!SelectedFieldForEdit ?
					<div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', justifyContent: 'space-between', width: '100%', paddingTop: 15 }}>
						<div style={{ width: '47%' }}>
							{!isSaved ? <Tooltip title='Save and Close' placement='top'>
								{CreateSaveButton}
							</Tooltip> : CreateSaveButton}
						</div>
						<div style={{ width: '47%' }}>
							<Tooltip title='Save and Add Another' placement='top'>
								<Button
									className='Add_Another_Button'
									variant='outlined'
									fullWidth
									onClick={() => onSaveNewField(true)}
									disabled={isCreateSaveDisabled()}
								>
									Add Another Field
								</Button>
							</Tooltip>
						</div>
					</div>
					:
					<div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', justifyContent: 'space-between', width: '100%', paddingTop: 15 }}>
						<div style={{ width: '47%' }}>
							<Button
								className='EditField_CancelButton'
								variant='outlined'
								fullWidth
								onClick={() => closeWindow()}
							>
								Cancel
							</Button>
						</div>
						<div style={{ width: '47%' }}>
							{!isSaved ? <Tooltip title='Save' placement='top'>
								{CreateEditSaveButton}
							</Tooltip> : CreateEditSaveButton}
						</div>
					</div>
			}
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	DrawnFeatures: state.ui.DrawnFeatures,
	MapMode: state.ui.MapInteractionMode,
	SelectedFieldForEdit: state.ui.SelectedFieldForEdit,
	SelectedGrowerId: state.ui.SelectedGrowerId,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ClearCluState: (() => dispatch(clearCluState())),
		ClearSelectedFieldForEdit: makeDispatch(dispatch, clearSelectedFieldForEdit),
		SaveNewFarm: (request: ISaveFarmRequest) => dispatch(saveFarm(request)),
		SaveNewField: (request: ISaveFieldRequest) => dispatch(saveField(request)),
		UpdateExistingField: makeDispatch(dispatch, updateField),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const FieldDrawingToolsPanel = connector(FieldDrawingToolsPanelBase);