import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { IUserSeedAttributeResponse } from '../../Models/Responses/UserSeedAttributeResponse';
import { getCurrentActingUser } from './AuthSlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export const getUserSeedAttributes = createTracedAsyncThunk<IUserSeedAttributeResponse[], null, { dispatch: AppDispatch, state: RootState }>(
	'get/userSeedAttributes',
	async (context, request: null, thunkAPI) =>
	{
		try
		{
			const state = thunkAPI.getState();
			const api = new Api('/api/4', state.auth.userAuthToken, context);
			const response = await api.getAsync<IUserSeedAttributeResponse[]>(`users/${getCurrentActingUser(state).UserId}/seedattributes`);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const saveUserSeedAttributes = createTracedAsyncThunk<boolean, IUserSeedAttributeResponse[], { dispatch: AppDispatch, state: RootState }>(
	'post/userSeedAttributes',
	async (context, request: IUserSeedAttributeResponse[], thunkAPI) =>
	{
		try
		{
			const state = thunkAPI.getState();
			const api = new Api('/api/4', state.auth.userAuthToken, context);
			const response = await api.postAsync<boolean>(`users/${getCurrentActingUser(state).UserId}/seedattributes`, request);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IUserSeedAttributeState
{
	isError: boolean;
	isLoading: boolean;
	isSaving: boolean;
	errorMessage: string;
	UserSeedAttributes: IUserSeedAttributeResponse[];
}

export const initialState: IUserSeedAttributeState = {
	isError: false,
	isLoading: false,
	isSaving: false,
	errorMessage: undefined,
	UserSeedAttributes: []
};

export const userSeedAttributeSlice = createSlice({
	name: 'userSeedAttributes',
	initialState: initialState,
	reducers: {
		clearState: (state) => 
		{
			state.UserSeedAttributes = [];
			state.isError = false;
			state.isLoading = false;
			state.errorMessage = undefined;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		}
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getUserSeedAttributes.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getUserSeedAttributes.fulfilled, (state, { payload }: PayloadAction<IUserSeedAttributeResponse[]>) =>
		{
			state.isLoading = false;
			state.UserSeedAttributes = payload;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getUserSeedAttributes.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			state.UserSeedAttributes = [];
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of User Seed Attributes. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the list of User Seed Attributes. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of User Seed Attributes. Please refresh and try again.';
			}
		});
		builder.addCase(saveUserSeedAttributes.pending, (state) =>
		{
			state.isSaving = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(saveUserSeedAttributes.fulfilled, (state, { payload }: PayloadAction<boolean>) =>
		{
			state.isSaving = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(saveUserSeedAttributes.rejected, (state, action) =>
		{
			state.isSaving = false;
			state.isError = true;
			state.UserSeedAttributes = [];
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to save the list of User Seed Attributes. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem saving the list of User Seed Attributes. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem saving the list of User Seed Attributes. Please refresh and try again.';
			}
		});
	},
});

export const { clearState, clearError } = userSeedAttributeSlice.actions;