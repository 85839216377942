import { createTracedAsyncThunk } from '../../../tracing/trace';
import { Api } from '../../Api/Api';
import { ICompetitiveAllowanceResponse } from '../../Models/Responses/CompetitiveAllowanceResponse';
import { IDiscountReasonCode } from '../../Models/Responses/DiscountReasonCodeResponse';
import { IPlanResponse } from '../../Models/Responses/PlanResponse';
import { IKnownGetPlansError } from '../Grower/PlanThunks';
import { AppDispatch, RootState } from '../Store';
import { getCurrentActingUser } from '../User/AuthSlice';

// Used in the UserSlice.tsx
// Returns a dictionary of key = crop Id with a list of reason codes
export const getDiscountReasonCodes = createTracedAsyncThunk<{[key: string]: IDiscountReasonCode[]}, null, { dispatch: AppDispatch, state: RootState }>(
	'user/discountreasoncodes/get',
	// Nothing needs to be passed here so set the request to null
	async (context, request: null, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { crops, auth } = state as RootState;
			const api = new Api('/api/5', auth.userAuthToken, context);
			const currentYear = crops.CropYear;
			const currentActingUser = getCurrentActingUser(state);
			const response = await api.getAsync<boolean>(`user/${currentActingUser.UserId}/year/${currentYear}/competitiveallowance/reason`);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getPlanDiscounts = createTracedAsyncThunk<{discounts: ICompetitiveAllowanceResponse, growerId: string}, null, { dispatch: AppDispatch, state: RootState, rejectValue: IKnownGetPlansError }>(
	'plan/discounts/get',
	async (context, request: null, thunkAPI) => 
	{
		const { auth, ui, } = thunkAPI.getState() as RootState;
		try
		{
			const api = new Api('/api/5', auth.userAuthToken, context);
			const selectedPlanId = ui.SelectedPlanId;
			const response = await api.getAsync<IPlanResponse[]>(
				`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/plan/${selectedPlanId}/discounts`,
			);

			if (response.ErrorCode === null && response.Success)
			{
				return { discounts: response.Data, growerId: ui.SelectedGrowerId };
			}
			else 
			{
				return thunkAPI.rejectWithValue({ error: response.ErrorMessage, growerId: ui.SelectedGrowerId });
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue({ error: e.message, growerId: ui.SelectedGrowerId });
		}
	}
);

export const updatePlanDiscounts = createTracedAsyncThunk<{discounts: ICompetitiveAllowanceResponse, growerId: string}, null, { dispatch: AppDispatch, state: RootState, rejectValue: IKnownGetPlansError }>(
	'plan/discounts/update',
	async (context, request: null, thunkAPI) => 
	{
		const { auth, ui, grower } = thunkAPI.getState() as RootState;
		try
		{
			const api = new Api('/api/5', auth.userAuthToken, context);
			const selectedPlanId = ui.SelectedPlanId;
			const selectedGrower = grower.Growers.find(g => g.Id === ui.SelectedGrowerId);
			const selectedPlan = selectedGrower.Plans.find(p => p.Id === ui.SelectedPlanId);
			const compAllowance = selectedPlan.CompetitiveAllowance;
			const response = await api.postAsync<IPlanResponse[]>(
				`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/plan/${selectedPlanId}/discounts`, compAllowance
			);

			if (response.ErrorCode === null && response.Success)
			{
				return { discounts: response.Data, growerId: ui.SelectedGrowerId };
			}
			else 
			{
				return thunkAPI.rejectWithValue({ error: response.ErrorMessage, growerId: ui.SelectedGrowerId });
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue({ error: e.message, growerId: ui.SelectedGrowerId });
		}
	}
);