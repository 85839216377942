import { themeGet } from '@styled-system/theme-get';
import { Input, InputNumber, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { IStewardshipReplantOrLostFieldRequest, IStewardshipReplantOrLostRequest } from '../../../logic/store/Grower/EnogenContractThunk';
import { dynamicSort } from '../../../logic/Utility/Utils';
import { StyledDatePicker, StyledDatePickerPanel, StyledDivider } from './Stewardship';
import { ReactComponent as ExclaimIcon } from '../../../assets/images/Conflict.svg';

export interface IReplantLossModalProps
{
	fieldList: IStewardshipReplantOrLostFieldRequest[];
	type: ReplantOrLoss;
	visible: boolean;
	stewardshipActivityId: string;
	onCancel: () => void;
	onSave: (type: ReplantOrLoss, request: IStewardshipReplantOrLostRequest) => void;
}

export enum ReplantOrLoss
{
	Replant,
	FieldLoss,
}

const NumberInput = styled(InputNumber)`
	width: 60px;
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: -2px;
	border-radius: 4px;
	border: thin solid ${themeGet('colors.lightGrey')};
	&:focus {
		outline: none;
		box-shadow: 0 0 1pt 1pt white;
	}
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: left;
		height: auto;
		padding-right: 3px;
	}
`;

const StyledSelect = styled(Select)`
	width: 100%;
	.ant-select-selector {
		border-radius: 4px !important;
		color: white;
		font-family: 'Roboto';
		font-size: ${themeGet('fontSizes.small')};
		font-weight: ${themeGet('fontWeights.bold')};
	}

	// the active hover
	.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
		background-color: ${themeGet('colors.primary')};
		color: ${themeGet('colors.white')}; 
	}

	// Highlight actual selected options
	.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		background-color: ${themeGet('colors.secondary')};
		color: ${themeGet('colors.white')};
	}

	.ant-select-selection-search {
		color: ${themeGet('colors.darkGrey')}
	}

	.ant-select-selection-item {
		background: ${themeGet('colors.primary')};
	}

	.ant-select-selection-item-remove {
		svg path {
			fill: ${themeGet('colors.white')};
			stroke: ${themeGet('colors.white')};
			stroke-width: 100;
		}
	}

	svg path {
		fill: ${themeGet('colors.primary')};
		stroke: ${themeGet('colors.primary')};
		stroke-width: 100;
	}
`;

export const ReplantLossModal = (props: IReplantLossModalProps) =>
{
	const { fieldList, stewardshipActivityId, type, visible, onCancel, onSave } = props;

	const theme = useTheme();
	const dateFormat = 'MM/DD/YYYY';

	const [options, setOptions] = useState<IOptionItem[]>([]);
	const [editableFieldList, setEditableFieldList] = useState<IStewardshipReplantOrLostFieldRequest[]>([]);
	const [selectedFieldIds, setSelectedFieldIds] = useState<string[]>([]);
	const [replantOrLossDate, setReplantOrLossDate] = useState<string>(undefined);
	const [replantOrLossNotes, setReplantOrLossNotes] = useState<string>(undefined);

	const disabledDates = (currentDate: moment.Moment): boolean =>
	{
		const compareDate = moment(currentDate, dateFormat);
		// Dates can only be between March 1 and July 15th of the current year
		const marchDate = moment(`03/01/${moment().year()}`, dateFormat);
		const julyDate = moment(`12/31/${moment().year()}`, dateFormat);

		// the [] means both beginning and end dates are inclusive, () would mean exclusive
		// you can mix and match (] or [) as well.
		return !compareDate.isBetween(marchDate, julyDate, 'day', '[]');
	};

	useEffect(() => 
	{
		if (!fieldList || fieldList.length === 0)
		{
			return;
		}

		// Set the options for the select
		const options: IOptionItem[] = fieldList.map(f => ({
			value: f.FieldId,
			label: f.FieldName
		}));		
		setOptions(options);

		// Set the editable field list
		setEditableFieldList([..._.cloneDeep(fieldList)]);

		// Empty the selected field Id's
		setSelectedFieldIds([]);

		// Clear the Date
		setReplantOrLossDate(undefined);

		// Clear the Notes
		setReplantOrLossNotes(undefined);

	}, [fieldList]);

	const selectField = (value: string) =>
	{
		selectedFieldIds.push(value);
		setSelectedFieldIds([...selectedFieldIds]);
	};

	const deselectField = (value: string) =>
	{
		const foundIndex = selectedFieldIds.findIndex(v => v === value);
		selectedFieldIds.splice(foundIndex, 1);
		setSelectedFieldIds([...selectedFieldIds]);
	};

	const saveReplantOrFieldLoss = () =>
	{
		const selectedFields = editableFieldList.filter(f => selectedFieldIds.includes(f.FieldId));

		if (selectedFields.length !== selectedFieldIds.length)
		{
			return;
		}

		// Create the request
		const request: IStewardshipReplantOrLostRequest =
		{
			StewardshipActivityId: stewardshipActivityId,
			Date: replantOrLossDate,
			Notes: replantOrLossNotes,
			Fields: selectedFields,
		};

		onSave(type, request);
	};

	const editReplantAcres = (fieldId: string, updatedValue: Number) =>
	{
		const updatedFields = editableFieldList.map(field =>
		{
			if (field.FieldId === fieldId && updatedValue >= 0)
			{
				// Only update if the value has not changed
				if (field.Acres !== updatedValue.toString())
				{
					field.Acres = updatedValue.toString();
					return field;
				}
			}
			return field;
		});

		setEditableFieldList([...updatedFields]);
	};

	const saveIsDisabled = (): boolean =>
	{
		let saveIsDisabled = false;
		if (!replantOrLossNotes)
		{
			saveIsDisabled = true;
		}
		if (!replantOrLossDate)
		{
			saveIsDisabled = true;
		}
		if (selectedFieldIds.length === 0)
		{
			saveIsDisabled = true;
		}

		return saveIsDisabled;
	};

	return (
		<StyledModal
			title={
				<div style={{
					fontWeight: theme.fontWeights.bold,
					fontFamily: theme.fonts.heading,
					fontSize: theme.fontSizes.xLarge,
					paddingTop: 10,
				}}>{type === ReplantOrLoss.Replant ? 'Replanting' : 'Field Lost'}</div>
			}
			width={550}
			open={visible}
			closable={true}
			onCancel={onCancel}
			destroyOnClose={true}
		>
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginTop: type === ReplantOrLoss.FieldLoss ? -30 : 0, overflowY: 'auto' }}>
				{
					type === ReplantOrLoss.FieldLoss &&
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							fontSize: theme.fontSizes.extraSmall,
							fontWeight: theme.fontWeights.bold, marginBottom: 5,
						}}
					>
						<ExclaimIcon width={31} height={31} style={{ marginRight: 5, width: '15%' }} />
						<span>{'You are about to mark this field as completely lost. This action cannot be undone. ' + 
						'If you replanted this field with Enogen, please choose cancel and utilize the replant form.'}</span>
					</div>
				}
				<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					<div>
						<div>{`Select all the fields from the list that ${type === ReplantOrLoss.Replant ? 'need a replanting.' : 'need to be declared as lost.'}`}</div>
						<StyledSelect
							mode='multiple'
							getPopupContainer={(trigger) =>
							{
								return trigger;
							}}
							showSearch
							showArrow
							placeholder='Search Fields'
							className='ReplantOrLoss_FieldSelect'
							optionFilterProp='label'
							onSelect={selectField}
							onDeselect={deselectField}
							options={options}
							maxTagCount='responsive'
						/>
					</div>
					<StyledDivider className='ReplantOrLoss_Divider' />
					<div className={'ReplantOrLoss_Date'} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
						<div>
							{`Date of field	${type === ReplantOrLoss.Replant ? 'replant' : 'loss'}:`}
						</div>
						<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
							<StyledDatePicker
								className='Planting_ApplyAll_Date'
								placeholder={'mm/dd/yyyy'}
								format={dateFormat}
								disabledDate={disabledDates}
								value={replantOrLossDate ? moment(replantOrLossDate) : undefined}
								onChange={(value: moment.Moment) => setReplantOrLossDate(value ? value.format(dateFormat) : '')}
								panelRender={originalPanel =>
								{
									return (
										<StyledDatePickerPanel className='StyledDatePickerPanel'>
											{originalPanel}
										</StyledDatePickerPanel>
									);
								}}
							/>
						</div>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }} >
						<div className={'ReplantOrLoss_FieldRowHeader'} style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 20 }}>
							<div style={{ width: '50%'}}>
								Fields
							</div>
							<div>
								Acres on Field
							</div>
							<div style={{ marginLeft: 'auto' }}>
								{type === ReplantOrLoss.Replant ? 'Acres to Replant' : 'Acres Lost'}
							</div>
						</div>
						<StyledDivider className='ReplantOrLoss_Divider' style={{ marginTop: 5, marginBottom: 5 }} />
						<div
							className={'ReplantOrLoss_FieldRowContainer'}
							style={{ flex: '1 1 auto', overflowY: 'auto', paddingLeft: 10, paddingRight: 10, maxHeight: 150 }}>
							{
								selectedFieldIds.length === 0 ? 
									<div
										className='ReplantOrLoss_NoSelectedFieldsMessage'
										style={{
											display: 'flex',
											justifyContent: 'center',
											fontWeight: theme.fontWeights.bold,
											alignItems: 'center',
											height: '100%'
										}}
									>
											No Selected Fields
									</div>
									:
									editableFieldList.filter(f => selectedFieldIds.includes(f.FieldId)).sort(dynamicSort('FieldName')).map(f =>
										<div
											key={f.FieldId}
											style={{
												fontWeight: theme.fontWeights.bold,
												display: 'flex',
												flexDirection: 'row',
												marginBottom: 5
											}}
										>
											<div style={{ width: '50%'}}>
												{f.FieldName}
											</div>
											<div>
												{f.TotalAcres}
											</div>
											{
												type === ReplantOrLoss.Replant ?
													<div style={{ width: '22%', marginLeft: 'auto' }}>
														<NumberInput
															style={{ fontSize: 12, width: '90%', height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
															className={'Planting_Acres_Input'}
															type='number'
															placeholder='Add'
															value={f.Acres}
															onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
																editReplantAcres(f.FieldId, evt.currentTarget.value ? 
																	Number(evt.currentTarget.value) : Number(f.Acres))}
															onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
																editReplantAcres(f.FieldId, evt.target && (evt.target as HTMLInputElement).value ?
																	Number((evt.target as HTMLInputElement).value)
																	: Number(f.Acres))}
														/>
													</div>
													:
													<div style={{ display: 'flex', width: '22%', marginLeft: 'auto', justifyContent: 'center' }}>
														{f.Acres}
													</div>
											}
										</div>
									)
							}
						</div>
						<div className='ReplantOrLoss_NotesContainer' style={{ marginTop: 20, marginBottom: 10 }}>
							<div>{type === ReplantOrLoss.Replant ? 'Notes (required)' : 'Type the details to declare selected field(s) as lost:'}</div>
							<div>
								<Input.TextArea
									rows={2}
									name='ReplantOrLoss_Notes'
									value={replantOrLossNotes}
									placeholder='Add text'
									onChange={(evt) => setReplantOrLossNotes(evt.currentTarget.value)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='ReplantOrLoss_ButtonContainer' style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
					<Button variant='outlined' width={'120px'} style={{ marginRight: 10 }} onClick={onCancel}>Cancel</Button>
					<Button
						variant='main'
						width={'120px'}
						onClick={saveReplantOrFieldLoss}
						disabled={saveIsDisabled()}
					>
						Save
					</Button>
				</div>
			</div>
		</StyledModal>
	);
};