export interface IBulkImportSessionResponse
{
	Session: IBulkImportSession;
	GrowerId: string;
}

export interface IBulkImportSession
{
	Id: string;
	UploadUrl: string;
	Status: BulkImportSessionStatus;
}

export enum BulkImportSessionStatus
{
	// There are no jobs are associated with the session.
	Created,

	// Jobs for the session are being created.
	Processing,

	// Jobs have been created, but not yet sent to Eluminate.
	Loaded,

	// Jobs are being sent to Eluminate.
	Transferring,

	// All jobs have been successfully sent to Eluminate.
	Completed,

	// There were errors while sending jobs to Eluminate.
	CompletedWithErrors,

	// The session was cancelled by the user.
	Cancelled
}