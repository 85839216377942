import { cornId, soyId, unknownId } from '../store/Seeds/CropsSlice';

// This number was chosen to be sufficiently small not to affect accuracy but sufficiently large to adjust for any
// floating point inaccuracy issues.
const _roundingFactor: number = 100000;

export const RoundAcres = (acres: number): number =>
{
	return RoundUpToTenths(acres);
};

const RoundUpToTenths = (value: number): number =>
{
	return Math.ceil(10 * Math.round(_roundingFactor * value) / _roundingFactor) / 10;
};

/**
 * Rounds a number by moving the decimal to the right by decimalPlaces,
 * scaling down the result by scale,
 * rounding up to the nearest integer,
 * scaling up the result by scale,
 * then moving the decimal to the left by decimalPlaces.
 * Number.Epsilon counteracts values that are slightly smaller than expected.
 * @param value 
 * @param scale 
 * @param decimalPlaces 
 * @returns 
 */
export const RoundWithPrecision = (value: number, scale: number, decimalPlaces: number): number =>
{
	const precision = Math.pow(10, Math.trunc(decimalPlaces));
	return Math.round(Math.round(((value + Number.EPSILON) * precision) / scale) * scale) / precision;
};

export const RoundPricing = (value: number): number =>
{
	return bankersRound(value);
};

export const RoundSeedingRate = (rate: number): number =>
{
	return Math.ceil(rate);
};

export const RoundYieldTarget = (rate: number): number =>
{
	return Math.round(rate);
};

function bankersRound(n:number, d:number = 2) 
{
	const x = n * Math.pow(10, d);
	const r = Math.round(x);
	const br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r-1) : r;
	return br / Math.pow(10, d);
}

/**
 * At the 'aggregate' level we round up to the nearest bag.
 * @param rawBags The number of bags (fractional)
 * @returns 
 */
export function CalculateBags(rawBags: number) 
/**
 * At the 'aggregate' level we round up to the nearest bag.
 * @param seeds The total number of seeds
 * @param seedsPerBag How many seeds are in a bag
 * @returns 
 */
export function CalculateBags(seeds: number, seedsPerBag: number) 
/**
 * At the 'aggregate' level we round up to the nearest bag - (acres * seedingrate) / seedsperbag
 * @param acres 
 * @param seedingRate 
 * @param seedsPerBag 
 * @returns 
 */
export function CalculateBags(acres: number, seedingRate: number, seedsPerBag: number) 
/**
 * The overloaded function for calculating seeds for a field
 */
export function CalculateBags(acresSeedsOrBags: number, rateOrSeedsPerBag?: number, seedsPerBag?: number) 
{
	// Rounds up to nearest bag
	if(seedsPerBag !== undefined)
	{
		return Math.ceil((acresSeedsOrBags * rateOrSeedsPerBag) / seedsPerBag);
	}
	else if(rateOrSeedsPerBag !== undefined)
	{
		return Math.ceil(acresSeedsOrBags / rateOrSeedsPerBag);		
	}
	else
	{
		return Math.ceil(acresSeedsOrBags);
	}
}

/**
 * At the field level we round to the nearest tenth of a bag.
 * @param rawBags The number of bags (fractional)
 * @returns 
 */
export function CalculateBagsForField(rawBags:number);
/**
 * At the field level we round to the nearest tenth of a bag.
 * @param seeds The total number of seeds
 * @param seedsPerBag How many seeds are in a bag
 * @returns 
 */
export function CalculateBagsForField(seeds:number, seedsPerBag: number);
/**
 * At the field level we round to the nearest tenth of a bag.
 * @param acres The area the rate is distributed over
 * @param seedingRate The rate of seeds/area 
 * @param seedsPerBag How many seeds are in a bag
 * @returns 
 */
export function CalculateBagsForField(acres: number, seedingRate: number, seedsPerBag: number);

/**
 * The overloaded function for calculating seeds for a field
 */
export function CalculateBagsForField(acresSeedsOrBags: number, rateOrSeedsPerBag?: number, seedsPerBag?: number)
{
	if(seedsPerBag !== undefined)
	{
		// Rounds up to nearest bag
		return Math.round(10 * (acresSeedsOrBags * rateOrSeedsPerBag) / seedsPerBag) / 10;
	}
	else if(rateOrSeedsPerBag !== undefined)
	{
		return Math.round(10 * acresSeedsOrBags / rateOrSeedsPerBag) / 10;
	}
	else
	{
		return Math.round(10 * acresSeedsOrBags) / 10;
	}
}

/**
 * Takes a crop name as a string and converts it to the corresponding Guid crop Id value
 * @param cropName Crop name as a string, like 'Corn' or 'corn'
 * @returns 
 */
export const ConvertCropNameToId = (cropName: string): string =>
{
	if (!cropName)
	{
		return null;
	}
	switch(cropName.toLocaleLowerCase())
	{
		case 'corn':
			return cornId;
		case 'soy':
			return soyId;
		case 'soybean':
			return soyId;
		case 'unknown':
			return unknownId;
	}
};