import {  } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { IFieldlessAgronomicsSettings, IFieldlessRecommendationSettings, IProductGamePlanDetails, IProductGamePlanProductRequest, IProductPlanRequest } from '../../Models/Responses/ProductGamePlan';
import { IProductLine } from '../../Models/Requests/ProductPlanRequest';
import { cornId, CropConfig, soyId } from '../Seeds/CropsSlice';
import { newPlanDummyId, updateProductData } from './ProductPlanUtils';
import { downloadProductPlan } from './downloadProductPlan';
import {IPlanDownloadRequest } from '../../Models/Requests/PlanRequest';
import { getSeedingRateForSeriesAndRates } from '../Seeds/PlantingRateSlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { RoundYieldTarget } from '../../Utility/CalculationUtilities';

/**
 * Add (or update) a product assigned
 */
export interface IUpdateProductPayload
{
	productData: IProductLine;
	productKey: string;
	planId: string;
}
export interface IUpdateProductParams
{
	planId: string;
	productKey: string;
	cropId: string;
	update: Partial<IProductLine>;
}
export const updateProduct = createTracedAsyncThunk<IUpdateProductPayload, IUpdateProductParams, { dispatch: AppDispatch; state: RootState; }>(
	'productGamePlan/updateProduct',
	async (context, { planId, cropId, productKey, update }, thunkAPI) =>
	{
		const { grower, ui, productGamePlan } = thunkAPI.getState();
		const cropConfig = CropConfig()[cropId];
		const selectedGrower = grower.Growers.find(grower => grower.Id === ui.SelectedGrowerId);
		try
		{
			if (!selectedGrower)
			{
				return thunkAPI.rejectWithValue(`Grower with id ${ui.SelectedGrowerId} not found.`);
			}

			if (!productGamePlan.productGamePlans[planId])
			{
				return thunkAPI.rejectWithValue(`Plan with id ${planId} not found.`);
			}

			if (!productGamePlan.productGamePlans[planId].localEditingState.products[productKey])
			{
				return thunkAPI.rejectWithValue(`Product with id ${productKey} not found.`);
			}

			return {
				productData: updateProductData({
					productData: productGamePlan.productGamePlans[planId].localEditingState.products[productKey].product,
					update,
					cropConfig,
					cropId,
					defaultGrowerSeedingRate: cropConfig.defaultSeedingRate
				}),
				productKey,
				planId
			};
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e);
		}
	}
);

// post full list of valid products
export const saveProductPlan = createTracedAsyncThunk<IProductGamePlanDetails, {request: IPlanDownloadRequest, printPDF: boolean}, { rejectValue: string, dispatch: AppDispatch; state: RootState; }>(
	'productGamePlan/saveProductGamePlan',
	async (context, args, thunkAPI) =>
	{
		const request = args.request;
		const printPDF = args.printPDF;
		const { productGamePlan, auth } = thunkAPI.getState() as RootState;
		const planEditId = productGamePlan.selectedGamePlanId === newPlanDummyId ? newPlanDummyId : request.PlanId;
		const localEditingState = productGamePlan.productGamePlans[planEditId].localEditingState;

		if (!localEditingState)
		{
			return thunkAPI.rejectWithValue('No product plan edits found.');
		}
		// validate editing state products for required props
		if (Object.values(localEditingState.products).some(item =>
		{
			const { product } = item;
			return !product.AppliedArea || !product.Quantity || !product.SeedingRate;
		}))
		{
			return thunkAPI.rejectWithValue('All products must have valid applied area, quantity, and seeding rate.');
		}
		const api = new Api('/api/4', auth.userAuthToken, context);

		try
		{
			const req: IProductPlanRequest = { Products: [] };
			// send the full array of products. this will overwrite existing ones
			const productsRequest: IProductGamePlanProductRequest[] = Object.keys(localEditingState.products)
				.map(productKey => localEditingState.products[productKey])
				.map<IProductGamePlanProductRequest>(productState => 
				{
					return {
						AppliedArea: productState.product.AppliedArea,
						BrandAssociation: productState.cropType,
						CropId: productState.cropId,
						HybridId: productState.hybridId,
						LocalPositioning: productState.product.LocalPositioning,
						PriceRate: productState.product.PriceRate,
						Quantity: productState.product.Quantity,
						QuantityRequired: productState.product.QuantityRequired,
						SeedingRate: productState.product.SeedingRate,
						TotalPrice: productState.product.TotalPrice,
						YieldTarget: productState.product.YieldTarget,
						Treatment: productState.product.Treatment
					};
				});
			req.Products = productsRequest;
			const recommendationOptionRequest: IFieldlessRecommendationSettings = {};
			// Agronomics Settings
			const agronomicsSettingsRequest: IFieldlessAgronomicsSettings[] = [];
			Object.keys(localEditingState.recommendationOptions).map(optionKey =>
			{
				const currentOptions = localEditingState.recommendationOptions[optionKey];
				
				if (currentOptions)
				{
					let brandApplication = 'Any';
					let cropId = cornId;
					if (optionKey.indexOf('Enogen') > -1)
					{
						recommendationOptionRequest.EnogenAcres = currentOptions.acres;
						recommendationOptionRequest.EnogenIrrigated = currentOptions.irrigated;
						recommendationOptionRequest.EnogenRequiredSeeds = currentOptions.seedsRequired;
						recommendationOptionRequest.EnogenTargetYield = currentOptions.targetYield;
						recommendationOptionRequest.EnogenZipCode = currentOptions.zip;

						brandApplication = 'Enogen';
						cropId = cornId;
					}
					else if (optionKey.indexOf(cornId) > -1 && optionKey.indexOf('Any') > -1)
					{
						recommendationOptionRequest.CornAcres = currentOptions.acres;
						recommendationOptionRequest.CornIrrigated = currentOptions.irrigated;
						recommendationOptionRequest.CornRequiredSeeds = currentOptions.seedsRequired;
						recommendationOptionRequest.CornTargetYield = currentOptions.targetYield;
						recommendationOptionRequest.CornZipCode = currentOptions.zip;

						brandApplication = 'Any';
						cropId = cornId;
					}
					else if (optionKey.indexOf(soyId) > -1)
					{
						recommendationOptionRequest.SoybeanAcres = currentOptions.acres;
						recommendationOptionRequest.SoybeanIrrigated = currentOptions.irrigated;
						recommendationOptionRequest.SoybeanRequiredSeeds = currentOptions.seedsRequired;
						recommendationOptionRequest.SoybeanTargetYield = currentOptions.targetYield;
						recommendationOptionRequest.SoybeanZipCode = currentOptions.zip;

						brandApplication = 'Any';
						cropId = soyId;
					}

					agronomicsSettingsRequest.push({
						BrandApplication: brandApplication,
						ConsistencyPerf: currentOptions.performance,
						CropId: cropId,
						Fungicide: currentOptions.fungicide,
						IsFilterApplied: currentOptions.isFilterApplied,
						ProductPlanId: request.PlanId,
						RmMax: currentOptions.rmMax,
						RmMin: currentOptions.rmMin,
						TopEndYield: currentOptions.topEndYield,
						Traits: currentOptions.selectedTraits.join(),
					});
				}
			});
			
			req.RecommendationSettings = recommendationOptionRequest;
			req.AgronomicSettings = agronomicsSettingsRequest;
			
			const response = await api.postAsync<IProductGamePlanDetails>(
				`productplans/${request.PlanId}/products`, req
			);

			if (response.ErrorCode === null && response.Success)
			{
				if (printPDF)
				{
					await thunkAPI.dispatch(downloadProductPlan( request ));
				}
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

/**
 * Set the yield target and implicitly the seeding rate
 */
export interface IUpdateYieldActionProps
{
	planId: string;
	productKey: string;
	cropId: string;
	yieldTarget: number;
	seriesName: string;
}

export const updateYieldTarget = createTracedAsyncThunk<IUpdateProductPayload, IUpdateYieldActionProps, { dispatch: AppDispatch; state: RootState; }>(
	'productGamePlan/updateYieldTarget',
	(context, { planId, cropId, yieldTarget, productKey, seriesName }, thunkAPI) => 
	{
		const { productGamePlan, plantingRate, grower, ui } = thunkAPI.getState();
		const selectedGrower = grower.Growers.find(grower => grower.Id === ui.SelectedGrowerId);
		const cropConfig = CropConfig()[cropId];
		const localEditingState = productGamePlan.productGamePlans[planId].localEditingState;

		yieldTarget = RoundYieldTarget(yieldTarget);

		const { yieldInterval, hasPlantingRateCalculator } = CropConfig()[cropId];
		const productData = {
			...localEditingState.products[productKey].product,
			YieldTarget: yieldTarget
		};

		// Accumulate all changes to pass to updateProductData to make sure
		// it has an opportunity to react to them.
		const update: Partial<IProductLine> = {
			YieldTarget: yieldTarget
		};

		if (hasPlantingRateCalculator)
		{
			const seedingRate = getSeedingRateForSeriesAndRates(plantingRate.rates, seriesName, yieldTarget);
			if (seedingRate) 
			{
				update.SeedingRate = seedingRate;
			}
		}
		return {
			productData: updateProductData({
				cropId,
				productData,
				update,
				cropConfig,
				defaultGrowerSeedingRate: cropConfig.defaultSeedingRate
			}), productKey, planId
		};
	});
