import { ActionReducerMapBuilder, AsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRoleProjection } from '../../Models/Responses/UserAdmin/IRoleProjection';
import { IUserAdminSlice } from './IUserAdminSlice';
import {
	createUser,
	getAccessControls,
	getCampaigns,
	getOrganizations,
	getPermissions,
	getRoles,
	getUsers,
	saveUser,
	searchForEmail,
	sendInvitationToUser,
	userFileImport,
	createRole,
	updateRole,
	deleteRole,
} from './UserAdminThunks';

// Our initial state is just empty
const initialState: IUserAdminSlice = {
	users: [],
	roles: [],
	permissions: [],
	organizations: [],
	accessControls: [],
	campaigns: [],
	loadingCount: 0,
	emailSearchResult: {},
	showSuccessNotification: false,
};

export const userAdminSlice = createSlice({
	name: 'userInfo',
	initialState: initialState,
	reducers: {
		setEditingUserId: (state, action: PayloadAction<string | undefined>) => 
		{
			state.editingUserId = action.payload;
			return this;
		},
		clearSuccessNotification: (state) =>
		{
			state.showSuccessNotification = false;
			return this;
		}
	},
	extraReducers: (builder) =>
	{
		bindLoadingCounter(builder, getUsers, (state, data) => 
		{
			state.users = data;
		});
		bindLoadingCounter(builder, saveUser, (state, data) => 
		{
			// replace the one that is updated
			state.users = state.users.map(p => p.Id === data.Id ? data : p);
		});
		bindLoadingCounter(builder, createUser, (state, data) => 
		{
			// replace the one that is updated
			state.users = [ data, ...state.users ];
			state.editingUserId = data.Id;
		});
		bindLoadingCounter(builder, getRoles, (state, data) => 
		{
			state.roles = data;
		});
		bindLoadingCounter(builder, getPermissions, (state, data) => 
		{
			state.permissions = data;
		});
		bindLoadingCounter(builder, getOrganizations, (state, data) => 
		{
			state.organizations = data;
		});
		bindLoadingCounter(builder, getAccessControls, (state, data) => 
		{
			state.accessControls = data;
		});
		bindLoadingCounter(builder, getCampaigns, (state, data) => 
		{
			state.campaigns = data;
		});
		bindLoadingCounter(builder, searchForEmail, (state, data) => 
		{
			state.emailSearchResult = data;
		});
		bindLoadingCounter(builder, sendInvitationToUser, (state, data) => {});
		builder.addCase(userFileImport.pending, (state) =>
		{
			state.loadingCount++;
		});
		builder.addCase(userFileImport.fulfilled, (state) =>
		{
			state.loadingCount--;
			state.showSuccessNotification = true;
		});
		builder.addCase(userFileImport.rejected, (state) =>
		{
			state.loadingCount--;
		});
		bindLoadingCounter(builder, updateRole, (state, data) => 
		{
			// replace the one that is updated
			state.roles = state.roles.map(p => p.Id === data.Id ? data : p);
		});
		bindLoadingCounter(builder, createRole, (state, data) => 
		{
			// replace the one that is updated
			state.roles = [ data, ...state.roles ];
		});
		bindLoadingCounter(builder, deleteRole, (state, data: {replacementRole: IRoleProjection, deletedId: string}) => 
		{
			state.roles = [ ...state.roles.filter(r => r.Id !== data.deletedId)];
		});
	}
});

/**
 * Simple wrapper to bind async data fetching to the loading counter
 */
function bindLoadingCounter<U,V,W> (builder: ActionReducerMapBuilder<IUserAdminSlice>, reducer: AsyncThunk<U, V, W>, apply: (slice: IUserAdminSlice, data: U) => unknown)
{
	builder.addCase(reducer.pending, (state) => { state.loadingCount++; } );
	builder.addCase(reducer.rejected, (state) => { state.loadingCount--; } );
	builder.addCase(reducer.fulfilled, (state, result) => 
	{ 
		apply(state, result.payload);
		state.loadingCount--;
	} );
}


export const { setEditingUserId, clearSuccessNotification } = userAdminSlice.actions;