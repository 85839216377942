import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as RoundedPlus } from '../../assets/images/RoundedPlus.svg';
import { ReactComponent as EmptyMap } from './Icons/EmptyMap.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/PlusIcon.svg';
import { Button } from '../../components/Button/Button';
import { CheckboxExpander, ICheckboxSection, ICheckboxChildOption } from '../../components/CheckboxExpander/CheckboxExpander';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, RootState } from '../../logic/store/Store';
import
{
	BoolDictionary,
	clearSelectedFieldForEdit,
	clearSelectedFields,
	deleteDrawnFeatures,
	DrawingInteractionMode,
	IInteractionMode,
	MapInteractionMode,
	setFieldIsSelected,
	setMapInteractionMode,
	setSelectedFieldForEdit,
	toggleDrawingTools,
} from '../../logic/store/UI/UISlice';
import { dynamicSort, makeDispatch, useInterval } from '../../logic/Utility/Utils';
import { FieldListActionDrawer } from './FieldListActionDrawer';
import { FieldDrawingToolsPanel } from './FieldDrawingTools';
import { IBulkImportRequest } from '../../logic/Models/Requests/BulkImportRequest';
import { startBulkImport, getBulkImportSession } from '../../logic/store/BulkImport/BulkImportThunks';
import { BulkImportSessionStatus } from '../../logic/Models/Responses/BulkImportResponse';
import { getFarms, IFarmRequest, deleteMultipleFarms, downloadCropHealthPdf, IMultiDeleteFarmRequest } from '../../logic/store/Grower/FarmThunks';
import { clearState } from '../../logic/store/CLUs/CLUSlice';
import { IDeleteFieldRequest, deleteField, IMultiDeleteFieldRequest, deleteMultipleFields } from '../../logic/store/Grower/FieldThunks';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
import { EditNamesModal } from './EditNamesModal';
import { UserPermission } from '../../logic/Models/Responses/UserPermissions';
import { hasEffectivePermission } from '../../logic/store/User/AuthSlice';
import { FieldListContainer, Footer } from './FieldListContainer';
import { ConnectedBackToGrowerList } from './BackToGrowerList';
import { MultiActionButton } from '../../components/MultiActionButton/MultiActionButton';
import { useTheme } from 'styled-components';

interface IFieldListDrawerProps extends PropsFromRedux
{
	className?: string;
}

const BaseFieldListDrawer = (props: IFieldListDrawerProps) =>
{
	const {
		Growers,
		IsDeletingFarm,
		IsDeletingField,
		IsGhx,
		IsLoadingGetBulkImport,
		IsLoadingStartBulkImport,
		SelectedFieldIds,
		SelectedGrowerId,
		ShowDrawingTools,
		UserIsGrower,
		ChangeInteractionMode,
		ClearCluState,
		ClearSelectedFields,
		ClearSelectedFieldForEdit,
		DeleteDrawn,
		DeleteFarms,
		DeleteField,
		DeleteFields,
		DownloadCropHealthPdf,
		GetBulkImportSession,
		PullFarms,
		SetDisplayDrawingTools,
		SetSelectedFieldForEdit,
		StartBulkImportSession,
		ToggleFieldIdSelection,
	} = props;

	const theme = useTheme();

	const [visible, setVisible] = useState(true);
	const [isActionDrawerVisible, setIsActionDrawerVisible] = useState<boolean>(false);
	const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState<boolean>(false);
	const [startedBulkImport, setStartedBulkImport] = useState(false);
	const [checkboxItems, setCheckboxItems] = useState<ICheckboxSection[]>([]);
	const [bulkImportPollingInterval, setBulkImportPollingInterval] = useState<number | null>(null);
	const [editNamesModalVisible, setEditNamesModalVisible] = useState<boolean>(false);
	const history = useHistory();

	const selectedGrower = Growers.find(g => g.Id === SelectedGrowerId);

	useEffect(() =>
	{
		// Pull farms again in case the user accepted a gameplan and are now looking to see their seed selections display
		if (selectedGrower)
		{
			PullFarms({ growerId: SelectedGrowerId });
		}
	}, [SelectedGrowerId]);

	useEffect(() =>
	{
		// If we have SelectedFieldIds (probably came from map clicks)
		if (SelectedFieldIds && checkboxItems && checkboxItems.length > 0)
		{
			let didChange: boolean = false;
			const updatedCheckboxItems = checkboxItems.map(farm =>
			{
				farm.options.forEach(field =>
				{
					if (field.isChecked != (SelectedFieldIds.indexOf(field.optionId) !== -1))
					{
						didChange = true;
						field.isChecked = SelectedFieldIds.indexOf(field.optionId) !== -1;
					}

					return field;
				});

				if (farm.options.every(fieldOpt => fieldOpt.isChecked))
				{
					farm.isChecked = true;
				}
				else
				{
					farm.isChecked = false;
				}

				return farm;
			});

			if (didChange)
			{
				setCheckboxItems(updatedCheckboxItems);
			}

			// Show the action drawer if any fields were checked, otherwise keep it hidden
			setIsActionDrawerVisible(checkboxItems.flatMap(farm => farm.options).some(field => field.isChecked));
		}
	}, [SelectedFieldIds, checkboxItems]);

	useEffect(() =>
	{
		if (selectedGrower.Farms?.length)
		{
			const allOptions: ICheckboxSection[] = [];
			const farmsWithFields = selectedGrower.Farms.filter(farm => farm.Fields.length);
			farmsWithFields.forEach((farm) =>
			{
				const fieldOptions: ICheckboxChildOption[] = [];
				farm.Fields.forEach((field) =>
				{
					const fieldCropIsCorn: boolean = field.CurrentCrop?.toLocaleLowerCase() === 'corn';
					const fieldHasPlantingDate: boolean = !!field.PlantingDate;
					fieldOptions.push(
						{
							option: field.Name,
							optionId: field.Id,
							isChecked: false,
							enabledForFieldHealth: fieldCropIsCorn && fieldHasPlantingDate,
						}
					);
				});
				const farmOption: ICheckboxSection =
				{
					header: farm.Name,
					headerId: farm.Id,
					options: fieldOptions.sort(dynamicSort('option')),
					isChecked: false,
					showFieldHealthButton: fieldOptions.some(f => f.enabledForFieldHealth),
					isExpanded: checkboxItems.find(section => section.headerId === farm.Id)?.isExpanded ?? false,
				};

				allOptions.push(farmOption);
			});

			setCheckboxItems(allOptions.sort(dynamicSort('header')));
		}
	}, [selectedGrower.Farms]);

	const onChange = useCallback((selectedIds: BoolDictionary) =>
	{
		ToggleFieldIdSelection(selectedIds);
	}, [ToggleFieldIdSelection]);

	const onExpand = useCallback((index) =>
	{
		const expandedCheckboxItems = checkboxItems;
		const item = expandedCheckboxItems[index];
		if (item)
		{
			expandedCheckboxItems[index].isExpanded = !item.isExpanded;
			setCheckboxItems([...expandedCheckboxItems]);
		}
	}, [checkboxItems]);

	const deleteFieldAndRemoveOption = ({ headerId: farmId, optionId: fieldId }: { headerId: string; optionId: string; }) =>
	{
		// Mark field as deleted on server
		DeleteField({ farmId, fieldId });

		// Remove field as being selected 
		onChange({ [fieldId]: false });

		// Remove option for field
		const updatedCheckboxItems = checkboxItems;
		const farmIndex = updatedCheckboxItems.findIndex(farm => farm.headerId === farmId);
		const fieldIndex = updatedCheckboxItems[farmIndex].options.findIndex(field => field.optionId === fieldId);
		if (farmIndex > -1 && fieldIndex > -1)
		{
			updatedCheckboxItems[farmIndex].options.splice(fieldIndex, 1);
			setCheckboxItems(updatedCheckboxItems);
		}
	};

	const editField = ({ headerId: farmId, optionId: fieldId }: { headerId: string; optionId: string; }) =>
	{
		SetSelectedFieldForEdit(fieldId);
		SetDisplayDrawingTools(true);
		ChangeInteractionMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Nothing });
	};

	const bulkDeleteFarmsFieldsAndRemoveOptions = () =>
	{
		if (IsDeletingFarm || IsDeletingField) return;

		const farmIdsToDelete: string[] = [];
		let updatedCheckboxItems = [];

		checkboxItems.forEach(farmSection =>
		{
			// If the farm and all of its fields are checked, we can add the entire farm for deletion
			const checkedLength = farmSection.options.filter(field => field.isChecked).length;
			if (checkedLength === farmSection.options.length)
			{
				farmIdsToDelete.push(farmSection.headerId);
			}
			// but if only some fields are checked, then just delete those
			else if (checkedLength > 0 && checkedLength < farmSection.options.length)
			{
				const fieldIdsToDelete = farmSection.options.filter(fieldOption => fieldOption.isChecked)
					.map(fieldOption => fieldOption.optionId);

				if (fieldIdsToDelete.length > 0)
				{
					// Mark the fields as deleted on the server
					DeleteFields({ farmId: farmSection.headerId, fieldIds: fieldIdsToDelete });
	
					// Unselect the fields
					const fieldOptionsDict = {};
					fieldIdsToDelete.forEach(fieldId => fieldOptionsDict[fieldId] = false);
					onChange(fieldOptionsDict);
	
					// And remove the options from the list
					updatedCheckboxItems = checkboxItems;
					const farmIndex = updatedCheckboxItems.findIndex(farm => farm.headerId === farmSection.headerId);
					let fieldIndex;
					fieldIdsToDelete.forEach(fieldId => 
					{
						fieldIndex = updatedCheckboxItems[farmIndex].options.findIndex(field => field.optionId === fieldId);
						if (farmIndex > -1 && fieldIndex > -1)
						{
							updatedCheckboxItems[farmIndex].options.splice(fieldIndex, 1);
						}
					});
				}
			}
		});

		setCheckboxItems([...updatedCheckboxItems]);
		
		if (farmIdsToDelete.length > 0)
		{
			// Mark the farms as deleted on the server
			DeleteFarms({ farmIds: farmIdsToDelete });
	
			// Unselect the deleted options and remove them from the list
			const updatedCheckboxItems = checkboxItems;
			farmIdsToDelete.forEach(farmId =>
			{
				const fieldOptionsDict = {};
				const fieldIdsToDelete = updatedCheckboxItems.find(section => section.headerId === farmId)?.options.map(option => option.optionId);
				fieldIdsToDelete.forEach(fieldId => fieldOptionsDict[fieldId] = false);
				onChange(fieldOptionsDict);
	
				const farmIndex = updatedCheckboxItems.findIndex(farm => farm.headerId === farmId);
				if (farmIndex > -1)
				{
					updatedCheckboxItems.splice(farmIndex, 1);
				}
			});
			setCheckboxItems([...updatedCheckboxItems]);
		}

		//Force-close the action drawer
		setIsActionDrawerVisible(false);
	};

	const toggleDrawingMode = () =>
	{
		if (ShowDrawingTools)
		{
			ChangeInteractionMode({ mode: MapInteractionMode.Selection });
			ClearSelectedFieldForEdit();
		}
		else
		{
			ClearSelectedFields();
			ChangeInteractionMode({ mode: MapInteractionMode.Drawing, drawingMode: DrawingInteractionMode.Nothing });
		}
		ClearCluState();
		DeleteDrawn();
		SetDisplayDrawingTools(!ShowDrawingTools);
	};

	const startBulkImport = () =>
	{
		if (!IsLoadingStartBulkImport) 
		{
			setStartedBulkImport(true);
			StartBulkImportSession({ GrowerId: SelectedGrowerId });
		}
	};

	// Upon successfully starting the BI session, begin polling 
	const TenSecondsInMilliseconds = 10000;
	useEffect(() =>
	{
		if (selectedGrower.BulkImportSession && startedBulkImport && !IsLoadingStartBulkImport && window && window.open) 
		{
			setStartedBulkImport(false);
			setBulkImportPollingInterval(TenSecondsInMilliseconds);
			window.open(selectedGrower.BulkImportSession.UploadUrl, '_blank');
		}
	}, [selectedGrower.BulkImportSession?.UploadUrl, IsLoadingStartBulkImport]);

	// If there's an incomplete BI session upon re-entering this component, begin polling
	useEffect(() =>
	{
		if (selectedGrower.BulkImportSession?.Id
			&& Number.parseInt(BulkImportSessionStatus[selectedGrower.BulkImportSession?.Status]) < BulkImportSessionStatus.Completed)
		{
			setBulkImportPollingInterval(TenSecondsInMilliseconds);
		}
	}, []);

	// Poll as long as there's a session to fetch and we aren't already fetching the session and
	useInterval(() =>
	{
		if (selectedGrower.BulkImportSession?.Id && !IsLoadingGetBulkImport)
		{
			GetBulkImportSession({
				GrowerId: SelectedGrowerId,
				SessionId: selectedGrower.BulkImportSession.Id
			});
		}
	}, bulkImportPollingInterval);

	// Timeout fallback to stop polling if process takes longer than 5 minutes
	// TODO: Determine what to do in case of stuck BI process ~mburroughs 7/15/21
	const FiveMinutesInMilliseconds = 300000;
	useInterval(() =>
	{
		setBulkImportPollingInterval(null);
	}, FiveMinutesInMilliseconds);

	useEffect(() =>
	{
		// Completed or cancelled, stop polling and refresh grower field/farm data
		if (Number.parseInt(BulkImportSessionStatus[selectedGrower.BulkImportSession?.Status]) >= BulkImportSessionStatus.Completed)
		{
			setBulkImportPollingInterval(null);
			PullFarms({ growerId: SelectedGrowerId });
		}
	}, [selectedGrower.BulkImportSession]);

	const getFieldHealthPDF = (farmId: string) =>
	{
		DownloadCropHealthPdf({ FarmId: farmId });
	};

	const selectedFields = selectedGrower && selectedGrower.Farms
		? selectedGrower.Farms.flatMap(farm => farm.Fields).filter(field => field && SelectedFieldIds.includes(field.Id))
		: [];

	const hasFarms = selectedGrower.Farms?.length > 0;

	const goToBulkImport = useCallback(() =>
	{
		history.push('/fieldactivities/bulkimport');
	}, [history]);

	return (
		<FieldListContainer className={props.className} expandable={true} setVisible={setVisible} visible={visible}>
			<div className='content' style={{ visibility: visible ? 'visible' : 'hidden' }}>
				{
					!UserIsGrower &&
					<ConnectedBackToGrowerList />
				}
				<div className='sectionContainer'
					style={{
						height: 'calc(100% - 55px)',
						display: ShowDrawingTools ? 'none' : 'flex',
						flexDirection: 'column'
					}}
				>
					<div className='sectionInner' style={{ padding: '0 16px', height: '94%', display: 'flex', flexDirection: 'column' }}>
						<div className='section header' style={{ display: 'flex' }}>
							<span style={{ flexGrow: 1, fontSize: theme.fontSizes.large }}>
								Field List
							</span>
							{/* <IconButton className='filterButton' disabled variant='filter' /> */}
						</div>
						{/* 07/06/2021 - ELM - Temporary hide Groups until usable */}
						{/* {hasFarms && <div className='section groupSection'>
							<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								Group(s)
								<div style={{ marginLeft: 'auto' }}>
									<Button className='addfieldToExisting' variant='light'>
										<PlusIcon style={{ position: 'relative', top: 4, marginRight: 10, width: 14 }} />
										<span style={{ fontWeight: theme.fontWeights.bold }}>Add a Group</span>
									</Button>
								</div>
							</span>
						</div>} */}
						<div className='section fieldSection'>
							{hasFarms ? <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingBottom: 40 }}>
								<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									Field(s)
									<div style={{ marginLeft: 'auto' }}>
										<MultiActionButton
											buttonProps={{ variant: 'outlined', className: 'addfieldToExisting' }}
											menuProps={{ items: [{
												onClick: toggleDrawingMode,
												key: 'draw',
												label: 'Draw Fields'
											},
											{
												onClick: goToBulkImport,
												key: 'import',
												label: 'Import Farm/Field Tree'
											}] }}
										>
											<PlusIcon style={{ position: 'relative', top: 4, marginRight: 10, width: 14 }} fill={theme.colors.darkGrey} />
											<span style={{ fontWeight: theme.fontWeights.bold }}>Add a Field</span>
										</MultiActionButton>
									</div>
								</span>
								<CheckboxExpander
									checkboxSections={checkboxItems}
									includeIconButton={false}
									iconButtonFunction={getFieldHealthPDF}
									onChecked={onChange}
									onDelete={deleteFieldAndRemoveOption}
									onEdit={editField}
									onExpand={onExpand}
									outerContainerStyle={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
								/>
							</div>
								:
								<div>
									<div style={{ marginBottom: 10 }}><span>Field(s)</span></div>
									<Button
										className='addFieldsButton'
										variant='dashed'
										fullWidth
										onClick={toggleDrawingMode}
									>
										<RoundedPlus fill={theme.colors.primary} style={{ position: 'relative', top: 1, marginRight: 5 }} />
										<span style={{ fontWeight: theme.fontWeights.normal }}>Add a Field</span>
									</Button>
									<Button className='addFieldsButton'
										variant='dashed'
										fullWidth
										style={{ marginTop: 15 }}
										onClick={goToBulkImport}
										disabled={selectedGrower.BulkImportSession?.Status < BulkImportSessionStatus.Completed}
									>
										<RoundedPlus fill={theme.colors.primary} style={{ position: 'relative', top: 1, marginRight: 5 }} />
										<span style={{ fontWeight: theme.fontWeights.normal }}>Import Farm/Field Tree</span>
									</Button>
									<EmptyMap fill={theme.colors.primary}/>
								</div>
							}
						</div>
					</div>
					<Footer style={{ display: selectedFields.length >= 1 && !ShowDrawingTools ? 'flex' : 'none', zIndex: 1 }}>
						<Button
							variant='outlined'
							className='bulkFieldEditButton'
							onClick={() => setIsConfirmDeleteModalVisible(true)}
						>
							Delete
						</Button>
						<Button
							style={{ marginLeft: 10, marginRight: 10 }}
							variant='dark'
							className='bulkEditNamesButton'
							onClick={() => setEditNamesModalVisible(true)}
						>
							Edit Names
						</Button>
					</Footer>
				</div>
				<div className='drawingContainer'
					style={{
						padding: '0 16px',
						height: '100%',
						overflowY: 'auto',
						display: ShowDrawingTools ? 'flex' : 'none'
					}}
				>
					<FieldDrawingToolsPanel
						onExit={toggleDrawingMode}
						farms={selectedGrower.Farms}
					/>
				</div>
			</div>
			<FieldListActionDrawer
				selectedGrower={selectedGrower}
				selectedFields={selectedFields}
				visible={isActionDrawerVisible && visible && !UserIsGrower}
				display={isActionDrawerVisible && !ShowDrawingTools && !UserIsGrower}
			/>
			<ConfirmDeleteModal
				visible={isConfirmDeleteModalVisible}
				onCancel={() => setIsConfirmDeleteModalVisible(false)}
				onDelete={() =>
				{
					bulkDeleteFarmsFieldsAndRemoveOptions();
					setIsConfirmDeleteModalVisible(false);
				}}
			/>
			<EditNamesModal
				visible={editNamesModalVisible}
				farmFieldTree={checkboxItems}
				onCancel={() => setEditNamesModalVisible(false)}
				onSave={() => console.log('Save clicked')}
			/>
		</FieldListContainer>
	);
};

const mapStateToProps = (state: RootState) => ({
	Growers: state.grower.Growers,
	IsDeletingFarm: state.grower.isDeletingFarm,
	IsDeletingField: state.grower.isDeletingField,
	IsGhx: hasEffectivePermission(state, UserPermission.CanSeePricing),
	IsLoadingFarms: state.grower.isLoadingFarms,
	IsLoadingGetBulkImport: state.grower.isFetchingBulkImportStatus,
	IsLoadingStartBulkImport: state.grower.isLoadingStartBulkImport,
	SelectedFieldIds: state.ui.SelectedFields,
	SelectedGrowerId: state.ui.SelectedGrowerId,
	SelectedUserId: state.userInfo.UserId,
	ShowDrawingTools: state.ui.ShowDrawingTools,
	UserIsGrower: hasEffectivePermission(state, UserPermission.IsGrower),
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ChangeInteractionMode: (mode: IInteractionMode) => dispatch(setMapInteractionMode(mode)),
		ClearSelectedFieldForEdit: makeDispatch(dispatch, clearSelectedFieldForEdit),
		ClearSelectedFields: makeDispatch(dispatch, clearSelectedFields),
		ClearCluState: (() => dispatch(clearState())),
		DownloadCropHealthPdf: makeDispatch(dispatch, downloadCropHealthPdf),
		DeleteDrawn: () => dispatch(deleteDrawnFeatures()),
		DeleteFarms: (request: IMultiDeleteFarmRequest) => dispatch(deleteMultipleFarms(request)),
		DeleteField: (request: IDeleteFieldRequest) => dispatch(deleteField(request)),
		DeleteFields: (request: IMultiDeleteFieldRequest) => dispatch(deleteMultipleFields(request)),
		GetBulkImportSession: (request: IBulkImportRequest) => dispatch(getBulkImportSession(request)),
		PullFarms: (request: IFarmRequest) => dispatch(getFarms(request)),
		SetDisplayDrawingTools: (display: boolean) => dispatch(toggleDrawingTools(display)),
		SetSelectedFieldForEdit: makeDispatch(dispatch, setSelectedFieldForEdit),
		StartBulkImportSession: (request: IBulkImportRequest) => dispatch(startBulkImport(request)),
		ToggleFieldIdSelection: (selectedDictionary: BoolDictionary) => dispatch(setFieldIsSelected(selectedDictionary)),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const FieldListDrawer = connector(BaseFieldListDrawer);
