import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { IPlanResponse, } from '../../Models/Responses/PlanResponse';
import { IGetPlansRequest, IPlanUpdateRequest, IPlanLaunchRequest, IPlanLaunchExistingRequest, IPlanDownloadRequest } from '../../Models/Requests/PlanRequest';
import { getCurrentActingUser } from '../User/AuthSlice';
import { sleepOnIt } from '../../Utility/Utils';
import { getCropYear } from '../Seeds/CropsSlice';
import { setSelectedYear } from '../UI/UISlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export interface IPlans
{
	Plans: IPlanResponse[];
	RequestedGrowerId: string;
}

export interface ILaunchPlan
{
	LaunchUrl: string;
	RequestedGrowerId: string;
}

export interface ILaunchExistingPlan extends ILaunchPlan
{
	PlanId: string;
}

export interface IKnownGetPlansError
{
	error: string;
	growerId: string;
}

export const getPlans = createTracedAsyncThunk<IPlans, IGetPlansRequest, { dispatch: AppDispatch, state: RootState, rejectValue: IKnownGetPlansError }>(
	'plans/get',
	async (context, request: IGetPlansRequest, thunkAPI) => 
	{
		const { auth, ui, } = thunkAPI.getState();
		const RequestedGrowerId = request.growerId || ui.SelectedGrowerId;
		try
		{
			const api = new Api('/api/4', auth.userAuthToken, context);
			const response = await api.getAsync<IPlanResponse[]>(
				`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/growers/${RequestedGrowerId}/years/${request.cropYear}/plans`,
			);

			if (response.ErrorCode === null && response.Success)
			{
				const plansResponse: IPlans = {
					Plans: response.Data,
					RequestedGrowerId
				};
				return plansResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue({ error: response.ErrorMessage, growerId: RequestedGrowerId });
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue({ error: e.message, growerId: RequestedGrowerId });
		}
	}
);

export const updatePlanStatus = createTracedAsyncThunk<IPlans, IPlanUpdateRequest, { dispatch: AppDispatch, state: RootState }>(
	'plans/post',
	async (context, request: IPlanUpdateRequest, thunkAPI) => 
	{
		try
		{
			const { auth, ui, crops } = thunkAPI.getState() as RootState;
			const RequestedGrowerId = ui.SelectedGrowerId;

			const api = new Api('/api/4', auth.userAuthToken, context);
			const response = await api.postAsync<IPlanResponse[]>(
				`plans/fieldplan/user/${getCurrentActingUser(thunkAPI.getState()).UserId}/status`,
				request
			);

			if (response.ErrorCode === null && response.Success)
			{
				// Use the getPlans response to return the plan list so that it contains pricing (if the user is GHX)
				const cropYear = ui.SelectedYear ? ui.SelectedYear : crops.CropYear;

				await thunkAPI.dispatch(getPlans({ cropYear: cropYear, growerId: RequestedGrowerId }));

				const plansResponse: IPlans = {
					Plans: response.Data,
					RequestedGrowerId
				};
				return plansResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const launchFieldGamePlan = createTracedAsyncThunk<ILaunchPlan, IPlanLaunchRequest, { dispatch: AppDispatch, state: RootState }>(
	'plans/launch/field',
	async (context, request: IPlanLaunchRequest, thunkAPI) =>
	{
		try
		{
			const { auth, ui, recording } = thunkAPI.getState();
			const RequestedGrowerId = ui.SelectedGrowerId;

			const api = new Api('/api/4', auth.userAuthToken, context);
			const response = await api.postAsync<string>(
				`plans/fieldplans/users/${getCurrentActingUser(thunkAPI.getState()).UserId}/growers/${RequestedGrowerId}`, request);

			if (response.ErrorCode === null && response.Success)
			{
				let launchUrl = response.Data;

				// Inject recording data into URL if recording
				launchUrl += (recording.isRecording ? `&record=true&cardid=${recording.cardId}` : '');

				const launchResponse: ILaunchPlan = {
					LaunchUrl: launchUrl,
					RequestedGrowerId
				};
				return launchResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const launchExistingPlan = createTracedAsyncThunk<string, IPlanLaunchExistingRequest, { dispatch: AppDispatch, state: RootState }>(
	'plans/launchExisting',
	async (context, request: IPlanLaunchExistingRequest, thunkAPI) =>
	{
		try
		{
			const { auth, recording } = thunkAPI.getState();
			const PlanId = request.planId;

			const api = new Api('/api/4', auth.userAuthToken, context);
			const response = await api.postAsync<string>(`plans/user/${getCurrentActingUser(thunkAPI.getState()).UserId}/launch/${PlanId}`, request);

			if (response.ErrorCode === null && response.Success)
			{
				let launchUrl = response.Data;
				
				// Inject recording data into URL for field plan if recording
				const isFieldPlan = launchUrl.indexOf('proposal') > -1;
				launchUrl += (recording.isRecording && isFieldPlan ? `&record=true&cardid=${recording.cardId}` : '');

				if (launchUrl.startsWith('/'))
				{
					request.history.push(launchUrl);
				}
				else
				{
					window.location.href = launchUrl;
				}

				return launchUrl;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const downloadPlanPdf = createTracedAsyncThunk<Blob, IPlanDownloadRequest, { dispatch: AppDispatch, state: RootState }>(
	'plans/download',
	async (context, request: IPlanDownloadRequest, thunkAPI) =>
	{
		try
		{
			const { auth } = thunkAPI.getState();
			const api = new Api('/api/4', auth.userAuthToken, context);

			const PlanId = request.PlanId;
			const currentActingUser = getCurrentActingUser(thunkAPI.getState());
			const pdfSession = await api.postAsync<string>(`plans/${PlanId}/pdf/async`, request.CoverConfig ?? {});

			let pdfData = undefined;
			do 
			{
				// wait half a second for each look
				await sleepOnIt(500);
				pdfData = await api.getFileAsync(`plans/${PlanId}/pdf/${pdfSession.Data}`);
			} while(!pdfData);

			const { name, data } = pdfData;

			if (!data.size)
			{
				return thunkAPI.rejectWithValue('There was an error downloading the PDF data.');
			}

			if (window.navigator.userAgent.toLowerCase().indexOf('crios') > -1)
			{//iOS Chrome
				const typedBlob = new Blob([data], {type: 'application/pdf'});
				const link = (window.URL || window['webkitURL']).createObjectURL(typedBlob);
				window.open(link);
			}
			else
			{
				// Store this file as a hidden 'a' element and then automatically click it.
				const link = document.createElement('a');
				link.href = (window.URL || window['webkitURL']).createObjectURL(data);

				// The renderer always adds ".pdf" to the filename. If there's more than that, use it. Otherwise fall back on the session name.
				link.download = name && name.slice(0, name.indexOf('.pdf'))
					? name : `${currentActingUser.UserName}'s Plan`;

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

interface IDeletePlanRequest
{
	planId: string;
}
export interface IDeleteResponse
{
	planId: string;
	growerId: string;
}

export const deletePlan = createTracedAsyncThunk<IDeleteResponse, IDeletePlanRequest, { dispatch: AppDispatch, state: RootState }>(
	'plans/delete',
	async (context, request: IDeletePlanRequest, thunkAPI) =>
	{
		try
		{
			const { auth, ui } = thunkAPI.getState();
			const { SelectedGrowerId } = ui;
			const api = new Api('/api/4', auth.userAuthToken, context);

			const { planId } = request;
			const currentActingUser = getCurrentActingUser(thunkAPI.getState());
			const response = await api.deleteAsync(`users/${currentActingUser.UserId}/plans/${planId}/delete`);

			if (response.ErrorCode === null && response.Success)
			{
				return { planId, growerId: SelectedGrowerId };
			}
			else
			{
				thunkAPI.rejectWithValue('');
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const downloadMaxscriptPdf = createTracedAsyncThunk<Blob, undefined, { dispatch: AppDispatch, state: RootState }>(
	'plans/maxscript/download',
	async (context, request: undefined, thunkAPI) =>
	{
		try
		{
			const state = thunkAPI.getState() as RootState;
			const { auth, ui, crops } = state;
			const api = new Api('/api/5', auth.userAuthToken, context);

			const selectedYear = ui.SelectedYear ? ui.SelectedYear : crops.CropYear;

			const currentActingUser = getCurrentActingUser(thunkAPI.getState());
			const pdfSession = await api.getAsync<string>(
				`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/orders/growers/${ui.SelectedGrowerId}/year/${selectedYear}/pdf/async`);

			let pdfData = undefined;
			do 
			{
				// wait half a second for each look
				await sleepOnIt(500);
				pdfData = await api.getFileAsync(`orders/pdf/${pdfSession.Data}`);
			} while(!pdfData);

			const { name, data } = pdfData;

			if (!data.size)
			{
				return thunkAPI.rejectWithValue('There was an error downloading the PDF data.');
			}

			if (window.navigator.userAgent.toLowerCase().indexOf('crios') > -1)
			{//iOS Chrome
				const typedBlob = new Blob([data], {type: 'application/pdf'});
				const link = (window.URL || window['webkitURL']).createObjectURL(typedBlob);
				window.open(link);
			}
			else
			{
				// Store this file as a hidden 'a' element and then automatically click it.
				const link = document.createElement('a');
				link.href = (window.URL || window['webkitURL']).createObjectURL(data);

				// The renderer always adds ".pdf" to the filename. If there's more than that, use it. Otherwise fall back on the session name.
				link.download = name && name.slice(0, name.indexOf('.pdf'))
					? name : `${currentActingUser.UserName}'s Plan`;

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);