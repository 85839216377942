import React, { useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { theme as themeEnogen } from './themeEnogen';
import { Routing } from './pages/Routing/Routing';
import { GlobalStyle } from './styles';
import { ThemeName } from './logic/store/UI/UISlice';

const App = () =>
{
	const [currentThemeFile, setCurrentThemeFile] = useState<DefaultTheme>();

	const changeTheme = (name: ThemeName) =>
	{
		switch (name) 
		{
			case ThemeName.Enogen:
			{
				setCurrentThemeFile(themeEnogen);

				// Change the favicon
				const favicon = document.getElementById('favicon');
				favicon.setAttribute('href', '/enogen_favicon.ico');

				// Change the site title
				document.title = 'E-Luminate';

				break;
			}
			default:
			{
				setCurrentThemeFile(theme);

				// Change the favicon
				const favicon = document.getElementById('favicon');
				favicon.setAttribute('href', '/favicon.ico');

				// Change the site title
				document.title = 'GHX Fields';
				
				break;
			}
		}
	};

	return (
		<ThemeProvider theme={currentThemeFile ?? theme}>
			<GlobalStyle />
			<Routing changeTheme={changeTheme} />
		</ThemeProvider>
	);
};

export default App;