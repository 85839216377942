import React, { useCallback } from 'react';
import { StyledContainer } from '../../../components/Table/TableContainer';
import { TableHeader } from '../../../components/Table/TableHeader';
import { StyledRowCell, TableRow } from '../../../components/Table/TableRow';
import { ReactComponent as CirclePlusIcon } from './Icons/CirclePlus.svg';
import { ReactComponent as PlantingIcon } from './Icons/Planting.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/PDFIcon.svg';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import {
	Acres,
	getExpandedSpacing,
	getHeaderSpacing,
	IDashboardTableProps,
	keysToHeaders,
	StyledDashboardScrollableTableRows,
	StyledDashboardTableRows
} from './DashboardRows';
import { NameSegment } from './Segments/NameSegmentComponent';
import { ListChildComponentProps } from 'react-window';
import { ExpandedPlan } from './Segments/ExpandedPlan';
import { BoolDictionary } from '../../../logic/store/UI/UISlice';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { IconButton } from '../../../components/IconButton/IconButton';
import styled from 'styled-components';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { IGrowerUpdateRequest } from '../../../logic/store/Grower/GrowerThunks';

const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
`;

// Execute-specific components
const MaxScript = (props) =>
{
	const { data } = props;
	return <StyledRowCell>
		<CirclePlusIcon style={{ visibility: !data ? 'visible' : 'hidden' }} />
		<PlantingIcon style={{ visibility: data ? 'visible' : 'hidden' }} />
	</StyledRowCell>;
};

interface IMachineDataProps extends IGrowerResponse
{
	SetSelectedGrowerId: (growerId: string) => void;
}
const MachineData = React.memo<IGrowerResponse>(
	function MachineData(props: IMachineDataProps)
	{
		const { Id, SetSelectedGrowerId } = props;
		return (
			<Tooltip title={'Open FileUpload'}>
				<StyledRowCell style={{ margin: 0, height: '100%' }}>
					<Link className='FileUploadLink' to='/dashboard/fileupload' onClick={() =>{SetSelectedGrowerId(Id);}} style={{ display: 'contents', }}>
						<CirclePlusIcon />
					</Link>
				</StyledRowCell>
			</Tooltip>
		);
	}
);

interface IDownloadGrowerCropHealthPdfProps extends IGrowerResponse
{
	downloadGrowerCropHealth: (selectedGrowerId: string) => void;
	isDownloadEnabled: boolean;
	cropYear: number;
}

const DownloadGrowerCropHealthPdf = React.memo<IGrowerResponse>(function DownloadGrowerCropHealthPdf(props: IDownloadGrowerCropHealthPdfProps)
{
	const { Id, SeasonData, cropYear, isDownloadEnabled, downloadGrowerCropHealth } = props;

	const currentSeason = SeasonData.find((season) => season.SeasonYear === cropYear);
	const hasCropHealthDataAvailable: boolean = currentSeason?.HasCropHealthDataAvailable;

	return (
		<Tooltip title={isDownloadEnabled ? hasCropHealthDataAvailable ?
			'Download Crop Health PDF' : 'To view Crop Health, add a Planting Date and Actual Seed Assignments to a Field.'
			: 'Crop Health Pdf Coming Soon!'}>
			<StyledRowCell style={{ margin: 0, height: '100%' }}>
				<StyledIconButton
					onClick={() => downloadGrowerCropHealth(Id)}
					disabled={!isDownloadEnabled || !hasCropHealthDataAvailable}
				>
					<DownloadIcon />
				</StyledIconButton>
			</StyledRowCell>
		</Tooltip>
	);
});

interface ISelectGrowerPDFLanguageProps extends IGrowerResponse
{
	updateGrower: (req: IGrowerUpdateRequest) => void;
}

const SelectGrowerPDFLanguage = React.memo<IGrowerResponse>(function SelectGrowerPDFLanguage(props: ISelectGrowerPDFLanguageProps)
{
	const { Id, LanguagePreference, updateGrower } = props;

	const languageOptions: IOptionItem[] =
	[
		{
			label: 'English',
			value: 'en'
		},
		{
			label: 'French-Canadian',
			value: 'fr-CA'
		}
	];

	const selectLanguage = useCallback((value: string) =>
	{
		updateGrower({ GrowerId: Id, RequestBody: { LanguagePreference: value }});
	},[Id, updateGrower]);

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Dropdown
				variant='outlinednarrow'
				options={languageOptions}
				onSelect={selectLanguage}
				selected={LanguagePreference}
				placeholder='Select a Language'
				className='Language_Dropdown'
				menuStyle={{ maxHeight: 250, overflow: 'auto' }}
			/>
		</div>
	);
});

const columns = [
	'Name / Segment',
	'Accepted Acres / Mapped Acres',
	'MaxScript',
	'Machine Data',
	'Crop Health',
	'PDF Language'
];
const headerSpacing = getHeaderSpacing(columns);
const expandedSpacing = getExpandedSpacing(columns);

interface IExecuteDashboardTableProps extends IDashboardTableProps
{
	downloadGrowerCropHealth: (selectedGrowerId: string) => void;
	isDownloadEnabled: boolean;
	UpdateGrower: (req: IGrowerUpdateRequest) => void;
}

export const ExecuteDashboardTable = (props: IExecuteDashboardTableProps) =>
{
	const {
		cropYear,
		currentCropYear,
		DashboardSort,
		DashboardTableExpanded,
		growers,
		isDownloadEnabled,
		onSort,
		downloadGrowerCropHealth,
		SetExpanded,
		SetSelectedGrowerId,
		UpdateGrower,
	} = props;

	const RenderTableRow = useCallback(({ data, index, style }: ListChildComponentProps<{ rows: IGrowerResponse; ref: React.RefObject<any> }>) =>
	{
		const { rows, ref } = data;
		const grower = rows[index];

		const onExpand = (idEntry: BoolDictionary) =>
		{
			if (ref && ref.current?.resetAfterIndex)
			{
				ref.current?.resetAfterIndex(index, false);
			}
			SetExpanded(idEntry);
		};

		return (
			<div key={`tableRow-${index}`}
				style={{ marginTop: '10px', ...style }}>
				<TableRow
					Id={grower.Id}
					items={[
						<NameSegment key={`execute-cell-name-${grower.Name}-${index}`} {...grower} DashboardTableExpanded={DashboardTableExpanded} />,
						<Acres key={`execute-cell-acres-${grower.Name}-${index}`} {...grower} cropYear={cropYear} />,
						<MaxScript key={`execute-cell-maxscript-${grower.Name}-${index}`} {...grower} />,
						<MachineData key={`execute-cell-machinedata-${grower.Name}-${index}`} {...grower} SetSelectedGrowerId={SetSelectedGrowerId} />,
						<DownloadGrowerCropHealthPdf key={`execute-cell-crophealth-${grower.Name}-${index}`} {...grower}
							downloadGrowerCropHealth={downloadGrowerCropHealth}
							isDownloadEnabled={isDownloadEnabled}
							cropYear={cropYear}
						/>,
						<SelectGrowerPDFLanguage key={`execute-cell-pdflanguage-${grower.Name}=`} {...grower} updateGrower={UpdateGrower} />,
					]}
					expanded={DashboardTableExpanded[grower.Id]}
					columnSpacing={headerSpacing}
					setExpanded={onExpand}
					expandedSection={
						<ExpandedPlan
							Grower={grower}
							currentTab={'Execute'}
							expandedSpacing={expandedSpacing}
							SetSelectedGrowerId={SetSelectedGrowerId}
							cropYear={cropYear}
							currentCropYear={currentCropYear}/>
					}
				/>
			</div>
		);
	}, [DashboardTableExpanded, cropYear, currentCropYear, isDownloadEnabled, SetSelectedGrowerId, downloadGrowerCropHealth]);

	return (
		<StyledContainer>
			<TableHeader headers={columns}
				columnSpacing={headerSpacing}
				initialSorts={{ [keysToHeaders[DashboardSort.field]]: DashboardSort.sortDirection }}
				sortActions={{ 'Name / Segment': (order) => onSort(order, 'Name / Segment') }}
				defaultSort={{ headerName: 'Name / Segment', direction: 'ascending' }} />
			<StyledDashboardScrollableTableRows>
				<StyledDashboardTableRows rows={growers}
					renderComponent={RenderTableRow}
					itemSize={(index) => DashboardTableExpanded[growers[index].Id] ? 265 : 85} />
			</StyledDashboardScrollableTableRows>
		</StyledContainer>
	);
};
