import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { IFieldResponse } from '../../Models/Responses/FieldResponse';
import { getCurrentActingUser } from '../User/AuthSlice';
import { DrawingInteractionMode, MapInteractionMode, setMapInteractionMode, deleteDrawnFeatures } from '../UI/UISlice';
import { getFarms, IEditRequest } from './FarmThunks';
import { Geometry } from '@turf/turf';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export interface IFieldRequest
{
	farmId: string;
	selectedGrowerId: string;
}

export interface IFields
{
	Fields: IFieldResponse[];
	RequestedGrowerId: string;
	RequestedFarmId: string;
}

export const getFields = createTracedAsyncThunk<IFields, IFieldRequest, { dispatch: AppDispatch, state: RootState }>(
	'fields/get',
	async (context, request: IFieldRequest, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.getAsync<IFieldResponse[]>(`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/farms/${request.farmId}/fields`);

			if (response.ErrorCode === null && response.Success)
			{
				const fieldsResponse: IFields = {
					Fields: response.Data,
					RequestedGrowerId: request.selectedGrowerId,
					RequestedFarmId: request.farmId
				};
				return fieldsResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface ISaveFieldRequest
{
	FarmId: string;
	SelectedGrowerId: string;
	Name: string;
	Boundary: Geometry;
	Area: number;
	MapMode: MapInteractionMode;
	DrawingInteractionMode: DrawingInteractionMode;
}

interface IBaseField
{
	RequestedGrowerId: string;
	RequestedFarmId: string;
}

export interface IField extends IBaseField
{
	Field: IFieldResponse;
}

export const saveField = createTracedAsyncThunk<IField, ISaveFieldRequest, { dispatch: AppDispatch, state: RootState }>(
	'field/save',
	async (context, request: ISaveFieldRequest, thunkAPI) => 
	{
		try
		{
			const { SelectedGrowerId, FarmId, MapMode, DrawingInteractionMode } = request;
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.postAsync<IFieldResponse>(
				`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/farms/${request.FarmId}/fields`, request);

			if (response.ErrorCode === null && response.Success)
			{
				const fieldResponse: IField = {
					Field: response.Data,
					RequestedGrowerId: SelectedGrowerId,
					RequestedFarmId: FarmId
				};

				// Tell the map to cleanup the drawn boundaries that were just saved and remove them from the state
				if (MapMode === MapInteractionMode.Selection)
				{
					thunkAPI.dispatch(setMapInteractionMode({ mode: MapMode }));
				}
				else
				{
					thunkAPI.dispatch(setMapInteractionMode({ mode: MapMode, drawingMode: DrawingInteractionMode }));
				}
				thunkAPI.dispatch(deleteDrawnFeatures());
				thunkAPI.dispatch(getFarms({ growerId: SelectedGrowerId }));
				return fieldResponse;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IDeleteFieldRequest
{
	farmId: string;
	fieldId: string;
}

export const deleteField = createTracedAsyncThunk<IField, IDeleteFieldRequest, { dispatch: AppDispatch, state: RootState }>(
	'field/delete',
	async (context, request: IDeleteFieldRequest, thunkAPI) =>
	{
		try
		{
			const { auth, ui } = thunkAPI.getState();
			const { SelectedGrowerId } = ui;
			const api = new Api('/api/4', auth.userAuthToken, context);

			const { farmId, fieldId } = request;
			const currentActingUser = getCurrentActingUser(thunkAPI.getState());
			const response = await api.deleteAsync<IFieldResponse>(`users/${currentActingUser.UserId}/farms/${farmId}/fields/${fieldId}`);

			if (response.ErrorCode === null && response.Success)
			{
				thunkAPI.dispatch(getFarms({ growerId: SelectedGrowerId }));
				return {
					Field: response.Data,
					RequestedFarmId: farmId,
					RequestedGrowerId: SelectedGrowerId
				};
			}
			else
			{
				return thunkAPI.rejectWithValue('');
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IMultiDeleteFieldRequest
{
	farmId: string;
	fieldIds: string[];
}

export interface IFields extends IBaseField
{
	Fields: IFieldResponse[];
}

export const deleteMultipleFields = createTracedAsyncThunk<IFields, IMultiDeleteFieldRequest, { dispatch: AppDispatch, state: RootState }>(
	'fields/delete',
	async (context, request: IMultiDeleteFieldRequest, thunkAPI) =>
	{
		try
		{
			const { auth, ui } = thunkAPI.getState();
			const { SelectedGrowerId } = ui;
			const api = new Api('/api/4', auth.userAuthToken, context);

			const { farmId, fieldIds } = request;
			const currentActingUser = getCurrentActingUser(thunkAPI.getState());
			const response = await api.deleteAsync<IFieldResponse[]>(`users/${currentActingUser.UserId}/farms/${farmId}/fields`, { FieldIds: fieldIds });

			if (response.ErrorCode === null && response.Success)
			{
				thunkAPI.dispatch(getFarms({ growerId: SelectedGrowerId }));
				return {
					Fields: response.Data,
					RequestedFarmId: farmId,
					RequestedGrowerId: SelectedGrowerId
				};
			}
			else
			{
				thunkAPI.rejectWithValue('');
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const renameField = createTracedAsyncThunk<IFieldResponse, IEditRequest, { dispatch: AppDispatch, state: RootState }>(
	'field/rename',
	async (context, request: IEditRequest, thunkAPI) =>
	{
		try
		{
			const { auth } = thunkAPI.getState();
			const api = new Api('/api/4', auth.userAuthToken, context);

			const { Id, EditedName } = request;
			const currentActingUser = getCurrentActingUser(thunkAPI.getState());
			const response = await api.putAsync<IFieldResponse>(`users/${currentActingUser.UserId}/fields/${Id}`, { Name: EditedName });

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IEditFieldRequest
{
	Name?: string;
	Boundary?: IEditFieldBoundary;
	MapMode: MapInteractionMode;
}

export interface IEditFieldBoundary
{
	Boundary: Geometry;
}

/**
 * Update a field's boundary and/or name
 */
export const updateField = createTracedAsyncThunk<IField, IEditFieldRequest, { dispatch: AppDispatch, state: RootState }>(
	'field/update',
	async (context, request: IEditFieldRequest, thunkAPI) => 
	{
		try
		{
			const { MapMode } = request;
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			const state = thunkAPI.getState();
			const editedFieldId = state.ui.SelectedFieldForEdit;
			const growerId = state.ui.SelectedGrowerId;
			const response = await api.putAsync<IFieldResponse>(
				`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/fields/${editedFieldId}`, request);

			if (response.ErrorCode === null && response.Success)
			{
				// Tell the map to cleanup the drawn boundaries that were just saved and remove them from the state
				if (MapMode === MapInteractionMode.Selection)
				{
					thunkAPI.dispatch(setMapInteractionMode({ mode: MapMode }));
				}
				thunkAPI.dispatch(deleteDrawnFeatures());
				thunkAPI.dispatch(getFarms({ growerId: growerId }));
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);