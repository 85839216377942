import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { ICustomTreatmentResponse } from '../../Models/Responses/CustomTreatmentResponse';
import { ICustomTreatmentRequest } from '../../Models/Requests/CustomTreatmentRequest';
import { AppDispatch, RootState } from '../Store';
import { getCurrentActingUser } from './AuthSlice';
import { soyId } from '../Seeds/CropsSlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export const DummyIdPrefix = 'new-treatment-';

export const getCustomTreatments = createTracedAsyncThunk<ICustomTreatmentResponse[], null, { dispatch: AppDispatch, state: RootState }>(
	'get/customtreatments',
	async (context, request: null, thunkAPI) =>
	{
		const state = thunkAPI.getState();

		try
		{
			const api = new Api('/api/4', state.auth.userAuthToken, context);
			const response = await api.getAsync<ICustomTreatmentResponse[]>(`customtreatments/users/${getCurrentActingUser(state).UserId}`);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface ISaveTreatmentsRequest
{
	customTreatments: ICustomTreatmentRequest[];
	selectedDefault: {
		TreatmentName: string;
		CustomTreatmentId?: string;
	};
}
export const saveTreatments = createTracedAsyncThunk<ICustomTreatmentResponse[], ISaveTreatmentsRequest, { dispatch: AppDispatch, state: RootState }>(
	'save/treatments',
	async (context, request, thunkAPI) =>
	{
		const state = thunkAPI.getState();
		const { customTreatments, selectedDefault } = request;

		try
		{
			const api = new Api('/api/4', state.auth.userAuthToken, context);
			const response = await api.postAsync<ICustomTreatmentResponse[]>(`customtreatments/users/${getCurrentActingUser(state).UserId}`, customTreatments);

			if (response.ErrorCode === null && response.Success)
			{
				const data = response.Data;
				// If no custom treatment id, get treatment id from the response
				const customFromResponse = data.find(treatment => treatment.Name === selectedDefault.TreatmentName);
				// If there is no matching custom treatment from the response we must have a standard treatment selected, send a null CustomId
				const customTreatmentId = customFromResponse ? customFromResponse.Id : null;
				await thunkAPI.dispatch(setUserDefaultTreatment({ CropId: soyId, CustomTreatmentId: customTreatmentId, Treatment: selectedDefault.TreatmentName }));
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IUserDefaultTreatmentResponse 
{
	[cropId: string]: {
		UserId: string;
		CropId: string;
		Treatment: string;
		CustomTreatmentId?: string;
	}
}

export const getUserDefaultTreatment = createTracedAsyncThunk<IUserDefaultTreatmentResponse, { cropId: string }, { dispatch: AppDispatch, state: RootState }>(
	'get/userDefaultTreatment',
	async (context, request, thunkAPI) =>
	{
		const state = thunkAPI.getState();

		try
		{
			const api = new Api('/api/4', state.auth.userAuthToken, context);
			const response = await api.getAsync<IUserDefaultTreatmentResponse>(`defaulttreatments/users/${getCurrentActingUser(state).UserId}`);

			if (response.ErrorCode === null && response.Success)
			{
				// get the default treatment for the crop we want
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const setUserDefaultTreatment = createTracedAsyncThunk<boolean, {
	CustomTreatmentId: string;
	Treatment: string;
	CropId: string;
}, { dispatch: AppDispatch, state: RootState }>(
	'set/userDefaultTreatment',
	async (context, request, thunkAPI) =>
	{
		const state = thunkAPI.getState();
		const actingUserId = getCurrentActingUser(state).UserId;

		try
		{
			const api = new Api('/api/4', state.auth.userAuthToken, context);
			const response = await api.postAsync<boolean>(`defaulttreatments/users/${actingUserId}`, 
				{ [request.CropId] : {...request, UserId: actingUserId } });

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);