import React from 'react';
import { IGrowerResponse } from '../../../../logic/Models/Responses/GrowerResponse';
import { GrowerSegment, GrowerSegmentColors, GrowerSegmentKeys} from '../../GrowerFilterModal';
import { StyledNameCell } from '../DashboardRows';
import styled, { useTheme } from 'styled-components';
import _ from 'lodash';
import { themeGet } from '@styled-system/theme-get';
import { IGrowerWithContractDetails } from '../../../../logic/Models/Responses/EnogenContractResponse';

export const StyledSalesforceId = styled.div`
	font-weight: ${themeGet('fontWeights.normal')};
	font-size: ${themeGet('fontSizes.extraSmall')};
`;

// Common components
const NameSegmentComponent = (props: (IGrowerResponse & IGrowerWithContractDetails) & {  DashboardTableExpanded }) =>
{
	const { Name, Classification, GoldenAdvantage, Id, FoundationId, ContractId, NewContractId, DashboardTableExpanded } = props;

	const theme = useTheme();

	const segments = _.cloneDeep(Classification) ?? [];
	if (GoldenAdvantage)
	{
		segments.push(`GA: ${GoldenAdvantage}`);
	}
	const isExpanded = !DashboardTableExpanded[Id];
	const capitalizeFirst = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';
	return <StyledNameCell className='NameSegment'>
		<span style={{ display: 'flex' }}>{Name}</span>
		<StyledSalesforceId >{NewContractId ?? FoundationId}</StyledSalesforceId>
		{
			isExpanded && segments.map((segment, index) => 
			{
				const upperCasedSegment = capitalizeFirst(segment);
				return (
					<div
						key={`${Name}-${upperCasedSegment}-${index}`}
						className={`ClassificationIndicator ${upperCasedSegment}`}
						style={{
							display: 'inline-flex',
							marginRight: '5px',
							width: '60px',
							height: '8px',
							borderRadius: '10px',
							backgroundColor: GrowerSegmentColors(theme)[GrowerSegmentKeys[upperCasedSegment]] || GrowerSegmentColors(theme)[GrowerSegment.Unknown]
						}}
					/>
				);
			})
		}
	</StyledNameCell>;
};

export const NameSegment = React.memo(NameSegmentComponent);