import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { setTreatmentsExpanded, setTreatmentSort } from '../../../logic/store/UI/UISlice';
import { ICustomTreatmentResponse } from '../../../logic/Models/Responses/CustomTreatmentResponse';
import { dynamicSort, makeDispatch, SortDirection } from '../../../logic/Utility/Utils';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { ReactComponent as PlusIcon } from '../../../assets/images/PlusIcon.svg';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { DummyIdPrefix, getCustomTreatments, getUserDefaultTreatment, saveTreatments } from '../../../logic/store/User/CustomTreatmentsActions';
import { ICustomTreatmentRequest } from '../../../logic/Models/Requests/CustomTreatmentRequest';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { soyId } from '../../../logic/store/Seeds/CropsSlice';
import { getTreatmentPricing } from '../../../logic/store/Plans/ProductGamePlanActions';
import { getCurrentActingUser } from '../../../logic/store/User/AuthSlice';
import { CustomTreatmentRow } from './TreatmentRow';
import _ from 'lodash';

const StyledHeaderButton = styled(Button)`
	margin-left: 10px;
	padding-left: 15px;
	padding-right: 15px;
`;

const mapStateToProps = (state: RootState) => ({
	CurrentActingUser: getCurrentActingUser(state),
	UserDefaultTreatments: state.customTreatments.userDefaultTreatments,
	TreatmentSort: state.ui.TreatmentSort,
	isLoadingGetTreatments: state.customTreatments.isLoadingGetTreatments,
	isLoadingGetUserDefaultTreatments: state.customTreatments.isLoadingGetUserDefaultTreatments,
	isLoadingSaveTreatments: state.customTreatments.isLoadingSaveTreatments,
	CustomTreatments: state.customTreatments.treatments,
	Treatments: state.productGamePlan.treatments
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		GetUserDefaultTreatment: makeDispatch(dispatch, getUserDefaultTreatment),
		GetCustomTreatments: makeDispatch(dispatch, getCustomTreatments),
		GetTreatmentPricing: makeDispatch(dispatch, getTreatmentPricing),
		SaveTreatments: makeDispatch(dispatch, saveTreatments),
		SetExpanded: makeDispatch(dispatch, setTreatmentsExpanded),
		SetTreatmentSort: makeDispatch(dispatch, setTreatmentSort),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ISoybeanTreatmentsProps extends PropsFromRedux
{

}

// Custom treatments for soybean CRUD ui
const SoybeanTreatmentsComponent = (props: ISoybeanTreatmentsProps) =>
{
	const {
		CurrentActingUser,
		CustomTreatments,
		isLoadingGetTreatments,
		isLoadingGetUserDefaultTreatments,
		isLoadingSaveTreatments,
		Treatments,
		TreatmentSort,
		UserDefaultTreatments,
		GetCustomTreatments,
		GetTreatmentPricing,
		GetUserDefaultTreatment,
		SaveTreatments,
		SetExpanded,
		SetTreatmentSort,
	} = props;

	const theme = useTheme();

	const [treatments, setTreatments] = useState<ICustomTreatmentResponse[]>([]);
	const [displayResetModal, setDisplayResetModal] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);
	const [newTreatmentCounter, setNewTreatmentCounter] = useState(0);
	const [modifiedTreatments, setModifiedTreatments] = useState<{ [id: string]: ICustomTreatmentRequest }>({});
	const [selectedDefaultTreatment, setSelectedDefaultTreatment] = useState(undefined);

	const addTreatment = () =>
	{
		const Id = [
			DummyIdPrefix,
			newTreatmentCounter
		].join('');
		const newTreatment: ICustomTreatmentResponse = {
			Id,
			IsDeleted: false,
			Name: '',
			Notes: '',
			PricePerBag: undefined,
			IsNew: true,
			ErrorMessage: undefined,
		};
		const newTreatments = [newTreatment, ...treatments];
		setNewTreatmentCounter(newTreatmentCounter + 1);
		setTreatments(newTreatments);
		SetExpanded({ [Id]: true });
	};

	const onSave = () => 
	{
		if (!hasChanges || isLoadingSaveTreatments)
		{
			return;
		}

		const request: ICustomTreatmentRequest[] = Object.values(modifiedTreatments).map(mt =>
		{
			if (mt.Id.startsWith(DummyIdPrefix))
			{
				const requestFormat: ICustomTreatmentRequest =
				{
					Name: mt.Name,
					Notes: mt.Notes,
					PricePerBag: mt.PricePerBag,
					ErrorMessage: undefined,
				};

				return requestFormat;
			}
			else
			{
				const requestFormat: ICustomTreatmentRequest =
				{
					Id: mt.Id,
					IsDeleted: mt.IsDeleted,
					Name: mt.Name,
					Notes: mt.Notes,
					PricePerBag: mt.PricePerBag,
					ErrorMessage: undefined,
				};

				return requestFormat;
			}
		});

		SaveTreatments({
			customTreatments: request,
			selectedDefault: {
				TreatmentName: selectedDefaultTreatment,
				CustomTreatmentId: treatments.find(treatment => treatment.Name === selectedDefaultTreatment)?.Id
			}
		});

	};

	const onReset = () =>
	{
		if (displayResetModal)
		{
			const extendedTreatments: ICustomTreatmentResponse[] = _.cloneDeep(CustomTreatments).map(ct =>
			{
				return ({
					...ct,
					ErrorMessage: undefined,
				});
			});
			setTreatments(extendedTreatments);
			setModifiedTreatments({});
			if (UserDefaultTreatments)
			{
				const soyDefault = UserDefaultTreatments[soyId];
				setSelectedDefaultTreatment(soyDefault?.Treatment);
			}
		}
		setDisplayResetModal(false);
	};

	const onSetUserDefaultTreatment = (treatmentName: string) =>
	{
		setHasChanges(true);
		setSelectedDefaultTreatment(treatmentName);
	};

	const sortTreatments = useCallback((treatmentsToSort = []) =>
	{
		const { field, sortDirection } = TreatmentSort;
		if (sortDirection === 'none')
		{
			setTreatments([...treatmentsToSort]);
		}
		else
		{
			setTreatments(
				[...treatmentsToSort].sort(dynamicSort(field, sortDirection as SortDirection))
			);
		}
	}, [TreatmentSort]);

	useEffect(() =>
	{
		if (!isLoadingGetTreatments)
		{
			GetCustomTreatments();
			GetTreatmentPricing();
		}
		if (!isLoadingGetUserDefaultTreatments)
		{
			GetUserDefaultTreatment({ cropId: soyId });
		}
	}, []);

	useEffect(() =>
	{
		if (UserDefaultTreatments)
		{
			const soyDefault = UserDefaultTreatments[soyId];
			setSelectedDefaultTreatment(soyDefault?.Treatment);
		}
	}, [UserDefaultTreatments]);

	useEffect(() =>
	{
		sortTreatments(treatments);
	}, [TreatmentSort]);

	useEffect(() =>
	{
		if (!CustomTreatments)
		{
			return;
		}
		setModifiedTreatments({});
		
		const extendedTreatments: ICustomTreatmentResponse[] = _.cloneDeep(CustomTreatments).map(ct =>
		{
			return ({
				...ct,
				ErrorMessage: undefined,
			});
		});
		sortTreatments(extendedTreatments);
		setTreatments(extendedTreatments);
	}, [CustomTreatments]);

	useEffect(() =>
	{
		// if treatment changes are gone, reset treatments
		if (!Object.keys(modifiedTreatments).length)
		{
			sortTreatments(treatments);
			setHasChanges(false);
		}
		else
		{
			const newTreatments =
			{
				...treatments.reduce((map, treatment) =>
				{
					map[treatment.Id] = treatment;
					return map;
				}, {} as { [key: string]: ICustomTreatmentResponse }),
				...modifiedTreatments
			};
			if (Object.values(newTreatments).some(nt => nt.ErrorMessage || (!nt.PricePerBag || nt.PricePerBag === 0) || (!nt.Name)))
			{
				setHasChanges(false);
			}
			else
			{
				setHasChanges(true);
			}
			setTreatments(Object.values(newTreatments));
		}
	}, [modifiedTreatments]);

	const onDelete = (index: number) =>
	{
		const treatmentToDelete = treatments[index];

		if (!treatmentToDelete)
		{
			return;
		}

		// Check to see if the name is identical to any existing treatments
		const existingTreatments =
		{
			...treatments.reduce((map, treatment) =>
			{
				map[treatment.Id] = treatment;
				return map;
			}, {} as { [key: string]: ICustomTreatmentResponse }),
			...modifiedTreatments
		};
		
		let duplicateNameFound = false;
		Object.values(existingTreatments).forEach(et =>
		{
			if (et.Id !== treatmentToDelete.Id)
			{
				if ((et.Name.toLowerCase() === treatmentToDelete.Name.toLowerCase()) && !et.IsDeleted)
				{
					duplicateNameFound = true;
				}
			}
		});

		if (duplicateNameFound)
		{
			return;
		}

		if (treatmentToDelete.Id.startsWith(DummyIdPrefix))
		{
			const newModifiedTreatments = { ...modifiedTreatments };
			delete newModifiedTreatments[treatmentToDelete.Id];

			setModifiedTreatments(newModifiedTreatments);

			let newTreatments = [ ...treatments ];
			newTreatments = newTreatments.filter(nt => nt.Id !== treatmentToDelete.Id);

			setTreatments(newTreatments);
		}
		else
		{
			setModifiedTreatments({
				...modifiedTreatments,
				[treatmentToDelete.Id]: {
					...treatmentToDelete,
					IsDeleted: !treatmentToDelete.IsDeleted
				}
			});
		}
		GetUserDefaultTreatment({ cropId: soyId });
	};

	const onNameChange = (index: number, value: string) =>
	{
		const treatmentToUpdate: ICustomTreatmentResponse = treatments[index];
		if (!treatmentToUpdate)
		{
			return;
		}

		treatmentToUpdate.ErrorMessage = undefined;

		// Check to see if the name is identical to any existing treatments
		const existingTreatments =
		{
			...treatments.reduce((map, treatment) =>
			{
				map[treatment.Id] = treatment;
				return map;
			}, {} as { [key: string]: ICustomTreatmentResponse }),
			...modifiedTreatments
		};
		
		Object.values(existingTreatments).forEach(et =>
		{
			if ((et.Name.toLowerCase() === value.toLowerCase()) && !et.IsDeleted)
			{
				treatmentToUpdate.ErrorMessage = 'Name cannot be the same as a existing treatment. Please delete the existing treatment to re-create a new treatment with the same name.';
			}
		});

		setModifiedTreatments({
			...modifiedTreatments,
			[treatmentToUpdate.Id]: {
				...treatmentToUpdate,
				Name: value
			}
		});
	};

	const onPriceChange = (index: number, value: number) =>
	{
		const treatmentToUpdate = treatments[index];
		if (!treatmentToUpdate)
		{
			return;
		}
		setModifiedTreatments({
			...modifiedTreatments,
			[treatmentToUpdate.Id]: {
				...treatmentToUpdate,
				PricePerBag: value
			}
		});
	};

	const onNotesChange = (index: number, value: string) =>
	{
		const treatmentToUpdate = treatments[index];
		if (!treatmentToUpdate)
		{
			return;
		}
		setModifiedTreatments({
			...modifiedTreatments,
			[treatmentToUpdate.Id]: {
				...treatmentToUpdate,
				Notes: value
			}
		});
	};

	return (
		<div
			className='Container'
			style={{
				display: 'flex',
				flexDirection: 'column',
				flex: '1 1 auto',
				marginTop: 15,
				paddingBottom: 20,
				height: '100%'
			}}
		>
			<div className='SoybeanTreatment-Header' style={{ paddingTop: 20, display: 'flex' }}>
				<div style={{ fontSize: `${theme.fontSizes.xLarge}`, fontWeight: theme.fontWeights.bold }}>
					Soybean Treatments for {CurrentActingUser?.UserName ? CurrentActingUser?.UserName : CurrentActingUser?.UserEmail}
				</div>
				<div style={{ display: 'flex', flex: .5, marginLeft: 'auto', minWidth: 330 }}>
					<StyledHeaderButton
						variant='outlined'
						style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
						onClick={() => addTreatment()}
					>
						<PlusIcon fill={theme.colors.darkGrey} style={{ marginRight: 10 }} />
						<span>Add a treatment</span>
					</StyledHeaderButton>
					<StyledHeaderButton
						className='Reset_Button'
						variant='outlined'
						onClick={() => setDisplayResetModal(true)}
						disabled={!hasChanges}
					>Reset</StyledHeaderButton>
					<StyledHeaderButton
						className='Save_Button'
						variant='outlined'
						onClick={onSave}
						disabled={!hasChanges || isLoadingSaveTreatments}
					>Save</StyledHeaderButton>
				</div>
				<StyledModal
					title='Are you sure you want to discard changes?'
					open={displayResetModal}
					onCancel={() => setDisplayResetModal(false)}
				>
					<Button onClick={onReset} variant='outlined' style={{ marginRight: 10 }}>Reset</Button>
					<Button onClick={() => setDisplayResetModal(false)} variant='outlined'>Cancel</Button>
				</StyledModal>
			</div>
			{
				Treatments[soyId] &&
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ fontSize: `${theme.fontSizes.normal}`, fontWeight: theme.fontWeights.bold, }}>
						Default Treatment:
					</div>
					<div className='SoybeanTreatment-DefaultSelect' style={{ paddingLeft: 20 }}>
						<Dropdown
							variant='grey'
							options={
								(Treatments[soyId] as Partial<{ Name: string; }>[]).concat(
									treatments.filter(treatment => !treatment.IsDeleted)
								).map((treatment: Partial<{ Name: string }>) => treatment.Name)}
							placeholder={selectedDefaultTreatment}
							selected={selectedDefaultTreatment}
							onSelect={onSetUserDefaultTreatment}
						/>
					</div>
				</div>
			}
			<div style={{ marginBottom: 10, fontSize: theme.fontSizes.extraSmall }}>
				**Treatment Name and Price Per Bag are required for new entries. Treatment Name cannot be the same as any other non-deleted entries.
			</div>
			{
				treatments && 
				<div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflow: 'hidden' }}>
					<div
						style={{
							backgroundColor: 'black',
							color: 'white',
							fontWeight: theme.fontWeights.bold,
							display: 'flex',
							flexDirection: 'row',
							fontSize: 12,
							borderTopLeftRadius: 6,
							borderTopRightRadius: 6,
							paddingLeft: 10,
							paddingRight: 10,
							paddingTop: 5,
							paddingBottom: 5,
						}}>
						<div style={{ width: '30%', textAlign: 'left' }}>Name</div>
						<div style={{ width: '15%', textAlign: 'left' }}>Price Per Bag</div>
						<div style={{ width: '40%', textAlign: 'left' }}>Date Created</div>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
						{
							treatments.map((t, index) =>
								<CustomTreatmentRow
									treatment={t}
									index={index}
									key={`${t.Id}-${index}`}
									onDelete={onDelete}
									onNameChange={onNameChange}
									onPriceChange={onPriceChange}
									onNotesChange={onNotesChange}
								/>
							)
						}
					</div>
				</div>
			}
			
		</div >
	);
};

export const SoybeanTreatments = connector(SoybeanTreatmentsComponent);