import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { dynamicSort, makeDispatch, roundOff } from '../../../logic/Utility/Utils';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { VrsMap } from '../../../logic/Map/VrsMap/VrsMap';
import { IPlantingPlanSummaryCropPlan, IPlantingPlanSummaryResponse, IPlantingPlanSummary } from '../../../logic/Models/Responses/PlantingPlanSummaryResponse';
import {
	downloadVRSPdf,
	exportFieldPlantingPlan,
	getOrCreatePlantingPlan,
	IExportMultiFieldPlanRequest,
	IPlantingPlanPdfRequest,
	IManagementZoneUpdateRequest,
	IUpdateFieldPlanRequest,
	IZoneRequestData,
	IZoneUpdateRequestData,
	retrieveFieldPlantingPlan,
	updateFieldPlantingPlan,
	updateManagementZones
} from '../../../logic/store/Grower/PlantingPlanThunks';
import { ReactComponent as LeftCaret } from '../Icons/LeftCaret.svg';
import { ReactComponent as Ellipse } from '../../../assets/images/Ellipse.svg';
import { CropPlanOverview } from './CropPlanOverview';
import { FieldListing } from './FieldListing';
import { FieldSeedView } from './FieldSeedView';
import { YieldTableView } from './YieldTableView';
import _ from 'lodash';
import { IHybridItem, IMinMaxSeedingRateValues } from '../../../logic/Models/Responses/PlantingPlanResponse';
import { CropConfig } from '../../../logic/store/Seeds/CropsSlice';
import { ZoneManagement } from './ZoneManagement';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { Button } from '../../../components/Button/Button';
import {
	addZone,
	initializeZoneEditingState,
	resetAllActions,
	saveNewZone,
	SelectionInteractionMode,
	setSelectedZone,
	setVRSMapInteractionMode,
	undoLastAction
} from '../../../logic/store/ManagementZones/ManagementZoneSlice';
import { ZoneColorList } from '../../../logic/Utility/Colors';
import { feature } from '@turf/turf';
import { getPlantingRates, getSeedingRateForSeriesAndRates, IGetPlantingRatesRequest, ISeriesYields } from '../../../logic/store/Seeds/PlantingRateSlice';
import { setSelectedYear } from '../../../logic/store/UI/UISlice';
import { RoundWithPrecision } from '../../../logic/Utility/CalculationUtilities';
import { globalSession } from '../../../tracing/session';

interface IVRSProps extends PropsFromRedux
{
	selectedGrower: IGrowerResponse;
	selectedYear: string;
}

const VRSMainContainer = styled.div`
	min-width: 382px;
	max-width: 382px;
	background-color: ${themeGet('colors.white')};
	display: flex;
	flex: 1 1 auto;
	overflow: hidden;
`;

const VRSInnerContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const TitleContainer = styled.div`
	align-items: center;
	background-color: #363636;
	color: ${themeGet('colors.white')};
	display: flex;
	font-weight: ${themeGet('fontWeights.bold')};
	height: 46px;
	padding-left: 25px;
	padding-right: 25px;
`;

const CrumbContainer = styled.div`
	align-items: center;
	background-color: #F3F4F6;
	display: flex;
	font-weight: ${themeGet('fontWeights.bold')};
	height: 35px;
	padding-left: 25px;
	padding-right: 25px;
`;

enum VRSState
{
	CropPlanOverview, // The List of all CropPlans
	FieldListing, // The List of all fields on a selected CropPlan
	FieldSeedView, // The List of seeds on a selected field
	ZoneManagementView, // The Zone Management page
}

interface IVRSState
{
	view: VRSState.CropPlanOverview | VRSState.FieldListing | VRSState.FieldSeedView | VRSState.ZoneManagementView
}

type IVRSViewMode = IVRSState;

export interface ICropPlan
{
	Area: number;
	Color: string;
	CropPlanId: string;
	CropPlanName: string;
	FieldCount: number;
	FieldListing: IFieldListingItem[];
}
export interface IFieldListingItem
{
	Area: number;
	CropName: string;
	FarmId: string;
	FarmName: string;
	FieldId: string;
	FieldName: string;
	FileName: string;
	ManagementZoneName: string;
}

export interface IFieldPlantingPlanData
{
	AssignedHybrids: string[];
	AveragePopulationBySeed: {[key: string]: number};
	DefaultMaxSeedingRate: number;
	DefaultMinSeedingRate: number;
	EditedTargetYield?: number;
	FieldId: string;
	FieldYieldModifier: number; // TargetYield divided by YieldAverage
	HybridMinMaxSeedingRates: { [key: string]: IMinMaxSeedingRateValues };
	RecommendedHybrids: string[];
	SliderValueBySeed: {[key: string]: number};
	TargetYield: number; // User Entered
	YieldAverage: number;
	ZoneData: IZoneData[];
}

export interface IZoneData
{
	AddedZone: boolean;
	ZoneName: number;
	SoilName: string;
	CropName: string;
	Color: string;
	Acres: number;
	TargetYield: number; // Should be displayed as the FieldYieldModifier * this value
	Nccpi: number;
	SeedRates: {[key: string]: ISeedRateData};
}

export interface ISeedRateData
{
	DisplayedSeedRate?: number; // The final displayed value
	UserEnteredSeedRate?: number; // The seed rate the user entered, if any, adjusted for a 0 point
	CurrentSeedRate: number; // The current value calculated from the yield target of the zone
	OriginalSeedRate: number; // This is the original rate recommended when the planting plan was created
}

const VRSComponent = (props: IVRSProps) =>
{
	const {
		selectedGrower,
		selectedYear,
		CurrentCropYear,
		IsLoadingError,
		IsLoadingGetSummary,
		IsLoadingPlantingPlanFieldData,
		IsLoadingPlantingRates,
		IsLoadingRetrieveSummary,
		IsLoadingSaveManagementZone,
		IsSaveManagementZoneError,
		MapMode,
		PlantingRates,
		SelectedZone,
		UISelectedYear,
		ZoneEditingState,
		ZoneEditingUndoStack,
		AddNewZone,
		DownloadVRSPdf,
		ExportFieldPlantingPlan,
		GetFieldPlantingPlanData,
		GetOrCreatePlantingPlanSummary,
		GetPlantingPlanRates,
		InitializeZoneEditingState,
		ResetAll,
		SaveNewZone,
		SaveZoneManagementState,
		SetSelectedYear,
		SetSelectedZone,
		SetVRSMapInteractionMode,
		UndoLastAction,
		UpdateFieldPlantingPlan,
	} = props;

	const theme = useTheme();

	const [currentPlantingPlanSummary, setCurrentPlantingPlanSummary] = useState<IPlantingPlanSummaryResponse>(undefined);
	const [currentViewState, setCurrentViewState] = useState<IVRSViewMode>({view: VRSState.CropPlanOverview});
	const [selectedCropPlan, setSelectedCropPlan] = useState<IPlantingPlanSummaryCropPlan>(undefined);
	const [cropPlanWithFields, setCropPlanWithFields] = useState<ICropPlan>(undefined);
	const [selectedFieldListing, setSelectedFieldListing] = useState<IFieldListingItem>(undefined);
	const [selectedFieldZoneData, setSelectedFieldZoneData] = useState<IFieldPlantingPlanData>(undefined);
	const [selectedSeed, setSelectedSeed] = useState<string>('');
	const [showCancelZoneManagementModal, setShowCancelZoneManagementModal] = useState<boolean>(false);
	const [newZoneYield, setNewZoneYield] = useState<number>(0);
	const [fieldArrayPosition, setFieldArrayPosition] = useState<string>('');
	const [error, setError] = useState<string>();

	const isOldPlan = (): boolean =>
	{
		const isOldPlan = Number.parseInt(selectedYear ? selectedYear : UISelectedYear) < Number.parseInt(CurrentCropYear);
		return isOldPlan;
	};

	useEffect(() =>
	{
		// Initial setup of all known data from the Redux store
		const year = selectedYear ? selectedYear : UISelectedYear;
		SetSelectedYear(year);
		GetOrCreatePlantingPlanSummary({ Theme: theme, RequestedGrowerId: selectedGrower.Id, Year: year });
		SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Default });
	}, []);

	useEffect(() =>
	{
		if (!IsLoadingGetSummary && !IsLoadingRetrieveSummary && !IsLoadingPlantingPlanFieldData
			&& !IsLoadingError && selectedGrower)
		{
			const planSummary = selectedGrower.PlantingPlanSummary;
			if (!planSummary)
			{
				return;
			}
			setCurrentPlantingPlanSummary({ ...planSummary });
		}
	}, [IsLoadingGetSummary, IsLoadingRetrieveSummary, IsLoadingPlantingPlanFieldData, selectedGrower]);

	/**
	 * Since the pre-set slider values could potentially be null/undefined, create a new dictionary from the recommended hybrids;
	 * otherwise use the pre-set slider values
	 * @param preSetSliderValues The values saved on the plan
	 * @param recommendedHybrids The list of strings for the available hybrid names
	 */
	const initializeSliderValues = (preSetSliderValues: {[key: string]: number}, recommendedHybrids: string[], reset: boolean): {[key: string]: number} =>
	{
		if (reset || !preSetSliderValues)
		{
			const newSliderValues: {[key: string]: number} = {};
			recommendedHybrids.forEach(rh =>
			{
				newSliderValues[rh] = 0;
			});

			return newSliderValues;
		}
		else
		{
			return preSetSliderValues;
		}
	};

	useEffect(() =>
	{
		if (currentPlantingPlanSummary && selectedFieldListing)
		{
			const currentCropPlan = _.cloneDeep(currentPlantingPlanSummary.CropPlans.find(cp => cp.CropPlanId === selectedCropPlan.CropPlanId));
			if (currentCropPlan)
			{
				const fieldPlanData = _.cloneDeep(currentCropPlan.PlantingPlanSummaries.find(ps => ps.FieldId === selectedFieldListing.FieldId).PlantingPlanFieldData);
				
				if (fieldPlanData)
				{
					// Create an object to store the transfer the data needed for the FieldSeedView and the YieldTableView
					const fieldPlantingPlanData: IFieldPlantingPlanData =
					{
						AssignedHybrids: fieldPlanData.AssignedHybrids.map(ah => ah.IsExternal? ah.label : ah.id),
						AveragePopulationBySeed: {},
						DefaultMaxSeedingRate: fieldPlanData.DefaultMaxSeedingRate,
						DefaultMinSeedingRate: fieldPlanData.DefaultMinSeedingRate,
						FieldId: fieldPlanData.FieldId,
						FieldYieldModifier: Math.round(fieldPlanData.TargetYield) / Math.round(fieldPlanData.YieldAverage),
						HybridMinMaxSeedingRates: _.cloneDeep(fieldPlanData.HybridMinMaxSeedingRates),
						RecommendedHybrids: fieldPlanData.RecommendedHybrids.map(ah => ah.IsExternal? ah.label : ah.id),
						SliderValueBySeed: { ...initializeSliderValues(fieldPlanData.SliderSettings, Object.keys(fieldPlanData.RecommendedHybrids), false) },
						TargetYield: fieldPlanData.TargetYield,
						YieldAverage: fieldPlanData.YieldAverage,
						ZoneData: [],
					};

					fieldPlanData.Zones.map(zone =>
					{
						// Get the area
						const zoneFeature = fieldPlanData.PlanData.features.find(f => f.properties.zone === zone.Zone);
						const area = zoneFeature.properties.meta_data.properties.area;
						
						// Setup the seed rate dictionary
						const seedRates: {[key: string]: ISeedRateData} = {};
						Object.keys(zone.SeedingRates).map(sr =>
						{
							const userEditedRatesExist = zone.UserEditedSeedingRates && Object.values(zone.UserEditedSeedingRates).length > 0;
							let userEditedRate : number = undefined;
							if (userEditedRatesExist)
							{
								userEditedRate = zone.UserEditedSeedingRates[sr] ? _.clone(zone.UserEditedSeedingRates[sr]) : undefined;
							}
							seedRates[sr] = 
							{
								CurrentSeedRate: _.clone(zone.SeedingRates[sr]),
								OriginalSeedRate: _.clone(zone.RecommendedSeedingRates[sr]),
								UserEnteredSeedRate: userEditedRate
							};
						});

						const soilTypes: string[] = zoneFeature.properties.meta_data.properties.soil_type;

						fieldPlantingPlanData.ZoneData.push(
							{
								AddedZone: false,
								ZoneName: zone.Zone,
								Acres: area,
								TargetYield: zone.EnvironmentalYield,
								Nccpi: zone.NccpiValue,
								SeedRates: seedRates,
								Color: zone.Color,
								CropName: selectedFieldListing.CropName,
								SoilName: soilTypes && soilTypes.length > 0 ? soilTypes[0] : 'Unknown'
							}
						);
					});

					// Calculate the weighted average of the population(seeding rate) vs the acres of a zone
					const avgPopBySeed: {[key: string]: number} = calculateAveragePopulation(fieldPlantingPlanData);
					fieldPlantingPlanData.AveragePopulationBySeed = {...avgPopBySeed};

					setSelectedFieldZoneData({ ...fieldPlantingPlanData });
				}
			}
		}
	},[currentPlantingPlanSummary, selectedFieldListing]);

	/**
	 * Get a fresh set of recommended seed rates from the Planting Rate Engine.
	 * Only fetches for the currently selected seed.
	 * 
	 * @returns The environment lookup rates for the selected seed.
	 */
	const fetchUpdatedZoneRates = async () =>
	{
		const currentSelectedFieldZoneData = selectedFieldZoneData;

		try 
		{
			const currentCropPlan = _.cloneDeep(currentPlantingPlanSummary.CropPlans.find(cp => cp.CropPlanId === selectedCropPlan.CropPlanId));
			const fieldPlanData = _.cloneDeep(currentCropPlan.PlantingPlanSummaries.find(ps => ps.FieldId === selectedFieldListing.FieldId).PlantingPlanFieldData);
			const assignedHybrid: IHybridItem = fieldPlanData.AssignedHybrids.find(ah => ah.label.toLowerCase() === selectedSeed.toLowerCase());
			
			if (!assignedHybrid.IsExternal)
			{
				setError(undefined);

				// Fetch the data from the engine
				// Make sure we supply the selected seed and the min/max rates the user has entered
				const plantingRatesRequest: IGetPlantingRatesRequest = {
					cropName: selectedFieldListing.CropName,
					seriesNames: [selectedSeed],
					vrs: true,
					minPopulationRate: currentSelectedFieldZoneData.HybridMinMaxSeedingRates[selectedSeed].MinimumSeedingRate,
					maxPopulationRate: currentSelectedFieldZoneData.HybridMinMaxSeedingRates[selectedSeed].MaximumSeedingRate
				};

				const plantingRatesResult = (await GetPlantingPlanRates(plantingRatesRequest));
				const lookup = (plantingRatesResult.payload as {
					[series: string]: {
						[yieldEnvironment: number]: number;
					};
				});

				const lookupSpecificSeed = lookup[selectedSeed];

				// Apply this data to the zones
				currentSelectedFieldZoneData.ZoneData.forEach(zoneData => 
				{
					zoneData.SeedRates[selectedSeed].CurrentSeedRate = lookupSpecificSeed[zoneData.TargetYield];
				});
				
				// Return the data that was applied
				return lookup;
			}
		}
		catch(err)
		{
			globalSession.error(err);
			setError('There was an error fetching rates for this product.');
		}
	};

	const calculateAveragePopulation = (fieldZoneData: IFieldPlantingPlanData): {[key: string]: number} =>
	{
		// Calculate the weighted average of the population(seeding rate) vs the acres of a zone
		const avgPopBySeed: {[key: string]: number} = {};
		let totalAcres = 0;
		fieldZoneData.ZoneData.map(zoneData =>
		{
			totalAcres += zoneData.Acres;
			Object.keys(zoneData.SeedRates).map(seedName => 
			{
				const seedingRate = zoneData.SeedRates[seedName].DisplayedSeedRate ? zoneData.SeedRates[seedName].DisplayedSeedRate : zoneData.SeedRates[seedName].CurrentSeedRate;
				if (avgPopBySeed[seedName])
				{
					avgPopBySeed[seedName] += seedingRate * zoneData.Acres;
				}
				else
				{
					avgPopBySeed[seedName] = seedingRate * zoneData.Acres;
				}
			});
		});

		Object.keys(avgPopBySeed).map(seedKey =>
		{
			avgPopBySeed[seedKey] = RoundWithPrecision(avgPopBySeed[seedKey] / totalAcres, 1, 0);
		});
		
		return avgPopBySeed;
	};

	const selectCropPlan = (cropPlanId: string) =>
	{
		const cropPlan = currentPlantingPlanSummary.CropPlans.find(cp => cp.CropPlanId === cropPlanId);
		if (cropPlan)
		{
			// Set the selected Crop Plan
			setSelectedCropPlan(cropPlan);

			// Grower Fields
			const allGrowerFields = selectedGrower.Farms?.flatMap(fa => fa.Fields);

			// Create the Crop Plan + Field data structure to pass to the FieldListing component
			const cropPlanWithFields: ICropPlan =
			{
				Area: cropPlan.CropPlanArea,
				Color: cropPlan.Color,
				CropPlanId: cropPlan.CropPlanId,
				CropPlanName: cropPlan.CropPlanName,
				FieldCount: cropPlan.CropPlanFieldCount,
				FieldListing: cropPlan.PlantingPlanSummaries.map(ps =>
				{
					const field = allGrowerFields.find(f => f.Id === ps.FieldId);
					const farm = selectedGrower.Farms.find(fa => fa.Id === field.FarmId);
					const fieldItem: IFieldListingItem =
					{
						Area: field.Area,
						CropName: ps.CropId,
						FarmId: field.FarmId,
						FarmName: farm.Name,
						FieldId: field.Id,
						FieldName: field.Name,
						FileName: ps.FileName,
						ManagementZoneName: ps.ManagementZoneName,
					};
					return fieldItem;
				})
			};
			setCropPlanWithFields(cropPlanWithFields);

			// Set the view state
			setCurrentViewState({ view: VRSState.FieldListing });
		}
	};

	const selectSeed = (seedName: string) =>
	{
		setSelectedSeed(seedName);
	};

	const onNextField = () =>
	{
		const fieldIds = cropPlanWithFields.FieldListing.sort(dynamicSort('Name')).map(f => f.FieldId);

		if (fieldIds.length > 0)
		{
			const currentFieldIndex = fieldIds.findIndex(f => f === selectedFieldListing.FieldId);
			if ((currentFieldIndex + 1) < fieldIds.length)
			{
				selectField(fieldIds[currentFieldIndex + 1], selectedCropPlan.CropPlanId);
				setSelectedSeed(undefined);
			}
			else
			{
				selectField(fieldIds[0], selectedCropPlan.CropPlanId);
				setSelectedSeed(undefined);
			}
		}
	};

	const onPreviousField = () =>
	{
		const fieldIds = cropPlanWithFields.FieldListing.sort(dynamicSort('Name')).map(f => f.FieldId);

		if (fieldIds.length > 0)
		{
			const currentFieldIndex = fieldIds.findIndex(f => f === selectedFieldListing.FieldId);
			if ((currentFieldIndex - 1) >= 0)
			{
				selectField(fieldIds[currentFieldIndex - 1], selectedCropPlan.CropPlanId);
				setSelectedSeed(undefined);
			}
			else
			{
				selectField(fieldIds[fieldIds.length - 1], selectedCropPlan.CropPlanId);
				setSelectedSeed(undefined);
			}
		}
	};

	const selectField = (fieldId: string, cropPlanId: string) =>
	{
		const currentPlan: IPlantingPlanSummaryCropPlan = currentPlantingPlanSummary.CropPlans.find(cp => cp.CropPlanId === cropPlanId);
		const fieldSummary: IPlantingPlanSummary = currentPlan.PlantingPlanSummaries.find(ps => ps.FieldId === fieldId);
		GetFieldPlantingPlanData(
			{
				RequestedGrowerId: selectedGrower.Id,
				FieldId: fieldId,
				CropName: fieldSummary.CropId,
				SelectedYear: selectedYear ? selectedYear : UISelectedYear,
			}
		);

		// Get the selected field data
		const selectedField: IFieldListingItem = cropPlanWithFields.FieldListing.find(fl => fl.FieldId === fieldId);
		setSelectedFieldListing(selectedField);
		const fieldIds = cropPlanWithFields.FieldListing.sort(dynamicSort('Name')).map(f => f.FieldId);
		const currentFieldIndex = fieldIds.findIndex(f => f === fieldId);
		setFieldArrayPosition(`Field ${currentFieldIndex + 1} out of ${fieldIds.length}`);
		// Set the view to the Field Seed View
		setCurrentViewState({ view: VRSState.FieldSeedView });
	};

	const selectManagementZone = (zoneName: number) =>
	{
		if (MapMode.mode === SelectionInteractionMode.Painting)
		{
			SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Painting, zone: zoneName });
		}
		else if (MapMode.mode === SelectionInteractionMode.AddingZone)
		{
			SetSelectedZone(undefined);
		}
		else
		{
			SetSelectedZone(zoneName);
		}		
	};

	const selectedZone = (): number =>
	{
		if (MapMode.mode === SelectionInteractionMode.Painting)
		{
			return MapMode.zone;
		}
		else
		{
			return SelectedZone;
		}
	};

	const addNewZone = useCallback(() =>
	{
		const lastZoneId = _.max(ZoneEditingState.zoneIds) ?? 0;
		const previousZone = ZoneEditingState.zones[lastZoneId];
		const nextZoneId = lastZoneId + 1;

		const newZoneData: IZoneData = (
			{
				AddedZone: true, // triggers the card to show the yield entry area + save and cancel buttons
				ZoneName: nextZoneId,
				Acres: 0,
				TargetYield: 0,
				Nccpi: previousZone.Nccpi,
				SeedRates: previousZone.SeedRates,
				Color: ZoneColorList[nextZoneId % ZoneColorList.length],
				CropName: previousZone.CropName,
				SoilName: 'Unknown'
			}
		);

		// Set the map mode to adding a zone so that drawing tools can be disabled until the user completes their zone
		SetVRSMapInteractionMode({ mode: SelectionInteractionMode.AddingZone });

		AddNewZone(newZoneData);
	},[ZoneEditingState]);

	const cancelNewZone = () =>
	{
		// Undo will remove the added zone
		undoLastMapAction();

		// Clear the yield
		setNewZoneYield(0);

		// Set the map mode back to the selection state
		SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Selection });
	};

	const saveNewZone = () =>
	{
		// Save the new Zone with it's yield
		const newZoneId = _.max(ZoneEditingState.zoneIds) ?? 0;

		const newZoneData = ZoneEditingState.zones[newZoneId];

		// New yield is the Target Yield, we need to calculate the predictive yield from this value
		// Since Predictive Yield * Yield Modifier = Target Yield
		// Then Predictive Yield = Target Yield / Yield Modifier
		const modifier = Math.round(selectedFieldZoneData.EditedTargetYield ?? (selectedFieldZoneData.YieldAverage))
			/ Math.round(selectedFieldZoneData.YieldAverage);

		const predictiveYield = newZoneYield / modifier;

		const newSeedRates: { [key: string]: ISeedRateData } = _.cloneDeep(newZoneData.SeedRates);
		// Now based on the entered Target Zone, calculate the rates from the planting rates
		Object.keys(newSeedRates).map(seedName =>
		{
			// Look up what the planting rate should be based on the target yield and our planting rate
			const plantingRateSuggestedRate = getSeedingRateForSeriesAndRates(PlantingRates, seedName, newZoneYield) ?? newSeedRates[seedName].CurrentSeedRate;
			newSeedRates[seedName].CurrentSeedRate = plantingRateSuggestedRate;
		});

		SaveNewZone({ zoneName: newZoneId, yield: predictiveYield, seedingRates: newSeedRates});

		// Put the map back into the selection state
		SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Selection });

		// Clear the yield in the state
		setNewZoneYield(0);
	};

	const updateNewZoneYield = (value: number) =>
	{
		const currentSelectedFieldZoneData = selectedFieldZoneData;
		const currentCropName = selectedCropPlan.PlantingPlanSummaries.find(pps => pps.FieldId === currentSelectedFieldZoneData.FieldId).CropId;

		// Make sure the yield value is within the max/min yield for the crop
		const cropConfig = CropConfig();
		const cropSpecificConfig = Object.values(cropConfig).find(cc => cc.cropName.toLowerCase() === currentCropName.toLowerCase());

		if (value > cropSpecificConfig.maxYield)
		{
			value = cropSpecificConfig.maxYield;
		}
		if (value < cropSpecificConfig.minYield)
		{
			value = cropSpecificConfig.minYield;
		}

		setNewZoneYield(value);
	};

	// For the VRSMap component un-do button
	const undoLastMapAction = () =>
	{
		// Undo will remove the added zone
		UndoLastAction();
	};

	// For the VRSMap component reset button
	const resetAllActions = () =>
	{
		// Reset all map actions
		ResetAll();
	};

	const isNewZoneButtonDisabled = (): boolean =>
	{
		const isAddingZone = MapMode.mode === SelectionInteractionMode.AddingZone;
		return (isAddingZone || isOldPlan());
	};

	const onSaveZoneManagement = () =>
	{
		const currentZoneEditingState = ZoneEditingState;

		const summary = selectedCropPlan.PlantingPlanSummaries.find(ps => ps.FieldId === selectedFieldListing.FieldId);
		const zoneUpdates = currentZoneEditingState.editedFeatures.features.map(zoneFeature =>
		{
			const seedingRates: {[key: string]: number} = {};
			Object.keys(zoneFeature.properties.SeedRates).map(seedName =>
			{
				const seedRateItem = zoneFeature.properties.SeedRates[seedName];
				seedingRates[seedName] = seedRateItem.DisplayedSeedRate ? seedRateItem.DisplayedSeedRate : seedRateItem.CurrentSeedRate;
			});

			const rebuiltFeature = feature(zoneFeature.geometry);

			const zoneRequest: IZoneUpdateRequestData =
			{
				Zone: zoneFeature.properties.ZoneName,
				SeedingRates: seedingRates,
				Color: zoneFeature.properties.Color,
				Area: zoneFeature.properties.Acres,
				Boundary: JSON.stringify(rebuiltFeature),
				ZoneYield: zoneFeature.properties.TargetYield
			};

			return zoneRequest;
		});

		const saveManagementZoneRequest: IManagementZoneUpdateRequest =
		{
			FieldId: selectedFieldListing.FieldId,
			RequestedGrowerId: selectedGrower.Id,
			Year: selectedYear ? selectedYear : UISelectedYear,
			Zones: zoneUpdates
		};

		// Save things
		SaveZoneManagementState(saveManagementZoneRequest);
	};

	/**
	 * Monitor the SaveZoneManagementState and return the user to the field seed view on a success,
	 * otherwise leave the user on the Zone Management screen so they can attempt to save again
	 */
	useEffect(() =>
	{
		const mapModeIsNotDefault = MapMode.mode !== SelectionInteractionMode.Default;
		const viewIsZoneManagement = currentViewState.view === VRSState.ZoneManagementView;
		if (mapModeIsNotDefault && viewIsZoneManagement && !IsSaveManagementZoneError
			&& !IsLoadingSaveManagementZone)
		{
			SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Default });
			setCurrentViewState({ view: VRSState.FieldSeedView });
		}
	}, [IsLoadingSaveManagementZone]);

	const isSaveZoneManagementButtonDisabled = (): boolean =>
	{
		const noZoneEdits = ZoneEditingUndoStack?.length === 0;
		const isAddingZone = MapMode.mode === SelectionInteractionMode.AddingZone;
		return (noZoneEdits || isAddingZone || isOldPlan());
	};

	// Determine what view state to display
	const determineView = () =>
	{
		switch(currentViewState.view)
		{
			case VRSState.CropPlanOverview :
				return (
					<CropPlanOverview cropPlans={currentPlantingPlanSummary.CropPlans} selectCropPlan={selectCropPlan} />
				);
			case VRSState.FieldListing :
				return (
					<FieldListing cropPlan={cropPlanWithFields} selectField={selectField} />
				);
			case VRSState.FieldSeedView :
				return (
					<FieldSeedView
						fieldInfoNumber = {fieldArrayPosition}
						fieldInfo={selectedFieldListing}
						fieldPlantingPlanData={selectedFieldZoneData}
						onClickSeed={selectSeed} selectedSeed={selectedSeed}
						onPrevious = {onPreviousField}
						onNext = {onNextField}
					/>
				);
			case VRSState.ZoneManagementView :
				return (
					<ZoneManagement
						addNewZone={addNewZone}
						addNewZoneCancel={cancelNewZone}
						addNewZoneSave={saveNewZone}
						newZoneYield={newZoneYield}
						updateNewZoneYield={updateNewZoneYield}
						cancelZoneManagement={backToFieldView}
						saveZoneManagement={onSaveZoneManagement}
						saveZoneManagementDisabled={isSaveZoneManagementButtonDisabled()}
						zoneEditingData={ZoneEditingState}
						selectedZone={selectedZone()}
						selectZone={selectManagementZone}
						disableAddNewZoneButton={isNewZoneButtonDisabled()}
						isOldPlan={isOldPlan()}
					/>
				);
		}
	};

	/**
	 * Erase any values the user has entered and reset to the calculated ones
	 */
	const clearUserEnteredValues = (dataToUpdate: IFieldPlantingPlanData) => 
	{
		const currentSelectedFieldZoneData = dataToUpdate;
		currentSelectedFieldZoneData.ZoneData.forEach(zoneData =>
		{
			const zoneRate = zoneData.SeedRates[selectedSeed];
			zoneRate.UserEnteredSeedRate = undefined;
		});

		setSelectedFieldZoneData({...currentSelectedFieldZoneData});
	};

	/**
	 * Recalculate all values from the original underlying data.
	 * 
	 * Optionally takes a seed rate table if a new one should be applied
	 */
	const calculateDisplayValues = useCallback((seedRates?: ISeriesYields) => 
	{
		const currentSelectedFieldZoneData = _.cloneDeep(selectedFieldZoneData);

		// The Field Year modifier is a ratio of the entered 'Target Yield' to the predicted 'Target Yield'
		// We round these values for the field modifier because we're looking at what the user entered relative to what they see (which is rounded)
		currentSelectedFieldZoneData.FieldYieldModifier = Math.round(currentSelectedFieldZoneData.EditedTargetYield ?? (currentSelectedFieldZoneData.YieldAverage))
			/ Math.round(currentSelectedFieldZoneData.YieldAverage);

		// Apply data to each zone
		currentSelectedFieldZoneData.ZoneData.forEach(zoneData =>
		{
			// and to each seed
			Object.keys(zoneData.SeedRates).forEach(seedName =>
			{
				const currentCropPlan = _.cloneDeep(currentPlantingPlanSummary.CropPlans.find(cp => cp.CropPlanId === selectedCropPlan.CropPlanId));
				const fieldPlanData = _.cloneDeep(currentCropPlan.PlantingPlanSummaries.find(ps => ps.FieldId === selectedFieldListing.FieldId).PlantingPlanFieldData);
				const assignedHybrid: IHybridItem = fieldPlanData.AssignedHybrids.find(ah => ah.label.toLowerCase() === seedName.toLowerCase());

				// Get the user entered min and max rates to fit
				const maxRate = Math.round(currentSelectedFieldZoneData.HybridMinMaxSeedingRates[seedName].MaximumSeedingRate);
				const minRate = Math.round(currentSelectedFieldZoneData.HybridMinMaxSeedingRates[seedName].MinimumSeedingRate);

				const zoneRate = zoneData.SeedRates[seedName];

				// Apply any adjustment ratio to the native target yield
				const adjustedZoneTargetYield = roundOff(zoneData.TargetYield * currentSelectedFieldZoneData.FieldYieldModifier, 2);
				// Look up what the planting rate should be based on the target yield and our planting rate
				const plantingRates = seedRates ?? PlantingRates;
				let plantingRateSuggestedRate = getSeedingRateForSeriesAndRates(plantingRates, seedName, adjustedZoneTargetYield) ?? minRate;
				plantingRateSuggestedRate = assignedHybrid.IsExternal ? 
					(plantingRateSuggestedRate === minRate || plantingRateSuggestedRate === maxRate) ? 
						zoneData.SeedRates[seedName].CurrentSeedRate : plantingRateSuggestedRate : plantingRateSuggestedRate;
				if (!plantingRateSuggestedRate)
				{
					return;
				}

				// Certain events can trigger whether or not to update the rates
				const shouldUpdateRates = (): boolean =>
				{
					let doUpdate = true;

					// Yield has not been updated
					if (currentSelectedFieldZoneData.FieldYieldModifier === 1)
					{
						doUpdate = false;
					}

					// Max has been updated
					if (maxRate !== currentSelectedFieldZoneData.DefaultMaxSeedingRate)
					{
						doUpdate = true;
					}

					// Min has been updated
					if (minRate !== currentSelectedFieldZoneData.DefaultMinSeedingRate)
					{
						doUpdate = true;
					}

					return doUpdate;
				};

				const updateRates = shouldUpdateRates();

				zoneRate.CurrentSeedRate = updateRates ? plantingRateSuggestedRate : zoneRate.OriginalSeedRate;

				// Now given the recommended seed rate, apply any slider adjustment the user wants (percentage increase)
				const sliderValue = currentSelectedFieldZoneData.SliderValueBySeed[seedName];
				// Take the user entered rate if there is one, otherwise use the calculated value
				const baseRate = zoneRate.UserEnteredSeedRate ?? zoneRate.CurrentSeedRate; 
				let plantingRateFinalRate = Math.round(baseRate + (baseRate * (sliderValue / 100)));

				// Cap the final rate at the user entered ranges
				if (plantingRateFinalRate > maxRate)
				{
					plantingRateFinalRate = maxRate;
				}
				if (plantingRateFinalRate < minRate)
				{
					plantingRateFinalRate = minRate;
				}

				zoneRate.DisplayedSeedRate = plantingRateFinalRate;
			});
		});

		// Derive the average pop from our final capped planting rates.
		const recalculatedAverageRate = calculateAveragePopulation(currentSelectedFieldZoneData);
		currentSelectedFieldZoneData.AveragePopulationBySeed = recalculatedAverageRate;
		setSelectedFieldZoneData({...currentSelectedFieldZoneData});

	}, [selectedFieldZoneData, PlantingRates]);

	const editTargetYield = async (value: number) =>
	{
		const currentSelectedFieldZoneData = selectedFieldZoneData;

		// No updates if it has not changed
		if (currentSelectedFieldZoneData.EditedTargetYield? value === Math.round(currentSelectedFieldZoneData.EditedTargetYield) 
			: value === Math.round(currentSelectedFieldZoneData.TargetYield))
		{
			return;
		}

		if (value < 0)
		{
			value = Math.abs(value);
		}

		const currentCropName = selectedCropPlan.PlantingPlanSummaries.find(pps => pps.FieldId === currentSelectedFieldZoneData.FieldId).CropId;
		currentSelectedFieldZoneData.SliderValueBySeed[selectedSeed] = 0;

		// Make sure the yield value is within the max/min yield for the crop
		const cropConfig = CropConfig;
		const cropSpecificConfig = Object.values(cropConfig).find(cc => cc.cropName.toLowerCase() === currentCropName.toLowerCase());

		if (value > cropSpecificConfig.maxYield)
		{
			value = cropSpecificConfig.maxYield;
		}
		if (value < cropSpecificConfig.minYield)
		{
			value = cropSpecificConfig.minYield;
		}

		currentSelectedFieldZoneData.EditedTargetYield = value;
		clearUserEnteredValues(currentSelectedFieldZoneData);
		calculateDisplayValues();
	};

	const editSeedingRate = (value: number, zoneName: number, seedName: string) =>
	{
		const currentSelectedFieldZoneData = selectedFieldZoneData;
		const selectedZone = currentSelectedFieldZoneData.ZoneData.find(z => z.ZoneName === zoneName);
		const sliderValue = currentSelectedFieldZoneData.SliderValueBySeed[seedName];
		// Normalize the entered rate for 0 on the track
		selectedZone.SeedRates[seedName].UserEnteredSeedRate = Math.round(value / (1 + (sliderValue/100)));
		calculateDisplayValues();
	};

	const resetYieldTarget = async (seedName: string) =>
	{
		const currentSelectedFieldZoneData = selectedFieldZoneData;
		currentSelectedFieldZoneData.TargetYield = currentSelectedFieldZoneData.YieldAverage;
		currentSelectedFieldZoneData.EditedTargetYield = undefined;

		// Reset the max/min values for the selected Hybrid to the default values
		currentSelectedFieldZoneData.HybridMinMaxSeedingRates[seedName].MaximumSeedingRate = currentSelectedFieldZoneData.DefaultMaxSeedingRate;
		currentSelectedFieldZoneData.HybridMinMaxSeedingRates[seedName].MinimumSeedingRate = currentSelectedFieldZoneData.DefaultMinSeedingRate;

		// Reset the slider values
		currentSelectedFieldZoneData.SliderValueBySeed[seedName] = 0;

		clearUserEnteredValues(currentSelectedFieldZoneData);
		calculateDisplayValues();
	};

	const onChangeSlider = (sliderValue: number, seedName: string) =>
	{
		const currentSelectedFieldZoneData = selectedFieldZoneData;
		
		currentSelectedFieldZoneData.SliderValueBySeed[seedName] = sliderValue; // Update the slider value
		
		calculateDisplayValues();
	};

	const backToCropPlanOverview = () =>
	{
		setCurrentViewState({ view: VRSState.CropPlanOverview });
		setCropPlanWithFields(undefined);
		setSelectedCropPlan(undefined);
	};

	const backToFieldListing = () =>
	{
		setCurrentViewState({ view: VRSState.FieldListing });
		setSelectedFieldListing(undefined);
		setSelectedSeed('');
	};

	const backToFieldView = () =>
	{
		if (ZoneEditingUndoStack?.length > 0)
		{
			// Show the Modal to give the user a choice between cancelling Zone Management or Continuing
			setShowCancelZoneManagementModal(true);
		}
		else
		{
			// No current changes so just send the user back to the field seed view
			SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Default });
			setCurrentViewState({ view: VRSState.FieldSeedView });
		}
	};

	const onCancelZoneManagement = () =>
	{
		// The user chose to cancel so return to the Field Seed View
		// TODO: clear anything with the editing state here?
		setShowCancelZoneManagementModal(false);
		SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Default });
		setCurrentViewState({ view: VRSState.FieldSeedView });
	};

	const onCancelTableView = () =>
	{
		setSelectedSeed('');
	};

	const onSavePlantingPlan = () =>
	{
		const summary = selectedCropPlan.PlantingPlanSummaries.find(ps => ps.FieldId === selectedFieldListing.FieldId);
		const zones = selectedFieldZoneData.ZoneData.map(zone =>
		{
			const seedingRates: {[key: string]: number} = {};
			const userEditedSeedingRates: {[key: string]: number} = {};
			Object.keys(zone.SeedRates).map(seedName =>
			{
				const seedRateItem = zone.SeedRates[seedName];
				seedingRates[seedName] = seedRateItem.DisplayedSeedRate ? seedRateItem.DisplayedSeedRate : seedRateItem.CurrentSeedRate;
				userEditedSeedingRates[seedName] = seedRateItem.UserEnteredSeedRate ? seedRateItem.UserEnteredSeedRate : null;
			});

			const zoneRequest: IZoneRequestData =
			{
				Zone: zone.ZoneName,
				SeedingRates: seedingRates,
				UserEditedSeedingRates: userEditedSeedingRates,
			};

			return zoneRequest;
		});

		const updateRequest: IUpdateFieldPlanRequest =
		{
			AverageRatesPerSeed: selectedFieldZoneData.AveragePopulationBySeed,
			FieldId: selectedFieldListing.FieldId,
			FileName: summary.FileName,
			HybridMinMaxSeedingRates: selectedFieldZoneData.HybridMinMaxSeedingRates,
			ManagementZoneName: summary.ManagementZoneName,
			RequestedGrowerId: selectedGrower.Id,
			SliderSettings: selectedFieldZoneData.SliderValueBySeed,
			TargetYield: selectedFieldZoneData.EditedTargetYield ? selectedFieldZoneData.EditedTargetYield : selectedFieldZoneData.TargetYield,
			Year: selectedYear ? selectedYear : UISelectedYear,
			Zones: zones
		};

		UpdateFieldPlantingPlan(updateRequest);
	};

	const onUpdateMaximumSeedingRate = async (value: number, seedName: string) =>
	{
		const currentSelectedFieldZoneData = selectedFieldZoneData;
		const currentMaxRate = currentSelectedFieldZoneData.HybridMinMaxSeedingRates[seedName].MaximumSeedingRate;

		if (value < 0)
		{
			value = Math.abs(value);
		}

		// Do nothing if the value has not changed
		if (value === currentMaxRate)
		{
			return;
		}
		currentSelectedFieldZoneData.HybridMinMaxSeedingRates[seedName].MaximumSeedingRate = value;

		if(selectedFieldListing.CropName.toLowerCase() === 'soybean')
		{
			clearUserEnteredValues(currentSelectedFieldZoneData);
			calculateDisplayValues(await fetchUpdatedZoneRates());
		}
		else
		{
			calculateDisplayValues();
		}
	};

	const onUpdateMinimumSeedingRate = async (value: number, seedName: string) =>
	{
		const currentSelectedFieldZoneData = selectedFieldZoneData;
		const currentMinRate = currentSelectedFieldZoneData.HybridMinMaxSeedingRates[seedName].MinimumSeedingRate;

		if (value < 0)
		{
			value = Math.abs(value);
		}

		// Do nothing if the value has not changed
		if (value === currentMinRate)
		{
			return;
		}
		currentSelectedFieldZoneData.HybridMinMaxSeedingRates[seedName].MinimumSeedingRate = value;

		if(selectedFieldListing.CropName.toLowerCase() === 'soybean')
		{
			clearUserEnteredValues(currentSelectedFieldZoneData);
			calculateDisplayValues(await fetchUpdatedZoneRates());
		}
		else
		{
			calculateDisplayValues();
		}
	};	

	const onExportFieldPlantingPlan = (format: string, monitor: string) =>
	{
		if (currentViewState.view === VRSState.FieldSeedView)
		{
			// Single field
			const request: IExportMultiFieldPlanRequest =
			{
				FieldIds: [selectedFieldListing.FieldId],
				Format: format,
				Monitor: monitor,
				SelectedYear: selectedYear ? selectedYear : UISelectedYear
			};

			ExportFieldPlantingPlan(request);
		}
		else if (currentViewState.view === VRSState.FieldListing)
		{
			// Single Crop Plan, All Fields
			const cropPlanFieldIds = cropPlanWithFields.FieldListing.map(f => f.FieldId);
			const request: IExportMultiFieldPlanRequest =
			{
				FieldIds: cropPlanFieldIds,
				Format: format,
				Monitor: monitor,
				SelectedYear: selectedYear ? selectedYear : UISelectedYear
			};

			ExportFieldPlantingPlan(request);
		}
		else if (currentViewState.view === VRSState.CropPlanOverview)
		{
			// All Crop Plans, All Fields
			const allCropPlanFields = currentPlantingPlanSummary.CropPlans.flatMap(cp => cp.PlantingPlanSummaries.map(summary => summary.FieldId));
			const request: IExportMultiFieldPlanRequest =
			{
				FieldIds: allCropPlanFields,
				Format: format,
				Monitor: monitor,
				SelectedYear: selectedYear ? selectedYear : UISelectedYear
			};

			ExportFieldPlantingPlan(request);
		}
	};

	const openZoneManagement = () =>
	{
		if (currentPlantingPlanSummary && selectedFieldListing)
		{
			const currentCropPlan = currentPlantingPlanSummary.CropPlans.find(cp => cp.CropPlanId === selectedCropPlan.CropPlanId);
			if (currentCropPlan)
			{
				const fieldPlanData = currentCropPlan.PlantingPlanSummaries.find(ps => ps.FieldId === selectedFieldListing.FieldId).PlantingPlanFieldData;
				if (fieldPlanData)
				{
					// Setup the editing state
					InitializeZoneEditingState({ zoneData: _.cloneDeep(selectedFieldZoneData.ZoneData), geometry: _.cloneDeep(fieldPlanData.PlanData) });
					SetVRSMapInteractionMode({ mode: SelectionInteractionMode.Selection });
					setCurrentViewState({ view: VRSState.ZoneManagementView });
					setSelectedSeed(''); // wipe out the selected seed in case the user had the yield table open
				}
			}
		}
	};

	const onDownloadPdf = useCallback(() =>
	{
		// Create the request
		const request: IPlantingPlanPdfRequest = 
		{
			GrowerId: selectedGrower.Id,
			SelectedYear: selectedYear ? selectedYear : UISelectedYear,
			CropPlans: [] // In this case we're just requesting all crop plans/fields be printed
		};

		// If there is a selected field then print that field
		if (selectedFieldListing && selectedFieldZoneData)
		{
			request.CropPlans = [{
				CropPlanId: selectedCropPlan.CropPlanId,
				Fields: [{
					FieldId: selectedFieldListing.FieldId,
					ManagementZoneName: selectedFieldListing.ManagementZoneName,
					FileName: selectedFieldListing.FileName,
					CropName: selectedFieldListing.CropName,
					Hybrids: Object.keys(selectedFieldZoneData.AveragePopulationBySeed).map(seedName =>
					{
						return {
							Hybrid: seedName,
							Color: '', // TODO: refactor colors logic out of FieldSeedView.tsx, though the backend doesn't actually need this at this time
							AverageRate: Math.round(selectedFieldZoneData.AveragePopulationBySeed[seedName])
						};
					})
				}]}
			];
		}
		// If there is no selected field, just send the crop plan so that the PDF will print with all fields for this crop plan
		else if (selectedCropPlan)
		{
			request.CropPlans = [{
				CropPlanId: selectedCropPlan.CropPlanId,
				Fields: []
			}];
		}

		DownloadVRSPdf(request);

	}, [selectedCropPlan, selectedFieldListing, selectedFieldZoneData, selectedYear, UISelectedYear]);

	return (
		<div style={{ width: 'calc(100% - 70px)', height: '100%', display: 'flex', marginLeft: 70 }}>
			<VRSMainContainer className='VRS_MainContainer'>
				{
					!!currentPlantingPlanSummary &&
					<VRSInnerContainer className='VRS_InnerContainer'>
						<TitleContainer className='VRS_TitleContainer'>
							{currentPlantingPlanSummary.GamePlanName}
						</TitleContainer>
						{/* TODO: Likely pull this into a function or its own component so that it can switch what it displays based on the state */}
						<CrumbContainer className='VRS_CrumbtrailContainer'>
							<Link to='/fieldactivities/recall' className='linkTo_PlanList'>
								<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
								<span style={{ color: theme.colors.darkGrey, fontFamily: theme.fonts.heading }}>{selectedGrower.Name}</span>
							</Link>
							{
								currentViewState.view === VRSState.FieldListing &&
									<div>
										<Ellipse style={{ margin: '2px 10px' }}/>
										<span
											style={{ fontWeight: theme.fontWeights.bold, fontFamily: theme.fonts.heading, width: '60%', cursor: 'pointer' }}
											onClick={backToCropPlanOverview}
										>
											Crop Plans
										</span>
									</div>
							}
							{
								currentViewState.view === VRSState.FieldSeedView &&
									<div>
										<Ellipse style={{ margin: '2px 10px' }}/>
										<span
											style={{ fontWeight: theme.fontWeights.bold, fontFamily: theme.fonts.heading, width: '60%', cursor: 'pointer' }}
											onClick={backToFieldListing}
										>
											{cropPlanWithFields.CropPlanName}
										</span>
									</div>
							}
							{
								currentViewState.view === VRSState.ZoneManagementView &&
									<div>
										<Ellipse style={{ margin: '2px 10px' }}/>
										<span
											style={{ fontWeight: theme.fontWeights.bold, fontFamily: theme.fonts.heading, width: '60%', cursor: 'pointer' }}
											onClick={backToFieldView}
										>
											{selectedFieldListing.FieldName}
										</span>
									</div>
							}
						</CrumbContainer>
						{determineView()}
					</VRSInnerContainer>
				}
			</VRSMainContainer>
			<VrsMap
				selectedGrower={selectedGrower}
				selectedCropPlanId={selectedCropPlan?.CropPlanId}
				showFieldZones={!!selectedFieldListing}
				selectedFieldId={selectedFieldListing?.FieldId}
				selectedSeed={selectedSeed}
				downloadPdf={onDownloadPdf}
				exportFieldPlan={onExportFieldPlantingPlan}
				openZoneManagement={openZoneManagement}
				displayZoneManagementButton={!!selectedFieldListing && currentViewState.view !== VRSState.ZoneManagementView}
				undoLast={undoLastMapAction}
				resetAll={resetAllActions}
			/>
			<YieldTableView
				selectedSeed={selectedSeed}
				selectedFieldPlanData={selectedFieldZoneData}
				editTargetYield={editTargetYield}
				editSeedingRate={editSeedingRate}
				resetYieldTarget={resetYieldTarget}
				onChangeSlider={onChangeSlider}
				onCancel={onCancelTableView}
				onSave={onSavePlantingPlan}
				disableSaveButton={isOldPlan()}
				updateMaximumRate={onUpdateMaximumSeedingRate}
				updateMinimumRate={onUpdateMinimumSeedingRate}
				isLoading={IsLoadingPlantingRates}
				error={error}
			/>
			<StyledModal onCancel={() => setShowCancelZoneManagementModal(false)} open={showCancelZoneManagementModal} title='Cancel Zone Management?'>
				<span>There are unsaved changes that will be lost, are you sure you want to cancel?</span>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
					<Button
						width={'100px'}
						className='cancelZoneManagement-No-button'
						variant='outlined'
						style={{ marginRight: '15px' }}
						onClick={() => setShowCancelZoneManagementModal(false)}
					>No</Button>
					<Button
						width={'100px'}
						className='cancelZoneManagement-Yes-button'
						variant='dark'
						style={{ marginRight: '15px' }}
						onClick={() => onCancelZoneManagement()}
					>Yes</Button>
				</div>
			</StyledModal>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	CurrentCropYear: state.crops.CropYear,
	IsLoadingError: state.grower.isError,
	IsLoadingGetSummary: state.grower.isLoadingGetPlantingPlansSummaries,
	IsLoadingPlantingPlanFieldData: state.grower.isLoadingFieldPlantingPlan,
	IsLoadingPlantingRates: state.plantingRate.isLoadingPlantingRates,
	IsLoadingRetrieveSummary: state.grower.isLoadingPlantingPlansSummaries,
	IsLoadingSaveManagementZone: state.grower.isLoadingSaveManagementZone,
	IsSaveManagementZoneError: state.grower.isError,
	MapMode: state.managementZoneEdits.mode,
	PlantingRates: state.plantingRate.rates,
	SelectedZone: state.managementZoneEdits.selectedZone,
	UISelectedYear: state.ui.SelectedYear,
	ZoneEditingState: state.managementZoneEdits.zoneEditingState,
	ZoneEditingUndoStack: state.managementZoneEdits.undoStack,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		AddNewZone: makeDispatch(dispatch, addZone),
		DownloadVRSPdf: makeDispatch(dispatch, downloadVRSPdf),
		ExportFieldPlantingPlan: makeDispatch(dispatch, exportFieldPlantingPlan),
		GetFieldPlantingPlanData: makeDispatch(dispatch, retrieveFieldPlantingPlan),
		GetOrCreatePlantingPlanSummary: makeDispatch(dispatch, getOrCreatePlantingPlan),
		GetPlantingPlanRates: makeDispatch(dispatch, getPlantingRates),
		InitializeZoneEditingState: makeDispatch(dispatch, initializeZoneEditingState),
		ResetAll: makeDispatch(dispatch, resetAllActions),
		SaveNewZone: makeDispatch(dispatch, saveNewZone),
		SaveZoneManagementState: makeDispatch(dispatch, updateManagementZones),
		SetSelectedYear: makeDispatch(dispatch, setSelectedYear),
		SetSelectedZone: makeDispatch(dispatch, setSelectedZone),
		SetVRSMapInteractionMode: makeDispatch(dispatch, setVRSMapInteractionMode),
		UndoLastAction: makeDispatch(dispatch, undoLastAction),
		UpdateFieldPlantingPlan: makeDispatch(dispatch, updateFieldPlantingPlan),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const VRSMainComponent = connector(VRSComponent);

