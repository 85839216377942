import React from 'react';
import styled from 'styled-components';
import { ICropPlan } from './VRSMain';
import { ColorTabButton } from '../../../components/ColorTabButton/ColorTabButton';
import { dynamicSort } from '../../../logic/Utility/Utils';

interface IFieldListingProps
{
	cropPlan: ICropPlan;
	selectField: (fieldId: string, cropPlanId: string) => void;
}

const FieldListingContainer = styled.div`
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 20px;
	padding-bottom: 20px;
	height: 88.5%;
`;

const DisplayTextContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	font-size: 11px;
	align-items: flex-start;
	padding-top: 3px;
`;

const DisplayNameText = styled.div`
	color: darkgray;
	margin-right: 3px;
`;

const DisplayValueText = styled.div`
	font-weight: bold;
`;

const TitleText = styled.div`
	width: 100%;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: flex-end;
`;

export const FieldListing = (props: IFieldListingProps) =>
{
	const { cropPlan, selectField } = props;

	return (
		<FieldListingContainer className={`FieldListingContainer_${cropPlan.CropPlanId}`}>
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
				<TitleText className="TitleText">{cropPlan.CropPlanName}</TitleText>
				<DisplayTextContainer className="PropertiesContainer">
					<div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
						<DisplayNameText className="CropPlanFieldsCountTitle">Fields: </DisplayNameText>
						<DisplayValueText className="CropPlanFieldsCountValue">{cropPlan.FieldCount}</DisplayValueText>
						<DisplayNameText className="CropPlanAcresTitle" style={{ paddingLeft: 30 }}>Acres: </DisplayNameText>
						<DisplayValueText className="CropPlanAcresValue">{cropPlan.Area.toFixed(1)}</DisplayValueText>
					</div>
				</DisplayTextContainer>
				<div style={{ overflowY: 'auto', paddingRight: 10 }}>
					{
						cropPlan.FieldListing.sort(dynamicSort('FieldName')).map(ps =>
							<ColorTabButton
								addCard={false}
								key={ps.FieldId}
								className={`FieldListingTabButton_${ps.FieldId}`}
								title={ps.FieldName}
								tabColor={cropPlan.Color}
								displayNamesAndValues={[['Farm Name', ps.FarmName], ['Acres', ps.Area.toFixed(1) + ' ac' ]]}
								onClick={() => selectField(ps.FieldId, cropPlan.CropPlanId)}
								variant='slim'
							/>
						)
					}
				</div>
			</div>
		</FieldListingContainer>
	);
};