import React, { useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../../components/Button/Button';
import { IGrowerResponse, IGrowerSeasonResponse } from '../../../../logic/Models/Responses/GrowerResponse';
import { ReactComponent as CircleCheckIcon } from '../Icons/CircleCheck.svg';
import { GrowerSegment, GrowerSegmentColors, GrowerSegmentKeys } from '../../GrowerFilterModal';
import _ from 'lodash';

const StyledExpandedSegment = styled.div`
	border-radius: 10px;
	margin-right: 2%;
	padding-left: 20px;
	padding-right: 20px;
`;
import { Tooltip } from 'antd';
import { TabType } from '../../Dashboard';
import { IGrowerWithContractDetails } from '../../../../logic/Models/Responses/EnogenContractResponse';

const StyledExpandedRow = styled.div <{ expandedSpacing: string }>`
	flex-grow: 1;
	padding-right: 26px;
	padding-left: 36px;
	display: grid;
    grid-auto-flow: column;
	margin-bottom: 15px;
    height: 100%;
    grid-template-columns: ${props => props.expandedSpacing};
    grid-template-rows: 100%;
`;
const StyledExtraRows = styled.div`
	justify-self: center;
`;

interface IExpandedPlanProps
{
	Grower: IGrowerResponse & IGrowerWithContractDetails;
	expandedSpacing: string;
	cropYear: number;
	currentCropYear: number;
	currentTab: TabType;
	displayEnogenFlow?: (selectedGrowerId: string, selectedContractId: string, viewDetails?: boolean) => void;
	SetSelectedGrowerId: (growerId: string) => void;
	SetSelectedEnogenContractId?: (contractId: string) => void;
	openResendForSigningModal?: (growerId: string, contractId: string) => void;
}
const defaultStatusCountMap = {
	Draft: 0,
	Planned: 0,
	Accepted: 0
};

const emptyStatusCountMap = {
	Draft: undefined,
	Planned: undefined,
	Accepted: undefined
};

const getFieldPlanStatus = (currentSeason: IGrowerSeasonResponse) =>
	currentSeason?.GamePlanSummary?.filter(summary => summary.PlanType === 'FieldGamePlan')
		.reduce((statusMap, planSummary) =>
		{
			const { PlanStatus, PlanCount } = planSummary;
			statusMap[PlanStatus] = PlanCount;
			return statusMap;
		}, { ...defaultStatusCountMap }) ?? emptyStatusCountMap;

export const ExpandedPlan = React.memo(
	function ExpandedPlan(props: IExpandedPlanProps)
	{
		const {
			cropYear,
			currentCropYear,
			currentTab,
			Grower,
			displayEnogenFlow,
			openResendForSigningModal,
			SetSelectedGrowerId,
			SetSelectedEnogenContractId,
		} = props;

		const theme = useTheme();

		const segments = _.cloneDeep(Grower.Classification) || [];
		if (Grower.GoldenAdvantage)
		{
			segments.push(`GA: ${Grower.GoldenAdvantage}`);
		}
		const history = useHistory();

		const openFieldActivities = useCallback((growerId: string) =>
		{
			SetSelectedGrowerId(growerId);
			history.push('/fieldactivities');
		}, [Grower.Id]);

		const openPlanList = useCallback(() =>
		{
			SetSelectedGrowerId(Grower.Id);
			history.push('/fieldactivities/recall');
		}, [Grower.Id]);

		const displayEnogenFrameOnClick = useCallback(() =>
		{
			displayEnogenFlow(Grower.Id, Grower.ContractId, true);
		},[Grower.Id, Grower.ContractId]);

		const currentSeason = useMemo(() => Grower.SeasonData.find((season) => season.SeasonYear === cropYear), [Grower.SeasonData, cropYear]);
		const isProductPlanPdfDownloaded = useMemo(() => currentSeason ? 
			currentSeason.GamePlanSummary?.some(summary => summary.PlanType === 'ProductGamePlan' && summary.PdfDownloaded)
			:
			false, [currentSeason]);
		const fieldPlanStatus = useMemo(() => getFieldPlanStatus(currentSeason), [currentSeason, getFieldPlanStatus]);

		const capitalizeFirst = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';

		const setSelectedGrowerAndContractId = (growerId: string, contractId: string) =>
		{
			SetSelectedGrowerId(growerId);
			SetSelectedEnogenContractId(contractId);
		};

		const isOldCropYear = cropYear < currentCropYear;
		
		return <>
			<StyledExpandedRow className='expanded-row' expandedSpacing={props.expandedSpacing} >
				<div className='expanded-user-section'>
					{Grower.Address && <div className='address-cell' style={{ display: 'inline-grid' }}>
						<span style={{ display: 'flex' }}>
							{Grower.Address}
						</span>
						<span>{Grower.City} {Grower.State}, {Grower.Zip}</span>
					</div>}
					<div className='phone-cell'>{Grower.Phone}</div>
					<div className='email-cell'>{Grower.Email}</div>
				</div>
				{
					currentTab === 'Plan' ?
						<StyledExtraRows>
							{
								isProductPlanPdfDownloaded
									? <span style={{ display: 'flex', alignItems: 'center' }}>Downloaded <CircleCheckIcon style={{ marginLeft: 5 }} /></span>
									: ''
							}
						</StyledExtraRows>
						:
						<></>
				}
				{
					currentTab === 'Plan' ?
						<StyledExtraRows className='field-plan-count' style={{ display: 'grid' }}>
							<span>Grower Accepted: {fieldPlanStatus.Accepted}</span>
							<span>Presented: {fieldPlanStatus.Planned}</span>
							<span>My Drafts: {fieldPlanStatus.Draft}</span>
						</StyledExtraRows>
						:
						<></>
				}
				{
					currentTab === 'Enogen' ? 
						<>
							<StyledExtraRows className='field-plan-count' style={{ display: 'grid' }}>
								<span># of Assigned Acres: {Grower.AssignedAcres.toString()}</span>
								<span># of Fields: {Grower.NumberOfFields.toString()}</span>
							</StyledExtraRows>
							
							<StyledExtraRows className='field-plan-count' style={{ display: 'grid' }}>
								<span>Submitted: {Grower.Submitted ? new Date(Grower.Submitted).toLocaleDateString() : ''}</span>
								<span>Signed: {Grower.Signed ? new Date(Grower.Signed).toLocaleDateString() : ''}</span>
								<span>Approved: {Grower.ContractState.toLowerCase().includes('pending') ? 'Pending' : 'Complete'}</span>
							</StyledExtraRows>

							<StyledExtraRows className='field-plan-count' style={{ display: 'grid' }}>
								<span>Field Selection: {Grower.FieldSelection}</span>
								<span>Planting: {Grower.Planting}</span>
								<span>Harvest: {Grower.Harvest}</span>
							</StyledExtraRows>

							<div />

							<StyledExtraRows className='field-plan-count' style={{ display: 'grid' }}>
								<span>Status: {fieldPlanStatus.Accepted ? 'Accepted' : fieldPlanStatus.Planned ? 'Presented' : fieldPlanStatus.Draft ? 'Draft' : 'Not Created'}</span>
							</StyledExtraRows>
						</>
						:
						<></>
				}
			</StyledExpandedRow>
			<div className='expanded-footer' style={{ flexShrink: 1, padding: '1.5% 1.5% 1.5% 36px', borderTop: '1px solid black' }}>
				<div
					className='expanded-segments'
					style={{
						textAlign: 'center',
						fontSize: theme.fontSizes.small,
						fontWeight: theme.fontWeights.bold,
						color: theme.colors.white,
						display: 'inline-flex',
						flexWrap: 'wrap',
						rowGap: 5,
						width: '50%'
					}}
				>
					{
						segments.map((segment) =>
						{
							const upperCasedSegment = capitalizeFirst(segment);
							return (
								<StyledExpandedSegment key={`expanded-segments-${Grower.Id}-${upperCasedSegment}`}
									style={{ backgroundColor: GrowerSegmentColors(theme)[GrowerSegmentKeys[upperCasedSegment]] || GrowerSegmentColors(theme)[GrowerSegment.Unknown] }}>
									{upperCasedSegment}
								</StyledExpandedSegment>
							);
						})
					}
				</div>
				<div id='expanded-footer-buttons' style={{ display: 'inline-flex', width: '50%', justifyContent: 'flex-end' }}>
					{
						currentTab == 'Enogen' && <div style={{display: 'flex', gap: '0px 10px'}}>
							{
								(!Grower.ContractState?.toLocaleLowerCase().includes('complete') && !Grower.ContractState?.toLocaleLowerCase().includes('grower signed'))
								&&
								<Button
									variant='outlined'
									onClick={() => openResendForSigningModal(Grower.Id, Grower.ContractId)}
								>
									Resend for Signing
								</Button>
							}
							<Button variant='outlined' onClick={() => openFieldActivities(Grower.Id)}>View Fields</Button>
							<CropYearTooltip isOldCropYear={isOldCropYear}>
								<Button variant='outlined' disabled={isOldCropYear} onClick={() => openPlanList()}>New Proposal</Button>
							</CropYearTooltip>
							<Button variant='outlined' onClick={displayEnogenFrameOnClick}>Contract Details</Button>
							<Link
								className='StewardshipLink'
								to='/dashboard/contract/stewardship'
								onClick={() =>setSelectedGrowerAndContractId(Grower.Id, Grower.ContractId)}
								style={{ 
									display: 'contents',
									pointerEvents: undefined, 
								}}
							>
								<Button variant='outlined'>Stewardship</Button>
							</Link>
						</div>
					}
					{ currentTab != 'Enogen' && <>
						<Button variant='outlined' onClick={() => openFieldActivities(Grower.Id)}>Field Activities</Button>
						<CropYearTooltip isOldCropYear={isOldCropYear} style={{ marginLeft: '2%' }}>
							<Button variant='outlined' onClick={() => openPlanList()}>
								Proposal List
							</Button>
						</CropYearTooltip>
					</>
					}
				</div>
			</div>
		</>;
	}
);

/** Convenience wrapper for showing a tooltip when crop year is old. */
const CropYearTooltip = (props: {
	isOldCropYear: boolean;
	children: React.ReactNode;
	style?: React.CSSProperties;
}) => 
{
	return (
		<Tooltip title={props.isOldCropYear ? 'Prior year contracts are not available to view.' : ''}>
			<div style={props.style}>{props.children}</div>
		</Tooltip>
	);
};