
import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { IHybridResponse, ISeedResponse } from '../../Models/Responses/SeedResponse';
import { ICompetitorWireObject, ICompetitorResponse, ICompetitorObject } from '../../Models/Responses/CompetitorResponse';
import { getCurrentActingUser } from '../User/AuthSlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';
export interface ISeries
{
	availability: string;
	brandName: string;
	rank?: number;
	relativeMaturity: string;
	seriesId: string;
	seriesName: string;
}
export interface ISeriesWithHybrid extends ISeries
{
	hybrids: IHybridResponse[];
}

export interface ISeedResponseWithGrowerZip
{
	seeds: ISeedResponse[];
	growerZip: string;
}

export const getSeedsForGrower = createTracedAsyncThunk<ISeedResponseWithGrowerZip, null, { dispatch: AppDispatch, state: RootState }>(
	'seeds/grower/get',
	async (context, request: null, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const currentUser = getCurrentActingUser(currentState);
		const selectedGrowerId = currentState.ui.SelectedGrowerId;
		let growerZip = '';
		if (selectedGrowerId)
		{
			const selectedGrower = currentState.grower.Growers.find(g => g.Id === selectedGrowerId);
			growerZip = selectedGrower?.Zip;
		}
		const { auth, ui } = currentState;
		const url = `seeds/users/${currentUser.UserId}/growers/${ui.SelectedGrowerId}?include=[CropId,BrandApplications[BrandApplication,Hybrids[Id,Name,CropId,SeriesId,SeriesName,TraitId,TraitName,TraitFullName,BrandName,RelativeMaturity,Availability,MaterialAvailability,Pricing,LocalPositioning[Ordinal,Position],AnaplanForecast]]]&includeUnavailable=true`;
		try
		{
			const api = new Api('/api/4', auth.userAuthToken, context);
			const response = await api.getAsync<ISeedResponse[]>(url);

			if (response.ErrorCode === null && response.Success)
			{
				const seedResponseWithGrowerZip: ISeedResponseWithGrowerZip = { seeds: response.Data, growerZip: growerZip };
				return seedResponseWithGrowerZip;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IGetEnogenContractCheck
{
	cropYear: string;
	totalBags: string;
}
export const getEnogenContractCheck = createTracedAsyncThunk<boolean, IGetEnogenContractCheck, { dispatch: AppDispatch, state: RootState }>(
	'enogen/contractCheck',
	async (context, request, thunkAPI) =>
	{
		try
		{
			const { cropYear, totalBags } = request;
			const currentState = thunkAPI.getState();
			const userId = currentState.userInfo.UserId;
			const growerId = currentState.ui.SelectedGrowerId;

			const api = new Api('/api/4', currentState.auth.userAuthToken, context);
			const response = await api.getAsync<boolean>(`seeds/users/${userId}/growers/${growerId}/enogen/contractStatus?cropYear=${cropYear}&totalBags=${totalBags}`);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getCompetitorData = createTracedAsyncThunk<{ [key: string]: ICompetitorObject[] }, null, { dispatch: AppDispatch, state: RootState }>(
	'competitors/get',
	async (context, request: null, thunkAPI) =>
	{
		try
		{
			const currentState = thunkAPI.getState();
			const selectedYear = currentState.crops.CropYear;

			const api = new Api('/api/4', currentState.auth.userAuthToken, context);
			const response = await api.getAsync<{ [key: string]: ICompetitorWireObject[] }>(`seeds/competitors?year=${selectedYear}`);

			if (response.ErrorCode === null && response.Success)
			{
				const competitorResponse: ICompetitorResponse = { Competitors: {} };
				competitorResponse.Competitors = response.Data;

				// Massage the returned data into the new object format
				const convertedCompetitorResponseData: { [key: string]: ICompetitorObject[] } = {};

				const allCompetitorWireData = Object.values(competitorResponse.Competitors).flatMap(c => c);
				allCompetitorWireData.forEach(c =>
				{
					if (!Object.keys(convertedCompetitorResponseData).includes(c.CropId))
					{
						// Add the crop ID and the first brand to the dictionary
						const convertedCompetitor: ICompetitorObject =
						{
							Id: c.CompetitorId,
							BrandName: c.CompetitorName,
							Products: [
								{
									Id: c.Id,
									ProductName: c.Name,
									RelativeMaturity: c.RelativeMaturity
								}
							],
							Year: c.Year
						};
						convertedCompetitorResponseData[c.CropId] = [convertedCompetitor];
					}
					else
					{
						const foundConvertedCompetitorIndex = convertedCompetitorResponseData[c.CropId].findIndex(cc => cc.Id === c.CompetitorId);
						if (foundConvertedCompetitorIndex < 0)
						{
							// Push the competitor brand and product onto the crop
							const convertedCompetitor: ICompetitorObject =
							{
								Id: c.CompetitorId,
								BrandName: c.CompetitorName,
								Products: [
									{
										Id: c.Id,
										ProductName: c.Name,
										RelativeMaturity: c.RelativeMaturity
									}
								],
								Year: c.Year
							};
							convertedCompetitorResponseData[c.CropId].push(convertedCompetitor);
						}
						else
						{
							// Push just the product onto the existing competitor brand
							convertedCompetitorResponseData[c.CropId][foundConvertedCompetitorIndex].Products.push(
								{
									Id: c.Id,
									ProductName: c.Name,
									RelativeMaturity: c.RelativeMaturity
								}
							);
						}
					}
				});

				return convertedCompetitorResponseData;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);