'use strict';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Api } from '../../../logic/Api/Api';
import { RootState } from '../../../logic/store/Store';
import { chunk, sortBy } from 'lodash';
import { IManageUserResponse } from '../../../logic/Models/Responses/ManageUsersResponse';
import { IFarmResponse } from '../../../logic/Models/Responses/FarmResponse';
import { globalSession, useScopedSession, useSession } from '../../../tracing/session';

// TODO this references context, which no longer exists. We need to create a subscope here.

export interface IGrowerSummary {
	Id:                 string;
	IsDeleted:          Date;
	Name:               string;
	Address:            string;
	City:               string;
	Zip:                string;
	State:              string;
	PricingZone:        string;
	Classification:     string[];
	SalesforceId:       string;
	FoundationId:       string;
	Created:            string;
	Modified:           string;
	LanguagePreference: string;
	BaseId?:             string;
	
	Farms?: {
		GrowerId: string;
		FarmId:   string;
		Name:     string;
		Fields?:   number;
		Acres?:    number;
	}[]
}



/**
 * A helper hook that tracks a user + their growers + the farms and a selection state across each.
 * @param startLoading Method to call when data is starting to load
 * @param endLoading Method to call when data completes loading
 * @param includeDeleted Whether to include deleted growers in the listing
 */
export function useSelectionState(startLoading: () => unknown, endLoading: () => unknown, includeDeleted: boolean, incrementProgress: () => unknown, addExpected: (items: number) => unknown) 
{
	const userAuthToken = useSelector((state: RootState) => state.auth.userAuthToken);
	const manageableUsers = useSelector((state: RootState) => state.auth.manageableUsers);
	const users = useMemo(() => sortBy(manageableUsers, mu => mu.UserName.toLocaleLowerCase()), [manageableUsers]);
	const session = useScopedSession(useSelectionState.name);

	const [user, setUser] = useState<IManageUserResponse>();
	const [growers, setGrowers] = useState<IGrowerSummary[]>();
	const [selectedGrower, setSelectedGrower] = useState<IGrowerSummary>();
	const [triggerRefresh, setTriggerRefresh] = useState(0);

	// When the user changes, fetch the growers
	useEffect(() => 
	{
		const controller = new AbortController();
		const { signal } = controller;
				
		async function execute() 
		{
			setGrowers([]);

			if (!user) 
			{
				return;
			}

			startLoading();
			try 
			{
				const api = new Api('/api/6', userAuthToken, session);
				const response = await api.getAsync<IGrowerSummary[]>(`users/${user.UserId}/growers?forceSlowUpdateFromCropEdge=true&includeFarmSummary=true&includeDeleted=${includeDeleted ? 'true' : 'false'}`, {signal});
				if(!signal.aborted)
				{
					setGrowers(sortBy(response.Data, g => g.Name.toLocaleLowerCase()));
				}
			}
			catch (err) 
			{
				globalSession.error(err);
			}

			finally 
			{
				endLoading();
			}

		}
		execute();
		
		return () => { controller.abort(); };
	}, [userAuthToken, startLoading, endLoading, user?.UserId, includeDeleted, incrementProgress, user, session, triggerRefresh]);

	const selectUser = useCallback((userId: string | undefined) => 
	{
		setUser(userId ? users.find(u => u.UserId === userId) : undefined);
	}, [users]);

	const selectGrower = useCallback((growerId: string | undefined) => 
	{
		setSelectedGrower(growerId && growers ? growers.find(g => g.Id == growerId) : undefined);
	}, [growers]);

	const refresh = useCallback(() => setTriggerRefresh(prev => prev+1), []);

	return { users, user, growers, selectedGrower, selectUser, selectGrower, refresh };
}
