import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Input, Modal, ModalProps, Select } from 'antd';
import { Button } from '../../components/Button/Button';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { getContacts } from '../../logic/store/Grower/GrowerThunks';

const Body = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px 24px;
`;

const InputGroup = styled.div`
	border-bottom: thin solid black;
	margin-top: 35px;
`;

const InputLabel = styled.div`
	color: ${themeGet('colors.lightGray')};
	font-size: ${themeGet('fontSizes.extraSmall')};
`;

const StyledInput = styled(Input)`
	color: black;
	padding: 2px;
`;

interface IModalProps extends ModalProps
{
	growerId: string;
	onInvite: (email: string, name: string, contactId: string) => void;
	onDownload: () => any;
}

const mapStateToProps = (state: RootState) => ({
	contacts: state.grower.Contacts ?? []
});
  
const mapDispatchToProps = (dispatch: AppDispatch, ownProps: IModalProps) =>
	({
		getContacts: () => dispatch(getContacts({growerId: ownProps.growerId}))
	});
  
export const connector = connect(mapStateToProps, mapDispatchToProps);
  
type PropsFromRedux =  ConnectedProps<typeof connector>;

const InviteModalComponent = (props: PropsFromRedux & IModalProps) =>
{
	const { contacts, onCancel, onDownload, onInvite } = props;

	const [contactId, setContactId] = useState<string>();
	const [email, setEmail] = useState<string>('');
	const [name, setName] = useState<string>('');

	const theme = useTheme();

	useEffect(()=>
	{
		props.getContacts();
	},
	[]);

	useEffect(() =>
	{
		if (contacts && contacts.length > 0)
		{
			if (contacts.length === 1)
			{
				setContactId(contacts[0].Id);
				setEmail(contacts[0].Email);
				setName(contacts[0].Name);
			}
		}
	},[contacts]);
	
	const InviteFooter = (
		<div>
			<Button
				onClick={(e) => onDownload()}
				variant='light'
				className='downloadButton'
			>
				Download
			</Button>
			<Button
				variant='light'
				style={{ marginLeft: 24 }}
				onClick={(e) => onCancel(e)}
				className='cancelButton'
			>
				Cancel
			</Button>
			<Button
				variant='light'
				style={{ marginLeft: 24, color: theme.colors.primary }}
				disabled={!email?.length || !name?.length || !contactId?.length}
				onClick={() => onInvite(email, name, contactId)}
				className='submitButton'
			>
				Send
			</Button>
		</div>
	);

	return (
		<Modal
			{...props}
			title='Send to Grower'
			className='InviteModal'
			closable={false}
			footer={InviteFooter}
			centered
		>
			<Body>
				<InputGroup style={{ marginTop: 0 }}>
					<InputLabel>Contact</InputLabel>
					<Select
						bordered={false}
						value={contactId}
						defaultValue={contactId}
						onChange={(value) => 
						{
							const contact = contacts.find(c => c.Id === value);
							if (contact)
							{
								setContactId(value);
								setEmail(contact.Email);
								setName(contact.Name);
							}
							else
							{
								setContactId('');
								setEmail('');
								setName('');
							}
						}}
						className='contactInput'
						style={{
							width:'100%'
						}}
					>
						{contacts.map(contact => <Select.Option key={contact.Id} value={contact.Id}>
							{contact.Name}
						</Select.Option>)}
					</Select>
				</InputGroup>
				<InputGroup>
					<InputLabel>Email Address</InputLabel>
					<StyledInput
						bordered={false}
						value={email}
						readOnly={true} /** They specifically don't want to be able to change the email address */
						onChange={(e) => setEmail(e.target.value)}
						className='emailInput'
					/>
				</InputGroup>
				<InputGroup>
					<InputLabel>Recipient Name</InputLabel>
					<StyledInput
						bordered={false}
						value={name}
						onChange={(e) => setName(e.target.value)}
						className='nameInput'
					/>
				</InputGroup>
			</Body>
		</Modal>
	);
};

export const InviteModal = connector(InviteModalComponent);