import {  } from '@reduxjs/toolkit';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { Api } from '../../Api/Api';
import { IEnogenContractResponse, IStewardshipActivity, IStewardshipCompletionDatesResponse } from '../../Models/Responses/EnogenContractResponse';
import { AppDispatch, RootState } from '../Store';
import { getCurrentActingUser } from '../User/AuthSlice';

export const getSellerEnogenContracts = createTracedAsyncThunk<IEnogenContractResponse[], { year: string }, { dispatch: AppDispatch, state: RootState }>(
	'seller/get/enogencontracts',
	async (context, request: { year: string }, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState() as RootState;
			const { auth } = state;
			const api = new Api('/api/5', auth.userAuthToken, context);
			const currentUserId = getCurrentActingUser(thunkAPI.getState()).UserId;

			const response = await api.getAsync<IEnogenContractResponse[]>(`users/${currentUserId}/contracts?year=${request.year}`);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getContractStewardshipData = createTracedAsyncThunk<IStewardshipActivity[], null, { dispatch: AppDispatch, state: RootState }>(
	'contract/get/stewardshipdata',
	async (context, request: null, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState() as RootState;
			const { auth, ui } = state;
			const api = new Api('/api/5', auth.userAuthToken, context);
			const currentUserId = getCurrentActingUser(thunkAPI.getState()).UserId;
			const currentContractId = ui.SelectedEnogenContractId;

			const response = await api.getAsync<IStewardshipActivity[]>(`users/${currentUserId}/stewardshipactivities/${currentContractId}`);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getStewardshipCompletionDates = createTracedAsyncThunk<{data: IStewardshipCompletionDatesResponse, contractId: string}, null, { dispatch: AppDispatch, state: RootState }>(
	'contract/get/stewardshipcompletiondates',
	async (context, request: null, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState() as RootState;
			const { auth, ui, crops } = state;
			const api = new Api('/api/5', auth.userAuthToken, context);
			const currentUserId = getCurrentActingUser(thunkAPI.getState()).UserId;
			const requestedYear = ui.SelectedYear ? ui.SelectedYear : crops.CropYear;
			const currentContractId = ui.SelectedEnogenContractId;

			const response = await api.getAsync<IStewardshipCompletionDatesResponse>(`users/${currentUserId}/stewardshipactivities/completiondates/${requestedYear}`);

			if (response.ErrorCode === null && response.Success) 
			{
				return { data: response.Data, contractId: currentContractId };
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface ISaveStewardshipDataRequest
{
	Type: string;
	StewardshipActivityId: string;
	Planting?: ISaveStewardshipPlantingRequest[];
	Harvest?: ISaveStewardshipHarvestRequest[];
}

export interface ISaveStewardshipPlantingRequest
{
	FieldId: string;
	Date: string;
	TotalPlantedAcres: string;
	PlantingRate: string;
	EstBuAcre: string;
}

export interface ISaveStewardshipHarvestRequest
{
	FieldId: string;
	TotalHarvest: string;
	TotalAcres: string;
	YieldPerAcre: string;
	UnitType: string;
	HarvestDate: string;
}

export const saveContractStewardshipData = createTracedAsyncThunk<IStewardshipActivity, ISaveStewardshipDataRequest, { dispatch: AppDispatch, state: RootState }>(
	'contract/save/stewardshipdata',
	async (context, request: ISaveStewardshipDataRequest, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState() as RootState;
			const { auth, ui } = state;
			const api = new Api('/api/5', auth.userAuthToken, context);
			const currentUserId = getCurrentActingUser(thunkAPI.getState()).UserId;
			const currentContractId = ui.SelectedEnogenContractId;

			const response = await api.postAsync<IStewardshipActivity>(`users/${currentUserId}/stewardshipactivities/${currentContractId}`, request);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IStewardshipReplantOrLostRequest
{
	StewardshipActivityId: string;
	Date: string;
	Notes: string;
	Fields: IStewardshipReplantOrLostFieldRequest[];
}
export interface IStewardshipReplantOrLostFieldRequest
{
	FieldName: string; // Used in the display on the Replant/Loss Modal but not necessary data on the Save
	FieldId: string;
	TotalAcres: string; // Used in the display on the Replant/Lost Modal but not necessary data on the Save
	Acres: string;
}
export interface IStewardshipReplantResponse
{
	StewardshipReplantId: string;
    StewardshipId: string; // This is the Stewardship Activity Id
    FieldId: string;
    ReplantedDate: string;
    AcresReplanted: string;
    Notes: string;
    Created: string;
    Modified: string;
}

export const updateContractStewardshipReplant = createTracedAsyncThunk<boolean, IStewardshipReplantOrLostRequest, { dispatch: AppDispatch, state: RootState }>(
	'contract/save/replant',
	async (context, request: IStewardshipReplantOrLostRequest, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState() as RootState;
			const { auth, ui } = state;
			const api = new Api('/api/5', auth.userAuthToken, context);
			const currentUserId = getCurrentActingUser(thunkAPI.getState()).UserId;
			const currentContractId = ui.SelectedEnogenContractId;

			const response = await api.postAsync<IStewardshipReplantResponse[]>(`users/${currentUserId}/stewardshipactivities/${currentContractId}/replant`, request);

			if (response.ErrorCode === null && response.Success)
			{
				// Now retrieve the stewardship data again
				thunkAPI.dispatch(getContractStewardshipData());
				return true;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IStewardshipFieldLostResponse
{
	StewardshipFieldLostId: string;
    StewardshipId: string; // This is the Stewardship Activity Id
    FieldId: string;
    LostDate: string;
    AcresLost: string;
    Notes: string;
    Created: string;
    Modified: string;
}

export const updateContractStewardshipFieldLoss = createTracedAsyncThunk<boolean, IStewardshipReplantOrLostRequest, { dispatch: AppDispatch, state: RootState }>(
	'contract/save/fieldloss',
	async (context, request: IStewardshipReplantOrLostRequest, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState() as RootState;
			const { auth, ui } = state;
			const api = new Api('/api/5', auth.userAuthToken, context);
			const currentUserId = getCurrentActingUser(thunkAPI.getState()).UserId;
			const currentContractId = ui.SelectedEnogenContractId;

			const response = await api.postAsync<IStewardshipFieldLostResponse[]>(`users/${currentUserId}/stewardshipactivities/${currentContractId}/fieldlost`, request);

			if (response.ErrorCode === null && response.Success)
			{
				// Now retrieve the stewardship data again
				thunkAPI.dispatch(getContractStewardshipData());
				return true;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);


export const ResendDeliveryType =
{
	Email: 'email',
	Phone: 'phone'
};
export interface IResendForSigningRequest
{
	DeliveryType: string;
	Phone: string;
	Email: string;
}

export const resendForSigning = createTracedAsyncThunk<boolean,{contractId: string, requestBody: IResendForSigningRequest}, { dispatch: AppDispatch, state: RootState }>(
	'contract/resendsigning',
	async (context, request: {contractId: string, requestBody: IResendForSigningRequest}, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState() as RootState;
			const { auth } = state;
			const api = new Api('/api/5', auth.userAuthToken, context);
			const currentUserId = getCurrentActingUser(thunkAPI.getState()).UserId;

			const response = await api.postAsync<IStewardshipFieldLostResponse[]>(`users/${currentUserId}/contracts/${request.contractId}/resend`, request.requestBody);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);