import {  } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { sleepOnIt } from '../../Utility/Utils';
import { IPlanDownloadRequest } from '../../Models/Requests/PlanRequest';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export const downloadProductPlan = createTracedAsyncThunk<string, IPlanDownloadRequest, { dispatch: AppDispatch; state: RootState; }>(
	'productGamePlan/downloadPdf',
	async (context, request, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const api = new Api('/api/4', currentState.auth.userAuthToken, context);

		const pdfSession = await api.postAsync<string>(`productplans/${request.PlanId}/pdf/async`, request.CoverConfig ?? {});
		let pdfData = undefined;
		do 
		{
			// wait half a second for each look
			await sleepOnIt(500);
			pdfData = await api.getFileAsync(`productplans/${request.PlanId}/pdf/${pdfSession.Data}`);
		} while(!pdfData);

		try
		{
			const { name, data } = pdfData;

			// If the blob is empty, nothing to download so send an error
			if (!data.size)
			{
				return thunkAPI.rejectWithValue('There was an error downloading the PDF data.');
			}

			if (window.navigator.userAgent.toLowerCase().indexOf('crios') > -1)
			{//iOS Chrome
				const typedBlob = new Blob([data], {type: 'application/pdf'});
				const link = (window.URL || window['webkitURL']).createObjectURL(typedBlob);
				window.open(link);
			}
			else
			{
				// Create an object URL for the blob object
				const url = URL.createObjectURL(data);

				// Create a new anchor element
				const a = document.createElement('a');

				// Set the href and download attributes for the anchor element
				// You can optionally set other attributes like `title`, etc
				// Especially, if the anchor element will be attached to the DOM
				a.href = url;
				a.download = name;

				// Programmatically trigger a click on the anchor element
				// Useful if you want the download to happen automatically
				// Without attaching the anchor element to the DOM
				// Comment out this line if you don't want an automatic download of the blob content
				a.click();
			}

			return name;

		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}

	}
);
