import { themeGet } from '@styled-system/theme-get';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import styled, { useTheme } from 'styled-components';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { MaxScriptMap } from '../../../logic/Map/MaxScriptMap/MaxScriptMap';
import { MaxScriptCropPlanOverview } from './CropPlanOverView';
import { ManageFieldsView } from './ManageFieldsView';
import { ReactComponent as LeftCaret } from '../Icons/LeftCaret.svg';
import { ReactComponent as Ellipse } from '../../../assets/images/Ellipse.svg';
import { Link } from 'react-router-dom';
import { dynamicSort, generateRandomColor, makeDispatch } from '../../../logic/Utility/Utils';
import { addCompetitorBrand, addProductToCompetitorBrand, setSelectedPlanId } from '../../../logic/store/UI/UISlice';
import { IPlanResponse } from '../../../logic/Models/Responses/PlanResponse';
import { MaxScriptProductInventory } from './ProductInventory';
import { getGrowerOrdersForYear, getOrderPlanProductsAndAssignments, importOrderPlanData } from '../../../logic/store/Grower/OrderThunks';
import { IAttributeSummary, IOrderPlanFieldAssignment, IOrderPlanProducts } from '../../../logic/Models/Responses/OrderPlanResponse';
import { FieldInventoryView, IExtendedOrderPlanFieldItem, IFieldInventoryDetails } from './FieldInventoryView';
import { cornId, CropConfig, soyId, unknownId } from '../../../logic/store/Seeds/CropsSlice';
import { ConvertCropNameToId, RoundAcres } from '../../../logic/Utility/CalculationUtilities';
import { IFieldResponse } from '../../../logic/Models/Responses/FieldResponse';
import { IUpdateSeedAssignmentRequest, updateSeedAssignmentsForField } from '../../../logic/store/Grower/SeedAssignmentThunks';
import { IUpdateFieldCropRequest, updateCropForFields } from '../../../logic/store/Grower/FieldCropThunks';
import { AssignmentSource, AssignmentType, IUpdateHybrid } from '../../../logic/Models/Requests/SourceUpdateTypes';
import { getPlantingRates, getSeedingRateForSeriesAndRates, IGetPlantingRatesRequest } from '../../../logic/store/Seeds/PlantingRateSlice';
import { IUpdateTargetYieldRequest, updateTargetYieldForField } from '../../../logic/store/Grower/TargetYieldThunk';
import { OverageDetailsModal } from './OverageDetailsModal';
import { getCropEdgeSsoLaunchUrl } from '../../../logic/store/User/UserInfoSlice';
import { ICompetitorSeedItem } from './Competitor Seeds/CompetitorRow';
import { UserPermission } from '../../../logic/Models/Responses/UserPermissions';
import { hasEffectivePermission } from '../../../logic/store/User/AuthSlice';
import { MaxscriptExternalProductColorsList, MaxscriptProductColorsList } from '../../../logic/Utility/Colors';
import { getCompetitorData } from '../../../logic/store/Seeds/SeedsActions';
import { ICompetitorObject, ICompetitorProduct } from '../../../logic/Models/Responses/CompetitorResponse';
import { useAsyncDebounce } from '../../../logic/Utility/useAsyncDebounce';
import { EventMaxScriptNames, EventNavigationNames } from '../../../tracing/EventNames';
import { useAmplitudePassthrough } from '../../../logic/Utility/useAmplitude';
import { useScopedSession } from '../../../tracing/session';
import { useMaxScriptTrackingState } from './useMaxScriptTrackingState';
import { EventStructureTags } from '../../../tracing/EventTagNames';

const MaxScriptMainContainer = styled.div`
	min-width: 382px;
	max-width: 382px;
	background-color: ${themeGet('colors.white')};
	display: flex;
	flex-direction: column;
`;

const MaxScriptInnerContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const TitleContainer = styled.div`
	align-items: center;
	background-color: #363636;
	color: ${themeGet('colors.white')};
	background-color: ${themeGet('colors.secondary')}
	display: flex;
	font-weight: ${themeGet('fontWeights.bold')};
	height: 46px;
	padding-left: 17px;
	padding-right: 25px;
`;

const CrumbContainer = styled.div`
	align-items: center;
	background-color: #F3F4F6;
	display: flex;
	font-weight: ${themeGet('fontWeights.bold')};
	height: 35px;
	min-height: 35px;
	padding-left: 17px;
	padding-right: 25px;
`;

interface IMaxScriptProps extends PropsFromRedux
{
	selectedGrower: IGrowerResponse;
	selectedOrderId: string;
	selectedYear: string;
}

enum MaxScriptViewState
{
	CropPlanOverview, // The List of all CropPlans
	ManageFields, // The Field Listing where a user can change what crop is assigned to a field
	ProductInventory, // The Full listing of products for a selected Crop Plan
}

interface IMaxScriptViewState
{
	view: MaxScriptViewState.CropPlanOverview 
	| MaxScriptViewState.ManageFields 
	| MaxScriptViewState.ProductInventory
}

type IMaxScriptViewMode = IMaxScriptViewState;

export interface ICropPlanDetails
{
	TotalFieldAcreage: number;
	TotalFieldCount: number;
}

export interface IMaxScriptOrderData
{
	CropPlanAttributeMasterList: IAttributeSummary[];
	CropPlanDetails: ICropPlanDetails;
	CropPlanFields: IFieldInventoryDetails[];
	CropPlanProducts: IOrderPlanProducts[];
}

const MaxScriptComponent = (props: IMaxScriptProps) =>
{
	const {
		ClientConfig,
		CompetitorBrandsAndProducts,
		CompetitorData,
		CurrentCropYear,
		IsLoadingMaxScriptPlan,
		IsLoadingSeedingRates,
		SeedingRates,
		selectedGrower,
		selectedOrderId,
		UISelectedPlanId,
		IsGhx,
		AddCompetitorBrand,
		AddCompetitorProductToBrand,
		BulkUpdateCropForFields,
		GetCompetitors,
		GetGrowerOrders,
		GetMaxScriptPlan,
		GetPlantingPlanRates,
		ImportOrder,
		LaunchCropEdgeSso,
		SetSelectedPlanId,
		UpdateSeedAssignmentsForField,
		UpdateTargetYieldForField,
	} = props;

	const theme = useTheme();

	const trackingData = useMaxScriptTrackingState();
	const session = useScopedSession(MaxScriptComponent.name, trackingData, {
		[EventStructureTags.PageContext]: 'maxscript',
		[EventStructureTags.PageUrl]: window.location.toString()
	});

	const [currentViewState, setCurrentViewState] = useState<IMaxScriptViewMode>({view: MaxScriptViewState.CropPlanOverview});
	const [currentOrder, setCurrentOrder] = useState<IPlanResponse>(undefined); // From the Recall Plan Listing
	const [maxScriptOrderData, setMaxScriptOrderData] = useState<{[key: string]: IMaxScriptOrderData}>(undefined); // Key is the crop id
	const [selectedCropId, setSelectedCropId] = useState<string>(undefined); // The crop that was selected from the crop plan listing
	const [maxScriptPlanRetrieved, setMaxScriptPlanRetrieved] = useState<boolean>(false);	
	const [selectedFieldId, setSelectedFieldId] = useState<string>(undefined);
	const [enableFieldInventoryView, setEnableFieldInventoryView] = useState<boolean>(false);
	const [allGrowerFields, setAllGrowerFields] = useState<IFieldResponse[]>([]);
	const [fieldArrayPosition, setFieldArrayPosition] = useState<string>('');
	const [showImportError, setShowImportError] = useState<boolean>(false);
	const [showOverageModal, setShowOverageModal] = useState<boolean>(false);
	const [overageModalCropId, setOverModalCropId] = useState<string>(undefined);
	const [combinedCompetitors, setCombinedCompetitors] = useState<ICompetitorObject[]>([]);

	useEffect(() =>
	{
		// Initial setup of all known data from the Redux store
		const orderId = selectedOrderId ? selectedOrderId : UISelectedPlanId;
		SetSelectedPlanId(orderId);
		const selectedOrder = selectedGrower.Plans.find(p => p.Id === orderId && p.Type === 'Order');
		setCurrentOrder(selectedOrder);
	},[]);

	useEffect(() =>
	{
		// Set all grower fields
		setAllGrowerFields(selectedGrower ? _.cloneDeep(selectedGrower.Farms?.flatMap(fa => fa.Fields)) : []);
	},[selectedGrower?.Farms]);

	useEffect(() =>
	{
		if (!maxScriptPlanRetrieved)
		{
			// TODO: Add a way to get the current requested year and not just the current crop year for cases of next season looking at old data
			GetMaxScriptPlan({ year: CurrentCropYear });
			const cropYear = Number.parseInt(CurrentCropYear);
			GetGrowerOrders({ growerId: selectedGrower.Id, year: cropYear });
			GetCompetitors();
			setMaxScriptPlanRetrieved(true);
			setShowImportError(false);
		}
		else
		{
			if (!IsLoadingMaxScriptPlan)
			{
				const currentOrderPlanData = _.cloneDeep(selectedGrower.OrderPlan);
				if (!currentOrderPlanData || Object.keys(currentOrderPlanData.OrderPlanByCrops).length === 0)
				{
					setShowImportError(true);
				}
				else
				{
					if (!IsLoadingSeedingRates)
					{
						// Get the seeding rates by yield values for both crops
						Object.keys(currentOrderPlanData.OrderPlanByCrops).forEach(cropId =>
						{
							const seriesNames: string[] = [];
							currentOrderPlanData.OrderPlanByCrops[cropId].Products?.forEach(product =>
							{
								if (!product.IsExternal)
								{
									// If we already have the rates for the series, don't pull them again
									if (!SeedingRates)
									{
										seriesNames.push(product.SeriesName);
									}
									else if (SeedingRates && 
										(!SeedingRates[product.SeriesName] || !getSeedingRateForSeriesAndRates(SeedingRates, product.SeriesName, CropConfig()[cropId].minYield)))
									{
										seriesNames.push(product.SeriesName);
									}
								}
							});
							if (seriesNames && seriesNames.length > 0)
							{
								const plantingRatesRequest: IGetPlantingRatesRequest = {
									cropId: cropId,
									seriesNames: seriesNames,
									vrs: true,
								};
								GetPlantingPlanRates(plantingRatesRequest);
							}
						});
					}
					
					setShowImportError(false);
				}
			}
		}
	}, [IsLoadingMaxScriptPlan, maxScriptPlanRetrieved]);

	useEffect(() =>
	{
		// Combine the official competitor list with the hand written values
		if (Object.values(CompetitorData).length > 0 && selectedCropId)
		{
			const officialCompetitorDataForCrop = _.cloneDeep(CompetitorData[selectedCropId]);
			const manualEnteredData = CompetitorBrandsAndProducts;
			const combinedData: ICompetitorObject[] = officialCompetitorDataForCrop;
			Object.keys(manualEnteredData).forEach(k =>
			{
				const combinedDataIndex = combinedData.findIndex(c => c.BrandName.toLowerCase() === k.toLowerCase());
				if (combinedDataIndex < 0)
				{
					const manualEnteredProducts = manualEnteredData[k];
					const convertedProducts: ICompetitorProduct[] = [];
					manualEnteredProducts.forEach(hp =>
					{
						convertedProducts.push(
							{
								Id: '',
								ProductName: hp,
								RelativeMaturity: ''
							});
					});
					const manualEnteredCompetitor: ICompetitorObject =
					{
						Id: '',
						BrandName: k,
						Products: convertedProducts,
						Year: Number.parseInt(CurrentCropYear)
					};
					combinedData.push(manualEnteredCompetitor);
				}
				else // Key already exists as a Brand
				{
					const manualEnteredProducts = manualEnteredData[k];
					const products = combinedData[combinedDataIndex].Products;
					manualEnteredProducts.forEach(p =>
					{
						if (products.findIndex(prod => prod.ProductName.toLowerCase() === p.toLowerCase()) < 0)
						{
							combinedData[combinedDataIndex].Products.push(
								{
									Id: '',
									ProductName: p,
									RelativeMaturity: ''
								});
						}
					});
				}
			});

			setCombinedCompetitors([...combinedData]);
		}
	},[CompetitorData, selectedCropId, CompetitorBrandsAndProducts]);

	useEffect(() =>
	{
		if (!maxScriptPlanRetrieved || IsLoadingMaxScriptPlan)
		{
			return;
		}

		if (!selectedGrower?.OrderPlan || allGrowerFields?.length === 0)
		{
			return;
		}

		const currentOrderPlanData = _.cloneDeep(selectedGrower.OrderPlan); // Clone so we do not run into any readonly issues

		// Convert the order plan into editable objects - key is the cropId
		const maxScriptDataContainer: {[key: string]: IMaxScriptOrderData} = {};

		Object.keys(currentOrderPlanData.OrderPlanByCrops).forEach(cropId =>
		{
			const cropName = CropConfig()[cropId].cropName;
			const allCropFields = allGrowerFields.filter(f => f.CurrentCrop?.toLocaleLowerCase() === cropName.toLocaleLowerCase());
			const totalAcreage = allCropFields.map(f => f.Area).reduce((prev, current) => prev + current, 0);

			const products = setupProductInventory(currentOrderPlanData.OrderPlanByCrops[cropId].Products);

			maxScriptDataContainer[cropId] =
			{
				CropPlanDetails: { 
					TotalFieldAcreage: RoundAcres(totalAcreage), TotalFieldCount: allCropFields.length
				},
				CropPlanFields: currentOrderPlanData.OrderPlanByCrops[cropId].HybridsByField ?
					setupFieldDetails(currentOrderPlanData.OrderPlanByCrops[cropId].HybridsByField, products, cropId)
					: [],
				CropPlanAttributeMasterList: currentOrderPlanData.OrderPlanByCrops[cropId].AttributeSummaries,
				CropPlanProducts: products,
			};

			// Loop through existing fields that have the crop plan type
			const fieldsWithoutAssignments = _.cloneDeep(allGrowerFields).filter(growerField =>
			{
				const fieldCurrentCrop = ConvertCropNameToId(growerField.CurrentCrop);
				if (fieldCurrentCrop === cropId)
				{
					if (maxScriptDataContainer[cropId].CropPlanFields.findIndex(assignedFieldId => assignedFieldId.Id === growerField.Id) === -1)
					{
						return growerField;
					}
				}
			});

			if (fieldsWithoutAssignments && fieldsWithoutAssignments.length > 0)
			{
				const unAssignedFieldDetails = fieldsWithoutAssignments.map(noAssign => getSelectedFieldDetails(noAssign.Id, cropId, undefined, undefined));
				maxScriptDataContainer[cropId].CropPlanFields = [...maxScriptDataContainer[cropId].CropPlanFields, ...unAssignedFieldDetails];
			}
		});

		const fieldsWithUnknownCrop = _.cloneDeep(allGrowerFields).filter(f => !f.CurrentCrop || f.CurrentCrop.toLocaleLowerCase() === 'unknown');

		if (fieldsWithUnknownCrop && fieldsWithUnknownCrop.length > 0)
		{
			const unknownFieldDetails = fieldsWithUnknownCrop.map(unkField => getSelectedFieldDetails(unkField.Id, unknownId, undefined, undefined));
			maxScriptDataContainer[unknownId] =
			{
				CropPlanDetails: undefined,
				CropPlanFields: unknownFieldDetails,
				CropPlanAttributeMasterList: undefined,
				CropPlanProducts: undefined,
			};
		}

		setMaxScriptOrderData({...maxScriptDataContainer});

	}, [allGrowerFields, selectedGrower?.OrderPlan, SeedingRates, IsLoadingMaxScriptPlan, maxScriptPlanRetrieved]);

	/**
	 * Adds colors to the Order Plan product response items
	 * @param responseProducts Order Plan response product list
	 * @returns 
	 */
	const setupProductInventory = (responseProducts: IOrderPlanProducts[]): IOrderPlanProducts[] =>
	{
		const products = responseProducts;
		let colorCounter = 0; // In case the product list is more than the color list length, use this to determine if a random color is needed
		let externalColorCounter = 0;

		// Set colors for non-external seeds
		products.filter(p => !p.IsExternal).sort(dynamicSort('Rm')).forEach(p =>
		{
			if (!p.Color)
			{
				if (colorCounter < MaxscriptProductColorsList.length)
				{
					p.Color = MaxscriptProductColorsList[colorCounter];
					colorCounter++;
				}
				else
				{
					const randomColor = generateRandomColor(1,1);
					p.Color = randomColor;
				}
			}
		});

		// Set a Hybrid Id for the external products, and then sort by that to assign colors - this assures that we get the alternating dark/light
		products.filter(p => p.IsExternal).forEach(p => p.HybridId = `${p.BrandName}_${p.HybridName}_${p.Rm}`);
		products.filter(p => p.IsExternal).sort(dynamicSort('HybridId')).forEach(p =>
		{
			if (externalColorCounter === MaxscriptExternalProductColorsList.length)
			{
				externalColorCounter = 0;
			}
			if (externalColorCounter < MaxscriptExternalProductColorsList.length)
			{
				p.Color = MaxscriptExternalProductColorsList[externalColorCounter];
				externalColorCounter++;
			}
		});

		return products;
	};

	/**
	 * Creates the FieldInventoryDetails[] object for each field in the OrderPlan
	 * @param fieldAssignments The OrderPlan HybridField Response
	 * @param products The formatted product listing (with colors)
	 * @param cropId CropId
	 * @returns 
	 */
	const setupFieldDetails = (
		fieldAssignments: {[key: string]: IOrderPlanFieldAssignment[]},
		products: IOrderPlanProducts[],
		cropId: string): IFieldInventoryDetails[] =>
	{
		const fieldInventoryDetails: IFieldInventoryDetails[] = Object.keys(fieldAssignments).map(fieldId =>
		{
			return getSelectedFieldDetails(fieldId, cropId, products, fieldAssignments[fieldId]);
		});

		return fieldInventoryDetails;
	};

	/**
	 * Creates the FieldInventoryDetails object for a specified field
	 * @param fieldId 
	 * @param cropId 
	 * @param productInventory 
	 * @param fieldInventory 
	 * @returns 
	 */
	const getSelectedFieldDetails = (
		fieldId: string,
		cropId: string,
		productInventory: IOrderPlanProducts[],
		fieldInventory: IOrderPlanFieldAssignment[]
	): IFieldInventoryDetails =>
	{
		const foundField = allGrowerFields.find(f => f.Id === fieldId);
		const fieldName = foundField.Name;
		const fieldAcres = foundField.Area;
		
		// Create the field inventory that will be passed to the Field Inventory View with the field inventory items as selected
		const editableFieldInventory: IExtendedOrderPlanFieldItem[] = fieldInventory?.map(item =>
		{
			// If IsExternal is false, this is an actual seed, otherwise we have an external/competitor seed
			if (!item.IsExternal)
			{
				const productInventorySeed = productInventory.find(p => p.HybridId === item.HybridId);
				let fieldYield = foundField?.TargetYield ? foundField.TargetYield : 0;
				if (fieldYield === 0)
				{
					if (foundField?.CurrentCrop && foundField.CurrentCrop.toLocaleLowerCase() === 'corn')
					{
						fieldYield = foundField.CornEstimatedYield ? foundField.CornEstimatedYield : 0;
					}
					else if (foundField?.CurrentCrop && foundField.CurrentCrop.toLocaleLowerCase() === 'soybean')
					{
						fieldYield = foundField.SoybeanEstimatedYield ? foundField.SoybeanEstimatedYield : 0;
					}
				}
				if (fieldYield === 0) // if it still is 0
				{
					fieldYield = CropConfig()[cropId].minYield;
				}
				const seedingRatesBySeries = SeedingRates[productInventorySeed.SeriesName];
				const seedingRateByYield = getSeedingRateForSeriesAndRates(SeedingRates, productInventorySeed.SeriesName, fieldYield) ?? CropConfig()[cropId].minSeedingRate;

				// This value will be calculated off the predicted rate and used to determine if a user-entered rate is going beyond the expected
				// percentage increase
				let predictedYield = 0;
				if (cropId === cornId)
				{
					predictedYield = foundField.CornEstimatedYield ? foundField.CornEstimatedYield :  CropConfig()[cropId].minYield;
				}
				else if (cropId === soyId)
				{
					predictedYield = foundField.SoybeanEstimatedYield ? foundField.SoybeanEstimatedYield : CropConfig()[cropId].minYield;
				}

				let predictedYieldMinRate = CropConfig()[cropId].minSeedingRate;
				let predictedYieldMaxRate = CropConfig()[cropId].maxSeedingRate;
				if (predictedYield && seedingRatesBySeries)
				{
					const maxPercent = ClientConfig?.VRSMaxScriptMaxPercent ?? 20;
					const minPercent = ClientConfig?.VRSMaxScriptMinPercent ?? -20;	
					
					// This is the recommended rate for the predicted yield
					const rateForPredictedYield = seedingRatesBySeries[predictedYield.toFixed(2)];

					if (rateForPredictedYield)
					{
						// Now calculate the +percentage/-percentage that the user should not be able to push the rate above/below
						predictedYieldMinRate =  Math.round(rateForPredictedYield + (rateForPredictedYield * (minPercent / 100)));
						predictedYieldMaxRate =  Math.round(rateForPredictedYield + (rateForPredictedYield * (maxPercent / 100)));
					}
				}

				const syngentaExtendedOrderPlanField: IExtendedOrderPlanFieldItem =
				{
					Acres: item.Acres,
					AttributeRatings: productInventorySeed.AttributeRatings,
					Color: productInventorySeed?.Color,
					CropId: cropId,
					HybridBrand: item.HybridBrand,
					HybridId: item.HybridId,
					HybridName: productInventorySeed?.HybridName,
					IsExternal: item.IsExternal,
					MatchStrength: item.MatchStrength ? item.MatchStrength : 0,
					MaxFieldAcreage: fieldAcres, // Total field area possible
					MaxPopulationRate: predictedYieldMaxRate,
					MinPopulationRate: predictedYieldMinRate,
					Rate: item.Rate === 0 ? seedingRateByYield : item.Rate,
					RelativeMaturity: item.RelativeMaturity,
					Selected: item.Acres > 0 ? true : false, // If no acres are applied for a hybrid, do not pre-select the field
					SeriesId: productInventorySeed.SeriesId,
					SeriesName: productInventorySeed.SeriesName,
				};
				return syngentaExtendedOrderPlanField;
			}
			else
			{
				const fakeExternalHybridId = `${item.HybridBrand}_${item.HybridName}_${item.RelativeMaturity}`;
				const productInventorySeed = productInventory.find(p => p.HybridId === fakeExternalHybridId);

				// Try adding the brand and product to the redux store so that it is available to the user as pre-fill text
				AddCompetitorBrand(item.HybridBrand);
				if (item.HybridName)
				{
					AddCompetitorProductToBrand({ brand: item.HybridBrand, product: item.HybridName });
				}

				const externalExtendedOrderPlanField: IExtendedOrderPlanFieldItem =
				{
					Acres: item.Acres,
					AttributeRatings: null,
					Color: productInventorySeed.Color,
					CropId: cropId,
					HybridBrand: item.HybridBrand,
					HybridId: fakeExternalHybridId,
					HybridName: item.HybridName,
					IsExternal: item.IsExternal,
					MatchStrength: 0,
					MaxFieldAcreage: fieldAcres, // Total field area possible
					MaxPopulationRate: CropConfig()[cropId].maxSeedingRate,
					MinPopulationRate: CropConfig()[cropId].minSeedingRate,
					Rate: item.Rate,
					RelativeMaturity: item.RelativeMaturity,
					Selected: item.Acres > 0 ? true : false, // If no acres are applied for a hybrid, do not pre-select the field
					SeriesId: undefined,
					SeriesName: undefined,
				};
				return externalExtendedOrderPlanField;
			}
		});

		const highestHybrid = editableFieldInventory?.reduce((prev, current) => (prev.Acres > current.Acres) ? prev : current);

		let predictedYield = 0;
		if (cropId === cornId)
		{
			predictedYield = foundField.CornEstimatedYield ? Math.round(foundField.CornEstimatedYield) :  CropConfig()[cropId].minYield;
		}
		else if (cropId === soyId)
		{
			predictedYield = foundField.SoybeanEstimatedYield ? Math.round(foundField.SoybeanEstimatedYield) : CropConfig()[cropId].minYield;
		}
		
		const fieldDetails: IFieldInventoryDetails =
		{
			Acres: fieldAcres, // Total field area possible
			Boundary: foundField.Boundary,
			HybridColor: highestHybrid && highestHybrid.Acres !== 0 ?
				highestHybrid.Color : cropId === unknownId ? theme.colors.mediumGrey : CropConfig(theme)[cropId].maxScriptColor,
			CropColor: cropId === unknownId ? theme.colors.mediumGrey : CropConfig(theme)[cropId].maxScriptColor,
			Name: fieldName,
			Id: fieldId,
			CropId: cropId,
			TargetYield: foundField.TargetYield ? Math.round(foundField.TargetYield) : cropId === unknownId ? 0 : CropConfig()[cropId].minYield,
			PredictedYield: predictedYield,
			Inventory: editableFieldInventory,
		};

		return fieldDetails;
	};

	const openManageFields = useAmplitudePassthrough(session, EventNavigationNames.To, () =>
	{
		setCurrentViewState({view: MaxScriptViewState.ManageFields});
	},[], {
		[EventStructureTags.DestinationName]: 'manage_fields'
	});

	// Select the given cropPlanId and open the Product Inventory view, where cropPlanId is actually the CropId
	const openProductInventory = useAmplitudePassthrough(session, EventMaxScriptNames.SelectCrop, (cropPlanId: string) =>
	{
		setSelectedCropId(cropPlanId);
		setCurrentViewState({view: MaxScriptViewState.ProductInventory});
	},[], {
		[EventStructureTags.DestinationName]: 'product_inventory'
	});

	const returnToCropPlanList = useAmplitudePassthrough(session, EventNavigationNames.Back, () =>
	{
		// Clear any currently selected crop data
		setSelectedCropId(undefined);
		setSelectedFieldId(undefined);
		setEnableFieldInventoryView(false);
		setCurrentViewState({view: MaxScriptViewState.CropPlanOverview});
	},[], {
		[EventStructureTags.DestinationName]: 'cropplan_list'
	});

	// Determine what view state to display
	const determineView = () =>
	{
		switch(currentViewState.view)
		{
			case MaxScriptViewState.CropPlanOverview :
				return (
					/* There is a timing issue with regards to saving to the state as the page initializes, check to see if the state values have values,
					   otherwise get the values straight from the functions' returns */
					<MaxScriptCropPlanOverview
						cropPlanData={maxScriptOrderData}
						selectCropPlan={openProductInventory}
						openManageFields={openManageFields}
						openOverageModal={openOverageModal}
						importOrder={importOrder}
						showImportError={showImportError}
					/>
				);
			case MaxScriptViewState.ManageFields :
				return (
					<ManageFieldsView
						farmAndFields={selectedGrower.Farms}
						returnToCropPlanList={returnToCropPlanList}
						bulkUpdateCropForFields={bulkUpdateFieldCrop}
						fieldYear={CurrentCropYear}
						growerId={selectedGrower.Id}
					/>
				);
			case MaxScriptViewState.ProductInventory :
				return (
					<MaxScriptProductInventory
						competitorBrandsAndProducts={combinedCompetitors}
						cropAcreageCounts={maxScriptOrderData[selectedCropId].CropPlanDetails}
						cropFillColor={selectedCropId ? CropConfig(theme)[selectedCropId].maxScriptColor : theme.colors.secondary}
						saveCompetitors={saveCompetitors}
						selectedCropFieldInventory={maxScriptOrderData[selectedCropId].CropPlanFields}
						selectedCropId={selectedCropId}
						selectedCropName={CropConfig()[selectedCropId].cropName}
						selectedCropProductInventory={maxScriptOrderData[selectedCropId].CropPlanProducts}
					/>
				);
		}
	};

	// Navigate to the recall page
	const returnToRecallPage = useAmplitudePassthrough(session, EventNavigationNames.Back, () =>
	{
		// Do nothing -- this is just here to fire the amplitude tracking
	}, [], {
		[EventStructureTags.DestinationUrl]: '/fieldactivities/recall',
		[EventStructureTags.DestinationName]: 'recall'
	});

	// Determine the crumb trail based on the view
	const determineCrumbTrail = () =>
	{
		switch(currentViewState.view)
		{
			case MaxScriptViewState.CropPlanOverview :
				return (
					<CrumbContainer className='MaxScript_CrumbtrailContainer'>
						<Link to='/fieldactivities/recall' className='linkTo_PlanList' onClick={returnToRecallPage}>
							<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
							<span style={{ color: theme.colors.darkGrey }}>{selectedGrower.Name}</span>
						</Link>
						<div>
							<Ellipse style={{ margin: '2px 10px' }}/>
							<span
								style={{ fontWeight: theme.fontWeights.bold, width: '60%' }}
							>
								{IsGhx ? 'MaxScript' : 'Order Plan'}
							</span>
						</div>
					</CrumbContainer>
				);
			case MaxScriptViewState.ManageFields :
				return (
					<CrumbContainer className='MaxScript_CrumbtrailContainer'>
						<div onClick={returnToCropPlanList} className='linkTo_CropPlanOverview' style={{ cursor: 'pointer' }}>
							<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
							<span style={{ color: theme.colors.darkGrey }}>{selectedGrower.Name}</span>
						</div>
						<div>
							<Ellipse style={{ margin: '2px 10px' }}/>
							<span
								style={{ fontWeight: theme.fontWeights.bold, width: '60%' }}
							>
								Manage Fields
							</span>
						</div>
					</CrumbContainer>
				);
			case MaxScriptViewState.ProductInventory :
				return (
					<CrumbContainer className='MaxScript_CrumbtrailContainer'>
						<div onClick={returnToCropPlanList} className='linkTo_CropPlanOverview' style={{ cursor: 'pointer' }}>
							<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
							<span style={{ color: theme.colors.darkGrey }}>{selectedGrower.Name}</span>
						</div>
						<div>
							<Ellipse style={{ margin: '2px 10px' }}/>
							<span
								style={{ fontWeight: theme.fontWeights.bold, width: '60%' }}
							>
								Products
							</span>
						</div>
					</CrumbContainer>
				);
		}
	};

	const selectField = (fieldId: string) =>
	{
		if (currentViewState.view !== MaxScriptViewState.ProductInventory)
		{
			return;
		}

		const fieldIds = maxScriptOrderData[selectedCropId].CropPlanFields.sort(dynamicSort('Name')).map(f => f.Id);
		const currentFieldIndex = fieldIds.findIndex(f => f === fieldId);

		setFieldArrayPosition(`Field ${currentFieldIndex + 1} out of ${fieldIds.length}`);

		setSelectedFieldId(fieldId);

		// Tell the Field Inventory Panel to display
		setEnableFieldInventoryView(true);
	};

	const selectFieldInventorySeed = useAmplitudePassthrough(session, EventMaxScriptNames.AddOrRemoveHybridToField, (hybridId: string, checked: boolean) =>
	{
		let changes: boolean = false;
		const updatedFields = maxScriptOrderData[selectedCropId].CropPlanFields.map(f =>
		{
			if (f.Id === selectedFieldId)
			{
				const updatedInventory = f.Inventory.map(item =>
				{
					if (item.HybridId === hybridId)
					{
						changes = !checked && item.Acres > 0 ? true : false;
						return {...item, Acres: 0, Selected: checked};
					}
					
					return item;
				});
				const newHighestHybrid = updatedInventory.reduce((prev, current) => (prev.Acres > current.Acres) ? prev : current);
				return {...f, HybridColor: newHighestHybrid.Acres !== 0 ? newHighestHybrid.Color : f.CropColor, Inventory: updatedInventory};
			}

			return f;
		});

		maxScriptOrderData[selectedCropId].CropPlanFields = [...updatedFields];
		setMaxScriptOrderData({...maxScriptOrderData});

		if (changes)
		{
			updateFieldSeedAssignments(maxScriptOrderData[selectedCropId].CropPlanFields.find(f => f.Id === selectedFieldId)); // Save the assignments because one has been removed
		}
	},[maxScriptOrderData, selectedCropId, selectedFieldId]);

	const onNextField = useAmplitudePassthrough(session, EventMaxScriptNames.NextField, () =>
	{
		const fieldIds = maxScriptOrderData[selectedCropId].CropPlanFields.sort(dynamicSort('Name')).map(f => f.Id);

		if (fieldIds.length > 0)
		{
			const currentFieldIndex = fieldIds.findIndex(f => f === selectedFieldId);
			if ((currentFieldIndex + 1) < fieldIds.length)
			{
				selectField(fieldIds[currentFieldIndex + 1]);
			}
			else
			{
				selectField(fieldIds[0]);
			}
		}
	},[maxScriptOrderData, selectedCropId, selectedFieldId]);

	const onPreviousField = useAmplitudePassthrough(session, EventMaxScriptNames.PreviousField, () =>
	{
		const fieldIds = maxScriptOrderData[selectedCropId].CropPlanFields.sort(dynamicSort('Name')).map(f => f.Id);

		if (fieldIds.length > 0)
		{
			const currentFieldIndex = fieldIds.findIndex(f => f === selectedFieldId);
			if ((currentFieldIndex - 1) >= 0)
			{
				selectField(fieldIds[currentFieldIndex - 1]);
			}
			else
			{
				selectField(fieldIds[fieldIds.length - 1]);
			}
		}
	},[maxScriptOrderData, selectedCropId, selectedFieldId]);

	const editTargetYield = useAmplitudePassthrough(session, EventMaxScriptNames.ChangeFieldTargetYield, (value: number) =>
	{
		if (!maxScriptOrderData[selectedCropId].CropPlanFields)
		{
			return;
		}

		// Don't allow negative values
		if (value < 0)
		{
			value = Math.abs(value);
		}

		// Make sure the value is between the min/max yields for the crop
		if (value > CropConfig()[selectedCropId].maxYield)
		{
			value = CropConfig()[selectedCropId].maxYield;
		}
		if (value < CropConfig()[selectedCropId].minYield)
		{
			value = CropConfig()[selectedCropId].minYield;
		}

		let changes: boolean = false;
		const updatedFields = maxScriptOrderData[selectedCropId].CropPlanFields.map(f =>
		{
			if (f.Id === selectedFieldId)
			{
				if (f.Inventory)
				{
					// Update the current field inventory to the rates that match the new Target Yield
					const updatedInventory = f.Inventory.map(item =>
					{
						if (!item.IsExternal)
						{
							const seedingRateByYield =  getSeedingRateForSeriesAndRates(SeedingRates, item.SeriesName, value) ?? 
								CropConfig()[f.CropId].defaultSeedingRate;	
							changes = true;
							// Return the item plus it's updated rate value
							return {...item, Rate: seedingRateByYield ?? item.Rate};
						}
						else
						{
							return item;
						}
					});
					// Return the field plus it's updated inventory and updated Target Yield
					return {...f, TargetYield: value, Inventory: updatedInventory};
				}
				else
				{
					// No inventory items to update, so just update the field's Target Yield
					return {...f, TargetYield: value};
				}
			}

			// Nothing to update so just return the field
			return f;
		});

		maxScriptOrderData[selectedCropId].CropPlanFields = [...updatedFields];
		setMaxScriptOrderData({...maxScriptOrderData});

		const selectedField = maxScriptOrderData[selectedCropId].CropPlanFields.find(f => f.Id === selectedFieldId);
		// Call to the server to do an automatic save
		updateFieldTargetYield(selectedField);

		// Call to the server to do an automatic save for any updated rates
		if (changes)
		{
			updateFieldSeedAssignments(selectedField);
		}
	},[maxScriptOrderData, selectedCropId, SeedingRates, selectedFieldId]);

	const updateFieldHybridAcres = useAmplitudePassthrough(session, EventMaxScriptNames.ChangeHybridAcresOnField, (hybridId: string, value: number) =>
	{
		if (!maxScriptOrderData[selectedCropId].CropPlanFields)
		{
			return;
		}
		let changes: boolean = false;
		const updatedFields = maxScriptOrderData[selectedCropId].CropPlanFields.map(f =>
		{
			if (f.Id === selectedFieldId)
			{
				const updatedInventory = f.Inventory.map(item =>
				{
					if (item.HybridId === hybridId)
					{
						// No changes
						if (item.Acres === value)
						{
							return item;
						}
						else
						{
							changes = true;

							// Update the acre value
							return {...item, Acres: value};
						}
					}
					
					return item;
				});
				const newHighestHybrid = updatedInventory.reduce((prev, current) => (prev.Acres > current.Acres) ? prev : current);
				return {...f, HybridColor:newHighestHybrid.Color, Inventory: updatedInventory};
			}

			return f;
		});

		maxScriptOrderData[selectedCropId].CropPlanFields = [...updatedFields];
		setMaxScriptOrderData({...maxScriptOrderData});

		// Call to the server to do an automatic save
		if (changes)
		{
			updateFieldSeedAssignments(maxScriptOrderData[selectedCropId].CropPlanFields.find(f => f.Id === selectedFieldId));
		}
	},[maxScriptOrderData, selectedCropId, selectedFieldId]);

	const updateFieldHybridRate = useAmplitudePassthrough(session, EventMaxScriptNames.ChangeHybridRateOnField, (hybridId: string, value: number) =>
	{
		if (!maxScriptOrderData[selectedCropId].CropPlanFields)
		{
			return;
		}

		let changes: boolean = false;
		const updatedFields = maxScriptOrderData[selectedCropId].CropPlanFields.map(f =>
		{
			if (f.Id === selectedFieldId)
			{
				const updatedInventory = f.Inventory.map(item =>
				{
					if (item.HybridId === hybridId)
					{
						// No changes
						if (item.Rate === value)
						{
							return item;
						}
						else
						{
							changes = true;
							// Update the acre value
							return {...item, Rate: value};
						}
					}
					
					return item;
				});
				return {...f, Inventory: updatedInventory};
			}

			return f;
		});

		maxScriptOrderData[selectedCropId].CropPlanFields = [...updatedFields];
		setMaxScriptOrderData({...maxScriptOrderData});

		// Call to the server to do an automatic save
		if (changes)
		{
			updateFieldSeedAssignments(maxScriptOrderData[selectedCropId].CropPlanFields.find(f => f.Id === selectedFieldId));
		}
	},[maxScriptOrderData, selectedCropId, selectedFieldId]);

	const innerUpdateFieldSeedAssignments = useCallback(async (selectedField: IFieldInventoryDetails) =>
	{
		// This is so that this callback will recreate when the selected field changes
		// if(!selectedFieldId)
		// {
		// 	return;
		// }

		const request: IUpdateSeedAssignmentRequest = 
		{
			FieldId: selectedField.Id,
			FieldYear: CurrentCropYear,
			Hybrids: [],
			Type: AssignmentType.Prescribed,
			SourceType: AssignmentSource.Manual,
			SourceName: 'MaxScript',
		};
		
		selectedField.Inventory.forEach(inventoryItem =>
		{
			if (inventoryItem.Acres > 0)
			{
				if (inventoryItem.IsExternal)
				{
					const hybridItem: IUpdateHybrid =
					{
						IsSyngenta: false,
						IsExternal: inventoryItem.IsExternal,
						Rate: inventoryItem.Rate,
						AppliedAcres: inventoryItem.Acres,
						Brand: inventoryItem.HybridBrand,
						CropId: inventoryItem.CropId,
					};
					if (inventoryItem.HybridName)
					{
						hybridItem.Name = inventoryItem.HybridName;
					}
					if (inventoryItem.RelativeMaturity)
					{
						hybridItem.Rm = inventoryItem.RelativeMaturity;
					}

					request.Hybrids.push(hybridItem);
				}
				else
				{
					const hybridItem: IUpdateHybrid =
					{
						Id: inventoryItem.HybridId,
						IsSyngenta: true,
						IsExternal: inventoryItem.IsExternal,
						Rate: inventoryItem.Rate,
						AppliedAcres: inventoryItem.Acres,
					};
					request.Hybrids.push(hybridItem);
				}	
			}
		});

		await UpdateSeedAssignmentsForField(request);
	}, [selectedFieldId, CurrentCropYear, UpdateSeedAssignmentsForField]);
	/**
	 * Debounced callback - Update the field seed assignments
	 */
	const updateFieldSeedAssignments = useAsyncDebounce(innerUpdateFieldSeedAssignments);

	const innerUpdateFieldTargetYield = useCallback(async (selectedField: IFieldInventoryDetails) => 
	{
		const request: IUpdateTargetYieldRequest = 
		{
			FieldId: selectedField.Id,
			FieldYear: CurrentCropYear,
			Type: AssignmentType.Prescribed,
			SourceType: AssignmentSource.Manual,
			SourceName: 'MaxScript',
			TargetYield: selectedField.TargetYield,
		};

		await UpdateTargetYieldForField(request);
	}, [CurrentCropYear, UpdateTargetYieldForField, selectedFieldId]);

	/**
	 * Debounced callback - Update the field seed assignments
	 */
	const updateFieldTargetYield = useAsyncDebounce(innerUpdateFieldTargetYield);

	/**
	 * Attempt to import an order that may have failed to create a MaxScript
	 */
	const importOrder = useAmplitudePassthrough(session, EventMaxScriptNames.ImportOrder, () =>
	{
		setMaxScriptPlanRetrieved(false); // try to make maxscript get pulled down again
		ImportOrder({ orderId: currentOrder.Name, year: CurrentCropYear });
	},[currentOrder, CurrentCropYear]);

	const bulkUpdateFieldCrop = useAmplitudePassthrough(session, EventMaxScriptNames.EditFields, (request: IUpdateFieldCropRequest) =>
	{
		setMaxScriptPlanRetrieved(false); // try to make maxscript get pulled down again
		BulkUpdateCropForFields(request);
	},[]);

	const openOverageModal = (cropId: string) =>
	{
		setShowOverageModal(true);
		setOverModalCropId(cropId);
	};

	const closeOverageModal = () =>
	{
		setShowOverageModal(false);
		setOverModalCropId(undefined);
	};

	// Add new competitors to Product Inventory and to Field Inventories as unselected
	const addNewCompetitors = useAmplitudePassthrough(session, EventMaxScriptNames.AddCompetitor, (newCompetitors: ICompetitorSeedItem[], cropId: string) =>
	{
		const currentOrderData = maxScriptOrderData[cropId];

		newCompetitors.forEach(newCompetitor =>
		{
			const fakeExternalHybridId = newCompetitor.HybridId ? newCompetitor.HybridId :
				`${newCompetitor.Brand}_${newCompetitor.Product ? newCompetitor.Product : null}_${newCompetitor.RM ? newCompetitor.RM : null}`;
			
			AddCompetitorBrand(newCompetitor.Brand);
			if (newCompetitor.Product)
			{
				AddCompetitorProductToBrand({ brand: newCompetitor.Brand, product: newCompetitor.Product });
			}

			if (currentOrderData.CropPlanProducts.findIndex(cp => cp.HybridId === fakeExternalHybridId) === -1)
			{
				currentOrderData.CropPlanProducts.push(
					{
						Acres: 0,
						AttributeRatings: null,
						Bags: 0,
						BrandName: newCompetitor.Brand,
						Color: '', // Set colors below
						Created: new Date(),
						CropId: cropId,
						HybridId: fakeExternalHybridId,
						HybridName: newCompetitor.Product ? newCompetitor.Product : null,
						IsExternal: true,
						Modified: new Date(),
						Rm: newCompetitor.RM ? newCompetitor.RM.toString() : null,
						SeriesId: undefined,
						SeriesName: undefined,
						TraitId: undefined,
						TraitName: undefined,
						VarietyId: undefined
					});
				currentOrderData.CropPlanFields.forEach(field =>
				{
					if (!field.Inventory)
					{
						field.Inventory = [];
					}

					field.Inventory.push(
						{
							Acres: 0,
							AttributeRatings: null,
							Color: '', // Set colors below
							CropId: cropId,
							HybridBrand: newCompetitor.Brand,
							HybridId: fakeExternalHybridId,
							HybridName: newCompetitor.Product ? newCompetitor.Product : null,
							IsExternal: true,
							MatchStrength: 0,
							MaxPopulationRate: CropConfig()[cropId].maxSeedingRate,
							MinPopulationRate: CropConfig()[cropId].minSeedingRate,
							MaxFieldAcreage: field.Acres, // Total field area possible
							Rate: 0,
							RelativeMaturity: newCompetitor.RM,
							Selected: false,
							SeriesId: undefined,
							SeriesName: undefined,
						});
				});
			}
			else
			{
				// In this case we have an edit - Edit the existing Product Inventory and Update any Field items
				const newId = `${newCompetitor.Brand}_${newCompetitor.Product ? newCompetitor.Product : null}_${newCompetitor.RM ? newCompetitor.RM : null}`;
				const indexToUpdate = currentOrderData.CropPlanProducts.findIndex(cp => cp.HybridId === fakeExternalHybridId);
				currentOrderData.CropPlanProducts[indexToUpdate].BrandName = newCompetitor.Brand;
				currentOrderData.CropPlanProducts[indexToUpdate].HybridName = newCompetitor.Product ? newCompetitor.Product : null;
				currentOrderData.CropPlanProducts[indexToUpdate].Rm = newCompetitor.RM ? newCompetitor.RM.toString() : null;

				// Update the Hybrid Id
				currentOrderData.CropPlanProducts[indexToUpdate].HybridId = newId;

				currentOrderData.CropPlanFields.forEach(field =>
				{
					if (!field.Inventory)
					{
						field.Inventory = [];
					}
					
					const itemIndex = field.Inventory.findIndex(item => item.HybridId === fakeExternalHybridId);
					if (itemIndex !== -1)
					{
						field.Inventory[itemIndex].HybridBrand = newCompetitor.Brand;
						field.Inventory[itemIndex].HybridName = newCompetitor.Product ? newCompetitor.Product : null;
						field.Inventory[itemIndex].RelativeMaturity = newCompetitor.RM ? newCompetitor.RM : null;
						field.Inventory[itemIndex].HybridId = newId;

						// Call the update assignment to change the name
						updateFieldSeedAssignments(maxScriptOrderData[cropId].CropPlanFields.find(f => f.Id === field.Id));
					}
				});
			}
		});

		// Re-set the colors for all externals
		let externalColorCounter = 0;
		currentOrderData.CropPlanProducts.filter(p => p.IsExternal).forEach(p => p.HybridId = `${p.BrandName}_${p.HybridName}_${p.Rm}`);
		currentOrderData.CropPlanProducts.filter(p => p.IsExternal).sort(dynamicSort('HybridId')).forEach(p =>
		{
			if (externalColorCounter === MaxscriptExternalProductColorsList.length)
			{
				externalColorCounter = 0;
			}
			if (externalColorCounter < MaxscriptExternalProductColorsList.length)
			{
				p.Color = MaxscriptExternalProductColorsList[externalColorCounter];
				// Find the competitors on the fields to set the new colors
				currentOrderData.CropPlanFields.forEach(f =>
				{
					f.Inventory.find(i => i.HybridId === p.HybridId).Color = MaxscriptExternalProductColorsList[externalColorCounter];
				});
				externalColorCounter++;
			}
		});

		setMaxScriptOrderData({...maxScriptOrderData});
	},[maxScriptOrderData]);

	// Delete competitor seeds
	const deleteCompetitors = useAmplitudePassthrough(session, EventMaxScriptNames.DeleteCompetitor, (deletedCompetitors: ICompetitorSeedItem[], cropId: string) =>
	{
		// Loop through the deleted competitors list and remove them from the product inventory and then from the fields' inventory
		// If any of the competitors were assigned to a field, updateFieldSeedAssignments needs to be called to remove them via the api
		const currentOrderData = maxScriptOrderData[cropId];
		const fieldIdsToUpdate: string[] = [];

		deletedCompetitors.forEach(deletedItem =>
		{
			// If there is not a hybrid ID on the deleted item, it shouldn't be in the product list
			if (deletedItem.HybridId)
			{
				// Remove from the Product Inventory
				const existingProductIndex = currentOrderData.CropPlanProducts.findIndex(p => p.HybridId === deletedItem.HybridId);
				if (existingProductIndex !== -1)
				{
					currentOrderData.CropPlanProducts.splice(existingProductIndex, 1);
				}

				// Remove from the Field Inventory
				currentOrderData.CropPlanFields.forEach(field =>
				{
					const existingfieldItemIndex = field.Inventory.findIndex(i => i.HybridId === deletedItem.HybridId);
					if (existingfieldItemIndex !== -1)
					{
						// If it was currently selected on the field, we need to call the api to remove it
						if (field.Inventory[existingfieldItemIndex].Selected)
						{
							fieldIdsToUpdate.push(field.Id);
						}
						field.Inventory.splice(existingfieldItemIndex, 1);
					}
				});
			}
		});

		setMaxScriptOrderData({...maxScriptOrderData});

		// Now call the api on the fields that had the product selected
		if (fieldIdsToUpdate.length > 0)
		{
			fieldIdsToUpdate.forEach(id =>
			{
				updateFieldSeedAssignments(maxScriptOrderData[cropId].CropPlanFields.find(f => f.Id === id));
			});
		}
	},[maxScriptOrderData]);

	const saveCompetitors = (newCompetitors: ICompetitorSeedItem[], deletedCompetitors: ICompetitorSeedItem[]) =>
	{
		addNewCompetitors(newCompetitors, selectedCropId);

		if (deletedCompetitors && deletedCompetitors.length > 0)
		{
			deleteCompetitors(deletedCompetitors, selectedCropId);
		}
	};

	return (
		<div style={{ height: '100%' }}>
			<TitleContainer className='MaxScript_TitleContainer' style={{ display: 'flex', width: 'calc(100% - 70px)', marginLeft: 70 }}>
				{currentOrder ? `${currentOrder.Name}` : `${selectedGrower.Name} Order`}
			</TitleContainer>
			<div style={{ width: 'calc(100% - 70px)', height: 'calc(100% - 46px)', display: 'flex', marginLeft: 70 }}>				
				<MaxScriptMainContainer className='MaxScript_MainContainer'>
					<MaxScriptInnerContainer className='MaxScript_InnerContainer'>
						{determineCrumbTrail()}
						{determineView()}
					</MaxScriptInnerContainer>
				</MaxScriptMainContainer>
				<MaxScriptMap
					maxScriptData={maxScriptOrderData}
					selectedGrower={selectedGrower}
					selectedCropId={selectedCropId ? selectedCropId : undefined}
					selectField={selectField}
					selectedFieldId={selectedFieldId}
				/>
				{
					selectedFieldId && <FieldInventoryView
						attributeList={maxScriptOrderData[selectedCropId].CropPlanAttributeMasterList}
						changeHybridSelection={selectFieldInventorySeed}
						close={() => setEnableFieldInventoryView(false)}
						displayView={enableFieldInventoryView}
						editTargetYield={editTargetYield}
						fieldDetails={maxScriptOrderData[selectedCropId].CropPlanFields.find(f => f.Id === selectedFieldId)}
						fieldNumberInfo={fieldArrayPosition}
						onNext={onNextField}
						onPrevious={onPreviousField}
						updateFieldHybridAcres={updateFieldHybridAcres}
						updateFieldHybridRate={updateFieldHybridRate}
					/>
				}
			</div>
			<OverageDetailsModal
				cropId={overageModalCropId}
				maxScriptOrderData={overageModalCropId ? maxScriptOrderData[overageModalCropId] : undefined}
				orderData={selectedGrower?.OrdersByYear ? selectedGrower?.OrdersByYear[CurrentCropYear] : undefined} // TODO: make this the current user selected crop year
				visible={showOverageModal}
				launchCropEdgeSso={LaunchCropEdgeSso}
				onCancel={closeOverageModal}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	ClientConfig: state.config,
	CompetitorBrandsAndProducts: state.ui.CompetitorBrandsAndProducts,
	CompetitorData: state.seeds.competitors,
	CurrentCropYear: state.crops.CropYear,
	IsGhx: hasEffectivePermission(state, UserPermission.CanSeePricing),
	IsLoadingMaxScriptPlan: state.grower.isLoadingOrderPlanMaxScript,
	IsLoadingSeedingRates: state.plantingRate.isLoadingPlantingRates,
	SeedingRates: state.plantingRate.rates,
	UISelectedPlanId: state.ui.SelectedPlanId,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		AddCompetitorBrand: makeDispatch(dispatch, addCompetitorBrand),
		AddCompetitorProductToBrand: makeDispatch(dispatch, addProductToCompetitorBrand),
		BulkUpdateCropForFields: makeDispatch(dispatch, updateCropForFields),
		GetCompetitors: makeDispatch(dispatch, getCompetitorData),
		GetGrowerOrders: makeDispatch(dispatch, getGrowerOrdersForYear),
		GetMaxScriptPlan: makeDispatch(dispatch, getOrderPlanProductsAndAssignments),
		GetPlantingPlanRates: makeDispatch(dispatch, getPlantingRates),
		ImportOrder: makeDispatch(dispatch, importOrderPlanData),
		LaunchCropEdgeSso: makeDispatch(dispatch, getCropEdgeSsoLaunchUrl),
		SetSelectedPlanId: makeDispatch(dispatch, setSelectedPlanId),
		UpdateSeedAssignmentsForField: makeDispatch(dispatch, updateSeedAssignmentsForField),
		UpdateTargetYieldForField: makeDispatch(dispatch, updateTargetYieldForField),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const MaxScriptMainComponent = connector(MaxScriptComponent);