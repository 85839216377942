
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { themeGet } from '@styled-system/theme-get';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { dynamicSort, makeDispatch } from '../../../logic/Utility/Utils';
import { ReactComponent as Ellipse } from '../../../assets/images/Ellipse.svg';
import { ReactComponent as LeftCaret } from '../DashboardTable/Icons/LeftCaret.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/AvailabilityIcons/Info.svg';
import { ReactComponent as RedExclamation } from '../../../assets/images/Conflict.svg';
import { StyledCaret } from '../../../components/Table/TableRow';
import { clearSelectedEnogenContractId, clearSelectedGrowerIdState, displayEnogenFlow, currentEnogenFlowUrl, hideEnogenFlow } from '../../../logic/store/UI/UISlice';
import { Button } from '../../../components/Button/Button';
import { DatePicker, Divider, InputNumber, Tooltip } from 'antd';
import { StyledCollapse, StyledPanel } from '../../../components/StyledCollapse/StyledCollapse';
import {
	getContractStewardshipData,
	ISaveStewardshipDataRequest,
	ISaveStewardshipHarvestRequest,
	ISaveStewardshipPlantingRequest,
	saveContractStewardshipData,
	getStewardshipCompletionDates,
	updateContractStewardshipReplant,
	updateContractStewardshipFieldLoss,
	IStewardshipReplantOrLostRequest,
	IStewardshipReplantOrLostFieldRequest,
} from '../../../logic/store/Grower/EnogenContractThunk';
import { authSelector, getCurrentActingUser } from '../../../logic/store/User/AuthSlice';
import { IFrameModal } from '../../../components/IFrameModal/IFrameModal';
import { IStewardshipField } from '../../../logic/Models/Responses/EnogenContractResponse';
import _ from 'lodash';
import { PlantingFieldRow } from './PlantingFieldRow';
import { RoundAcres, RoundWithPrecision } from '../../../logic/Utility/CalculationUtilities';
import { cornId, CropConfig } from '../../../logic/store/Seeds/CropsSlice';
import moment from 'moment';
import { HarvestFieldRow } from './HarvestFieldRow';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { useAsyncDebounce } from '../../../logic/Utility/useAsyncDebounce';
import { ReplantLossModal, ReplantOrLoss } from './ReplantLossModal';
import { updateStewardshipFields } from '../../../logic/store/Grower/GrowerSlice';

const StewardshipMainContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const BackToGrowerListContainer = styled.div`
	display: flex;
	height: 52px;
	padding: 10px 25px;
	align-items: center;
`;

const GrowersListHeaderTitle = styled.span`
	color: ${themeGet('colors.lightGrey')};
	font-family: ${themeGet('fonts.heading')};
`;

const GrowerNameHeaderTitle = styled.span`
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.large')};
	font-family: ${themeGet('fonts.heading')};
	width: 60%;
`;

const TitleMainContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const PageTitle = styled.div`
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.xLarge')};
	font-family: ${themeGet('fonts.heading')};
`;

const TitleButtonContainer = styled.div`
	margin-left: auto;
`;

const StewardshipInnerContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	padding-right: 1%;
	padding-left: 1%;
	flex-direction: column;
	overflow: hidden;
`;

export const StyledDivider = styled(Divider)`
	&.ant-divider {
		border-top-color: black;
	}
`;

const NumberInput = styled(InputNumber)`
	width: 60px;
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: -2px;
	border-radius: 4px;
	border: thin solid ${themeGet('colors.lightGrey')};
	&:focus {
		outline: none;
		box-shadow: 0 0 1pt 1pt white;
	}
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: left;
		height: auto;
		padding-right: 3px;
	}
`;

export const StyledDatePicker = styled(DatePicker)`
	border-radius: 4px;
	width: 88%;
	padding: 2px 5px 2px;
	&:focus {
		outline: none;
		box-shadow: 0 0 1pt 1pt white;
	}
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-picker-focused {
		border-color: black;
	}
`;

export const StyledDatePickerPanel = styled.div`
	.ant-picker-today-btn {
		color: ${themeGet('colors.primary')};
	}
	.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
		border-width: 1px;
		border-style: solid;
		border-color: ${themeGet('colors.secondary')};
	}
	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
		background: ${themeGet('colors.primary')};
	}
`;

const round1 = (value: number) => RoundWithPrecision(value, 1, 1);

/** Returns a function that can compute the sum of a property on IStewardShipField. */
const sumStewardshipFieldBy = 
	(by: (field:IStewardshipField) => string) => 
		(activityFields: IStewardshipField[]) => 
			activityFields?.map(field => Number.parseFloat(by(field) ?? '0'))
				.reduce((acc, cur) => acc + cur, 0);

const sumStewardshipFieldByTotalAcres = sumStewardshipFieldBy(field => field.Activity.Harvest.TotalAcres);
const sumStewardshipFieldByPlantedAcres = sumStewardshipFieldBy(field => field.Activity.Planting.TotalPlantedAcres);
const sumStewardshipFieldByTotalHarvest = sumStewardshipFieldBy(field => field.Activity.Harvest.TotalHarvest);

const mapStateToProps = (state: RootState) => 
{
	const SelectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId);
	const SelectedContract = state.grower.EnogenContracts.find(c => c.ContractId === state.ui.SelectedEnogenContractId);
	return {
		AuthenticationToken: authSelector(state)?.userAuthToken,
		CurrentActingUser: getCurrentActingUser(state),
		EnogenFlowUrl: currentEnogenFlowUrl(state),
		EnogenRootUrl: state.config.MapCentricUrl,
		Growers: state.grower.Growers,
		SelectedContract,
		SelectedContractId: state.ui.SelectedEnogenContractId,
		SelectedGrowerId: state.ui.SelectedGrowerId,
		SelectedGrower,
		UserId: state.userInfo.UserId,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ClearSelectedGrowerId: makeDispatch(dispatch, clearSelectedGrowerIdState),
		ClearSelectedEnogenContractId: makeDispatch(dispatch, clearSelectedEnogenContractId),
		DisplayEnogenFlow: makeDispatch(dispatch, displayEnogenFlow),
		GetStewardshipCompletionDates: makeDispatch(dispatch, getStewardshipCompletionDates),
		GetStewardshipData: makeDispatch(dispatch, getContractStewardshipData),
		HideEnogenFlowFrame: makeDispatch(dispatch, hideEnogenFlow),
		SavePlantingHarvestData: makeDispatch(dispatch, saveContractStewardshipData),
		SaveFieldLost: makeDispatch(dispatch, updateContractStewardshipFieldLoss),
		SaveReplant: makeDispatch(dispatch, updateContractStewardshipReplant),
		UpdateStewardshipFields: makeDispatch(dispatch, updateStewardshipFields),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface IStewardshipProps extends PropsFromRedux
{
}

const StewardshipMainComponent = (props: IStewardshipProps) =>
{
	const {
		AuthenticationToken,
		CurrentActingUser,
		EnogenFlowUrl,
		EnogenRootUrl,
		SelectedContract,
		SelectedGrower,
		UserId,
		ClearSelectedEnogenContractId,
		ClearSelectedGrowerId,
		DisplayEnogenFlow,
		GetStewardshipCompletionDates,
		GetStewardshipData,
		HideEnogenFlowFrame,
		SavePlantingHarvestData,
		SaveFieldLost,
		SaveReplant,
		UpdateStewardshipFields,
	} = props;

	const theme = useTheme();
	const dateFormat = 'MM/DD/YYYY'; // This is the format displayed on the UI
	const saveDateFormat = 'YYYY-MM-DD'; // This is the format expected by the Growmore API when the data is saved

	const [showReplantLossModal, setShowReplantLossModal] = useState<boolean>(false);
	const [selectedReplantLossType, setSelectedReplantLossType] = useState<ReplantOrLoss>(undefined);
	const [activityFields, setActivityFields] = useState<IStewardshipField[]>([]);
	const [openApplyForAllModal, setOpenApplyForAllModal] = useState<boolean>(false);

	/**
	 * Planting Apply All Values
	 */
	const [plantingAllEstBuAcres, setPlantingAllEstBuAcres] = useState<string>(undefined);
	const [plantingAllRate, setPlantingAllRate] = useState<string>(undefined);
	const [plantingAllDate, setPlantingAllDate] = useState<string>(undefined);

	/**
	 * Harvest Apply All Values
	 */
	const [harvestAllTotalHarvest, setHarvestAllTotalHarvest] = useState<string>(undefined);
	const [harvestAllYieldPerAcre, setHarvestAllYieldPerAcre] = useState<string>(undefined);
	const [harvestAllDate, setHarvestAllDate] = useState<string>(undefined);

	const plantingStatus = useMemo(() =>
		SelectedContract?.StewardshipActivities ? SelectedContract?.StewardshipActivities[0].PlantingStatus : SelectedContract.Planting
	, [SelectedContract, SelectedContract.StewardshipActivities]);
	const harvestStatus = useMemo(() =>
		SelectedContract?.StewardshipActivities ? SelectedContract?.StewardshipActivities[0].HarvestStatus : SelectedContract.Harvest
	, [SelectedContract, SelectedContract.StewardshipActivities]);

	const isPlantingComplete = plantingStatus.toLowerCase() === 'complete';
	const isHarvestComplete = harvestStatus.toLowerCase() === 'complete';

	// Planting should only be between 3/01 and 7/15
	const disabledPlantingDates = (currentDate: moment.Moment): boolean =>
	{
		const compareDate = moment(currentDate, dateFormat);
		// Dates can only be between March 1 and July 15th of the current year
		const marchDate = moment(`03/01/${moment().year()}`, dateFormat);
		const julyDate = moment(`07/15/${moment().year()}`, dateFormat);

		// the [] means both beginning and end dates are inclusive, () would mean exclusive
		// you can mix and match (] or [) as well.
		return !compareDate.isBetween(marchDate, julyDate, 'day', '[]');
	};

	// Harvest should only be between 3/01 and 12/31
	const disabledHarvestDates = (currentDate: moment.Moment): boolean =>
	{
		const compareDate = moment(currentDate, dateFormat);
		// Dates can only be between March 1 and July 15th of the current year
		const marchDate = moment(`03/01/${moment().year()}`, dateFormat);
		const julyDate = moment(`12/31/${moment().year()}`, dateFormat);
	
		// the [] means both beginning and end dates are inclusive, () would mean exclusive
		// you can mix and match (] or [) as well.
		return !compareDate.isBetween(marchDate, julyDate, 'day', '[]');
	};

	const cropConfig = CropConfig()[cornId];
	
	const calculatedPlantedAcres = useMemo(() => RoundAcres(sumStewardshipFieldByPlantedAcres(activityFields.filter(f => !f.FieldLost))),[activityFields]);
	const calculatedHarvestAcres = useMemo(() => RoundAcres(sumStewardshipFieldByTotalAcres(activityFields.filter(f => !f.FieldLost))),[activityFields]);

	// Initial on-load/refresh useEffect - We need to pull the contracts frequently and "live" because of the changes
	// that can be done to them in other environments (Growmore) or from the user's own changes here
	useEffect(() => 
	{
		GetStewardshipData();
		GetStewardshipCompletionDates();
	}, []);

	useEffect(() =>
	{
		if (SelectedContract?.StewardshipActivities && SelectedContract?.StewardshipActivities.length > 0)
		{
			let contractFields = _.cloneDeep(SelectedContract.StewardshipActivities[0].StewardshipActivity?.Fields);
			if (contractFields && contractFields.length > 0)
			{
				contractFields = contractFields.filter(f => f.FieldId.includes('-')); // filter out "field_id": "total_enogen_acres_id"
				contractFields = contractFields.sort(dynamicSort('FieldName')); // Sort by FieldName
				contractFields.forEach(f =>
				{
					// Default TotalPlantedAcres to field's total acres if TotalPlantedAcres is undefined/empty
					if (f.Activity.Planting && f.Activity.Planting?.TotalPlantedAcres === undefined)
					{
						f.Activity.Planting.TotalPlantedAcres = f.Acres;
					}
					else if (!f.Activity.Planting)
					{
						f.Activity.Planting =
						{
							Date: '',
							TotalPlantedAcres: f.Acres,
							PlantingRate: '',
							EstBuAcre: '',
						};
					}
					// Default TotalPlantedAcres to a field's harvest if the field is Planted and Harvested acres is undefined/empty
					if (f.Activity.Harvest && f.Activity.Planting && f.Activity.Harvest.TotalAcres === undefined || f.Activity.Harvest.TotalAcres === 'NaN')
					{
						f.Activity.Harvest.TotalAcres = f.Activity.Planting.TotalPlantedAcres !== undefined ? f.Activity.Planting.TotalPlantedAcres : f.Acres;
					}
					else if (!f.Activity.Harvest)
					{
						f.Activity.Harvest =
						{
							HarvestDate: '',
							TotalAcres: f.Activity.Planting && f.Activity.Planting.TotalPlantedAcres !== undefined ? f.Activity.Planting.TotalPlantedAcres : f.Acres,
							YieldPerAcre: '',
							TotalHarvest: '',
							UnitType: 'bushels'
						};
					}

					if (!f.Activity.Harvest?.UnitType)
					{
						f.Activity.Harvest.UnitType = 'bushels';
					}
				});
				setActivityFields([...contractFields]);
				setHarvestAllTotalHarvest(round1(sumStewardshipFieldByTotalHarvest(contractFields)).toString());
				setHarvestAllYieldPerAcre(round1(weightedYieldPerAcreAvg(contractFields)).toString());
			}
		}
	},[SelectedContract.StewardshipActivities]);

	const backToGrowerList = () =>
	{
		ClearSelectedGrowerId();
		ClearSelectedEnogenContractId();
	};

	const displayEnogenFlowOnClick = useCallback((assignFields?: boolean, viewDetails?: boolean) =>
	{
		DisplayEnogenFlow({
			accessToken: AuthenticationToken,
			apiBaseUrl: document.location.host,
			enogenFlowBaseUrl: EnogenRootUrl,
			selectedGrowerId: SelectedGrower.Id,
			userId: CurrentActingUser.UserId,
			contractId: SelectedContract.ContractId,
			viewDetails: viewDetails,
			assignFields: assignFields,
		});
	}, [AuthenticationToken, document.location.host, UserId, CurrentActingUser, SelectedGrower, SelectedContract, DisplayEnogenFlow]);

	const onOpenApplyForAllModal = () =>
	{
		setOpenApplyForAllModal(true);
	};

	/**
	 * Debounced Auto-save
	 */
	const innerAutoSave = useCallback(async (type: 'planting' | 'harvest', updatedFields: IStewardshipField[]) => 
	{
		if(!updatedFields || updatedFields.length === 0 || !SelectedContract.StewardshipActivityId)
		{
			return;
		}
	
		// The values for Type, SourceId, SourceType and SourceName cannot be changed here, or the Crop Plan will not register
		// that the assignment belongs to it.
		const request: ISaveStewardshipDataRequest = 
		{
			Type: type,
			StewardshipActivityId: SelectedContract.StewardshipActivityId,
		};

		if (type === 'planting')
		{
			request.Planting = [];

			updatedFields.forEach(updatedField =>
			{
				const plantingFieldData: ISaveStewardshipPlantingRequest = 
				{
					FieldId: updatedField.FieldId,
					Date: updatedField.Activity.Planting.Date ? moment(updatedField.Activity.Planting.Date).format(saveDateFormat) : '',
					TotalPlantedAcres: updatedField.Activity.Planting.TotalPlantedAcres ?? '',
					PlantingRate: updatedField.Activity.Planting.PlantingRate ?? '',
					EstBuAcre: updatedField.Activity.Planting.EstBuAcre?? '',
				};
				request.Planting.push(plantingFieldData);
			});
		}
		else if (type === 'harvest')
		{
			request.Harvest = [];

			updatedFields.forEach(updatedField =>
			{
				const harvestFieldData: ISaveStewardshipHarvestRequest = 
				{
					FieldId: updatedField.FieldId,
					TotalHarvest: updatedField.Activity.Harvest.TotalHarvest ?? '',
					TotalAcres: updatedField.Activity.Harvest.TotalAcres ?? '',
					YieldPerAcre: updatedField.Activity.Harvest.YieldPerAcre ?? '',
					UnitType: updatedField.Activity.Harvest.UnitType ?? '',
					HarvestDate: updatedField.Activity.Harvest.HarvestDate ? moment(updatedField.Activity.Harvest.HarvestDate).format(saveDateFormat) : '',
				};
				request.Harvest.push(harvestFieldData);
			});
		}
	
		// Update the Stewardship in the store
		await UpdateStewardshipFields({contractId: SelectedContract.ContractId, fields: updatedFields});
		await SavePlantingHarvestData(request);
	}, [SavePlantingHarvestData, SelectedContract]);
	
	const autoSavePlantingHarvestData = useAsyncDebounce(innerAutoSave);

	/**
	 * Editing Functions for Planting
	 */
	const plantingEditAcres = (fieldId: string, updatedValue: Number) =>
	{
		let changes: boolean = false;

		const updatedFields = activityFields.map(field =>
		{
			if (field.FieldId === fieldId && updatedValue >= 0)
			{
				// Only update if the value has not changed
				if (field.Activity.Planting.TotalPlantedAcres !== updatedValue.toString())
				{
					field.Activity.Planting.TotalPlantedAcres = updatedValue.toString();
					changes = true;
					return field;
				}
			}
			return field;
		});

		if (changes)
		{
			autoSavePlantingHarvestData('planting', updatedFields);
			setActivityFields([...updatedFields]);
		}
	};

	const plantingEditRate = (fieldId: string, updatedValue: Number) =>
	{
		let changes: boolean = false;

		// Make sure the rate is within the rate boundaries
		if (updatedValue < cropConfig.minSeedingRate)
		{
			updatedValue = cropConfig.minSeedingRate;
		}
		if (updatedValue > cropConfig.maxSeedingRate)
		{
			updatedValue = cropConfig.maxSeedingRate;
		}

		const updatedFields = activityFields.map(field =>
		{
			if (fieldId === 'all' && updatedValue)
			{
				if (field.FieldLost)
				{
					return field;
				}
				else
				{
					// Apply value to all
					field.Activity.Planting.PlantingRate = updatedValue.toString();
					setPlantingAllRate(updatedValue.toString());
					changes = true;
					return field;
				}
			}
			else if (field.FieldId === fieldId && updatedValue >= 0)
			{
				// Only update if the value has not changed
				if (field.Activity.Planting.PlantingRate !== updatedValue.toString())
				{
					// If there is an all-apply value, clear it
					if (plantingAllRate)
					{
						setPlantingAllRate(undefined);
					}
					field.Activity.Planting.PlantingRate = updatedValue.toString();
					changes = true;
					return field;
				}
			}
			return field;
		});

		if (changes)
		{
			autoSavePlantingHarvestData('planting', updatedFields);
			setActivityFields([...updatedFields]);
		}
	};

	const plantingEditEstBuAcres = (fieldId: string, updatedValue: Number) =>
	{
		let changes: boolean = false;

		const updatedFields = activityFields.map(field =>
		{
			if (fieldId === 'all' && updatedValue)
			{
				if (field.FieldLost)
				{
					return field;
				}
				else
				{
					// Apply value to all
					field.Activity.Planting.EstBuAcre = updatedValue.toString();
					setPlantingAllEstBuAcres(updatedValue.toString());
					changes = true;
					return field;
				}
				
			}
			else if (field.FieldId === fieldId && updatedValue >= 0)
			{
				// Only update if the value has not changed
				if (field.Activity.Planting.EstBuAcre !== updatedValue.toString())
				{
					// If there is an all-apply value, clear it
					if (plantingAllEstBuAcres)
					{
						setPlantingAllEstBuAcres(undefined);
					}
					field.Activity.Planting.EstBuAcre = updatedValue.toString();
					changes = true;
					return field;
				}
			}
			return field;
		});
	
		if (changes)
		{
			autoSavePlantingHarvestData('planting', updatedFields);
			setActivityFields([...updatedFields]);
		}
	};

	const plantingEditDate = (fieldId: string, updatedValue: string) =>
	{
		let changes: boolean = false;

		const updatedFields = activityFields.map(field =>
		{
			if (fieldId === 'all')
			{
				if (field.FieldLost)
				{
					return field;
				}
				else
				{
					// Apply value to all
					field.Activity.Planting.Date = updatedValue;
					setPlantingAllDate(updatedValue);
					changes = true;
					return field;
				}
			}
			else if (field.FieldId === fieldId)
			{
				// Only update if the value has not changed
				if (field.Activity.Planting.Date !== updatedValue)
				{
					// If there is an all-apply value, clear it
					if (plantingAllDate)
					{
						setPlantingAllDate(undefined);
					}
					field.Activity.Planting.Date = updatedValue;
					changes = true;
					return field;
				}
			}
			return field;
		});
	
		if (changes)
		{
			autoSavePlantingHarvestData('planting', updatedFields);
			setActivityFields([...updatedFields]);
		}
	};

	/**
	 * Editing Functions for Harvest
	 */
	
	/** Average yield per acre values using the harvested acres of each field as a weight. */
	const weightedYieldPerAcreAvg = (fields: IStewardshipField[]) =>
	{
		const totalAcresHarvested = activityFields.filter(f => !f.FieldLost)
			.map(field => Number.parseFloat(field.Activity.Harvest.TotalAcres))
			.reduce((acc, cur) => acc + cur, 0);
		const weights = fields.filter(f => !f.FieldLost).map(field => Number.parseFloat(field.Activity.Harvest.TotalAcres) / totalAcresHarvested);
		const avg = fields.filter(f => !f.FieldLost).reduce((acc, field, i) => acc + Number.parseFloat(field.Activity.Harvest.YieldPerAcre) * weights[i], 0);
		return avg;
	};

	const harvestEditAcres = (fieldId: string, updatedValue: number) =>
	{
		let changes: boolean = false;
		if (!updatedValue || updatedValue < 0)
		{
			return;
		}

		const updatedFields = activityFields.map(field =>
		{
			const harvest = field.Activity.Harvest;
			if (field.FieldId === fieldId && !field.FieldLost)
			{
				// Only update if the value has not changed
				if (harvest.TotalAcres !== round1(updatedValue).toString())
				{
					harvest.TotalAcres = round1(updatedValue).toString();
					if (harvest.TotalHarvest)
					{
						harvest.YieldPerAcre = round1(Number.parseFloat(harvest.TotalHarvest) / updatedValue).toString();
					}
					changes = true;
					return field;
				}
			}
			return field;
		});
	
		if (changes)
		{
			setHarvestAllYieldPerAcre(round1(weightedYieldPerAcreAvg(updatedFields)).toString());
			setHarvestAllTotalHarvest(round1(sumStewardshipFieldByTotalHarvest(updatedFields)).toString());

			autoSavePlantingHarvestData('harvest', updatedFields);
			setActivityFields([...updatedFields]);
		}
	};

	const harvestEditPerAcre = (fieldId: string, updatedValue: number) =>
	{
		if (!updatedValue || updatedValue < 0)
		{
			return;
		}
		const notChanged = round1(updatedValue).toString() === (
			fieldId === 'all' ? harvestAllYieldPerAcre : activityFields.filter(f => !f.FieldLost).find(f => f.FieldId === fieldId).Activity.Harvest.YieldPerAcre
		);
		if (notChanged)
		{
			return;
		}

		const updatedFields = activityFields.map(field =>
		{
			// All fields must be returned but only some should be updated.
			const shouldUpdate = fieldId === 'all' || field.FieldId === fieldId;
			if (!shouldUpdate)
			{
				return field;
			}

			if (field.FieldLost)
			{
				return field;
			}

			const harvest = field.Activity.Harvest;

			harvest.YieldPerAcre = round1(updatedValue).toString();
			if (harvest.TotalAcres)
			{
				harvest.TotalHarvest = round1(Number.parseFloat(harvest.TotalAcres) * updatedValue).toString();
			}
			return field;
		});
	
		if (fieldId === 'all')
		{
			setHarvestAllYieldPerAcre(round1(updatedValue).toString());
		}
		else
		{
			setHarvestAllYieldPerAcre(round1(weightedYieldPerAcreAvg(updatedFields)).toString());
		}
		setHarvestAllTotalHarvest(round1(sumStewardshipFieldByTotalHarvest(updatedFields)).toString());
		autoSavePlantingHarvestData('harvest', updatedFields);
		setActivityFields([...updatedFields]);
	};

	const harvestEditTotalHarvest = (fieldId: string, updatedValue: number) =>
	{
		if (!updatedValue|| updatedValue < 0)
		{
			return;
		}
		const notChanged = round1(updatedValue).toString() === (
			fieldId === 'all' ? harvestAllTotalHarvest : activityFields.filter(f => !f.FieldLost).find(f => f.FieldId === fieldId).Activity.Harvest.TotalHarvest
		);
		if (notChanged)
		{
			return;
		}

		let changes = false;
		let updatedFields: IStewardshipField[];

		// Spread the 'all' value across all fields proportional to each field's harvested acres.
		if (fieldId === 'all') 
		{
			const totalAcresHarvested = activityFields.filter(f => !f.FieldLost)
				.map(field => Number.parseFloat(field.Activity.Harvest.TotalAcres))
				.reduce((acc, cur) => acc + cur, 0);
		
			// Save this so we can set the 'all' value for yield per acre. This will be the same for all fields.
			let anyYieldPerAcre:string = undefined;

			updatedFields = activityFields.map(field =>
			{
				if (field.FieldLost)
				{
					return field;
				}

				const harvest = field.Activity.Harvest;
				if (harvest.TotalAcres)
				{
					changes = true;

					const weight = Number.parseFloat(harvest.TotalAcres) / totalAcresHarvested;
					harvest.TotalHarvest = round1(updatedValue * weight).toString();
					harvest.YieldPerAcre = round1(Number.parseFloat(harvest.TotalHarvest) / Number.parseFloat(harvest.TotalAcres)).toString();
					anyYieldPerAcre = harvest.YieldPerAcre;
				}
				return field;
			});

			if (changes)
			{
				setHarvestAllTotalHarvest(round1(updatedValue).toString());
				setHarvestAllYieldPerAcre(anyYieldPerAcre);
			}
		}
		else
		{
			updatedFields = activityFields.map(field =>
			{
				if (field.FieldLost)
				{
					return field;
				}

				// All fields must be returned but only some should be updated.
				const harvest = field.Activity.Harvest;
				if (field.FieldId === fieldId)
				{
					changes = true;
					harvest.TotalHarvest = round1(updatedValue).toString();
					if (harvest.TotalHarvest)
					{
						harvest.YieldPerAcre = round1(updatedValue / Number.parseFloat(harvest.TotalAcres)).toString();
					}
				}
	
				return field;
			});

			if (changes)
			{
				setHarvestAllTotalHarvest(round1(sumStewardshipFieldByTotalHarvest(updatedFields)).toString());
				setHarvestAllYieldPerAcre(round1(weightedYieldPerAcreAvg(updatedFields)).toString());
			}
		}

		if (changes)
		{
			autoSavePlantingHarvestData('harvest', updatedFields);
			setActivityFields([...updatedFields]);
		}
	};

	const harvestEditDate = (fieldId: string, updatedValue: string) =>
	{
		let changes: boolean = false;

		const updatedFields = activityFields.map(field =>
		{
			if (fieldId === 'all')
			{
				if (field.FieldLost)
				{
					return field;
				}
				else
				{
					// Apply value to all
					field.Activity.Harvest.HarvestDate = updatedValue;
					setHarvestAllDate(updatedValue);
					changes = true;
					return field;
				}
			}
			else if (field.FieldId === fieldId)
			{
				// Only update if the value has not changed
				if (field.Activity.Harvest.HarvestDate !== updatedValue)
				{
					// If there is an all-apply value, clear it
					if (harvestAllDate)
					{
						setHarvestAllDate(undefined);
					}
					field.Activity.Harvest.HarvestDate = updatedValue;
					changes = true;
					return field;
				}
			}
			return field;
		});
	
		if (changes)
		{
			autoSavePlantingHarvestData('harvest', updatedFields);
			setActivityFields([...updatedFields]);
		}
	};

	const unitOptions: IOptionItem[] =
	[
		{
			label: 'bu',
			value: 'bushels'
		},
		{
			label: 'tons',
			value: 'tons'
		}
	];

	const harvestSelectUnit = (updatedValue: string) =>
	{
		let change = false;
		const updatedFields = activityFields.map(field =>
		{
			if (field.FieldLost)
			{
				return field;
			}

			const harvest = field.Activity.Harvest;
			if (harvest.UnitType !== updatedValue)
			{
				change = true;
				const oldUnit = harvest.UnitType;
				harvest.UnitType = updatedValue;

				const buToTons = (bu: string) => ((Number.parseFloat(bu) * 60) / 2000);
				const tonsToBu = (tons: string) => ((Number.parseFloat(tons) * 2000) / 60);

				let totalHarvest:number;
				const totalAcres: number = Number.parseFloat(harvest.TotalAcres);

				if (oldUnit === 'bushels')
				{
					totalHarvest = buToTons(harvest.TotalHarvest);
				}
				else if (oldUnit === 'tons')
				{
					totalHarvest = tonsToBu(harvest.TotalHarvest);
				}
				harvest.TotalHarvest = round1(totalHarvest).toString() !== 'NaN' ? round1(totalHarvest).toString() : '0';
				harvest.YieldPerAcre = round1(totalHarvest / totalAcres).toString() !== 'NaN' ? round1(totalHarvest / totalAcres).toString() : '0';
			}
			return field;
		});
			
		if(change)
		{
			setHarvestAllTotalHarvest(round1(sumStewardshipFieldByTotalHarvest(updatedFields)).toString());

			autoSavePlantingHarvestData('harvest', updatedFields);
			setActivityFields([...updatedFields]);
		}
	};

	const showReplantOrLossModal = (type: ReplantOrLoss) =>
	{
		setShowReplantLossModal(true);
		setSelectedReplantLossType(type);
	};

	const cancelReplantOrLossModal = () =>
	{
		setShowReplantLossModal(false);
		setSelectedReplantLossType(undefined);
	};

	const saveReplantOrLoss = (type: ReplantOrLoss, request: IStewardshipReplantOrLostRequest) =>
	{
		if (type === ReplantOrLoss.Replant) // Replant
		{
			cancelReplantOrLossModal(); // Close the modal
			SaveReplant(request);
		}
		else // Field Lost
		{
			cancelReplantOrLossModal(); // Close the modal
			SaveFieldLost(request);
		}
	};

	const getFieldsForReplantOrLoss = (): IStewardshipReplantOrLostFieldRequest[] =>
	{
		const fieldsForReplantOrLoss:IStewardshipReplantOrLostFieldRequest[] = [];
		
		// If a field is not already lost, add it to the list
		activityFields.forEach(f =>
		{
			if (!f.FieldLost)
			{
				fieldsForReplantOrLoss.push(
					{
						FieldName: f.FieldName,
						FieldId: f.FieldId,
						Acres: f.Activity.Planting.TotalPlantedAcres,
						TotalAcres: f.Acres,
					}
				);
			}
		});

		return fieldsForReplantOrLoss;
	};

	return (
		<StewardshipMainContainer className='StewardshipdMainContainer'>
			<BackToGrowerListContainer className='backTo_GrowerList_Container'>
				<Link to='/dashboard' className='linkTo_GrowerList' onClick={backToGrowerList}>
					<LeftCaret style={{ marginRight: '20px' }} />
					<GrowersListHeaderTitle>
						Growers List
					</GrowersListHeaderTitle>
				</Link>
				<Ellipse style={{ margin: '0 10px' }} />
				<GrowerNameHeaderTitle className='Stewardship_Title_GrowerName'>
					{SelectedGrower.Name}
				</GrowerNameHeaderTitle>
			</BackToGrowerListContainer>
			<StewardshipInnerContainer className='StewardshipInnerContainer'>
				<TitleMainContainer className='TitleMainContainer'>
					<PageTitle className='Stewardship_Contract_Title'>
						Contract: {SelectedContract.NewContractId}
					</PageTitle>
					<TitleButtonContainer className='TitleButtonContainer'>
						<Button variant='outlined' width={'120px'} style={{ marginRight: 10 }} onClick={() => displayEnogenFlowOnClick(false, true)}>Contract Details</Button>
						<Button variant='main' width={'120px'} onClick={() => displayEnogenFlowOnClick(true)} >Assign Fields</Button>
					</TitleButtonContainer>
				</TitleMainContainer>
				<StyledDivider className='Divider' />
				<div className='StewardshipEditingContainer' style={{ display: 'flex', flexDirection: 'row', height: '100%', overflow: 'hidden' }}>
					<div
						className='StewardshipCollapseContainer'
						style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden', overflowY: 'auto', height: '100%' }}
					>
						<StyledCollapse
							className='PlantingCollapseContainer'
							style={{
								boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
								marginBottom: 20,
								borderRadius: 6,
								borderWidth: 1,
								borderColor: theme.colors.lightestGrey,
								borderStyle: 'solid',
								overflowY: 'unset',
							}}
							defaultActiveKey={!isPlantingComplete ? 1 : 0}
							expandIcon={(props) => 
							{
								return <div><StyledCaret className='RowExpander' expanded={props.isActive ? 'true' : 'false'} /></div>;
							}}
							expandIconPosition='end'
						>
							<StyledPanel
								style={{ backgroundColor: theme.colors.white }}
								header={
									<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<div>Planting Stewardship</div>
											{
												!isPlantingComplete && <div style={{ fontSize: theme.fontSizes.extraSmall }}>
													{`Stewardship needs to be completed by ${moment(SelectedContract?.StewardshipCompletionDates?.PlantingDate).format('MMMM Do, YYYY')}`}
												</div>
											}
										</div>
										<div
											style={{
												backgroundColor: isPlantingComplete ? theme.colors.greenLM : theme.colors.redGA,
												borderRadius: 4,
												color: theme.colors.white,
												paddingRight: 8,
												paddingLeft: 8,
												marginLeft: 'auto',
												height: 22,
											}}>{SelectedContract.StewardshipActivities ? SelectedContract.StewardshipActivities[0].PlantingStatus : ''}</div>
									</div>
								}
								key='1'
							>
								<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
									<div
										style={{
											backgroundColor: 'black',
											color: 'white',
											fontWeight: theme.fontWeights.bold,
											display: 'flex',
											flexDirection: 'row',
											fontSize: 12,
											borderTopLeftRadius: 6,
											borderTopRightRadius: 6,
											paddingLeft: 10,
											paddingRight: 10,
											paddingTop: 5,
											paddingBottom: 5
										}}>
										<div style={{ width: '18%', textAlign: 'left' }}>Field</div>
										<div style={{ width: '20%', textAlign: 'left' }}>Planted Acres</div>
										<div style={{ width: '22%', textAlign: 'left' }}>Planting Date</div>						
										<div style={{ width: '20%', textAlign: 'left' }}>Planting Rate</div>
										<div style={{ width: '20%', textAlign: 'left' }}>Est. Bu/Acres</div>
									</div>
									<div style={{ height: '100%', overflowY: 'auto' }}>
										<div
											className={'PlantingSummaryRow'}
											key={'Planting_Summary_Row'}
											style={{
												display: 'flex',
												flexDirection: 'row',
												fontSize: 12,
												paddingLeft: 10,
												paddingRight: 10,
												height: 40,
												alignItems: 'center',
												backgroundColor: theme.colors.lightestGrey
											}}
										>
											<div style={{ width: '18%', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
												<div style={{ display: 'flex', alignItems: 'center'}}>
													Apply for all
													<InfoIcon
														style={{ position: 'relative', top: -1, left: 3, cursor: 'pointer' }}
														fill={theme.colors.darkGrey}
														onClick={onOpenApplyForAllModal}
													/>
												</div>
											</div>
											<div style={{ width: '20%', textAlign: 'left' }}>
												{`${calculatedPlantedAcres}/${SelectedContract.ContractedAcres} Ac.`}
											</div>
											<div style={{ width: '22%', display: 'flex', alignItems: 'center'}}>
												<StyledDatePicker
													className='Planting_ApplyAll_Date'
													placeholder={'mm/dd/yyyy'}
													format={dateFormat}
													disabledDate={disabledPlantingDates}
													value={plantingAllDate ? moment(plantingAllDate) : undefined}
													onChange={(value: moment.Moment) => plantingEditDate('all', value ? value.format(dateFormat) : '')}
													panelRender={originalPanel =>
													{
														return (
															<StyledDatePickerPanel className='StyledDatePickerPanel'>
																{originalPanel}
															</StyledDatePickerPanel>
														);
													}}
												/>
											</div>
											<div style={{ width: '20%', textAlign: 'left' }}>
												<NumberInput
													style={{ fontSize: 12, width: '90%', height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
													className={'Planting_ApplyAll_Rate_Input'}
													type='number'
													placeholder='Add'
													value={plantingAllRate}
													onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
														plantingEditRate('all', evt.currentTarget.value ? Number(evt.currentTarget.value) : plantingAllRate ? Number(plantingAllRate) : undefined )}
													onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
														plantingEditRate('all', evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
															: Number(plantingAllRate))}
												/>
											</div>
											<div style={{ width: '20%', textAlign: 'left' }}>
												<NumberInput
													style={{ fontSize: 12, width: '90%', height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
													className={'Planting_ApplyAll_EstBuAcres_Input'}
													type='number'
													placeholder='Add'
													value={plantingAllEstBuAcres}
													onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
														plantingEditEstBuAcres('all', evt.currentTarget.value ? Number(evt.currentTarget.value) : plantingAllEstBuAcres ? Number(plantingAllEstBuAcres) : undefined )}
													onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
														plantingEditEstBuAcres('all', evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
															: Number(plantingAllEstBuAcres))}
												/>
											</div>
										</div>
										{
											activityFields && activityFields.map((field, index) =>
												<PlantingFieldRow
													key={`Planting_Field_${field.FieldId}`}
													field={field}
													disabledDates={disabledPlantingDates}
													editAcres={plantingEditAcres}
													editDate={plantingEditDate}
													editEstBuAcres={plantingEditEstBuAcres}
													editRate={plantingEditRate}													
													lastItem={activityFields.length === index + 1}
												/>
											)
										}
									</div>
								</div>
								<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
									<Tooltip title={!isPlantingComplete ? 'Please complete Planting section before attempting to Replant.' : ''}>
										<span>
											<Button
												variant='outlined'
												style={{ marginLeft: 10, padding: '0px 10px' }}
												disabled={!isPlantingComplete}
												onClick={() => showReplantOrLossModal(ReplantOrLoss.Replant)}
											>
												Replant
											</Button>
										</span>
									</Tooltip>
									<Tooltip title={!isPlantingComplete ? 'Please complete Planting section before attempting to mark a Field as lost.' : ''}>
										<span>
											<Button
												variant='outlined'
												style={{ marginLeft: 10, padding: '0px 10px' }}
												disabled={!isPlantingComplete}
												onClick={() => showReplantOrLossModal(ReplantOrLoss.FieldLoss)}
											>
												Field Lost
											</Button>
										</span>
									</Tooltip>
								</div>
							</StyledPanel>
						</StyledCollapse>
						<StyledCollapse
							className='HarvestCollapseContainer'
							style={{
								boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
								marginBottom: 20,
								borderRadius: 6,
								borderWidth: 1,
								borderColor: theme.colors.lightestGrey,
								borderStyle: 'solid',
								overflowY: 'unset',
							}}
							defaultActiveKey={!isHarvestComplete ? 1 : 0}
							expandIcon={(props) => 
							{
								return <div><StyledCaret className='RowExpander' expanded={props.isActive ? 'true' : 'false'} /></div>;
							}}
							expandIconPosition='end'
						>
							<StyledPanel
								style={{ backgroundColor: theme.colors.white }}
								header={
									<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<div>Harvest Stewardship</div>
											{
												!isHarvestComplete && <div
													style={{ fontSize: theme.fontSizes.extraSmall }}
												>
													{`Stewardship needs to be completed by ${moment(SelectedContract?.StewardshipCompletionDates?.HarvestDate).format('MMMM Do, YYYY')}`}
												</div>
											}
										</div>
										<div
											style={{
												backgroundColor: isHarvestComplete ? theme.colors.greenLM : theme.colors.redGA,
												borderRadius: 4,
												color: theme.colors.white,
												paddingRight: 8,
												paddingLeft: 8,
												marginLeft: 'auto',
												height: 22,
											}}>{SelectedContract.StewardshipActivities ? SelectedContract.StewardshipActivities[0].HarvestStatus : ''}</div>
									</div>
								}
								key='1'
							>
								<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
									<div
										style={{
											backgroundColor: 'black',
											color: 'white',
											fontWeight: theme.fontWeights.bold,
											display: 'flex',
											flexDirection: 'row',
											fontSize: 12,
											borderTopLeftRadius: 6,
											borderTopRightRadius: 6,
											paddingLeft: 10,
											paddingRight: 10,
											paddingTop: 5,
											paddingBottom: 5,
											overflowX: 'unset'
										}}>
										<div style={{ width: '18%', textAlign: 'left' }}>Field</div>
										<div style={{ width: '20%', textAlign: 'left' }}>Harvested Acres</div>
										<div style={{ width: '22%', textAlign: 'left' }}>Harvest Date</div>						
										<div style={{ width: '18%', textAlign: 'left' }}>Yield Per Acre</div>
										<div style={{ width: '20%', textAlign: 'left' }}>Total Harvest</div>
										<div style={{ width: '10%', textAlign: 'left' }}>Unit</div>
									</div>
									<div style={{ height: '100%', overflowY: 'auto' }}>
										<div
											className={'HarvestSummaryRow'}
											key={'Harvest_Summary_Row'}
											style={{
												display: 'flex',
												flexDirection: 'row',
												fontSize: 12,
												paddingLeft: 10,
												paddingRight: 10,
												height: 40,
												alignItems: 'center',
												backgroundColor: theme.colors.lightestGrey,
												borderWidth: 1,
												borderColor: theme.colors.lightestGrey,
												borderBottomStyle: 'solid',
												borderLeftStyle: 'solid',
												borderRightStyle: 'solid'
											}}
										>
											<div style={{ width: '18%', textAlign: 'left' }}>
												<div style={{ display: 'flex', alignItems: 'center'}}>
													Apply for all
													<InfoIcon
														style={{ position: 'relative', top: -1, left: 3, cursor: 'pointer' }}
														fill={theme.colors.darkGrey}
														onClick={onOpenApplyForAllModal}
													/>
												</div>
											</div>
											<div style={{ width: '20%', textAlign: 'left' }}>
												{`${calculatedHarvestAcres	}/${SelectedContract.ContractedAcres} Ac.`}
											</div>
											<div style={{ width: '22%', display: 'flex', alignItems: 'center'}}>
												<StyledDatePicker
													className={'Harvest_ApplyAll_HarvestDate'}
													placeholder={'mm/dd/yyyy'}
													format={dateFormat}
													disabledDate={disabledHarvestDates}
													value={harvestAllDate ? moment(harvestAllDate) : undefined}
													onChange={(value: moment.Moment) => harvestEditDate('all', value ? value.format(dateFormat) : '')}
													panelRender={originalPanel =>
													{
														return (
															<StyledDatePickerPanel className='StyledDatePickerPanel'>
																{originalPanel}
															</StyledDatePickerPanel>
														);
													}}
												/>
											</div>
											<div style={{ width: '18%', textAlign: 'left' }}>
												<NumberInput
													style={{ fontSize: 12, width: '90%', height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
													className={'Harvest_ApplyAll_PerAcre_Input'}
													type='number'
													placeholder='Add'
													value={harvestAllYieldPerAcre}
													onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
														harvestEditPerAcre('all', evt.currentTarget.value ? Number(evt.currentTarget.value) : harvestAllYieldPerAcre ? Number(harvestAllYieldPerAcre) : undefined)}
													onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
														harvestEditPerAcre('all', evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
															: Number(harvestAllYieldPerAcre))}
												/>
											</div>
											<div style={{ width: '20%', textAlign: 'left' }}>
												<NumberInput
													style={{ fontSize: 12, width: '90%', height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
													className={'Harvest_ApplyAll_TotalHarvest_Input'}
													type='number'
													placeholder='Add'
													value={harvestAllTotalHarvest}
													onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
														harvestEditTotalHarvest('all', evt.currentTarget.value ? Number(evt.currentTarget.value) : harvestAllTotalHarvest ? Number(harvestAllTotalHarvest) : undefined)}
													onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
														harvestEditTotalHarvest('all', evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
															: Number(harvestAllTotalHarvest))}
												/>
											</div>
											<div style={{ width: '10%', textAlign: 'left' }}>
												<Dropdown
													variant='outlinednarrownopad'
													options={unitOptions}
													onSelect={harvestSelectUnit}
													selected={activityFields && activityFields[0] ? 
														activityFields[0].Activity.Harvest.UnitType ?? unitOptions[0].value : unitOptions[0].value}
													placeholder='Select a Brand'
													className='Brand_Dropdown'
												/>
											</div>
										</div>
										{
											activityFields && activityFields.map((field, index) =>
												<HarvestFieldRow
													key={`Harvest_Field_${field.FieldId}`}
													field={field}
													disabledDates={disabledHarvestDates}
													editAcres={harvestEditAcres}
													editDate={harvestEditDate}
													editPerAcre={harvestEditPerAcre}
													editTotalHarvest={harvestEditTotalHarvest}
													lastItem={activityFields.length === index + 1}
												/>
											)
										}
									</div>
								</div>
							</StyledPanel>
						</StyledCollapse>
						{/* 04.23.24 - Hiding Storage until it is in scope
						<StyledCollapse
							className='StorageCleanoutCollapseContainer'
							style={{
								boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
								borderRadius: 6,
								borderWidth: 1,
								borderColor: theme.colors.lightestGrey,
								borderStyle: 'solid'
							}}
							expandIcon={(props) => 
							{
								return <div><StyledCaret className='RowExpander' expanded={props.isActive ? 'true' : 'false'} /></div>;
							}}
							expandIconPosition='end'
						>
							<StyledPanel style={{ backgroundColor: theme.colors.white }} header='Storage Cleanout'key='1'>
								<div>Table goes here</div>
							</StyledPanel>
						</StyledCollapse> */}
					</div>
				</div>
			</StewardshipInnerContainer>
			<IFrameModal title="Enogen Contracting" width={1200} open={!!EnogenFlowUrl}
				onCancel={() => HideEnogenFlowFrame()}
				footer={null}>
				<iframe src={EnogenFlowUrl} />
			</IFrameModal>
			<StyledModal
				width={520}
				onCancel={() => setOpenApplyForAllModal(false)}
				bodyStyle={{ paddingTop: 10 }}
				open={openApplyForAllModal}
				title={
					<span
						style={{ fontFamily: theme.fonts.heading, fontWeight: theme.fontWeights.bold }}
					>Apply All will Overwrite your Previous Information</span>}
			>
				<div style={{ display: 'flex', flexDirection:'column' }}>
					<div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<div style={{ marginRight: 5 }}><RedExclamation width={28} height={28} /></div>
						<div>
							Prefilled information will be substituted with the new one you enter. If you need to preserve previous information, fill all spaces one by one.
						</div>
					</div>
					<Button className='ApplyAll_Understood_Button'
						style={{ height: 40, width: 100, marginRight: 15, marginLeft: 'auto' }}
						variant='dark'
						onClick={() => setOpenApplyForAllModal(false)}
					>
						Understood
					</Button>
				</div>
			</StyledModal>
			<ReplantLossModal
				fieldList={getFieldsForReplantOrLoss()}
				stewardshipActivityId={SelectedContract?.StewardshipActivityId}
				type={selectedReplantLossType}
				visible={showReplantLossModal}
				onCancel={cancelReplantOrLossModal}
				onSave={saveReplantOrLoss}
			/>
		</StewardshipMainContainer>
	);
};

export const StewardshipMain = connector(StewardshipMainComponent);
