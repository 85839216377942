import { LineString, buffer, difference, AllGeoJSON, polygon, MultiPolygon, booleanContains, pointsWithinPolygon, points, rewind, Polygon, Position } from '@turf/turf';
import { parse, stringify } from 'wkt';
import { AllowedPolygonTypes, SupportedGeometryFormats, AllowedLineTypes } from './SupportedGeometryFormats';
import { IdentifiedGeometry } from './IdentifiedGeometry';
import { convertGeometry } from './convertGeometry';

/**
 * Split a geometry with a line.
 * 
 * May not handle holes well.
 */
export function split<TGeometry>(geometries: IdentifiedGeometry<TGeometry>[], cut: AllowedLineTypes, format: SupportedGeometryFormats)
{
	// Make sure our cutting string is in geojson format
	const cuttingString: LineString = typeof cut === 'string'
		? parse(cut)
		: cut;

	// Convert it to a tiny polygon so that the difference function works
	// Note the somewhat large buffer size. This allows users to create holes in polgyons
	// by submitting a split line that crosses itself. The hole produced is not perfect
	// but is nevertheless desired.
	const splitter = buffer(cuttingString, 1, {
		units: 'inches'
	});

	const results: Array<IdentifiedGeometry<TGeometry>> = [];
	// iterate over every identified geometry
	for (const item of geometries)
	{
		const inputGeometry: AllGeoJSON = typeof item.geometry === 'string'
			? parse(item.geometry)
			: item.geometry;
		
		if(!inputGeometry)
		{
			continue;
		}

		let polygons = [inputGeometry];

		if(inputGeometry.type === 'MultiPolygon')
		{
			const multiPolygon = inputGeometry as MultiPolygon;
			polygons = multiPolygon.coordinates.map(polyCoords => polygon(polyCoords).geometry);
		}

		// Attempt to rectify holes that are outside their polygon as new polygons.
		polygons = rectifyBadHoles(polygons);

		for(const polyGeometry of polygons)
		{
			if (polyGeometry.type !== 'Polygon')
			{
				throw new Error(`Unsupported geometry type provided: ${polyGeometry.type}.  Only top-level polygons are allowed`);
			}

			// 'subtract' our string
			const result = difference(polyGeometry as any, splitter);
			
			// If the result isn't a multipolygon, then the cutting line didn't actually divide the input, so just keep the input
			if (result == null || result.geometry.type === 'Polygon')
			{
				results.push({ id: item.id, geometry: convertGeometry(polyGeometry, format) });
			}
			// Otherwise iterate over every resulting polygon and add it with a modified identifier
			else if (result.geometry.type === 'MultiPolygon')
			{
				let idx = 0;
				// Convert this multipolygon coordinate set into just the polygon coordinates
				for (const coordinateSet of result.geometry.coordinates)
				{
					// and wrap as a Polygon
					const singlePoly = polygon(coordinateSet);
					results.push({ id: item.id + `/${idx}`, geometry: convertGeometry(singlePoly.geometry, format) });
					++idx;
				}
			}
			else
			{
				throw new Error(`Unexpected output format: ${result.type}->${(result as any).geometry?.type}`);
			}
		}
	}

	return results;
}

/**
 * Finds holes that are outside their polgyon's shell. Converts those holes to polygons.
 * 
 * Modifies in place the passed in polygons so don't reuse whatever you pass in. This is to avoid
 * making unnecessary copies of potentially massive geojson payloads.
 */
const rectifyBadHoles = (polygons: AllGeoJSON[]) => 
{
	const newPolygons: Polygon[] = [];
	for (const geom of polygons)
	{
		if (geom.type !== 'Polygon')
		{
			continue;
		}

		const poly = geom as Polygon;
		if (poly.coordinates.length <= 1)
		{
			continue;
		}

		const shell = polygon([poly.coordinates[0]]);
		const goodHoles: Position[][] = [];
		for (let i = 1; i < poly.coordinates.length; ++i)
		{
			const coords = poly.coordinates[i];
			const holeAsPolygon = polygon([coords]);
			if (booleanContains(shell, holeAsPolygon)) 
			{
				goodHoles.push(coords);
			}
			else
			{
				newPolygons.push(rewind(holeAsPolygon.geometry));
			}
		}
		// Update polygon's coordinates.
		poly.coordinates = [poly.coordinates[0], ...goodHoles];
	}

	return [...polygons, ...newPolygons];
};