import React, { useCallback } from 'react';
import { StyledContainer } from '../../../components/Table/TableContainer';
import { SortType, TableHeader } from '../../../components/Table/TableHeader';
import { StyledRowCell, TableRow } from '../../../components/Table/TableRow';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import {
	Acres,
	getExpandedSpacing,
	getHeaderSpacing,
	keysToHeaders,
	StyledDashboardScrollableTableRows,
	StyledDashboardTableRows
} from './DashboardRows';
import { NameSegment } from './Segments/NameSegmentComponent';
import { ListChildComponentProps } from 'react-window';
import { ExpandedPlan } from './Segments/ExpandedPlan';
import { BoolDictionary, UISort } from '../../../logic/store/UI/UISlice';
import { IGrowerWithContractDetails } from '../../../logic/Models/Responses/EnogenContractResponse';
import { FieldGamePlan, ProductGamePlan } from './PlanDashboardTable';
import { DefaultTheme, useTheme } from 'styled-components';
import { ScaleLoader } from 'react-spinners';

const ContractSubstateDisplayMap = (theme: DefaultTheme) => [
	{ key:'Amendment Pending', text: theme.colors.white, color: theme.colors.purpleLM, label: 'Amend. Pending' },
	{ key:'Pending', text: theme.colors.white, color: theme.colors.blueLM, label: 'Pending' },
	{ key:'Grower Signed', text: theme.colors.darkGrey, color: theme.colors.availabilityYellow, label: 'Grower Signed' },
	{ key:'Amendment Completed', text: theme.colors.white, color:  theme.colors.greenLM, label: 'Amend. Complete' },
	{ key:'Completed', text: theme.colors.white, color:theme.colors.greenLM, label: 'Complete' },
];

// Enogen Tab specific components
const ContractStatus = (props: IGrowerWithContractDetails) =>
{
	const { ContractState } = props;
	const theme = useTheme();
	
	let label = ContractState;
	const pills : React.ReactNode[] = [];

	for(const substate of ContractSubstateDisplayMap(theme))
	{
		if(label.includes(substate.key))
		{
			pills.push(
				<div
					key={substate.key}
					style={{
						display: 'inline-flex',
						marginRight: '5px',
						borderRadius: 8,
						padding: '4px 12px 4px 12px',
						color: substate.text,
						backgroundColor: substate.color,
						fontSize: 12,
						fontWeight: 600,
						lineHeight: '20px'
					}}
				>{substate.label}
				</div>
			);
			label = label.replace(substate.key, '');
		}
	}
	return(
		<StyledRowCell style={{ width: '100%', justifyContent: 'flex-start', flexWrap: 'wrap', rowGap: 4 }}>
			{pills} {label}
		</StyledRowCell>
	);
};

const Stewardship = (props: IGrowerWithContractDetails) =>
{
	const numberOfCompleted = (): Number =>
	{
		let totalCompleted = 0;
		if (props.Planting?.toLowerCase() === 'complete')
		{
			totalCompleted++;
		}
		if (props.FieldSelection?.toLowerCase() === 'complete')
		{
			totalCompleted++;
		}
		if (props.Harvest?.toLowerCase() === 'complete')
		{
			totalCompleted++;
		}

		return totalCompleted;
	};

	return(
		<StyledRowCell>
			<div>{numberOfCompleted().toString()}/3 Complete</div>
		</StyledRowCell>
	);
};

interface IAssignedVsContractedAcresProps extends IGrowerResponse,IGrowerWithContractDetails
{
	cropYear: number;
}
export const AssignedVsContractedAcres = React.memo<IAssignedVsContractedAcresProps>(
	function Acres(props: IAssignedVsContractedAcresProps)
	{
		const { NewContractId, ContractedAcres, AssignedAcres, SeasonData, cropYear } = props;
		const theme = useTheme();
		
		const displayedAcres = () =>
		{
			// if we have an Enogen Contract Id, return the Enogen Contract values instead
			if (NewContractId)
			{
				return `${AssignedAcres}/${ContractedAcres}`;
			}
			else
			{
				return '0';
			}
		};

		return(
			<StyledRowCell
				className='Acres'
				style={{ margin: 'auto', width: '80%', borderBottom: `1px solid ${theme.colors.darkGrey}` }}>
				{displayedAcres()}
			</StyledRowCell>
		);
	}
);

interface IOrderedUnitsVsContractedUnitsProps extends IGrowerResponse,IGrowerWithContractDetails
{
	cropYear: number;
}
const OrderedUnitsVsContractedUnits = React.memo<IOrderedUnitsVsContractedUnitsProps>(
	function Acres(props: IOrderedUnitsVsContractedUnitsProps)
	{
		const { EnogenUnits, OrderedUnits } = props;
		const theme = useTheme();
		
		const displayedAcres = () =>
		{
			return `${OrderedUnits}/${EnogenUnits}`;
		};

		return(
			<StyledRowCell
				className='Acres'
				style={{
					margin: 'auto',
					width: '80%',
					borderBottom: `1px solid ${theme.colors.darkGrey}` }}>
				{displayedAcres()}
			</StyledRowCell>
		);
	}
);

const columns = [
	'Name / Segment',
	'Enogen Assigned / Contracted Acres',
	'Contract Status',
	'Stewardship',
	'Acre Proposals',
	'Field Proposals',
	'Units Ordered / Contracted'
];

const minWidths = [0,0,190,0,0,0];
const headerSpacing = getHeaderSpacing(columns, minWidths);
const expandedSpacing = getExpandedSpacing(columns);

interface IEnogenDashboardTableProps
{
	DashboardSort: UISort<IGrowerResponse>;
	DashboardTableExpanded: BoolDictionary;
	growers: IGrowerWithContractDetails[];
	displayEnogenFlow: (selectedGrowerId: string, selectedContractId: string, viewDetails?: boolean) => void;
	onSort: (sort: SortType, header: string) => void;
	openResendForSigningModal: (growerId: string, contractId: string) => void;
	SetExpanded: (idEntry: BoolDictionary) => void;
	SetSelectedGrowerId: (growerId: string) => void;
	SetSelectedEnogenContractId: (growerId: string) => void;
	ClearSelectedProductPlanId: () => void;
	/** The selected crop year. */
	cropYear: number;
	currentCropYear: number;
	contractsLoading: boolean;
}

export const EnogenDashboardTable = (props: IEnogenDashboardTableProps) =>
{
	const {
		contractsLoading,
		cropYear,
		currentCropYear,
		DashboardSort,
		DashboardTableExpanded,
		growers,
		displayEnogenFlow,
		ClearSelectedProductPlanId,
		onSort,
		openResendForSigningModal,
		SetExpanded,
		SetSelectedGrowerId,
		SetSelectedEnogenContractId,
	} = props;

	const theme = useTheme();

	const RenderTableRow = useCallback(({ data, index, style }: ListChildComponentProps<{ rows: IGrowerWithContractDetails[]; ref: React.RefObject<any> }>) =>
	{
		const { rows, ref } = data;
		const grower = rows[index];

		const onExpand = (idEntry: BoolDictionary) =>
		{
			if (ref && ref.current?.resetAfterIndex)
			{
				ref.current?.resetAfterIndex(index, false);
			}
			SetExpanded(idEntry);
		};

		return (
			<div key={`tableRow-${index}`}
				style={{ marginTop: '10px', ...style }}>
				<TableRow
					Id={grower.ContractId}
					items={[
						<NameSegment key={`enogen-cell-name-${grower.Name}-${grower.NewContractId}-${index}`} {...grower} DashboardTableExpanded={DashboardTableExpanded} />,
						<AssignedVsContractedAcres
							key={`enogen-cell-acrescontractedassigned-${grower.Name}-${grower.NewContractId}-${index}`} {...grower} cropYear={cropYear} />,
						<ContractStatus key={`enogen-cell-contractstatus-${grower.Name}-${grower.NewContractId}-${index}`} {...grower} />,
						<Stewardship key={`enogen-cell-stewardship-${grower.Name}-${grower.NewContractId}-${index}`} {...grower} />,
						<ProductGamePlan key={`plan-cell-prodgameplan-${grower.Name}-${grower.NewContractId}-${index}`} {...grower}
							ClearSelectedProductPlanId={ClearSelectedProductPlanId}
							SetSelectedGrowerId={SetSelectedGrowerId} cropYear={cropYear} 
						/>,
						<FieldGamePlan key={`plan-cell-gameplan-${grower.Name}-${grower.NewContractId}-${index}`} {...grower}
							SetSelectedGrowerId={SetSelectedGrowerId} cropYear={cropYear} />,
						<OrderedUnitsVsContractedUnits 
							key={`enogen-cell-unitsordercontracted-${grower.Name}-${grower.NewContractId}-${index}`} {...grower} cropYear={cropYear} />,
					]}
					expanded={DashboardTableExpanded[grower.ContractId]}
					columnSpacing={headerSpacing}
					setExpanded={onExpand}
					expandedSection={
						<ExpandedPlan
							Grower={grower as IGrowerWithContractDetails}
							currentTab={'Enogen'}
							expandedSpacing={headerSpacing}
							SetSelectedGrowerId={SetSelectedGrowerId}
							SetSelectedEnogenContractId={SetSelectedEnogenContractId}
							cropYear={cropYear}
							currentCropYear={currentCropYear}
							displayEnogenFlow={displayEnogenFlow}
							openResendForSigningModal={openResendForSigningModal}
						/>
					}
				/>
			</div>
		);
	}, [DashboardTableExpanded, cropYear]);

	return (
		<StyledContainer>
			<TableHeader headers={columns}
				columnSpacing={headerSpacing}
				initialSorts={{ [keysToHeaders[DashboardSort.field]]: DashboardSort.sortDirection }}
				sortActions={{ 'Name / Segment': (order) => onSort(order, 'Name / Segment') }}
				defaultSort={{ headerName: 'Name / Segment', direction: 'ascending' }}
			/>	
			<StyledDashboardScrollableTableRows>
				{
					contractsLoading ?
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<ScaleLoader color={theme.colors.primary} loading={true} />
							Loading Contracts
						</div>
						:
						<StyledDashboardTableRows rows={growers}
							renderComponent={RenderTableRow}
							itemSize={(index) => DashboardTableExpanded[growers[index].ContractId] ? 265 : 85}
						/> 
				}
			</StyledDashboardScrollableTableRows>
		</StyledContainer>
	);
};
