import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { IBulkImportSessionResponse, IBulkImportSession } from '../../Models/Responses/BulkImportResponse';
import { IBulkImportRequest } from '../../Models/Requests/BulkImportRequest';
import { getCurrentActingUser } from '../User/AuthSlice';
import { FeatureCollection, MultiPolygon, Polygon } from '@turf/turf';
import { sleepOnIt } from '../../Utility/Utils';
import { updatePublishProgress } from './BulkImportSlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export const startBulkImport = createTracedAsyncThunk<IBulkImportSessionResponse, IBulkImportRequest, { dispatch: AppDispatch, state: RootState }>(
	'bulkImports/start',
	async (context, request: IBulkImportRequest, thunkAPI) => 
	{
		try
		{
			const growerId = request.GrowerId;
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.postAsync<IBulkImportSession>('bulkimports', {
				userId: getCurrentActingUser(thunkAPI.getState()).UserId,
				growerId
			});

			if (response.ErrorCode === null && response.Success)
			{
				const bulkImportResponse: IBulkImportSessionResponse = {
					Session: response.Data,
					// @ts-ignore
					GrowerId: request.GrowerId
				};
				return bulkImportResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getBulkImportSession = createTracedAsyncThunk<IBulkImportSessionResponse, IBulkImportRequest, { dispatch: AppDispatch, state: RootState }>(
	'bulkImports/get',
	async (context, request: IBulkImportRequest, thunkAPI) =>
	{
		try
		{
			const sessionId = request.SessionId;
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.getAsync<IBulkImportSession>(`bulkimports/${sessionId}`);

			if (response.ErrorCode === null && response.Success)
			{
				const bulkImportResponse: IBulkImportSessionResponse = {
					Session: response.Data,
					// @ts-ignore
					GrowerId: request.GrowerId
				};
				return bulkImportResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export type BulkImportHeaderResponse = { 
	Id: string, 
	Error?: string, 
	UserId: string, 
	GrowerId: string, 
	Status: string, 
	Files: { Name: string, Columns: Record<string, string[]> }[], 
	Progress: number 
};

export const getBulkImportHeader = createTracedAsyncThunk<BulkImportHeaderResponse, { UserId: string }, { dispatch: AppDispatch, state: RootState }>(
	'bulkImports/header',
	async (context, request: { UserId: string }, thunkAPI) =>
	{
		const api = new Api('/api/5', thunkAPI.getState().auth.userAuthToken, context);
		try
		{
			const response = await api.getAsync<BulkImportHeaderResponse>(`users/${request.UserId}/bulkimport/header`);
	
			if (response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export type FieldConflictResolutions = { FarmId: string, FieldId: string, Skip: boolean, ClipFieldIds: string[] };
export type SaveBulkImportRequest = { FarmKey: string|undefined, FieldKey: string|undefined, Resolutions: FieldConflictResolutions[] }; 
	
export const saveBulkImportSession = createTracedAsyncThunk<BulkImportHeaderResponse, { userId: string, farmKey: string|undefined, fieldKey: string|undefined }, { dispatch: AppDispatch, state: RootState }>(
	'bulkImports/save',
	async (context, request: { userId: string, farmKey: string|undefined, fieldKey: string|undefined}, thunkAPI) =>
	{
		const state = thunkAPI.getState() as RootState;
		const { bulkImport } = state;

		const importEntries = Object.values(bulkImport.farms!).flatMap((farm) => Object.values(farm.Fields).map((field) => ({farm, field})));
		const resolutions = importEntries.map((entry) => 
		{
			return {
				FarmId: entry.farm.Id,
				FieldId: entry.field.Id,
				Skip: entry.field.skip,
				ClipFieldIds: entry.field.clipFieldIds
			};
		});
		const saveBulkImportRequest: SaveBulkImportRequest =
		{
			FarmKey: request.farmKey,
			FieldKey: request.fieldKey,
			Resolutions: resolutions
		};

		const api = new Api('/api/5', thunkAPI.getState().auth.userAuthToken, context);
		try
		{
			const response = await api.putAsync<BulkImportHeaderResponse>(`users/${request.userId}/bulkimport/published`, saveBulkImportRequest);
	
			if (response.Success)
			{
				let statusCheckResponse = undefined;
				//poll for the completion
				do 
				{
					// wait 1 seconds for each poll
					await sleepOnIt(1000);
					statusCheckResponse = await api.getAsync<BulkImportHeaderResponse>(`users/${request.userId}/bulkimport/header`);
					thunkAPI.dispatch(updatePublishProgress(statusCheckResponse.Data.Progress));

				} while(statusCheckResponse && statusCheckResponse.Data.Status === 'Publishing');

				if(statusCheckResponse.Data.Status === 'Completed')
				{
					return statusCheckResponse.Data;
				}
				else 
				{
					return thunkAPI.rejectWithValue(statusCheckResponse.Data.Error);					
				}
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export type BulkImportFeatureProperties = { 'oi:farm-key': string, 'oi:field-key': string } & Record<string, unknown>;
type BulkImportGeometry = FeatureCollection<Polygon | MultiPolygon, BulkImportFeatureProperties>;
export type BulkImportFeatureCollection = FeatureCollection<Polygon | MultiPolygon, BulkImportFeatureProperties>;
export interface IBulkImportGeometryResult { GrowerId: string, Geometry: BulkImportGeometry }

export const getBulkImportGeometry = createTracedAsyncThunk<IBulkImportGeometryResult, { FarmKey: string, FieldKey: string, GrowerId: string, UserId: string }, { dispatch: AppDispatch, state: RootState }>(
	'bulkImports/geometry',
	async (context, request: { FarmKey: string, FieldKey: string, GrowerId: string, UserId: string }, thunkAPI) =>
	{
		const api = new Api('/api/5', thunkAPI.getState().auth.userAuthToken, context);
		try
		{
			const response = await api.getAsync<BulkImportGeometry>(`users/${request.UserId}/bulkimport/geometry?field_key=${request.FieldKey}&farm_key=${request.FarmKey}`);
			if (response.Success)
			{
				return { Geometry: response.Data, GrowerId: request.GrowerId };
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

type BulkImportOverlap = { OverlappingFieldId: string, Geometry: Polygon | MultiPolygon };
export type BulkImportProfileField = { Overlaps: BulkImportOverlap[], Id: string, Name: string, Data: Record<string, string> };
export type BulkImportProfileFarm = {
	Id: string,
	Name: string,
	Fields: Record<string, BulkImportProfileField>
};
export type BulkImportProfileResponse = Record<string, BulkImportProfileFarm>;

export const getBulkImportProfile = createTracedAsyncThunk<BulkImportProfileResponse, { FarmKey: string, FieldKey: string, GrowerId: string, UserId: string }, { dispatch: AppDispatch, state: RootState }>(
	'bulkImports/profile',
	async (context, request: { FarmKey: string, FieldKey: string, GrowerId: string, UserId: string }, thunkAPI) =>
	{
		const api = new Api('/api/5', thunkAPI.getState().auth.userAuthToken, context);
		try
		{
			const response = await api.getAsync<BulkImportProfileResponse>(`users/${request.UserId}/bulkimport/profile?field_key=${request.FieldKey}&farm_key=${request.FarmKey}`);
			if (response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);