import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { getCurrentActingUser } from '../../../logic/store/User/AuthSlice';
import { IUpdateSeedAssignments } from '../../Models/Requests/SourceUpdateTypes';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export interface IUpdateSeedAssignmentRequest extends IUpdateSeedAssignments
{
	FieldId: string;
	FieldYear: string;
}

export const updateSeedAssignmentsForField = createTracedAsyncThunk<object, IUpdateSeedAssignmentRequest, { dispatch: AppDispatch, state: RootState }>(
	'update/seedassignmentforfield',
	async (context, request: IUpdateSeedAssignmentRequest, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const api = new Api('/api/5', thunkAPI.getState().auth.userAuthToken, context);
			const { FieldId, FieldYear } = request;
			
			const response = await api.putAsync<object>(`users/${getCurrentActingUser(state).UserId}/fields/${FieldId}/years/${FieldYear}/assignments`, request);

			if (response.ErrorCode === null && response.Success) 
			{
				// 10/18/2022 - this function actually returns a FieldYearProjection but we do not currently care about the return data, only that it was a success
				return true;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);