import {  } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { sleepOnIt } from '../../Utility/Utils';
import { getCurrentActingUser } from '../User/AuthSlice';
import { IPlantingPlanSummaryCropPlan, IPlantingPlanSummaryResponse } from '../../Models/Responses/PlantingPlanSummaryResponse';
import { IMinMaxSeedingRateValues, IPlantingPlanResponse } from '../../Models/Responses/PlantingPlanResponse';
import { IPlantingPlanEquipmentResponse } from '../../Models/Responses/PlantingPlanEquipmentResponse';
import { ApiResponse } from '../../Api/ApiResponse';
import { getPlantingRates, IGetPlantingRatesRequest } from '../Seeds/PlantingRateSlice';
import { CropConfig } from '../Seeds/CropsSlice';
import { DefaultTheme } from 'styled-components';
import { createTracedAsyncThunk } from '../../../tracing/trace';


// Models for the Polling process
interface IPollResponse
{
	Status: FieldSessionStatus;
	Message: string;
	ErrorMessage: string;
}

enum FieldSessionStatus
{
	InProgress = 'In Progress',
	Complete = 'Complete',
	Error = 'Error'
}

export interface IPlantingPlanSummaryRequest
{
	Theme: DefaultTheme;
	RequestedGrowerId: string;
	Year: string;
}

// Starts the create process or attempts to retrieve existing planting plan summary for the PlanId
export const getOrCreatePlantingPlan = createTracedAsyncThunk<string, IPlantingPlanSummaryRequest, { dispatch: AppDispatch; state: RootState; }>(
	'plantingPlans/getOrCreate',
	async (context, request, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const currentActingUser = getCurrentActingUser(currentState);
		const api = new Api('/api/4', currentState.auth.userAuthToken, context);

		const { RequestedGrowerId, Year } = request;

		try
		{
			// Do not really need this response = keep it for the future use in case more data is added
			const url = `users/${currentActingUser.UserId}/growers/${RequestedGrowerId}/years/${Year}/planting/variablerate/async`;
			const getOrCreateSession = await api.putAsync<string>(url, '');

			if (getOrCreateSession.ErrorCode !== null && !getOrCreateSession.Success)
			{
				return thunkAPI.rejectWithValue(getOrCreateSession.ErrorMessage);
			}

			// If there is no poll ID returned, then we can immediately get the planting plan
			// summaries with no wait!
			if(!getOrCreateSession.Data)
			{	
				// Use the summary retrieval endpoint to get the actual summaries
				thunkAPI.dispatch(retrievePlantingPlanSummaries(request));
				return;
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}

		// Timeout the do-while loop after 16 minutes
		let keepCalling = true;
		setTimeout(() =>
		{
			keepCalling = false;
		}, 960000); // 16 minutes - 1 minute longer than backend poll timeout which is 15

		let pollResponse: ApiResponse<IPollResponse>;
		let allFieldsDone: boolean = false;
		do 
		{
			// Wait half a second for each look
			await sleepOnIt(3000);
			const url = `users/${currentActingUser.UserId}/growers/${request.RequestedGrowerId}/years/${Year}/planting/variablerate/poll`;
			pollResponse = await api.getAsync<IPollResponse>(url);
			if (pollResponse)
			{
				if (pollResponse.ErrorMessage) 
				{
					// Throw the error that the poll failed
					return thunkAPI.rejectWithValue(pollResponse.ErrorMessage);
				}
				else if (pollResponse.Data)
				{
					if(pollResponse.Data.Status == FieldSessionStatus.Error)
					{
						return thunkAPI.rejectWithValue(pollResponse.Data.ErrorMessage);
					}
					else if(pollResponse.Data.Status == FieldSessionStatus.Complete)
					{
						allFieldsDone = true;
					}
					else
					{
						//still in progess.
					}
				}
			}
		} while(!(pollResponse && allFieldsDone) && keepCalling);

		try
		{
			const { Status, Message, ErrorMessage } = pollResponse.Data;

			// If there is an ErrorMessage, report the error
			if (ErrorMessage)
			{
				return thunkAPI.rejectWithValue(ErrorMessage);
			}

			// Use the summary retrieval endpoint to get the actual summaries
			thunkAPI.dispatch(retrievePlantingPlanSummaries(request));

		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IGrowerPlantingSummaryResponse
{
	RequestedGrowerId: string;
	PlanSummaryData: IPlantingPlanSummaryResponse;
}

export const retrievePlantingPlanSummaries = createTracedAsyncThunk<IGrowerPlantingSummaryResponse, IPlantingPlanSummaryRequest, { dispatch: AppDispatch; state: RootState; }>(
	'plantingPlans/retrieveSummaries',
	async (context, request, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const currentActingUser = getCurrentActingUser(currentState);
		const api = new Api('/api/4', currentState.auth.userAuthToken, context);

		const theme = request.Theme;

		const { RequestedGrowerId, Year } = request;

		try
		{
			const url = `users/${currentActingUser.UserId}/growers/${RequestedGrowerId}/years/${Year}/planting/variablerate/summary-profile`;
			const summaryResponse = await api.getAsync<IPlantingPlanSummaryCropPlan[]>(url);

			if (summaryResponse.ErrorCode === null && summaryResponse.Success)
			{
				if (summaryResponse.Data.length === 0)
				{
					return thunkAPI.rejectWithValue({ error: 'There was no Planting Plan data returned.', growerId: RequestedGrowerId });
				}
				const responseData = summaryResponse.Data;
				
				// There should only be 6 crop plans as that is the maximum allowed to be created on a Plan
				responseData.forEach((cp, index) => cp.Color = CropConfig(theme)[cp.CropPlanId].maxScriptColor);

				const formattedResponse: IPlantingPlanSummaryResponse =
				{
					GamePlanName: 'Variable Rate Plan',
					CropPlans: responseData
				};

				// Loop through and give the plans a color
				const plansResponse: IGrowerPlantingSummaryResponse = {
					PlanSummaryData: formattedResponse,
					RequestedGrowerId: RequestedGrowerId
				};
				return plansResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue({ error: summaryResponse.ErrorMessage, growerId: RequestedGrowerId });
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

/**
 * Allows a user to reset their planting plans
 */
export const resetPlantingPlanSummaries = createTracedAsyncThunk<IGrowerPlantingSummaryResponse, {Year: string}, { dispatch: AppDispatch; state: RootState; }>(
	'plantingPlans/resetSummaries',
	async (context, request, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const { ui, auth } = currentState;
		const currentActingUser = getCurrentActingUser(currentState);
		const api = new Api('/api/4', auth.userAuthToken, context);

		const { Year } = request;

		try
		{
			const url = `users/${currentActingUser.UserId}/growers/${ui.SelectedGrowerId}/years/${Year}/planting/variablerate/async?resetPlantingPlans=true&resetManagementPlans=true&resetSoilZones=true`;
			const summaryResponse = await api.putAsync<IPlantingPlanSummaryCropPlan[]>(url, '');

			if (summaryResponse.ErrorCode === null && summaryResponse.Success)
			{
				if (summaryResponse.Data.length === 0)
				{
					return thunkAPI.rejectWithValue({ error: 'There was no Planting Plan data returned.', growerId: ui.SelectedGrowerId });
				}

				// Don't care about the data in the response, this is just a simple call to force it to re-run the plans
			}
			else 
			{
				return thunkAPI.rejectWithValue({ error: summaryResponse.ErrorMessage, growerId: ui.SelectedGrowerId });
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}

		// Timeout the do-while loop after 16 minutes
		let keepCalling = true;
		setTimeout(() =>
		{
			keepCalling = false;
		}, 960000); // 16 minutes - 1 minute longer than backend poll timeout which is 15

		let pollResponse: ApiResponse<IPollResponse>;
		let allFieldsDone: boolean = false;
		do 
		{
			// Wait half a second for each look
			await sleepOnIt(3000);
			const url = `users/${currentActingUser.UserId}/growers/${ui.SelectedGrowerId}/years/${Year}/planting/variablerate/poll`;
			pollResponse = await api.getAsync<IPollResponse>(url);
			if (pollResponse)
			{
				if (pollResponse.ErrorMessage) 
				{
					// Throw the error that the poll failed
					return thunkAPI.rejectWithValue(pollResponse.ErrorMessage);
				}
				else if (pollResponse.Data)
				{
					if(pollResponse.Data.Status == FieldSessionStatus.Error)
					{
						return thunkAPI.rejectWithValue(pollResponse.Data.ErrorMessage);
					}
					else if(pollResponse.Data.Status == FieldSessionStatus.Complete)
					{
						allFieldsDone = true;
					}
					else
					{
						//still in progess.
					}
				}
			}
		} while(!(pollResponse && allFieldsDone) && keepCalling);

		try
		{
			const { Status, Message, ErrorMessage } = pollResponse.Data;

			// If there is an ErrorMessage, report the error
			if (ErrorMessage)
			{
				return thunkAPI.rejectWithValue(ErrorMessage);
			}

		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IPlantingPlanRequest
{
	RequestedGrowerId: string;
	FieldId: string;
	CropName: string;
	SelectedYear: string;
}
export interface IGrowerFieldPlantingPlanResponse
{
	RequestedGrowerId: string;
	PlantingPlanData: IPlantingPlanResponse;
}

export const retrieveFieldPlantingPlan = createTracedAsyncThunk<IGrowerFieldPlantingPlanResponse, IPlantingPlanRequest, { dispatch: AppDispatch; state: RootState; }>(
	'plantingPlans/getFieldPlantingPlan',
	async (context, request, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const currentActingUser = getCurrentActingUser(currentState);
		const api = new Api('/api/4', currentState.auth.userAuthToken, context);

		const { CropName, FieldId, RequestedGrowerId, SelectedYear } = request;

		try
		{
			const url = `users/${currentActingUser.UserId}/growers/${RequestedGrowerId}/years/${SelectedYear}/planting/variablerate/fields/${FieldId}`;
			const planResponse = await api.getAsync<IPlantingPlanResponse>
			(
				url
			);

			if (planResponse.ErrorCode === null && planResponse.Success)
			{
				const formattedResponse: IGrowerFieldPlantingPlanResponse = {
					PlantingPlanData: planResponse.Data,
					RequestedGrowerId: RequestedGrowerId
				};

				// Download the planting rates for the seeds on the field
				const plantingRatesRequest: IGetPlantingRatesRequest =
				{
					cropName: CropName,
					seriesNames: Object.values(planResponse.Data.AssignedHybrids).filter(h => !h.IsExternal).flatMap(hybridItem => hybridItem.id),
					vrs: true,
				};

				await thunkAPI.dispatch(getPlantingRates(plantingRatesRequest));

				return formattedResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue({ error: planResponse.ErrorMessage, growerId: RequestedGrowerId });
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IUpdateFieldPlanRequest
{
	AverageRatesPerSeed: {[key: string]: number};
	FieldId: string;
	FileName: string;
	ManagementZoneName: string;
	RequestedGrowerId: string;
	SliderSettings: {[key: string]: number};
	HybridMinMaxSeedingRates: {[key: string]: IMinMaxSeedingRateValues};
	TargetYield: number;
	Year: string;
	Zones: IZoneRequestData[];
}

export interface IZoneRequestData
{
	Zone: number; // Zone name
	SeedingRates: {[key:string]: number};
	UserEditedSeedingRates: {[key:string]: number};
}


export const updateFieldPlantingPlan = createTracedAsyncThunk<IGrowerFieldPlantingPlanResponse, IUpdateFieldPlanRequest, { dispatch: AppDispatch; state: RootState; }>(
	'plantingPlans/updateFieldPlantingPlan',
	async (context, request, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const currentActingUser = getCurrentActingUser(currentState);
		const api = new Api('/api/4', currentState.auth.userAuthToken, context);

		const { RequestedGrowerId, FieldId, Year } = request;

		try
		{
			const url = `users/${currentActingUser.UserId}/growers/${RequestedGrowerId}/years/${Year}/planting/variablerate/fields/${FieldId}`;

			const planResponse = await api.postAsync<IPlantingPlanResponse>
			(
				url, request
			);

			if (planResponse.ErrorCode === null && planResponse.Success)
			{
				const formattedResponse: IGrowerFieldPlantingPlanResponse = {
					PlantingPlanData: planResponse.Data,
					RequestedGrowerId: RequestedGrowerId
				};
				return formattedResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue({ error: planResponse.ErrorMessage, growerId: RequestedGrowerId });
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IManagementZoneUpdateRequest
{
	FieldId: string;
	RequestedGrowerId: string;
	Year: string;
	Zones: IZoneUpdateRequestData[];
}
export interface IZoneUpdateRequestData
{
	Area: number;
	Boundary: string;
	Color: string;
	SeedingRates: {[key:string]: number};
	Zone: number; // Zone name
	ZoneYield: number;
}

export const updateManagementZones = createTracedAsyncThunk<IGrowerFieldPlantingPlanResponse, IManagementZoneUpdateRequest, { dispatch: AppDispatch; state: RootState; }>(
	'plantingPlans/updateManagementZones',
	async (context, request, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const currentActingUser = getCurrentActingUser(currentState);
		const api = new Api('/api/4', currentState.auth.userAuthToken, context);

		const { FieldId, RequestedGrowerId, Year, Zones } = request;

		try
		{
			const url = `users/${currentActingUser.UserId}/growers/${request.RequestedGrowerId}/years/${Year}/planting/variablerate/fields/${FieldId}/geometry`;

			const planResponse = await api.postAsync<IPlantingPlanResponse>
			(
				url, Zones
			);

			if (planResponse.ErrorCode === null && planResponse.Success)
			{
				const formattedResponse: IGrowerFieldPlantingPlanResponse = {
					PlantingPlanData: planResponse.Data,
					RequestedGrowerId: RequestedGrowerId
				};
				return formattedResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue({ error: planResponse.ErrorMessage, growerId: RequestedGrowerId });
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getSupportedEquipment = createTracedAsyncThunk<IPlantingPlanEquipmentResponse, null, { dispatch: AppDispatch; state: RootState; }>(
	'plantingPlans/getSupportedEquipment',
	async (context, request: null, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const currentActingUser = getCurrentActingUser(currentState);
		const api = new Api('/api/4', currentState.auth.userAuthToken, context);

		try
		{
			const url = 'plans/planting/variablerate/supportedmachines';
			const equipmentResponse = await api.getAsync<IPlantingPlanEquipmentResponse>
			(
				url
			);

			if (equipmentResponse.ErrorCode === null && equipmentResponse.Success)
			{
				return equipmentResponse.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue({ error: equipmentResponse.ErrorMessage });
			}
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IExportMultiFieldPlanRequest
{
	FieldIds: string[];
	Format: string;
	Monitor: string;
	SelectedYear: string;
}

export const exportFieldPlantingPlan = createTracedAsyncThunk<string, IExportMultiFieldPlanRequest, { dispatch: AppDispatch; state: RootState; }>(
	'plantingPlans/exportFieldPlantingPlan',
	async (context, request, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const currentActingUser = getCurrentActingUser(currentState);
		const currentGrowerId = currentState.ui.SelectedGrowerId;
		const api = new Api('/api/4', currentState.auth.userAuthToken, context);

		const { Format, Monitor, SelectedYear } = request;

		try
		{
			const url = `users/${currentActingUser.UserId}/growers/${currentGrowerId}/years/${SelectedYear}/planting/variablerate/machineexport/multi`;
			const fileResponse = await api.postGetFileAsync(url + `?format=${Format}&monitor=${Monitor}`, request);

			// If the response is null, a filename could not be found.
			if (!fileResponse)
			{
				return thunkAPI.rejectWithValue('There was an error downloading the Exported Plan data.');
			}

			const { name, data } = fileResponse;

			// If the blob is empty, nothing to download so send an error
			if (!data.size)
			{
				return thunkAPI.rejectWithValue('There was an error downloading the Exported Plan data.');
			}

			if (window.navigator.userAgent.toLowerCase().indexOf('crios') > -1)
			{
				//iOS Chrome
				const typedBlob = new Blob([data], {type: 'application/zip'});
				const link = (window.URL || window['webkitURL']).createObjectURL(typedBlob);
				window.open(link);
			}
			else
			{
				// Create an object URL for the blob object
				const url = URL.createObjectURL(data);

				// Create a new anchor element
				const a = document.createElement('a');

				// Set the href and download attributes for the anchor element
				// You can optionally set other attributes like `title`, etc
				// Especially, if the anchor element will be attached to the DOM
				a.href = url;
				a.download = name;

				// Programmatically trigger a click on the anchor element
				// Useful if you want the download to happen automatically
				// Without attaching the anchor element to the DOM
				// Comment out this line if you don't want an automatic download of the blob content
				a.click();
			}

			return name;
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IPlantingPlanPdfRequest
{
	GrowerId: string;
	SelectedYear: string;
	CropPlans: IPlantingPlanPdfCropPlanRequest[];
}

export interface IPlantingPlanPdfCropPlanRequest
{
	CropPlanId: string;
	Fields: IPlantingPlanPdfFieldRequest[];
}

export interface IPlantingPlanPdfFieldRequest
{
	FieldId: string;
	ManagementZoneName: string;
	FileName: string;
	CropName: string;
	Hybrids: IPlantingPlanPdfHybridRequest[];
}

export interface IPlantingPlanPdfHybridRequest
{
	Hybrid: string;
	Color: string;
	AverageRate: number;
}

export const downloadVRSPdf = createTracedAsyncThunk<string, IPlantingPlanPdfRequest, { dispatch: AppDispatch; state: RootState; }>(
	'plantingPlans/downloadPdf',
	async (context, request, thunkAPI) =>
	{
		const currentState = thunkAPI.getState();
		const currentActingUser = getCurrentActingUser(currentState);
		const api = new Api('/api/4', currentState.auth.userAuthToken, context);
		
		const { GrowerId, SelectedYear } = request;

		const requestUrl = `users/${currentActingUser.UserId}/growers/${GrowerId}/years/${SelectedYear}/planting/variablerate/pdf/async`;
		const pdfSession = await api.postAsync<string>(
			requestUrl, request);
		let pdfData = undefined;
		do 
		{
			// wait half a second for each look
			await sleepOnIt(500);
			const pollUrl = `users/${currentActingUser.UserId}/growers/${GrowerId}/years/${SelectedYear}/planting/variablerate/pdf/${pdfSession.Data}`;
			pdfData = await api.getFileAsync(pollUrl);
		} while(!pdfData);

		try
		{
			const { name, data } = pdfData;

			// If the blob is empty, nothing to download so send an error
			if (!data.size)
			{
				return thunkAPI.rejectWithValue('There was an error downloading the PDF data.');
			}

			if (window.navigator.userAgent.toLowerCase().indexOf('crios') > -1)
			{//iOS Chrome
				const typedBlob = new Blob([data], {type: 'application/pdf'});
				const link = (window.URL || window['webkitURL']).createObjectURL(typedBlob);
				window.open(link);
			}
			else
			{
				// Create an object URL for the blob object
				const url = URL.createObjectURL(data);

				// Create a new anchor element
				const a = document.createElement('a');

				// Set the href and download attributes for the anchor element
				// You can optionally set other attributes like `title`, etc
				// Especially, if the anchor element will be attached to the DOM
				a.href = url;
				a.download = name;

				// Programmatically trigger a click on the anchor element
				// Useful if you want the download to happen automatically
				// Without attaching the anchor element to the DOM
				// Comment out this line if you don't want an automatic download of the blob content
				a.click();
			}

			return name;

		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);